import { ProgressMonitor } from '../progress-monitors/progress-monitor';

export const SNIPPET_DND_TYPE = 'application/arg-snippet';

export type SnippetId = string;

export interface Snippet {
    id: SnippetId;
    title: string;
    content: string;
    category: string;
    readonly: boolean;
}

export interface SnippetsRepository {
    getSnippets(progressMonitor: ProgressMonitor): Promise<Snippet[]>;
}

