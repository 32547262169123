import { MouseEvent } from 'react';

import { ArgTableColumn3 } from './arg-table3';

const HIDE_MIN_WIDTH = 32;

export function handleColumnResize<T>(
    container: HTMLElement,
    column: ArgTableColumn3<T>,
    event: MouseEvent,
    lockedColumn: boolean,
    setColumnWidth: (width: number) => void,
    hideColumn: () => void
) {
    event.preventDefault();
    //    event.stopPropagation(); // We must close popup menu

    const columnComponent = container.querySelector(`[data-column="${column.key}"]`) as HTMLElement;
    if (!columnComponent) {
        return;
    }
    const headerComponent = container.querySelector(`[data-header="${column.key}"]`) as HTMLElement;
    if (!headerComponent) {
        return;
    }

    const minWidth: number = (typeof (column.minWidth) === 'number') ? column.minWidth : 0;

    const columnParentComponent = columnComponent.parentElement as HTMLElement;
    const headerParentComponent = headerComponent.parentElement as HTMLElement;

    if (!columnParentComponent || !headerParentComponent) {
        return;
    }

    const rect = headerComponent.getBoundingClientRect();
    const parentRect = headerParentComponent.getBoundingClientRect();

    let lockedParentComponent: HTMLElement;
    let lockedParentRect: DOMRect;
    let unlockedParentComponent: HTMLElement;
    let unlockedParentRect: DOMRect;
    let unlockedParentLeft: number | undefined;
    if (lockedColumn) {
        unlockedParentComponent = container.querySelector('[data-columngroup="unlocked"]') as HTMLElement;
        unlockedParentRect = unlockedParentComponent.getBoundingClientRect();
        unlockedParentLeft = parseInt(unlockedParentComponent.style.left);
        lockedParentComponent = container.querySelector('[data-columngroup="locked"]') as HTMLElement;
        lockedParentRect = lockedParentComponent.getBoundingClientRect();
    }

    const cps: HTMLElement[] = [];
    Array.from(headerParentComponent.querySelectorAll('[data-header]')).forEach((c) => {
        if (c.getBoundingClientRect().left <= rect.left) {
            return;
        }
        cps.push(c as HTMLElement);
    });
    Array.from(columnParentComponent.querySelectorAll('[data-column]')).forEach((c) => {
        if (c.getBoundingClientRect().left <= rect.left) {
            return;
        }
        cps.push(c as HTMLElement);
    });


    const beginClientX = event.clientX;

    let hidden = false;

    function handleColumnResize(event: Event) {
        let dx = (event as any).clientX - beginClientX;

        if (rect.width + dx < minWidth) {
            dx = minWidth - rect.width;
        }

        if (rect.width + dx < HIDE_MIN_WIDTH) {
            dx = -rect.width;
            hidden = true;
        } else {
            hidden = false;
        }

        headerComponent.style.width = `${rect.width + dx}px`;
        columnComponent.style.width = `${rect.width + dx}px`;
        headerComponent.style.visibility = (hidden ? 'hidden' : 'visible');
        columnComponent.style.visibility = (hidden ? 'hidden' : 'visible');
        headerParentComponent.style.width = `${parentRect.width + dx}px`;
        columnParentComponent.style.width = `${parentRect.width + dx}px`;
        if (lockedParentComponent) {
            lockedParentComponent.style.width = `${lockedParentRect.width + dx}px`;
        }
        if (unlockedParentComponent) {
            unlockedParentComponent.style.width = `${unlockedParentRect.width - dx}px`;
            if (unlockedParentLeft) {
                unlockedParentComponent.style.left = `${unlockedParentLeft + dx}px`;
            }
        }

        cps.forEach((c) => {
            c.style.transform = `translate(${dx}px, 0)`;
        });
    }

    function handleColumnMouseUp(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        cps.forEach((c) => {
            c.style.transform = '';
        });

        window.removeEventListener('mousemove', handleColumnResize, true);
        window.removeEventListener('mouseup', handleColumnMouseUp, true);

        const newRect = headerComponent.getBoundingClientRect();

        if (!newRect.width) {
            hideColumn();

            return;
        }
        setColumnWidth(newRect.width);
    }

    window.addEventListener('mousemove', handleColumnResize, true);
    window.addEventListener('mouseup', handleColumnMouseUp, true);
}
