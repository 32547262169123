import { defineMessages, useIntl } from 'react-intl';
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef } from 'react';
import Debug from 'debug';

import { isPromise } from '../components/basic';
import { isAuthenticated } from '../utils/connector';

const debug = Debug('common:contexts:JobQuiControl');

export interface JobQuitControl {
    add: <T> (promise: Promise<T>) => Promise<T>;
}

export const JobQuitControlContext = createContext<JobQuitControl>({
    add: function <T>(promise: Promise<T>): Promise<T> {
        return promise;
    },
});

const messages = defineMessages({
    quitConfirm: {
        id: 'common.use-job-quit-control.quitConfirm',
        defaultMessage: 'Do you want to leave this page?',
    },
});

export interface JobQuitControlContextProviderProps {
    children?: ReactNode;
}

export function JobQuitControlProvider(props: JobQuitControlContextProviderProps) {
    const {
        children,
    } = props;

    const pendingJobsRef = useRef<Set<Promise<unknown>>>();
    const intl = useIntl();

    useEffect(() => {
        // Before unload handling
        function quitConfirm(event: Event) {
            debug('quitConfirm', 'count=', pendingJobsRef.current?.size);

            // If any job is pending
            if (!pendingJobsRef.current?.size) {
                return;
            }

            if (!isAuthenticated()) {
                return;
            }

            const message = intl.formatMessage(messages.quitConfirm);

            (event as any).returnValue = message;

            return message;
        }

        window.addEventListener('beforeunload', quitConfirm);

        return () => {
            window.removeEventListener('beforeunload', quitConfirm);
        };
    }, [intl]);

    const value = useMemo<JobQuitControl>(() => {
        function add<T>(promise: Promise<T>): Promise<T> {
            if (!pendingJobsRef.current) {
                pendingJobsRef.current = new Set();
            }

            // Add the job to pending jobs queue
            pendingJobsRef.current.add(promise);
            debug('AddPromise', 'count=', pendingJobsRef.current.size);

            // Run the promise and clear the pending job when done
            const newPromise = promise.finally(() => {
                pendingJobsRef.current!.delete(promise);
                debug('ReleasePromise', 'new count=', pendingJobsRef.current!.size);
            });

            return newPromise;
        }

        return {
            add,
        };
    }, []);

    return (
        <JobQuitControlContext.Provider value={value}>
            {children}
        </JobQuitControlContext.Provider>
    );
}

export function useJobQuitControl() {
    const jobQuitControl = useContext(JobQuitControlContext);

    if (jobQuitControl === undefined) {
        throw new Error('useJobQuiteControl must be used within a JobQuitControlProvider');
    }

    return jobQuitControl;
}

export function useInJobQuiControl<T>(): (run: () => (void | Promise<T>)) => Promise<T> {
    const jobQuiControl = useJobQuitControl();

    const ret = useMemo<(run: () => (void | Promise<T>)) => Promise<T>>(() => {
        return async (run: () => (void | Promise<T>)) => {
            const p = new Promise<T>(async (resolve, reject) => {
                try {
                    const ret = run();
                    if (!isPromise(ret)) {
                        return Promise.reject(undefined);
                    }

                    const pret = await ret;
                    resolve(pret);
                } catch (error) {
                    reject(error);
                }
            });

            const ret = await jobQuiControl.add(p);

            return ret;
        };
    }, [jobQuiControl]);

    return ret;
}
