export interface ExplorationPermissions {
    'exploration.create.folder': true,
    'exploration.screenshots': true,
    'exploration.export.table': true,
    'exploration.universe.settings': true,
    'exploration.import.export.settings': true,
    'exploration.brief': true,
    'exploration.export.brief': true,
    'exploration.lock.brief': true,
    'exploration.map': true,
    'exploration.graph': true,
    'exploration.table': true,
    'exploration.cart': true,
    'exploration.create.exploration': true,
    'exploration.update.exploration.style': true,
    'exploration.search': true,
}

export const EXPLORATION_PERMISSIONS_KEYS: (keyof ExplorationPermissions)[] = [
    'exploration.create.folder',
    'exploration.screenshots',
    'exploration.export.table',
    'exploration.universe.settings',
    'exploration.import.export.settings',
    'exploration.brief',
    'exploration.export.brief',
    'exploration.lock.brief',
    'exploration.map',
    'exploration.graph',
    'exploration.table',
    'exploration.cart',
    'exploration.create.exploration',
    'exploration.update.exploration.style',
    'exploration.search',
];

export const EXPLORATION_PERMISSIONS_REQUIRED = EXPLORATION_PERMISSIONS_KEYS;
