import { Fragment, ReactNode, useEffect } from 'react';
import Debug from 'debug';

const debug = Debug('basic:arg-dnd:DisableDndContainer');

const DISABLED_LISTENERS = localStorage.DISABLED_DND_CONTAINER === 'true';
if (DISABLED_LISTENERS) {
    console.warn('*** DISABLED_DND_CONTAINER is enabled');
}

export const ARG_BYPASS_DND_DISABLER_CLASSNAME = 'arg-bypass-dnd-disabler';
const bypassSelector = `.${ARG_BYPASS_DND_DISABLER_CLASSNAME}`;

interface DisableDndContainerProps {
    children: ReactNode;
    document?: Document;
}

// Cancel drop and dragover events on document. If needed you can force those events in some part of the dom by flagging them with .bypass-dnd-disabler, useful when a modal uses its own dnd mechanism
// for instance.
export function DisableDndContainer(props: DisableDndContainerProps) {
    const { children, document: myDocument } = props;

    useEffect(() => {
        if (DISABLED_LISTENERS) {
            return;
        }

        const doc = myDocument || document;

        function cancelDragOver(event: DragEvent): void {
            if (event.defaultPrevented || shouldForceDnd(event)) {
                return;
            }

            //console.log('DragOver', 'CANCEL DRAG OVER');
            //event.preventDefault();
            //event.stopPropagation();

            if (event.dataTransfer) {
                debug('DragOver', 'Types=', event.dataTransfer.types);

                event.dataTransfer.dropEffect = 'none';
            }
        }

        function cancelDrop(event: DragEvent): void {
            if (event.defaultPrevented || shouldForceDnd(event)) {
                return;
            }

            console.log('Drop', 'CANCEL DROP');

            event.preventDefault();
            event.stopPropagation();

            if (event.dataTransfer) {
                event.dataTransfer.dropEffect = 'none';
            }
        }

        /*
        function logDragStart(event: DragEvent) {
            console.log('LOG DragStart event=', event.defaultPrevented);
        }

        function logDragStartCapture(event: DragEvent) {
            console.log('LOG DragStartCapture event=', event.defaultPrevented);
        }

        function logDragEnd(event: DragEvent) {
            console.log('LOG DragEnd event=', event);
        }

        function logDragEndCapture(event: DragEvent) {
            console.log('LOG DragEndCapture event=', event);
        }
*/
        doc.addEventListener('drop', cancelDrop);
        //doc.addEventListener('dragover', cancelDragOver);

        /*
        doc.addEventListener('dragstart', logDragStart);
        doc.addEventListener('dragstart', logDragStartCapture, { capture: true });
        doc.addEventListener('dragend', logDragEnd);
        doc.addEventListener('dragend', logDragEndCapture, { capture: true });
*/
        return () => {
            doc.removeEventListener('drop', cancelDrop);
            doc.removeEventListener('dragover', cancelDragOver);
            /*
            doc.removeEventListener('dragstart', logDragStart);
            doc.removeEventListener('dragend', logDragEnd);
             */
        };
    }, [myDocument]);

    return (
        <Fragment>
            {children}
        </Fragment>
    );
}

// Check if event originate from a component that force dnd.
function shouldForceDnd(event: DragEvent) {
    if (event.target instanceof Element) {
        return event.target.closest(bypassSelector);
    }

    return false;
}
