import { ReactNode, useCallback, useRef, useState } from 'react';
import { isElement } from 'react-is';
import { MessageDescriptor, useIntl } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { computeText, renderText } from '../utils/message-descriptor-formatters';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';

import './arg-label-trimming.less';

interface ArgLabelTrimmingProps {
    className?: ClassValue;
    label?: ArgRenderedText;
    tooltip?: false | true | ReactNode;
    tooltipPlacement?: TooltipPlacement;
    tooltipMouseEnterDelay?: number;
    messageValues?: ArgMessageValues;
    children?: ReactNode;
    searchToken?: string;

    /*
     Pattern must contain  {label}  to specify where the label property will be filled
     */
    pattern?: string | MessageDescriptor,
    left?: ArgRenderedText;
    right?: ArgRenderedText;
    labelToken?: string;
}

export function ArgLabelTrimming(props: ArgLabelTrimmingProps) {
    const {
        label,
        className,
        tooltipPlacement,
        tooltipMouseEnterDelay,
        messageValues,
        children,
        searchToken,
        tooltip,
        right,
        left,
        pattern,
        labelToken = 'label',
    } = props;

    const classNames = useClassNames('arg-label-trimming');

    const intl = useIntl();

    const [tooltipVisible, setTooltipVisible] = useState<boolean>();

    const labelRef = useRef<HTMLLabelElement>(null);

    const handleTooltipOnVisible = useCallback((visible: boolean) => {
        if (!visible) {
            setTooltipVisible(false);

            return;
        }

        if (!labelRef.current) {
            setTooltipVisible(true);

            return;
        }

        if (labelRef.current.offsetWidth < labelRef.current.scrollWidth) {
            setTooltipVisible(true);

            return;
        }

        setTooltipVisible(false);
    }, []);

    let _label: ReactNode;
    if (label !== undefined) {
        _label = renderText(label, messageValues, searchToken);
    } else {
        _label = children;
    }

    _label = <label className={classNames('&-label')} ref={labelRef}>
        {_label}
    </label>;

    let _left: ReactNode = left && <label className={classNames('&-left')}>
        {renderText(left, messageValues, searchToken)}
    </label>;

    let _right: ReactNode = right && <label className={classNames('&-right')}>
        {renderText(right, messageValues, searchToken)}
    </label>;

    if (pattern && !_left && !_right) {
        const p = computeText(intl, pattern);

        if (p) {
            const reg = new RegExp(`^(.*)\{${labelToken}}(.*)$`).exec(p);
            if (reg) {
                _left = <label className={classNames('&-left')}>
                    {reg[1]}
                </label>;
                _right = <label className={classNames('&-right')}>
                    {reg[2]}
                </label>;
            }
        }
    }

    _label = <div className={classNames('&', className)}>
        {_left}
        {_label}
        {_right}
    </div>;

    if (tooltip === true || isElement(tooltip)) {
        _label = <ArgTooltip2
            className={classNames('&-tooltip')}
            title={label}
            open={tooltipVisible}
            onOpenChange={handleTooltipOnVisible}
            mouseEnterDelay={tooltipMouseEnterDelay}
            placement={tooltipPlacement}
        >
            {tooltip === true ? _label : tooltip}
        </ArgTooltip2>;
    }

    return _label;
}
