import React from 'react';
import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './arg-camera-flash.less';

const messages = defineMessages({});

interface ArgCameraFlashProps {
    className?: ClassValue;
}

export function ArgCameraFlash(props: ArgCameraFlashProps) {
    const { className } = props;

    const classNames = useClassNames('arg-camera-flash');

    return <div className={classNames('&', className)}>

    </div>;
}
