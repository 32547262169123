import { ArgLocale } from './arg-locale';

export const en_GB: ArgLocale = {
    getAntdLocale: () => import('antd/lib/locale/en_GB'),
    getNumberPolyfill: () => import('@formatjs/intl-numberformat/locale-data/en-GB'),
    getRelativeTimePolyfill: () => import('@formatjs/intl-relativetimeformat/locale-data/en-GB'),
    getDateTimePolyfill: () => import('@formatjs/intl-datetimeformat/locale-data/en-GB'),
    getPluralRulesPolyfill: () => import('@formatjs/intl-pluralrules/locale-data/en'),

    getMessages: () => import('../../locales/en.json'),

    getDayjsLocale: () => import('dayjs/locale/en-gb'),
};
