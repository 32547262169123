import { MessageDescriptor } from 'react-intl';

export function isMessageDescriptor(message: any): message is MessageDescriptor {
    if (!message) {
        return false;
    }

    if (typeof (message) === 'object' && (message as MessageDescriptor).id && (message as MessageDescriptor).defaultMessage !== undefined) {
        return true;
    }

    return false;
}

export function toMessageDescriptor(message: any): MessageDescriptor {
    if (isMessageDescriptor(message)) {
        return message as MessageDescriptor;
    }

    throw new Error('Message is not a message descriptor');
}
