import React, { ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import { isBoolean, isFunction } from 'lodash';

import { ArgToolItemRenderFunction, ArgToolItemRenderWithContextFunction } from './arg-toolbar-item';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue } from '../arg-hooks/use-classNames';
import { ArgButtonType, ArgRenderedIcon, ArgRenderedText } from '../types';
import { TooltipPlacement } from '../arg-tooltip/utils';

export type ToolType =
    | 'label'
    | 'button'
    | 'combo'
    | 'group'
    | 'custom'
    | 'panel'
    | 'editor'
    | 'marker'
    | 'separator'
    | 'menu'
    ;

export interface Tool {
    key?: React.Key;
    path: string; // Unique
    type?: ToolType;
    selected?: boolean;
    visible?: boolean | (() => boolean);
    disabled?: boolean | (() => boolean);
    loading?: boolean;
    order?: number;
    testid?: string;
    override?: number; // Tools with higher override number takes precedence

    computeChildren?: (parentTool: Tool, progressMonitor: ProgressMonitor) => Promise<Tool[]>;

    tooltip?: ReactNode | MessageDescriptor | ((props: Record<string, any>) => ReactNode | MessageDescriptor);
    tooltipPlacement?: TooltipPlacement;

    icon?: ArgRenderedIcon;
    label?: ArgRenderedText;
    // messageValues?: ArgMessageValues;
    description?: ArgRenderedText;
    //componentType?: ComponentType;
    buttonType?: ArgButtonType;

    customRender?: ArgToolItemRenderWithContextFunction;
    menuItemCustomRender?: boolean;

    preventCloseMenuOnClick?: boolean;

    panelRender?: ArgToolItemRenderFunction;

    onClick?: (props: Tool, event?: ButtonClickEvent) => void;
    onShiftClick?: (props: Tool, event: ButtonClickEvent) => void;
    onCtrlClick?: (props: Tool, event: ButtonClickEvent) => void;
    onAltClick?: (props: Tool, event: ButtonClickEvent) => void;
    onMouseOver?: (event: ButtonClickEvent) => void;

    keyBinding?: KeyBindingDescriptor;
    shiftKeyBinding?: KeyBindingDescriptor;
    altKeyBinding?: KeyBindingDescriptor;
    ctrlKeyBinding?: KeyBindingDescriptor;

    className?: ClassValue;
    menuClassName?: ClassValue;

    onUnmount?: () => void;
}

export type ToolChanges = Omit<Tool, 'path'>;

export function isToolDisabled(tool: Tool): boolean {
    const { disabled } = tool;
    if (isBoolean(disabled)) {
        return disabled;
    }
    if (isFunction(disabled)) {
        return disabled();
    }

    return false;
}

export function isToolVisible(tool: Tool): boolean {
    const { visible } = tool;
    if (isBoolean(visible)) {
        return visible;
    }
    if (isFunction(visible)) {
        return visible();
    }

    return true;
}
