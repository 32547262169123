import React, {
    CSSProperties, ReactNode, useCallback,
} from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ScrollDisplayManager } from '../../../utils/scroll-display-manager';
import {
    ArgTable4AdditionalRow, ArgTable4AdditionalRowGen,
} from './arg-table4';

import './additional-rows-container.less';

interface AdditionalRowsContainerProps {
    startNode: number;
    visibleNodeCount: number;
    globalScrollTop?: number;
    scrollTop?: number;
    totalHeight: number;
    scrollDisplayManager: ScrollDisplayManager;
    bodyRef?: React.MutableRefObject<HTMLDivElement | null>;
    className?: ClassValue;
    additionalRows: ArgTable4AdditionalRow[];
    additionalRowsContent: Map<number, ReactNode>;
    additionalHeaderHeight?: number;
}

export function AdditionalRowsContainer({
    startNode,
    visibleNodeCount,
    scrollTop,
    totalHeight,
    bodyRef,
    additionalRows,
    additionalRowsContent,
    scrollDisplayManager,
}: AdditionalRowsContainerProps) {
    const classNames = useClassNames('arg-table4-additional-rows-container');

    const visibleAdditionRows = scrollDisplayManager.getVisibleAdditionalRows(startNode, visibleNodeCount, additionalRows);

    const containerBodyStyle: CSSProperties = {
        top: (scrollTop ?? 0) * -1,
        height: totalHeight,
    };

    const handleBodyRef = useCallback((ref: HTMLDivElement | null) => {
        if (bodyRef) {
            bodyRef.current = ref;
        }
    }, [bodyRef]);

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-body')} ref={handleBodyRef} style={containerBodyStyle}>
                {visibleAdditionRows.map((row) => {
                    const { key, style, index } = row;

                    return (
                        <div key={key} className={classNames('&-additional-row')} style={style}>
                            {additionalRowsContent.get(index)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
