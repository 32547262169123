import React, { ReactNode, useCallback, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ToolTreeNode } from './tool-context';
import { ArgFilteredMenu } from '../arg-menu/arg-filtered-menu';
import { isToolDisabled, isToolVisible, Tool } from './tool';
import { ArgChangeReason } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgMenuItemDivider } from '../arg-menu/arg-menu-item-divider';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgSubMenu } from '../arg-menu/arg-sub-menu';

type ArgToolbarComboProps = ToolTreeNode

export function ArgToolbarCombo(props: ArgToolbarComboProps) {
    const {
        className,
        icon,
        label,
        children,
        tooltip,
        keyBinding,
        onClick,
        disabled,
        tooltipPlacement,
    } = props;

    const classNames = useClassNames('arg-toolbar-combo');

    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const handleToolClick = useCallback((tool: Tool, reason: ArgChangeReason, event?: ButtonClickEvent) => {
        setPopoverVisible(false);

        onClick?.(tool, event);

        tool.onClick?.(tool, event);
    }, [onClick]);


    const computeItemLabel = useCallback((toolItem: Tool) => {
        const { label, keyBinding } = toolItem;
        if (label !== undefined) {
            return renderText(label);
        }

        if (keyBinding) {
            return keyBinding.name;
        }

        return undefined;
    }, []);

    const computeMenu = useCallback(() => {
        const _children = children?.filter((child) => {
            if (!isToolVisible(child)) {
                return false;
            }

            return true;
        });

        if (!_children?.length) {
            return null;
        }

        const _menuItems: ReactNode[] = [];

        let needSeparator = false;

        function renderMenuItems(menuItems: ReactNode[], children: ToolTreeNode[]) {
            children.forEach((node: ToolTreeNode) => {
                const { path, icon, type } = node;

                if (type === 'separator') {
                    menuItems.push(<ArgMenuItemDivider key={path} />);
                    needSeparator = false;

                    return;
                }

                if (type === 'group') {
                    if (node.children?.length) {
                        if (needSeparator) {
                            menuItems.push(<ArgMenuItemDivider key={path} />);
                        }
                        renderMenuItems(menuItems, node.children);
                        needSeparator = true;
                    }

                    return;
                }

                const _disabled = isToolDisabled(node);
                const _label = computeItemLabel(node);

                if (type === 'combo' && node.children?.length) {
                    needSeparator = true;

                    const subMenuItems: ReactNode[] = [];
                    renderMenuItems(subMenuItems, node.children);

                    const sub = <ArgSubMenu
                        key={path}
                        className={classNames('&-menu-item', '&-submenu')}
                        popupClassName={classNames('&-submenu-popup')}
                        disabled={_disabled}
                        label={_label}
                        icon={icon}
                    >
                        {subMenuItems}
                    </ArgSubMenu>;

                    menuItems.push(sub);

                    return;
                }

                if (type !== 'button') {
                    console.error('*** Unsupported type=', type, 'in menuItem for tool=', node);

                    return;
                }

                needSeparator = true;
                const selected = node.selected;
                const menuItem = <ArgMenuItem
                    key={path}
                    id={path}
                    className={classNames('&-menu-item', { selected })}
                    disabled={_disabled}
                    label={_label}
                    icon={icon}
                    onClick={(event) => handleToolClick(node, 'selection', event)}
                />;

                menuItems.push(menuItem);
            });
        }

        renderMenuItems(_menuItems, _children);

        return <ArgMenu className={classNames('&-menu')}>
            {_menuItems}
        </ArgMenu>;
    }, [children, classNames, computeItemLabel, handleToolClick]);

    let _tooltip: MessageDescriptor | React.ReactNode = null;
    if (tooltip === undefined && keyBinding) {
        _tooltip = keyBinding.name;
    } else if (typeof tooltip === 'function') {
        _tooltip = tooltip(props);
    } else {
        _tooltip = tooltip;
    }

    const _disabled = (disabled === true) || (typeof (disabled) === 'function' && disabled() === true);

    const _children = children?.filter((child) => {
        if (!isToolVisible(child)) {
            return false;
        }

        return true;
    });

    if (!_children?.length) {
        return null;
    }

    return <ArgButton
        className={classNames('&', className, 'arg-toolbar-item')}
        icon={icon}
        label={label}
        tooltip={_tooltip}
        popover={computeMenu}
        popoverPlacement='bottomLeft'
        disabled={_disabled}
        type='ghost'
        size='medium'
        right={icon !== 'icon-options' ? 'dropdown' : undefined}
        tooltipClassName={classNames('&-tooltip')}
        keyBinding={keyBinding}
        popoverVisible={popoverVisible}
        popoverClassName={classNames('&-popover')}
        onPopoverVisibleChange={setPopoverVisible}
        tooltipPlacement={tooltipPlacement}
        data-testid='toolbar-combo-button'
    />;
}
