import React from 'react';
import classNames from 'classnames';

import { ClassValue } from '../arg-hooks/use-classNames';
import { getDataTestIdFromProps } from '../utils';

import './three-dots-loading.less';

export interface ThreeDotLoadingProps {
    char?: string;
    className?: ClassValue;
}

export const DEFAULT_CHAR = '.';

export const ThreeDotsLoading: React.FunctionComponent<ThreeDotLoadingProps> = (props) => {
    const { className, char = DEFAULT_CHAR } = props;
    const dataTestId = getDataTestIdFromProps(props);

    return (
        <span
            data-testid={dataTestId}
            className={classNames('arg-threeDotsLoading', className)}
        >
            <span className='dot1'>{char}</span>
            <span className='dot2'>{char}</span>
            <span className='dot3'>{char}</span>
        </span>
    );
};
