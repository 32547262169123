export interface AdministrationPermissions {
    'admin.synchronization.management': true,
    'admin.synchronization.edition': true,
    'admin.synchronization.execution': true,
    'admin.environments': true,
}

export const ADMIN_PERMISSIONS_KEYS: (keyof AdministrationPermissions)[] = [
    'admin.synchronization.management',
    'admin.synchronization.edition',
    'admin.synchronization.execution',
    'admin.environments',
];

export const ADMIN_PERMISSIONS_REQUIRED = ADMIN_PERMISSIONS_KEYS;
