export const EXPLORATION_ROOT_URL = '/exploration';
export const PREPARATION_ROOT_URL = '/preparation';
export const COLLECT_ROOT_URL = '/collect';
export const SETTINGS_ROOT_URL = '/settings';
export const ADMINISTRATION_ROOT_URL = '/admin';
export const PROCEO_ROOT_URL = '/proceo';

export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
