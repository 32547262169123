import React, { ReactNode } from 'react';
import { isFunction } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { Tool } from './tool';
import { ArgRenderedText } from '../types';
import { ArgPopoverRenderer } from '../arg-popover/types';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';

import './arg-toolbar-label.less';

export interface ArgToolBarLabelRenderContext {
    onCloseMenu?(): void;

    menuItemClassName?: ClassValue;
}

export type ArgToolBarLabelRenderWithContextFunction = (props: Tool, context: ArgToolBarLabelRenderContext, popoverRender?: ArgPopoverRenderer) => ReactNode;

export interface ArgToolBarLabelProps {
    label: ArgRenderedText;
    testid?: string;
    tooltip?: boolean | ArgRenderedText;
    customRender?: ArgToolBarLabelRenderWithContextFunction;
    visible?: boolean | (() => boolean);
    className?: ClassValue;
}

export function ArgToolBarLabel(props: ArgToolBarLabelProps) {
    const {
        testid,
        customRender,
        visible = true,
        tooltip,
        label,
        className,
    } = props;

    const classNames = useClassNames('arg-toolbar-label');

    const toolProps = props as Tool;

    if (visible === false || (isFunction(visible) && visible() === false)) {
        return null;
    }

    if (isFunction(customRender)) {
        const context = {};

        return <>
            {customRender(toolProps, context)}
        </>;
    }

    let body = (
        <ArgMessageRenderer
            message={label}
            data-testid={testid}
            className={classNames('&', className)}
        />
    );

    if (tooltip) {
        body = (
            <ArgTooltip2 title={tooltip}>
                {body}
            </ArgTooltip2>
        );
    }

    return body;
}
