import { useCallback, useEffect, useRef } from 'react';

export type ImmediateDebounceEngine = (func: () => void) => void;

export type CancelImmediateDebounce = () => void;

const DEFAULT_DEBOUNCE_MS = 250;

export function useImmediateDebounce(debounceMs = DEFAULT_DEBOUNCE_MS): [ImmediateDebounceEngine, CancelImmediateDebounce] {
    const previousDateRef = useRef<number>(0);
    const timerIdRef = useRef<ReturnType<typeof setTimeout>>();
    const unmountedRef = useRef<boolean>(false);

    useEffect(() => {
        return () => {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current);
            }
            unmountedRef.current = true;
        };
    }, []);

    const cancel = useCallback(() => {
        if (!timerIdRef.current) {
            return;
        }
        clearTimeout(timerIdRef.current);
        timerIdRef.current = undefined;
    }, []);


    const immediateDebounce = useCallback((callback: () => void) => {
        const date = Date.now();
        const previous = previousDateRef.current;

        if (previous && date - previous >= debounceMs) {
            previousDateRef.current = date;
            if (unmountedRef.current) {
                return;
            }
            callback();

            return;
        }

        if (timerIdRef.current) {
            return;
        }

        timerIdRef.current = setTimeout(() => {
            timerIdRef.current = undefined;

            previousDateRef.current = Date.now();
            if (unmountedRef.current) {
                return;
            }
            callback();
        }, debounceMs);
    }, [debounceMs]);

    return [immediateDebounce, cancel];
}
