import { defineMessages } from 'react-intl';

import { NavItem } from '../models/navigation';

export const CONTEXTUAL_VARIABLES_PATH = '/settings/contextual-variables';

export const messages = defineMessages({
    contextualVariables: {
        id: 'settings.contextual-variables.menu.title',
        defaultMessage: 'Contextual variables',
    },
});


export const contextualVariablesNavItem: NavItem[] = [
    {
        path: CONTEXTUAL_VARIABLES_PATH,
        label: messages.contextualVariables,
        icon: 'icon-contextual-variable',
        iconSize: 22,
        children: [],
    },
];
