import { isEmpty } from 'lodash';

import { DetailedWebHook } from '../models/detailed-webhooks';
import { RetentionPolicy, RetentionPolicyAction } from '../universes/retention/types';
import { mapDate } from 'src/utils/connectors/mappers';
import { ValuationPolicy } from '../models/valuation-policy';
import { DetailedWebHookDTO, Policy, Role } from '../models/dtoApi';
import { ExternalComponent, ExternalComponentDto, ExternalComponentType } from '../models/external-component';
import { Group } from '../../model/user';
import { UserInputType, UserProfileField } from '../../model/user-metadata';
import { FormPolicy } from '../models/form-policy';
import { UserProfileFieldRaw } from './raws';
import { RuleSet } from 'src/components/common/graph/customisation/graph-style';
import {
    FullOntologyItemStyle,
    OntologyItemStyle,
    OntologyLinkType,
    OntologyObjectType,
} from '../universes/ontology/types';


function mapAction(result: any): RetentionPolicyAction {
    /*Temporary wiating for the API to send a correct response in camel case and without underscores in target and from indices */
    const action = {
        description: result.description,
        target: {
            kind: result.target._kind,
            type: result.target._type,
        },
        effects: result.effects,
    };

    return action;
}

function mapReverseAction(action: RetentionPolicyAction): any {
    /*Temporary waiting for the API to send a correct response in camel case and without underscores in target and from indices */
    const reversedAction = {
        description: action.description,
        target: {
            _kind: action.target.kind,
            _type: action.target.type,
        },
        effects: action.effects,
    };

    return reversedAction;
}

export function mapRetentionPolicy(result: any): RetentionPolicy {
    const retensionPolicy = {
        createdDate: mapDate(result.createdDate),
        createdBy: result.createdBy,
        lastUpdatedDate: mapDate(result.lastUpdatedDate),
        lastUpdatedBy: result.lastUpdatedBy,
        type: result.type,

        actions: result.actions.map(mapAction),
    };

    return retensionPolicy;
}

export function mapReverseRetentionPolicy(retentionPolicy: RetentionPolicy): any {
    const reversedRetentionPolicy = {
        type: retentionPolicy.type,
        actions: retentionPolicy.actions.map(mapReverseAction),
    };

    return reversedRetentionPolicy;
}

export function mapDetailedWebhook(rawDetailedWebhook: DetailedWebHookDTO): DetailedWebHook {
    const webhook: DetailedWebHook = {
        ...rawDetailedWebhook,
        createdDate: mapDate(rawDetailedWebhook.createdDate),
        lastUpdatedDate: mapDate(rawDetailedWebhook.lastUpdatedDate),
    };

    return webhook;
}

export function mapValuationPolicy(rawValuationPolicy: any): ValuationPolicy {
    const valuationPolicy: ValuationPolicy = {
        ...rawValuationPolicy,
        createdDate: mapDate(rawValuationPolicy.createdDate),
        lastUpdatedDate: mapDate(rawValuationPolicy.lastUpdatedDate),
        lastPublishedDate: mapDate(rawValuationPolicy.lastPublishedDate),
    };

    return valuationPolicy;
}

export function mapFormPolicy(rawFormPolicy: any) {
    const valuationPolicy: FormPolicy = {
        ...rawFormPolicy,
        createdDate: mapDate(rawFormPolicy.createdDate),
        lastUpdatedDate: mapDate(rawFormPolicy.lastUpdatedDate),
        lastPublishedDate: mapDate(rawFormPolicy.lastPublishedDate),
    };

    return valuationPolicy;
}

export function mapExternalComponentType(rawType: number): ExternalComponentType {
    switch (rawType) {
        case 0:
            return ExternalComponentType.Connector;
        case 1:
            return ExternalComponentType.Module;
        case 2:
            return ExternalComponentType.Injector;
        default:
            return ExternalComponentType.Invalid;
    }
}

export function mapExternalComponent(rawExternalComponent: ExternalComponentDto): ExternalComponent {
    const externalComponent: ExternalComponent = {
        ...rawExternalComponent,
        getDefinitionUrl: rawExternalComponent.remoteComponentURL,
        type: mapExternalComponentType(rawExternalComponent.remoteComponentType),
        status: rawExternalComponent.remoteComponentStatus,
        lastUpdatedDate: mapDate(rawExternalComponent.lastUpdatedDate),
    };

    return externalComponent;
}

export function mapRole(rawRole: any): Role {
    const role: Role = {
        ...rawRole,
        publishDate: mapDate(rawRole.publishDate),
    };

    return role;
}

export function mapPolicy(rawPolicy: any): Policy {
    const policy: Policy = {
        ...rawPolicy,
        lastPublishedDate: mapDate(rawPolicy.lastPublishedDate),
        createdDate: mapDate(rawPolicy.createdDate),
    };

    return policy;
}

export function mapGroup(rawGroup: any): Group {
    const group: Group = {
        ...rawGroup,
        createdDate: mapDate(rawGroup.createdDate),
    };

    return group;
}

export function mapUserProfileField(raw: UserProfileFieldRaw): UserProfileField {
    const ret: UserProfileField = {
        ...raw,
        createdDate: mapDate(raw.createdDate),
        lastUpdatedDate: mapDate(raw.lastUpdatedDate),
        inputMode: mapInputMode(raw.isMultivalued, raw.possibleValues),
        type: raw.customDataType ? raw.customDataType : raw.baseType,
    };

    return ret;
}

export function mapInputMode(isMultivalued?: boolean, possibleValues?: string[]): UserInputType {
    if (!possibleValues) {
        return UserInputType.freeInput;
    }

    if (!isMultivalued) {
        return UserInputType.singleSelect;
    }

    return UserInputType.multiSelect;
}

/**
 * Add 'isUndefinedRuleSet' flag if ruleSet have empty condition
 */
function mapOntologyRuleSets(ruleSet: Record<string, RuleSet[]>) {
    const newRuleSets = Object.entries(ruleSet).reduce((acc, [property, rules]) => {
        const newRules = rules.map((rule) => ({
            ...rule,
            isUndefinedRuleSet: isEmpty(rule.condition),
        }));

        acc[property] = newRules;

        return acc;
    }, {} as Record<string, RuleSet[]>);

    return newRuleSets;
}

export function mapOntologyObjectsStyle(
    objectTypes: (OntologyObjectType | OntologyLinkType)[],
    styles: Record<string, OntologyItemStyle>
): Record<string, OntologyItemStyle> {
    const objectTypesWithStyle = objectTypes.reduce((acc, object) => {
        const objectStyle = styles[object.name];

        const newRuleSets = mapOntologyRuleSets(objectStyle?.ruleSets || {});

        acc[object.name] = { ...objectStyle, ruleSets: newRuleSets };

        return acc;
    }, {} as Record<string, OntologyItemStyle>);

    return objectTypesWithStyle;
}

export function mapObjectsWithStyle<T extends OntologyObjectType | OntologyLinkType>(
    objectTypes: T[],
    objectsStyle: Record<string, OntologyItemStyle>
): (T & { style: FullOntologyItemStyle })[] {
    const stylesRecord = objectTypes.map((objectType) => {
        const objectStyle = objectsStyle[objectType.name];

        const objectWithStyle = {
            ...objectType,
            style: {
                ...objectStyle?.userDefinedContent,
                ruleSets: objectStyle?.ruleSets,
                titleProperty: objectStyle.titleProperty,
            },
        };

        return objectWithStyle;
    });

    return stylesRecord;
}
