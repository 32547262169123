import React, { ReactNode } from 'react';

import { KeyBindingDescriptor, KeyBindingHandler as Handler } from './keybinding';
import { useKeyBinding } from './use-keybinding';


interface KeyBindingHandlerProps {
    children: ReactNode;

    keyBinding: KeyBindingDescriptor;
    onKeyHandler: Handler;
    enabled?: boolean;
}

export function KeyBindingHandler(props: KeyBindingHandlerProps) {
    const {
        children,
        keyBinding,
        onKeyHandler,
        enabled,
    } = props;

    useKeyBinding(keyBinding, onKeyHandler, enabled);

    return <>
        {children}
    </>;
}
