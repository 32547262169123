import { isFunction, isString } from 'lodash';
import { useCallback, useState } from 'react';

import { ClassValue } from '../arg-hooks/use-classNames';
import { renderText } from '../utils/message-descriptor-formatters';
import { TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { ArgMessageValues } from '../types';
import { ArgPopoverProps, OffsetValue } from './arg-popover';
import { isIn } from '../utils/is-in';
import { ArgPopoverController, ArgPopoverRenderer, TriggerType } from './types';

const NO_TRIGGERS: TriggerType[] = [];
const CLICK_TRIGGERS: TriggerType[] = ['click'];

export interface ArgComponentPopoverProps {
    popover?: ArgPopoverRenderer;
    popoverPlacement?: TooltipPlacement;
    popoverTrigger?: TriggerType | TriggerType[] | false;
    popoverVisible?: boolean;
    popoverClassName?: ClassValue;
    popoverEnterDelay?: number;
    onPopoverVisibleChange?: (visible: boolean) => void;
    popoverOffset?: OffsetValue;
    disabled?: boolean;
    className?: ClassValue;
    popoverFitWidth?: boolean;
    messageValues?: ArgMessageValues;
}

export interface ReturnType {
    hasPopover: boolean;
    popoverVisible: boolean;
    getPopoverProps: () => ArgPopoverProps;
    handlePopoverVisibleChange: (visible: boolean) => void;
}

// Return an array with the popover triggers. Triggers default to ['click'] when props has a popover.
export function getPopoverTriggerAsArray(props: ArgComponentPopoverProps): TriggerType[] {
    if (isIn(props, 'popoverTrigger')) {
        if (Array.isArray(props.popoverTrigger)) {
            return props.popoverTrigger;
        }
        if (isString(props.popoverTrigger)) {
            return [props.popoverTrigger];
        }

        return NO_TRIGGERS;
    }

    if (isIn(props, 'popover')) {
        return CLICK_TRIGGERS;
    }

    return NO_TRIGGERS;
}

// A hook that map props from ArgComponentPopoverProps into props suitable for the ArgPopover component.
//
// NOTE: This is advanced stuff and you generally don't need this. If you're looking for how to add a popover to a component; arg-popover is what your looking for.
//
// This is a helper hook that helps with extending a component with some extra `popover...` properties to make it easy for caller to add popover, very much in the spirit of what arg-button or
// arg-input do.
//
export function usePopover(props: ArgComponentPopoverProps): ReturnType {
    const {
        disabled = false,
        popover,
        popoverPlacement,
        onPopoverVisibleChange,
        popoverOffset,
        popoverClassName,
        popoverFitWidth,
        messageValues,
    } = props;

    const hasPopover = popover !== undefined;

    const useInternalPopoverVisible = !('popoverVisible' in props);

    const [internalPopoverVisible, setInternalPopoverVisible] = useState<boolean>(false);

    const popoverTrigger = getPopoverTriggerAsArray(props);

    let popoverVisible = false;
    if (hasPopover && disabled !== true) {
        if (useInternalPopoverVisible) {
            popoverVisible = internalPopoverVisible;
        } else {
            popoverVisible = !!props.popoverVisible;
        }
    }

    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        setInternalPopoverVisible(visible);

        onPopoverVisibleChange?.(visible);
    }, [onPopoverVisibleChange, setInternalPopoverVisible]);

    const contentCallback = useCallback((ctl: ArgPopoverController) => (
        renderText(isFunction(popover) ? popover(ctl) : popover, messageValues)
    ), [popover, messageValues]);

    const getPopoverProps = () => {
        const popoverProps: ArgPopoverProps = {
            content: contentCallback,
            className: popoverClassName,
            placement: popoverPlacement,
            onOpenChange: handlePopoverVisibleChange,
            trigger: popoverTrigger,
            offset: popoverOffset,
            fitWidth: popoverFitWidth,
        };
        if (popoverVisible !== undefined) {
            popoverProps.open = popoverVisible;
            if (popoverVisible && !popoverProps.trigger) {
                popoverProps.trigger = 'click';
            }
        }

        if (popoverVisible !== undefined) {
            popoverProps.open = popoverVisible;
        }

        return popoverProps;
    };

    return {
        hasPopover,
        popoverVisible,
        getPopoverProps,
        handlePopoverVisibleChange,
    };
}
