import React, { ReactNode, SyntheticEvent, useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton } from '../arg-button/arg-button';
import { useClamp } from 'src/hooks/use-clamp';

import './arg-clamped-label.less';

const CLASSNAME = 'arg-clamped-label';
const messages = defineMessages({
    seeMore: {
        id: 'basic.arg-clamped-label.read-more-label.SeeMore',
        defaultMessage: 'See more',
    },
    seeLess: {
        id: 'basic.arg-clamped-label.read-more-label.SeeLess',
        defaultMessage: 'See less',
    },
});

const ROW_HEIGHT = 17;
const ROWS_COUNT = 2;

export interface ArgClampedLabelProps {
    className?: ClassValue;
    children: ReactNode;
    rowHeight?: number;
    rowsCount?: number;
}

export function ArgClampedLabel(props: ArgClampedLabelProps) {
    const { className, children, rowsCount = ROWS_COUNT, rowHeight = ROW_HEIGHT } = props;
    const classNames = useClassNames(CLASSNAME);
    const [clamp, clampToggle, commentContainerRef] = useClamp<HTMLDivElement>(rowsCount, rowHeight);

    const handleClickMoreOrLess = useCallback((evt: SyntheticEvent) => {
        evt.preventDefault();

        clampToggle();
    }, [clampToggle]);

    const cls = {
        'is-expanded': !clamp,
    };

    return (
        <div ref={commentContainerRef} className={classNames('&', className, cls)} data-testid='arg-clamped-label'>
            {children}
            {
                clamp !== undefined && (
                    <span className={classNames('&-btn')}>
                        {
                            clamp && (<span>...</span>)
                        }
                        <ArgButton
                            type='ghost'
                            label={!clamp ? messages.seeLess : messages.seeMore}
                            onClick={handleClickMoreOrLess}
                        />
                    </span>
                )
            }
        </div>
    );
}
