import { isArray } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { useSetTimeout } from '../arg-hooks/use-set-timeout';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { MESSAGE_DESCRIPTOR_FORMATTERS, renderText } from '../utils/message-descriptor-formatters';

import './arg-object-tooltip.less';


let SHOW_BODY_TIMER_MS = 1000 * 1;
if (localStorage.TOOLTIP_BODY_TIMER_MS) {
    SHOW_BODY_TIMER_MS = parseInt(localStorage.TOOLTIP_BODY_TIMER_MS);
}

const messages = defineMessages({
    noProperties: {
        id: 'basic.arg-object-tooltip.NoProperties',
        defaultMessage: 'No properties',
    },
    noName: {
        id: 'basic.arg-object-tooltip.NoName',
        defaultMessage: '<italic>No name</italic>',
    },
});

export type TooltipFormat = 'short' | 'full';

export interface ArgObjectTooltipProperty {
    name: ArgRenderedText;
    value: ArgRenderedText | (ArgRenderedText) [];
    className?: ClassValue;
}

interface ArgObjectTooltipProps {
    className?: ClassValue;

    title: ArgRenderedText;
    description?: ArgRenderedText;

    properties: ArgObjectTooltipProperty[];
    hideEmptyValues?: boolean;

    messageValues?: ArgMessageValues;

    left?: ArgRenderedText;
    right?: ArgRenderedText;

    tooltipFormat?: TooltipFormat;
}

export function ArgObjectTooltip(props: ArgObjectTooltipProps) {
    const {
        title,
        description,
        properties,
        className,
        messageValues,
        hideEmptyValues,
        left,
        right,
    } = props;

    const classNames = useClassNames('arg-object-tooltip');

    const [showBody, setShowBody] = useState(false);
    const showBodyTimer = useSetTimeout(SHOW_BODY_TIMER_MS);

    useEffect(() => {
        showBodyTimer(() => {
            setShowBody(true);
        });
    }, []);

    let body: ReactNode | ReactNode[];
    let loading: ReactNode;

    if (showBody) {
        body = properties.map((property, index) => {
            const propertyName = renderText(property.name, messageValues);

            let _propertyValue: ReactNode | ReactNode[] = null;
            const propertyValue = property.value;
            if (isArray(propertyValue)) {
                _propertyValue = propertyValue.map((propertyValueItem) => {
                    return renderText(propertyValueItem, messageValues);
                });
            } else {
                _propertyValue = renderText(propertyValue, messageValues);
            }

            if (hideEmptyValues && !_propertyValue) {
                return null;
            }

            return <li key={index} className={classNames(property.className)}>
                <span className={classNames('&-name')}>{propertyName}</span>
                {' : '}
                <span className={classNames('&-value', 'clamp-2')}>{_propertyValue}</span>
            </li>;
        }).filter((body) => (body));

        if (!body || !(body as ReactNode[]).length) {
            body = <div key='no-properties' className={classNames('&-no-properties')}>
                <FormattedMessage {...messages.noProperties} values={MESSAGE_DESCRIPTOR_FORMATTERS} />
            </div>;
        } else {
            body = <ul className={classNames('&-properties')}>
                {body}
            </ul>;
        }

        body = <div className={classNames('&-body')}>
            {renderText(left)}
            {body}
            {renderText(right)}
        </div>;
    } else {
        loading = null; //<ThreeDotsLoading className={classNames('&-loading')}/>;
    }

    const cls = {
        'no-title': !title,
        'no-description': !description,
        'no-properties': !body && showBody,
        loading: !showBody,
    };

    const tooltip = <div className={classNames('&', cls, className)}>
        {title && <div className={classNames('&-title', 'clamp-2')}>
            {renderText(title, messageValues)} {loading}
        </div>}
        {description && <div className={classNames('&-description', 'clamp-2')}>
            {renderText(description, messageValues)}
        </div>}
        {body}
    </div>;

    return tooltip;
}
