/**
 * Allows to render a modal off the react tree. This free callers from
 * managing modal visibility and also simplifies passing argument to
 * the modal.
 *
 * USAGE:
 *  const { open } = useArgModalManager((close) => <MyModal user={...} close={close}/>);
 *  return <div onClick={() => open()}/>;
 *
 *  // passing argument
 *  const { open } = useArgModalManager((close, mode) => <MyModal mode={mode} .../>);
 *  return (
 *    <>
 *      <div onClick={() => open('foo')}/>
 *      <div onClick={() => open('bar')}/>
 *    </>
 *   );
 *
 * TODO: add support for openAndWaitClose similar to ./arg-modal-container.tsx
 */
import { noop, pull } from 'lodash';
import { DependencyList, RefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { createContext, Dispatch, ReactNode, useState } from 'react';

const ModalContainerContext = createContext<ArgModalContainerContext>({
    modalsRef: { current: [] },
    update: noop,
});

const ModalContext = createContext<ArgModalManagerContext<any>>({
    open() {},
    close() {},
});

interface Modal<P extends any[] = any> {
    element?: ReactNode;
    render(...args: P): ReactNode;
    args?: P;
    opened?: boolean;
    close(): void;
    open(...args: P): void;
}

interface ArgModalContainerContext {
    modalsRef: RefObject<Modal[]>;
    update: Dispatch<void>;
}


export function ArgModalManagerContainer(props: { children: ReactNode }) {
    const { children } = props;
    const modalsRef = useRef<Modal[]>([]);
    const modals = modalsRef.current;
    const [_stateId, setStateId] = useState(0);
    const update = useCallback(() => {
        setStateId(prev => (prev + 1) % Number.MAX_VALUE);
    }, []);

    const value = useMemo(() => ({ modalsRef, update }), [update]);

    return (
        <ModalContainerContext.Provider value={value}>
            {children}
            {modals && Array.from(modals).map((modal, index) => {
                const { opened, render, args, element } = modal;

                return opened && (
                    <ModalContext.Provider value={modal} key={index}>
                        {element || render(...args)}
                    </ModalContext.Provider>
                );
            })}
        </ModalContainerContext.Provider>
    );
}

export interface ArgModalManagerContext<P extends any[]> {
    open(...args: P): void;
    close(): void;
}

export function useArgModalManager<P extends any[]>(render: (close: () => void, ...args: P) => ReactNode, deps?: DependencyList): ArgModalManagerContext<P> {
    const { modalsRef: modalsListRef, update } = useContext(ModalContainerContext);
    const modalRef = useRef<Modal>();
    if (!modalRef.current) {
        modalRef.current = {
            render,
            open(...args: P) {
                if (!modalRef.current) {
                    return;
                }

                const render = modalRef.current.render;
                modalRef.current.args = args;
                modalRef.current.opened = true;
                modalRef.current.element = render(modalRef.current.close, ...args);
                update();
            },
            close() {
                if (!modalRef.current) {
                    return;
                }

                modalRef.current.opened = false;
                update();
            },
        };
    }

    useEffect(() => {
        if (!modalRef.current) {
            return;
        }

        const { args = [] } = modalRef.current;
        modalRef.current.render = render;
        if (modalRef.current.opened) {
            modalRef.current.element = render(modalRef.current.close, ...args);
        }
        update();
    }, deps);

    useEffect(() => {
        if (!modalRef.current || !modalsListRef.current) {
            return;
        }
        const modalsList = modalsListRef.current!;
        const modal = modalRef.current;

        // add modal
        modalsList.push(modal);
        update();

        return () => {
            // remove modal
            pull(modalsList, modal);
            update();
        };
    }, []);

    return modalRef.current;
}
