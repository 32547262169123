import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { KeyBindingDescriptor } from './keybinding';
import { KeyBindingKeys } from './keybinding-keys';

import './keybinding-tooltip-content.less';

const CLASSNAME = 'keybinding-tooltip-content';

interface KeyBindingTooltipContentProps {
    tooltip: ArgRenderedText;
    keyBinding?: KeyBindingDescriptor;
    className?: ClassValue;
    messageValues?: ArgMessageValues;
}

export function KeyBindingTooltipContent(props: KeyBindingTooltipContentProps) {
    const {
        tooltip,
        keyBinding,
        className,
        messageValues,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    if (!keyBinding) {
        return (
            <ArgMessageRenderer
                className={classNames('&', className)}
                message={tooltip}
                messageValues={messageValues}
            />
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-message')}>
                <ArgMessageRenderer
                    message={tooltip}
                    messageValues={messageValues}
                />
            </div>
            <KeyBindingKeys
                keyBindingDescriptor={keyBinding}
                className={classNames('&-keybinding')}
            />
        </div>
    );
}
