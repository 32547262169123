import { defaultTo } from 'lodash';

export const stringSorter = <T>(a: T, b: T, getItemValueToSort: ((item: T) => string | undefined)) => {
    const itemValueToSortA = getItemValueToSort(a);
    const itemValueToSortB = getItemValueToSort(b);

    return defaultTo(itemValueToSortA, '').localeCompare(defaultTo(itemValueToSortB, ''));
};

export const dateSorter = <T>(a: T, b: T, getItemValueToSort: ((item: T) => Date | undefined)) => {
    const itemValueToSortA = getItemValueToSort(a)?.getTime();
    const itemValueToSortB = getItemValueToSort(b)?.getTime();

    return numberSorter<number | undefined>(itemValueToSortA, itemValueToSortB, item => item);
};

export const numberSorter = <T>(a: T, b: T, getItemValueToSort: ((item: T) => number | undefined)) => {
    const itemValueToSortA = defaultTo(getItemValueToSort(a), 0);
    const itemValueToSortB = defaultTo(getItemValueToSort(b), 0);

    return Math.sign(itemValueToSortA - itemValueToSortB);
};


export const booleanSorter = <T>(a: T, b: T, getItemValueToSort: ((item: T) => boolean | undefined)) => {
    const itemValueToSortA = getItemValueToSort(a);
    const itemValueToSortB = getItemValueToSort(b);

    if (itemValueToSortA === itemValueToSortB) {
        return 0;
    }

    if (itemValueToSortB) {
        return 1;
    }

    return -1;
};
