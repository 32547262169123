import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useGetApplicationDescriptor } from 'src/components/application/use-get-application-descriptor';
import { useArgI18n } from 'src/i18n/arg-locales/use-arg-i18n';
import { IntlContextProvider } from './intl-provider';
import { ApplicationSessionIdProvider } from 'src/contexts/application-session-context';
import { AntdConfigProvider } from './antd-provider';
import { UserLocaleProvider } from 'src/contexts/user-locale-context';
import { JobQuitControlProvider } from 'src/contexts/job-quit-control-context';
import {
    ArgNotificationsProvider,
    ArgPageProvider,
    ArgUploaderProvider,
    DisableDndContainer,
    GlobalProgressProvider,
    useClassNames,
} from 'src/components/basic';
import { Environment } from 'src/utils/environment';
import { CheckVersion } from 'src/components/common/check-version';
import { BasicPane } from 'src/components/common/panes/basic-pane';

import 'src/styles/application.less';

const DEFAULT_FAVICON = '/favicon.ico';
const FORCE_LOADING = false;

interface AppGlobalProviderProps {
    children: ReactNode;
}

export function AppGlobalProvider(props: AppGlobalProviderProps) {
    const {
        children,
    } = props;

    const {
        userLocale,
        messages,
        userLocaleConfig,
        antdLocale,
    } = useArgI18n();

    const classNames = useClassNames('arg-application');

    const [applicationDescriptor] = useGetApplicationDescriptor('Application');

    if (FORCE_LOADING || !userLocale || messages === undefined) {
        return (
            <div className={classNames('&', 'loading')}>
                <BasicPane className='fill' backgroundAnimation='wave' icon='icon-loading' />
            </div>
        );
    }

    return (
        <BrowserRouter>
            <IntlContextProvider messages={messages} userLocale={userLocale}>
                <ApplicationSessionIdProvider>
                    <AntdConfigProvider antdLocale={antdLocale}>
                        <UserLocaleProvider userLocaleConfig={userLocaleConfig}>
                            <JobQuitControlProvider>
                                <ArgPageProvider
                                    pageTitle={applicationDescriptor?.applicationName || Environment.appName}
                                    iconURL={applicationDescriptor?.iconURL || DEFAULT_FAVICON}
                                    subTitlePosition='start'
                                >
                                    <DisableDndContainer>
                                        <ArgNotificationsProvider>
                                            {Environment.checkVersionMs > 0 &&
                                                <CheckVersion intervalMs={Environment.checkVersionMs} />}
                                            <ArgUploaderProvider>
                                                <GlobalProgressProvider>
                                                    {children}
                                                </GlobalProgressProvider>
                                            </ArgUploaderProvider>
                                        </ArgNotificationsProvider>
                                    </DisableDndContainer>
                                </ArgPageProvider>
                            </JobQuitControlProvider>
                        </UserLocaleProvider>
                    </AntdConfigProvider>
                </ApplicationSessionIdProvider>
            </IntlContextProvider>
        </BrowserRouter>
    );
}
