import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { OnErrorFn } from '@formatjs/intl';

interface IntlContextProviderProps {
    userLocale: string;
    messages: Record<string, string> | null | undefined;

    children: ReactNode;
}

export function IntlContextProvider(props: IntlContextProviderProps) {
    const {
        userLocale,
        messages,
        children,
    } = props;

    return (
        <IntlProvider
            key={userLocale}
            locale={userLocale}
            messages={messages || undefined}
            onError={onIntlProviderError}
        >
            {children}
        </IntlProvider>
    );
}

const onIntlProviderError: OnErrorFn = (error) => {
    // Do nothing (prevent console.error logs when translations are missing)
    if (localStorage?.SHOW_MISSING_TRANSLATION_ERROR) {
        console.error(error);
    }
};
