import React from 'react';
import classnames from 'classnames';

import { ClassValue } from '../basic';

import './chapsvision-background.less';

interface BackgroundProps {
    className?: ClassValue;
    animation?: string;
}

export const Background: React.FunctionComponent<BackgroundProps> = ({ className, animation }) => {
    const cls: { [key: string]: boolean } = {};
    if (animation) {
        cls[`animation-${animation}`] = true;
    }

    return <svg width='145' height='142' viewBox='-2.5 0 145 160' fill='none' xmlns='http://www.w3.org/2000/svg' className={classnames('arg-chapsvision-background', className, cls)}>
        <rect id='rect1' width='64.599' height='64.599'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 115.045 85.1587)' fill='currentColor' />
        <rect id='rect2' width='41.534' height='41.534'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 123.65 29.2408)' fill='currentColor' />
        <rect id='rect3' width='28.8172' height='28.8172'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 145 101.479)' fill='currentColor' />
        <rect id='rect4' width='29.0125' height='29.0125'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 41.208 61.9364)' fill='currentColor' />
        <rect id='rect5' width='12.8386' height='12.8386'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 65.6494 36.5094)' fill='currentColor' />
        <rect id='rect6' width='12.8386' height='12.8386'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 26.2373 92.6708)' fill='currentColor' />
        <rect id='rect7' width='12.8386' height='12.8386'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 109.989 114.037)' fill='currentColor' />
        <rect id='rect8' width='12.8386' height='12.8386'
              transform='matrix(-0.710179 0.704021 -0.710179 -0.704021 66.8828 132.961)' fill='currentColor' />
    </svg>;
};
