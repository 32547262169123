import { useCallback, useContext } from 'react';
import { defineMessages } from 'react-intl';

import { UseConfigurationReturnType, useConfiguration, useConfigurations } from './use-configuration';
import { UserConfigurations, UserConfigurationsContext } from '../contexts/user-configurations';
import { ConfigurationPath, ProgressMonitor, useArgNotifications } from '../components/basic';
import { UsersSettingsConnector } from '../utils/connectors/users-settings-connector';
import { useUserSettingsState } from '../utils/rt-states/users/use-user-settings';

const messages = defineMessages({
    loadUserAppSettingsError: {
        id: 'common.user-app-settings.LoadUserAppSettingsError',
        defaultMessage: 'Failed to load user app settings',
    },
    updateUserAppSettingsError: {
        id: 'common.user-app-settings.updateUserAppSettingsError',
        defaultMessage: 'Failed to update user app settings',
    },
});

export function useUserConfiguration<T>(
    path: ConfigurationPath,
    initialConfiguration: T | (() => T)
): UseConfigurationReturnType<T> {
    // Whole configurations object
    const configurations = useContext(UserConfigurationsContext);
    if (!configurations) {
        throw new Error('UserConfigurationContext is not defined');
    }

    return useConfiguration(configurations, path, initialConfiguration);
}

export function useUserConfigurations(usersConnector: UsersSettingsConnector):
    readonly [UserConfigurations | undefined, ProgressMonitor | undefined, Error | undefined] {
    const notifications = useArgNotifications();

    const stateObject = useUserSettingsState();

    const onFetchError = useCallback((error: Error) => {
        notifications.snackError({ message: messages.loadUserAppSettingsError }, error);
    }, [notifications]);

    const onSaveError = useCallback((error: Error) => {
        notifications.snackError({ message: messages.updateUserAppSettingsError }, error);
    }, [notifications]);

    const newUserConfigurations = useCallback(() => {
        const configurations = new UserConfigurations(usersConnector, onFetchError, onSaveError);

        return configurations;
    }, [onFetchError, onSaveError, usersConnector]);

    const ret = useConfigurations(newUserConfigurations, stateObject);

    return ret;
}
