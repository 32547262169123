import { cloneElement, createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import { omit } from 'lodash';

import { $yield } from '../utils/yield';
import { ArgModalManagerContainer } from './use-arg-modal-manager';

interface ArgModalContainerProps {
    children: ReactNode;
}

export interface ArgModalContext {
    open: (name: string, modal: ReactElement) => void;
    close: (name: string) => void;

    isOpened: (name: string) => boolean;

    openAndWaitClose: (name: string, modal: ReactElement) => Promise<void>;
}

interface MyModal {
    element: ReactElement;
    resolve?: (returnValue?: any) => void;
}

export const ArgModalContainerContext = createContext<ArgModalContext>({
    open: (name: string, modal: ReactElement): void => {
    },
    close: (name: string, returnValue?: any): void => {
    },
    openAndWaitClose: async (name: string, modal: ReactElement): Promise<any> => {
    },
    isOpened: (name: string) => {
        return false;
    },
});

export function ArgModalContainer(props: ArgModalContainerProps) {
    const { children } = props;

    const [modals, setModals] = useState<Record<string, MyModal>>({});

    const addModal = useMemo<ArgModalContext>(() => {
        const open = (name: string, modal: ReactElement) => {
            const newModal = cloneElement(modal, { key: name });

            setModals((prev) => {
                return {
                    ...prev || {},
                    [name]: { element: newModal },
                };
            });
        };
        const close = (name: string, returnValue?: any) => {
            setModals((prev) => {
                const myModal = prev[name];
                if (!myModal) {
                    throw new Error(`Modal name="${name}" is unknown`);
                }

                const resolve = myModal.resolve;
                if (resolve) {
                    $yield(() => {
                        resolve(returnValue);
                    });
                }

                const ret = omit(prev, name);

                return ret;
            });
        };

        const openAndWaitClose = async (name: string, modal: ReactElement): Promise<any> => {
            const promise = new Promise<any>((resolve, reject) => {
                const newModal = cloneElement(modal, { key: name });

                setModals((prev) => {
                    return {
                        ...prev || {},
                        [name]: { element: newModal, resolve },
                    };
                });
            });

            return promise;
        };

        const isOpened = (name: string) => {
            return !!modals[name];
        };

        return { open, close, openAndWaitClose, isOpened };
    }, [modals]);

    return (
        <ArgModalManagerContainer>
            <ArgModalContainerContext.Provider value={addModal}>
                {children}
                {modals && Object.values(modals).map((modal) => modal.element)}
            </ArgModalContainerContext.Provider>
        </ArgModalManagerContainer>
    );
}


export function useArgModalContext(): ArgModalContext {
    const modalContext = useContext(ArgModalContainerContext);

    return modalContext;
}
