export type RtMessageEventList = Record<string, true>;

export const USER_PROFILE_EVENT_NAMES: RtMessageEventList = {
    'UserUpdated': true,
};

export const USER_SETTINGS_EVENT_NAMES: RtMessageEventList = {
    'UserSettingsUpdated': true,
};

export const USER_CONNECTION_EVENT_NAMES: RtMessageEventList = {
    'UserConnected': true,
    'UserDisconnected': true,
};

export const USER_ALL_EVENTS = {
    ...USER_PROFILE_EVENT_NAMES,
    ...USER_SETTINGS_EVENT_NAMES,
    ...USER_CONNECTION_EVENT_NAMES,
};
