export enum SyncWorkerMessage {
    SelectionChanged,
    UpdateRepository,
    ExplorationWindowOpened,
    ExplorationWindowClosed,
    Disconnect,
    RequestScreenshots,
    ScreenshotGenerated,
    CloseExplorationWindowByName,
    FocusExplorationWindowByName,
}
