import { ArgLocale } from './arg-locale';

export const fr_FR: ArgLocale = {
    getAntdLocale: () => import('antd/lib/locale/fr_FR'),
    getNumberPolyfill: () => import('@formatjs/intl-numberformat/locale-data/fr'),
    getRelativeTimePolyfill: () => import('@formatjs/intl-relativetimeformat/locale-data/fr'),
    getDateTimePolyfill: () => import('@formatjs/intl-datetimeformat/locale-data/fr'),
    getPluralRulesPolyfill: () => import('@formatjs/intl-pluralrules/locale-data/fr'),

    getMessages: () => import('../../locales/fr.json'),

    getDayjsLocale: () => import('dayjs/locale/fr'),

    // @ts-ignore
    getCKEditorLocale: () => import('@ckeditor/ckeditor5-build-balloon/build/translations/fr.js'),
};
