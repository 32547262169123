import React, { MouseEventHandler, ReactNode, useCallback } from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-drawer.less';

type ArgDrawerSize = 'small' | 'medium' | 'large' | 'xlarge';
type ArgDrawerPlacement = 'left' | 'right'

interface ArgDrawerProps {
    children: ReactNode;

    title: ArgRenderedText;
    titleMessageValues?: ArgMessageValues;

    placement?: ArgDrawerPlacement;
    className?: ClassValue;
    bodyClassName?: ClassValue;

    size?: ArgDrawerSize;
    hideMask?: boolean;

    onBlur?: () => void;
}

const SIZES_VALUES = {
    small: {
        width: 300,
    },
    medium: {
        width: 500,
    },
    large: { width: 700 },
    xlarge: { width: 900 },
};
export const ArgDrawer = (props: ArgDrawerProps) => {
    const {
        size = 'medium',
        title,
        children,
        className,
        titleMessageValues,
        placement = 'right',
        hideMask = false,
        onBlur,
        bodyClassName,
    } = props;

    const classNames = useClassNames('arg-drawer');

    const handlePreventDefault: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const cls = {
        left: placement === 'left',
        right: placement === 'right',
    };

    return (
        <div className={classNames('&', className)} onClick={onBlur}>
            {!hideMask && (
                <div className={classNames('&-mask')} />
            )}

            <div
                style={{ ...SIZES_VALUES[size] }}
                className={classNames('&-body', bodyClassName, cls)}
                onClick={handlePreventDefault}
            >
                <span className={classNames('&-body-title')}>
                    {renderText(title, titleMessageValues)}
                </span>
                <div className={classNames('&-body-content')}>
                    {children}
                </div>
            </div>
        </div>
    );
};
