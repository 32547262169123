import React, { ErrorInfo, ReactNode } from 'react';

export interface ArgErrorCatcherProps {
    renderError: (error: Error, errorInfo?: ErrorInfo) => ReactNode;
    children?: ReactNode;
}

interface ArgErrorCatcherState {
    error?: Error;
    errorInfo?: ErrorInfo;
    errorComponent?: ReactNode;
}

export class ArgErrorCatcher extends React.Component<ArgErrorCatcherProps, ArgErrorCatcherState> {
    state: ArgErrorCatcherState = {};

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const errorComponent = this.props.renderError(error, errorInfo);
        this.setState({ errorComponent, error, errorInfo });
    }

    render() {
        const { children } = this.props;
        const { errorComponent } = this.state;

        if (errorComponent) {
            return errorComponent;
        }

        return children;
    }
}
