import { RtBasicState, RtStateMessage } from '../rt-basic-state';
import { WebSocketChannel, WebSocketConnectionChannel } from '../../../components/ws/websocket-connector';
import { ArgUserId } from '../../../components/basic';
import { RtMessageEventList, USER_ALL_EVENTS, USER_PROFILE_EVENT_NAMES } from '../events';
import { RtApi, RtApiSubType } from '../rt-api';

export type UserStateMessage = RtStateMessage;

export class UserState extends RtBasicState<UserStateMessage> {
    readonly #userId: ArgUserId;
    readonly #api: RtApi;
    readonly #acceptedEvents: RtMessageEventList;

    constructor(api: RtApi, url: string, userId: ArgUserId, acceptedEvents: RtMessageEventList = USER_PROFILE_EVENT_NAMES) {
        super(url);

        if (api.subType !== RtApiSubType.Users) {
            throw new Error(`Invalid subType: ${api.subType}`);
        }

        this.#api = api;
        this.#userId = userId;
        this.#acceptedEvents = acceptedEvents;
    }

    protected getRtMessageEventList(): RtMessageEventList {
        return USER_ALL_EVENTS;
    }

    protected getRtApi(): RtApi {
        return this.#api;
    }

    protected get keepChannelOpened() {
        return true;
    }

    get userId(): ArgUserId {
        return this.#userId;
    }

    async connected(channel: WebSocketConnectionChannel<UserStateMessage>): Promise<void> {
        await super.connected(channel);

        if (!channel.link(`watch:${this.#userId}`)) {
            return;
        }

        await channel.connection.invoke('Watch', this.#userId);
    }

    async disconnecting(channel: WebSocketConnectionChannel<UserStateMessage>): Promise<void> {
        if (channel.unlink(`watch:${this.#userId}`)) {
            await channel.connection.invoke('Unwatch', this.#userId);
        }

        await super.disconnecting(channel);
    }

    processMessage = async (channel: WebSocketChannel<UserStateMessage>, type: string, message: UserStateMessage): Promise<boolean | undefined> => {
        const { userId } = message.messageContent;

        if (userId !== this.#userId) {
            // Ignore message
            return true;
        }

        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        this.processUserMessage(message);

        // Process Message
        return false;
    };

    protected processUserMessage(message: UserStateMessage): void {

    }
}
