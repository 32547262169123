import React, { ReactNode, RefAttributes, useCallback, useRef } from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { getDataTestIdFromProps } from '../utils';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText, ArgSize } from '../types';
import {
    DEFAULT_SIZE,
    DEFAULT_TOOLTIP_PLACEMENT,
} from '../defaults';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { renderText } from '../utils/message-descriptor-formatters';
import { renderIcon } from '../arg-icon/arg-icon';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { ArgComponentPopoverProps, usePopover } from '../arg-popover/use-popover';
import { ArgPopover } from '../arg-popover/arg-popover';

import './arg-tag.less';

const DEFAULT_TAG_POPOVER_PLACEMENT = 'bottomRight';

export interface ArgTagProps extends ArgComponentPopoverProps {
    size: ArgSize;
    label?: ArgRenderedText;
    className?: ClassValue;
    onClick?: (event: React.MouseEvent<any>) => void;
    onClose?: (event: ButtonClickEvent) => void;
    loading?: boolean;
    icon?: ArgRenderedIcon;
    backgroundColor?: string;
    maxWidth?: number;
    closeIcon?: ArgRenderedIcon;
    closable?: boolean;
    children?: ReactNode;
    hidden?: boolean;
    messageValues?: ArgMessageValues;

    dropdown?: boolean;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    disabled?: boolean;
    iconColor?: string;
}

export function ArgTag(props: ArgTagProps & RefAttributes<HTMLDivElement>) {
    const {
        className,
        icon,
        iconColor,
        backgroundColor,
        maxWidth,
        label,
        children,
        hidden,
        messageValues,
        onClose,
        onClick,
        closable,
        closeIcon,
        dropdown,
        size = DEFAULT_SIZE,
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        popover,
        disabled,
    } = props;

    const htmlTagRef = useRef<HTMLDivElement | null>(null);

    const dataTestId = getDataTestIdFromProps(props);

    const _closable = ('closable' in props) ? closable : onClose;

    const hasIcon = icon;

    const classNames = useClassNames('arg-tag');

    const handleOnClose = useCallback((event: ButtonClickEvent) => {
        if (disabled) {
            return;
        }

        // Does not trigger onClick & avoid propagation to popover
        event.stopPropagation();

        onClose?.(event);
    }, [disabled, onClose]);

    const handleOnClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        if (disabled) {
            return;
        }

        // Event come from div (not ArgButton) so it need to be prevented manually
        event.preventDefault();

        onClick?.(event);
    }, [disabled, onClick]);

    const {
        popoverVisible,
        handlePopoverVisibleChange,
        getPopoverProps,
    } = usePopover({
        ...props,
    });

    if (hidden) {
        return null;
    }

    const _label = (label !== undefined) ? label : children;
    const labelNode = renderText(_label, messageValues);

    if (labelNode === undefined && icon === undefined) {
        throw new Error('Label or icon must be specified');
    }

    const cls = {
        [`size-${size}`]: true,
        'has-icon': hasIcon,
        'closable': _closable,
        'has-click': onClick,
        'disabled': disabled,
        dropdown: dropdown,
    };

    let tag: React.ReactNode = <div
        className={classNames('&', cls, className)}
        ref={htmlTagRef}
        onClick={handleOnClick}
        data-testid={dataTestId}
        style={{ backgroundColor: backgroundColor, maxWidth: maxWidth }}
    >
        {/* Icon */}
        {hasIcon && <span className={classNames('&-icon-container')}>
            {renderIcon(icon, classNames('&-icon'), iconColor)}
        </span>}

        {/* Label */}
        {labelNode && <span className={classNames('&-label')}>
            {labelNode}
        </span>}

        {dropdown &&
            <ArgButton
                key='dropdown'
                icon='icon-triangle-down'
                type='ghost'
                className={classNames('&-dropdown')}
                onClick={() => handlePopoverVisibleChange(!popoverVisible)}
                disabled={disabled}
            />
        }

        {/* Close icon */}
        {_closable && (
            <ArgButton
                key='close'
                type='ghost'
                icon={closeIcon || 'icon-cross'}
                tabIndex={-1}
                className={classNames('&-close')}
                onClick={handleOnClose}
                data-testid='arg-tag-close'
                disabled={disabled}
            />
        )}
    </div>;


    const tooltipContent = (tooltip === true) ? (labelNode) : tooltip;
    if (tooltipContent) {
        tag = (
            <ArgTooltip2
                className={classNames('&-tooltip', 'arg-tooltip', tooltipClassName)}
                title={tooltipContent}
                messageValues={messageValues}
                data-testid='tooltip'
                placement={tooltipPlacement || DEFAULT_TOOLTIP_PLACEMENT}
            >
                {tag}
            </ArgTooltip2>
        );
    }
    if (popover) {
        tag = (
            <ArgPopover
                {...getPopoverProps()}
                placement={props.popoverPlacement || DEFAULT_TAG_POPOVER_PLACEMENT}>
                {tag}
            </ArgPopover>
        );
    }

    return tag;
}
