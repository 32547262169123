import { defineMessages, FormattedMessage } from 'react-intl';
import React, { useContext, useEffect, useRef, useState, VideoHTMLAttributes } from 'react';

import connector from '../../../utils/connector';
import { getDataTestIdFromProps } from '../utils';
import { useClassNames } from '../arg-hooks/use-classNames';
import { useProgressMonitor } from '../progress-monitors/use-progress-monitor';
import { ArgResourcesContext } from '../arg-image/arg-resources-context';

import './arg-video.less';

export interface ArgVideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
    type: string;
    src: string;
}

const messages = defineMessages({
    unsupportedType: {
        id: 'basic.arg-video.Unsupported-mime-codec',
        defaultMessage: 'Unsupported MIME type or codec: {type}',
    },
});

export const ArgVideo: React.FunctionComponent<ArgVideoProps> = (props) => {
    const {
        type,
        src,
        className,
        ...restProps
    } = props;

    const isMounted = useRef<boolean>();

    const [url, setURL] = useState<string | undefined>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-video');

    const [progressMonitor, createProgressMonitor] = useProgressMonitor();

    const resourcesContext = useContext(ArgResourcesContext);

    useEffect(() => {
        setURL(undefined);
        setLoaded(false);
        setHasError(false);

        const api = resourcesContext.getServerApi();

        if (src && api) {
            // Check if src is a remote api address that should be fetched
            const shouldFetch = src.startsWith(api);

            if (shouldFetch) {
                const progressMonitor = createProgressMonitor('Fetch image', 1);

                connector.request(src, { api }, progressMonitor).then(async (response) => {
                    setURL(URL.createObjectURL(response));
                }).catch(() => {
                    if (progressMonitor.isCancelled) {
                        return;
                    }

                    setHasError(true);
                    setURL(undefined);
                }).finally(() => {
                    progressMonitor.done();
                });
            } else {
                setURL(src);
            }
        }
    }, [src]);

    // Manage the mounting of the component
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);


    const cls = {
        'has-error': hasError,
        'is-loading': progressMonitor?.isRunning,
        loaded,
    };

    return (
        <div className={classNames('&', className, cls)} data-testid={dataTestId}>
            <video {...restProps}
                   className={classNames('&-video')}
                   autoPlay={true}
                   muted={true}
                   height='100%'
                   width='100%'
                   onError={() => isMounted.current && setHasError(true)}
            >

                {/* Video src */}
                {url && (
                    <source src={url} />
                )}

                {/* Not supported message */}
                <FormattedMessage {...messages.unsupportedType} values={{ type }} />
            </video>
        </div>
    );
};
