import React, { useCallback, useEffect, useContext } from 'react';
import { Col, Row, Typography } from 'antd';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
    ArgButton,
    ArgImage,
    ArgPageConfig,
    GLOBAL_PM,
    isMessageDescriptor,
    ProgressMonitor,
    ThreeDotsLoading,
    useCallbackAsync,
    useClassNames,
    useSetTimeout,
} from '../../basic';
import { Environment } from '../../../utils/environment';
import { LoginForm } from './login-form';
import { computePageAccess, PageToTest } from '../../caches/pages-access';
import { computeOAuthLoginURL } from '../../../utils/connector';
import { ApplicationDescriptorContext } from '../../application/application-descriptor';
import {
    getAdministrationApi,
    getDataCollectionApi,
    getDataExplorationApi,
    getDataPreparationApi,
    getProceoApi,
    getSettingsApi,
} from '../../../utils/connectors/api-url';
import { COLLECT_ROOT_URL, EXPLORATION_ROOT_URL, PREPARATION_ROOT_URL, PROCEO_ROOT_URL } from 'src/components/containers/routes-urls';
import { MODULES } from 'src/components/application/modules';

import './login.less';

const messages = defineMessages({
    continueButton: {
        id: 'common.login.ContinueButton',
        defaultMessage: 'Continue',
    },
    welcomeLabel: {
        id: 'common.login.welcomeLabel',
        defaultMessage: 'Welcome',
    },
    pageWelcomeTitle: {
        id: 'common.login.pageWelcomeTitle',
        defaultMessage: 'Welcome',
    },
});

export function Login() {
    const classNames = useClassNames('login-page');

    const handleContinue = useCallback(async (progressMonitor: ProgressMonitor) => {
        const pagesToTest = listPageAccess();

        const pageAccess = await computePageAccess(pagesToTest, progressMonitor);
        if (pageAccess) {
            document.location.replace(pageAccess);

            return;
        }

        let redirectTo = `${EXPLORATION_ROOT_URL}/folders`;
        if (Environment.redirectTo) {
            redirectTo = Environment.redirectTo;
        } else if (MODULES.DataExploration.enabled) {
            redirectTo = `${EXPLORATION_ROOT_URL}/folders`;
        } else if (MODULES.DataPreparation.enabled) {
            redirectTo = `${PREPARATION_ROOT_URL}/folders`;
        } else if (MODULES.Administration.enabled) {
            redirectTo = '/admin';
        } else if (MODULES.DataCollect.enabled) {
            redirectTo = '/collect';
        } else if (MODULES.Proceo.enabled) {
            redirectTo = '/proceo';
        }

        document.location.replace(redirectTo);
    }, []);

    const [handleContinueOAuth] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        await handleContinue(progressMonitor);
    }, [handleContinue], undefined, undefined, GLOBAL_PM);

    const [handleTimerOAuth] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const pageAccess = await computePageAccess(listPageAccess(), progressMonitor);
        if (pageAccess) {
            document.location.replace(pageAccess);

            return;
        }

        if (Environment.redirectTo) {
            document.location.replace(Environment.redirectTo);

            return;
        }

        const loginUrl = computeOAuthLoginURL(document.location.origin);
        document.location.replace(loginUrl);
    }, [], undefined, undefined, GLOBAL_PM);

    // Should the user be redirected to folders or cases
    const shouldRedirectToFolders = Environment.redirectTo === '/folders';
    const shouldRedirectToAdmin = Environment.redirectTo === '/admin';

    const timerCallback = useSetTimeout(1000);

    useEffect(() => {
        if (!Environment.apiOAuth) {
            return;
        }

        timerCallback(handleTimerOAuth);
    }, []);

    let body;
    if (Environment.apiOAuth) {
        body = <>
            {/* Api login with OAuth */}
            <div className={classNames('&-oauth')}>
                <FormattedMessage {...messages.welcomeLabel} />

                <ThreeDotsLoading />
            </div>
            <ArgButton
                hidden={true}
                data-testid='login-button'
                size='large'
                htmlType='submit'
                className={classNames('&-form-item', '&-form-submit-button')}
                label={messages.continueButton}
                onClick={handleContinueOAuth}
                autoFocus={true}
            />
        </>;
    } else {
        body = <LoginForm onContinue={handleContinue} />;
    }

    const applicationDescriptor = useContext(ApplicationDescriptorContext);
    const applicationName: string = isMessageDescriptor(applicationDescriptor.applicationName)
        ? String(applicationDescriptor.applicationName.defaultMessage)
        : (applicationDescriptor.applicationName as string)
    ;

    return <ArgPageConfig pageSubTitle={messages.pageWelcomeTitle}>
        <div className={classNames('&')}>
            <div className={classNames('&-left')}>
                <div className={classNames('&-left-content')}>
                    {!localStorage.ANONYMIZE && <ArgImage
                        src={applicationDescriptor.logoURL}
                        alt={applicationName}
                        placeholder={false}
                        className={classNames('&-left-logo')}
                    />}
                    {body}
                </div>
            </div>

            <div className={classNames('&-right')}>
                <div className={classNames('&-right-background')}>
                    <div className={classNames('&-right-background-light-diamond')} />
                    <div className={classNames('&-right-background-dark-diamond')} />
                    <div className={classNames('&-right-background-cut-diamond')} />
                </div>
                <Row className={classNames('&-right-content')} justify='center' align='middle'>
                    <Col span={13} className={classNames('&-right-content-container')}>
                        <Typography.Title className={classNames('&-right-content-title')}>
                            {shouldRedirectToFolders
                                ? <FormattedMessage
                                    id='preparation.login.Title'
                                    defaultMessage='Data Preparation Module'
                                />

                                : shouldRedirectToAdmin
                                    ? <FormattedMessage
                                        id='settings.login.Title'
                                        defaultMessage='Your admin platform'
                                    />
                                    : <FormattedMessage
                                        id='exploration.login.Title'
                                        defaultMessage='Data Exploration Module'
                                    />
                            }
                        </Typography.Title>
                        <Typography.Text className={classNames('&-right-content-text')}>
                            {shouldRedirectToFolders
                                ? <FormattedMessage
                                    id='preparation.login.Explanation'
                                    defaultMessage='Log into the platform to define how your data will be extracted, transformed and enriched'
                                />
                                : shouldRedirectToAdmin
                                    ? <FormattedMessage
                                        id='settings.login.Explanation'
                                        defaultMessage='Log into the platform'
                                    />
                                    : <FormattedMessage
                                        id='exploration.login.Explanation'
                                        defaultMessage='Log into the platform in order to get insight on
                                        your data and create value out of it, through relevant searches and appealing
                                        visualizations.'
                                    />
                            }
                        </Typography.Text>
                    </Col>
                </Row>
            </div>
        </div>
    </ArgPageConfig>;
}

function listPageAccess(): PageToTest[] {
    const support: PageToTest[] = [];

    if (MODULES.Administration.enabled) {
        support.push({
            api: getAdministrationApi()!,
            testURL: '/../ontologies',
            gotoURL: '/admin',
        });
    }

    if (MODULES.Settings.enabled) {
        support.push({
            api: getSettingsApi()!,
            testURL: '/../ontologies',
            gotoURL: '/settings',
        });
    }

    if (MODULES.DataExploration.enabled) {
        support.push({
            api: getDataExplorationApi()!,
            testURL: '/cases',
            gotoURL: EXPLORATION_ROOT_URL,
        });
    }

    if (MODULES.DataPreparation.enabled) {
        support.push({
            api: getDataPreparationApi()!,
            testURL: '/cases',
            gotoURL: PREPARATION_ROOT_URL,
        });
    }

    if (MODULES.DataCollect.enabled) {
        support.push({
            api: getDataCollectionApi()!,
            testURL: '/cases',
            gotoURL: COLLECT_ROOT_URL,
        });
    }

    if (MODULES.Proceo.enabled) {
        support.push({
            api: getProceoApi()!,
            testURL: '/cases',
            gotoURL: PROCEO_ROOT_URL,
        });
    }

    return support;
}
