import { DragEvent, Key, ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { DndAction } from './droppable';

import './drop-list.less';

interface ShadowInfo<K> {
    shadowIndex: number;
    shadowComponent: ReactElement;
    shadowInfo?: K;
}

const ANIMATION_TIMEOUT = 500;

export const DROP_INDEX_ATTRIBUTE_NAME = 'data-drop-index';

interface DropListProps<T, K> {
    className?: ClassValue;
    list: T[];

    getItemKey: (item: T, index: number) => Key;
    renderItem: (item: T, index: number, className: ClassValue) => ReactNode;
    renderShadow?: (index: number, className: ClassValue, dragInfo?: K) => ReactNode;

    onDrop?: (event: DragEvent, dragInfo?: K) => void;
    canDragEnter?: (event: DragEvent) => K | undefined;
    canDragLeave?: (event: DragEvent, dragInfo?: K) => boolean;

    dndActions?: DndAction;

    defaultDropEffect?: 'none' | 'copy' | 'link' | 'move';

    dragItemKey?: Key;

    ignoreLastPositionDrop?: boolean;
    ignoreFirstPositionDrop?: boolean;
}


export function DropList<T, K = any>(props: DropListProps<T, K>) {
    const {
        className,
        list,
        getItemKey,
        renderItem,
        renderShadow,
        onDrop,
        canDragEnter,
        canDragLeave,
        defaultDropEffect = 'move',
        dndActions,
        dragItemKey,
        ignoreLastPositionDrop,
        ignoreFirstPositionDrop,
    } = props;

    const classNames = useClassNames('arg-drop-list');

    const bodyRef = useRef<HTMLDivElement>(null);

    const [shadow, setShadow] = useState<ShadowInfo<K>>();

    const itemClassName = classNames('&-item');

    const items: ReactElement[] = list.map((item: T, index: number) => {
        const key = getItemKey(item, index);

        const $itemClassName = itemClassName;
        if (dragItemKey === key) {
            //$itemClassName += ' dragging';
        }

        const component = renderItem(item, index, $itemClassName);

        return <CSSTransition
            key={key}
            timeout={ANIMATION_TIMEOUT}
            classNames={classNames('&-anim')}
        >
            {component}
        </CSSTransition>;
    });

    if (shadow) {
        items.splice(shadow.shadowIndex, 0, shadow.shadowComponent);
    }

    const processDragDrop = useCallback((event: DragEvent, shadowInfo?: K) => {
        const steps = (bodyRef.current as any as HTMLDivElement).querySelectorAll(`.${itemClassName}`);
        let shadowIndex: number | undefined = undefined;
        let indexWithoutShadow = 0;

        const shadowClassName = classNames('&-item-shadow');
        for (let i = 0; i < steps.length; i++) {
            const step = steps[i];

            const rect = step.getBoundingClientRect();

            if (step.classList.contains(shadowClassName)) {
                continue;
            }
            if (rect.top + rect.height / 2 > event.clientY) {
                shadowIndex = indexWithoutShadow;
                break;
            }
            indexWithoutShadow++;
        }

        if (shadowIndex === undefined && !ignoreLastPositionDrop) {
            shadowIndex = indexWithoutShadow;
        }

        if (shadowIndex === 0 && ignoreFirstPositionDrop) {
            shadowIndex = undefined;
        }

        setShadow((prev: ShadowInfo<K> | undefined) => {
            if (shadowIndex === prev?.shadowIndex) {
                return prev!;
            }

            if (shadowIndex === undefined) {
                return undefined;
            }

            //            console.log('NEW SHADOW INDEX=', shadowIndex);

            let component: ReactNode;
            if (renderShadow) {
                component = renderShadow(shadowIndex!, classNames(itemClassName, shadowClassName), shadowInfo);
            } else {
                component = <div className={classNames('&-shadow')} {...{ [DROP_INDEX_ATTRIBUTE_NAME]: shadowIndex }}>
                </div>;
            }
            const shadowComponent = <CSSTransition key='shadow'
                                                   timeout={ANIMATION_TIMEOUT}
                                                   classNames={classNames('&-shadow-anim')}
            >
                {component}
            </CSSTransition>;

            return {
                shadowIndex: shadowIndex!,
                shadowComponent,
                shadowInfo,
            };
        });
    }, [classNames, ignoreFirstPositionDrop, ignoreLastPositionDrop, itemClassName, renderShadow]);

    const handleDragEnter = useCallback((event: DragEvent) => {
        event.dataTransfer.dropEffect = 'none';

        if (dndActions) {
            const ret = dndActions.dragInfos({ types: event.dataTransfer.types });
            if (ret === undefined || !ret.supports) {
                return;
            }
            event.dataTransfer.dropEffect = dndActions.dropEffect || defaultDropEffect;

            event.preventDefault();
            event.stopPropagation();

            processDragDrop(event, undefined);

            return;
        }

        const shadowInfo = canDragEnter?.(event);
        if (!shadowInfo) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.dropEffect === 'none') {
            event.dataTransfer.dropEffect = defaultDropEffect;
        }

        processDragDrop(event, shadowInfo);
    }, [canDragEnter, defaultDropEffect, dndActions, processDragDrop]);

    const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        handleDragEnter(event);
    }, [handleDragEnter]);

    const handleDragLeave = useCallback((event: DragEvent) => {
        //        console.log('LEAVE');
        if (dndActions) {
            event.stopPropagation();

            setShadow(undefined);

            return;
        }

        if (canDragLeave && !canDragLeave?.(event, shadow!.shadowInfo)) {
            return;
        }

        event.stopPropagation();

        setShadow(undefined);
    }, [canDragLeave, dndActions, shadow]);

    const handleDrop = useCallback((event: DragEvent) => {
        //        console.log('DROP');
        event.stopPropagation();

        setShadow(undefined);

        if (dndActions) {
            dndActions.onDrop?.(event.dataTransfer, event.nativeEvent);

            return;
        }
        onDrop?.(event, shadow!.shadowInfo);
    }, [dndActions, onDrop, shadow]);

    const cls = {
        'has-shadow': !!shadow,
    };

    //
    return <div
        className={classNames('&', className, cls)}
        ref={bodyRef}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
    >
        <TransitionGroup component={null}>
            {items}
        </TransitionGroup>
    </div>;
}
