import React, {
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import { difference, find, pull, pullAll, union } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgSize } from '../types';
import { DEFAULT_SIZE } from '../defaults';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgCheckboxMinus } from '../arg-checkbox/arg-checkbox';
import {
    ArgNodeKey,
    ArgNodePath,
    ArgOpenChangedReason,
    ArgSelectionChangeReason,
    AsyncChildrenProvider,
    AsyncChildrenStates,
    AsyncChildState,
    GetNodeChildren,
    GetNodeKey,
    GetNodeResult,
    PaginatedResult,
} from './types';
import {
    ArgTreeUtilities,
    ArgTreeUtilitiesImpl,
    computeNodeChildren,
    computeNodeClassName,
    computeNodeKey,
    computeNodeLabel,
} from './utils';
import { useId } from '../../../hooks/use-id';
import { ArgPager } from '../arg-table/arg-pager';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { normalizeText } from '../utils';
import { useProgressMonitors } from '../progress-monitors/use-progress-monitors';
import { useProgressMonitor } from '../progress-monitors/use-progress-monitor';
import { ProgressMonitor, SubProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { renderText } from '../utils/message-descriptor-formatters';
import { isIn } from '../utils/is-in';

import './arg-tree.less';

const messages = defineMessages({
    searching: {
        id: 'basic.arg-tree.Searching',
        defaultMessage: 'Searching',
    },
    noResults: {
        id: 'basic.arg-tree.NoResults',
        defaultMessage: 'No results',
    },
    noData: {
        id: 'basic.arg-tree.NoData',
        defaultMessage: 'No data',
    },
    loading: {
        id: 'basic.arg-tree.Loading',
        defaultMessage: 'Loading',
    },
});

interface PaginationState {
    count: number | undefined;
    currentIndex: number;
}

export interface ArgTreeChangeDetails {
    addedKeys: string[];
    removedKeys: string[];
}

export interface ArgTreeProps<T> {
    size?: ArgSize;
    className?: ClassValue;
    root: T | T[];
    messageValues?: ArgMessageValues;
    checkable?: boolean;
    loading?: boolean | ProgressMonitor;

    initialValue?: ArgNodeKey[];
    value?: ArgNodeKey[];
    onChange?: (selection: ArgNodeKey[], details: ArgTreeChangeDetails, reason: ArgSelectionChangeReason) => void;

    openedNodes?: ArgNodeKey[];
    onOpen?: (nodeKeys: SetStateAction<ArgNodeKey[]>, reason: ArgOpenChangedReason) => void;
    openAllNodes?: boolean;

    cursor?: ArgNodeKey;
    onCursorChange?: (node: ArgNodeKey, event: ButtonClickEvent) => void;

    getNodeChildren?: GetNodeChildren<T>;

    getNodeKey: GetNodeKey<T>;
    getNodeLabel: string | ((nodePath: ArgNodePath<T>, searchedToken?: string) => (ReactNode | MessageDescriptor));
    getNodeBody?: (node: ArgNodePath<T>, searchedToken?: string) => ReactNode;
    isNodeDisabled?: (node: ArgNodePath<T>, nodeKey: ArgNodeKey) => boolean;
    isNodeDraggable?: (node: ArgNodePath<T>, nodeKey: ArgNodeKey) => boolean;
    isNodeUncheckable?: (node: ArgNodePath<T>, nodeKey: ArgNodeKey) => boolean;

    getNodeClassName?: string | ((nodePath: ArgNodePath<T>, searchedToken?: string) => ClassValue);

    // To optimize render
    hasNodeChildren?: (node: ArgNodePath<T>) => boolean;
    hasNodeBody?: (node: ArgNodePath<T>) => boolean;

    searchedToken?: string;
    hasNodeSearchedToken?: (node: ArgNodePath<T>, token: string) => boolean;
    onSearching?: (progressMonitor: ProgressMonitor | undefined) => void;

    treeUtilitiesRef?: MutableRefObject<ArgTreeUtilities<T> | undefined>;
    asyncChildrenStatesRef?: MutableRefObject<AsyncChildrenStates<T>>;

    noDataMessage?: MessageDescriptor | ReactNode;
}


export function ArgTree<T>(props: ArgTreeProps<T>) {
    const {
        className,
        size = DEFAULT_SIZE,
        root,
        loading,

        value: externalSelectedNodes,
        onChange,

        getNodeChildren,
        getNodeBody,
        getNodeLabel,
        getNodeKey,
        isNodeDisabled,
        isNodeUncheckable,
        hasNodeChildren,
        hasNodeBody,
        isNodeDraggable,
        getNodeClassName,

        checkable,

        messageValues,

        cursor,
        onCursorChange,

        openedNodes: externalOpenedNodes,
        onOpen,
        openAllNodes = false,

        searchedToken, hasNodeSearchedToken, onSearching,

        treeUtilitiesRef,
        asyncChildrenStatesRef,
        noDataMessage,
    } = props;

    const $id = useId();

    const classNames = useClassNames('arg-tree');

    const useInternalSelectedNodes = !isIn(props, 'value');
    const [internalSelectedNodes, setInternalSelectedNodes] = useState<ArgNodeKey[]>([]);
    const selectedNodes: ArgNodeKey[] = (useInternalSelectedNodes ? internalSelectedNodes : externalSelectedNodes) || [];

    const useInternalOpenedNodes = !isIn(props, 'openedNodes');
    const [internalOpenedNodes, setInternalOpenedNodes] = useState<ArgNodeKey[]>([]);
    const openedNodes: ArgNodeKey[] = (useInternalOpenedNodes ? internalOpenedNodes : externalOpenedNodes) || [];

    const [paginationState, setPaginationState] = useState<Record<ArgNodeKey, PaginationState>>({});

    let asyncChildrenStates = useRef<AsyncChildrenStates<T>>({});
    if (asyncChildrenStatesRef) {
        asyncChildrenStates = asyncChildrenStatesRef;
    }
    // state used only for rendered
    const [, setAsyncChildrenStatesId] = useState<number>(0);

    const [asyncProgressMonitors, createAsyncProgressMonitor] = useProgressMonitors();

    const [searchProgressMonitor, createSearchProgressMonitor] = useProgressMonitor();

    const treeUtilities = useMemo(() => {
        const newTreeUtilities = new ArgTreeUtilitiesImpl(getNodeChildren, getNodeKey, asyncChildrenStates.current);

        if (treeUtilitiesRef) {
            treeUtilitiesRef.current = newTreeUtilities;
        }

        return newTreeUtilities;
    }, [getNodeChildren, getNodeKey]);

    const cls = {
        [`size-${size}`]: true,
    };

    const handleClickNodeTitle = useCallback((nodeKey: ArgNodeKey, event: ButtonClickEvent) => {
        onCursorChange?.(nodeKey, event);
    }, [onCursorChange]);

    const handleOpenCloseNode = useCallback((nodeKey: ArgNodeKey, open: boolean, event: ButtonClickEvent) => {
        const setter = (prev: ArgNodeKey[]) => {
            const oldStateIndex = prev?.indexOf(nodeKey);
            if (open) {
                if (oldStateIndex >= 0) {
                    return prev;
                }

                const newList = [...prev];
                newList.push(nodeKey);

                return newList;
            }


            if (oldStateIndex < 0) {
                return prev;
            }

            const newList = [...prev];
            newList.splice(oldStateIndex, 1);

            return newList;
        };

        if (useInternalOpenedNodes) {
            setInternalOpenedNodes(setter);
        }
        onOpen?.(setter, open ? 'open' : 'close');
    }, [useInternalOpenedNodes, onOpen]);

    let _hasNodeSearchedToken = hasNodeSearchedToken;
    if (searchedToken && !_hasNodeSearchedToken) {
        const _normalizedSearchToken = normalizeText(searchedToken);
        _hasNodeSearchedToken = (nodePath: ArgNodePath<T>, token: string): boolean => {
            const label = computeNodeLabel(nodePath, getNodeLabel);

            if (typeof (label) !== 'string') {
                return false;
            }

            const normalizedLabel = normalizeText(label);

            if (_normalizedSearchToken.indexOf(normalizedLabel) < 0) {
                return false;
            }

            return true;
        };
    }

    useEffect(() => {
        if (!searchedToken || !_hasNodeSearchedToken) {
            return;
        }

        const progressMonitor = createSearchProgressMonitor('Search in tree', 1);

        const searchInAllTree = async (): Promise<Record<ArgNodeKey, true>> => {
            const toOpen: Record<ArgNodeKey, true> = {};

            let _root: ArgNodePath<T>[];
            if (!Array.isArray(root)) {
                _root = [[root as T]];
            } else {
                _root = (root as T[]).map((r) => ([r]));
            }

            try {
                for (let i = 0; i < _root.length; i++) {
                    const r = _root[i];

                    const sub = new SubProgressMonitor(progressMonitor, 1);

                    await treeUtilities.asyncWalkChildren(r, (childPath: ArgNodePath<T>) => {
                        if (!_hasNodeSearchedToken!(childPath, searchedToken)) {
                            return;
                        }

                        for (let i = 1; i < childPath.length; i++) {
                            const childKey = computeNodeKey(childPath.slice(0, i), getNodeKey);
                            toOpen[childKey] = true;
                        }
                    }, async (asyncProvider: AsyncChildrenProvider<T>, nodeKey: ArgNodeKey, nodePath: ArgNodePath<T>): Promise<AsyncChildState<T>> => {
                        const asyncChildState = createAsyncChild(asyncProvider, nodeKey, nodePath);

                        return asyncChildState;
                    }, sub);
                }
            } finally {
                progressMonitor.done();
            }

            return toOpen;
        };

        searchInAllTree().then((toOpen) => {
            const setter = ((prev: ArgNodeKey[]) => {
                const newOpenList = union(prev, Object.keys(toOpen));
                if (newOpenList.length === prev.length) {
                    return prev;
                }

                return newOpenList;
            });


            if (useInternalOpenedNodes) {
                setInternalOpenedNodes(setter);
            }
            onOpen && onOpen(setter, 'search');
        }, (error) => {
            if (progressMonitor.isCancelled) {
                return;
            }

            console.error(error);
        });
    }, [treeUtilities, searchedToken, root]);

    const searchingProgressMonitor = useMemo<ProgressMonitor | undefined>(() => {
        if (searchProgressMonitor?.isRunning) {
            return searchProgressMonitor;
        }

        const activeProgressMonitor = find(asyncProgressMonitors, (p) => p.isRunning);
        if (activeProgressMonitor) {
            return activeProgressMonitor;
        }

        return undefined;
    }, [searchProgressMonitor, asyncProgressMonitors]);

    useEffect(() => {
        onSearching?.(searchingProgressMonitor);
    }, [searchingProgressMonitor]);

    const handleCheckNode = useCallback((nodePath: ArgNodePath<T>, nodeKey: ArgNodeKey, state: true | false | string) => {
        let newList: ArgNodeKey[];
        let reason: ArgSelectionChangeReason;

        const { childrenCount, childrenKeys } = treeUtilities.computeCheckedChildren(nodePath);
        if (state === true) {
            if (childrenCount > 1) {
                newList = union([nodeKey], selectedNodes, childrenKeys);
            } else {
                newList = union([nodeKey], selectedNodes);
            }

            reason = 'add';
        } else {
            // Remove children and itself
            newList = pullAll([...selectedNodes], childrenKeys);
            for (let i = 0; i < nodePath.length - 1; i++) {
                const parentKey = computeNodeKey(nodePath.slice(0, i + 1), getNodeKey);

                pull(newList, parentKey);
            }
            reason = 'remove';
        }

        const details: ArgTreeChangeDetails = {
            addedKeys: difference(newList, selectedNodes),
            removedKeys: difference(selectedNodes, newList),
        };

        setInternalSelectedNodes(newList);
        onChange && onChange(newList, details, reason);
    }, [treeUtilities, selectedNodes, onChange, getNodeKey]);

    function createAsyncChild(asyncChildrenProvider: AsyncChildrenProvider<T>, nodeKey: ArgNodeKey, nodePath: ArgNodePath<T>): AsyncChildState<T> {
        const progressMonitor = createAsyncProgressMonitor(nodeKey, `Get children of ${nodeKey}`, 1);


        const asyncChild = {
            progressMonitor,
            knownChildren: {},
        };

        asyncChildrenStates.current[nodeKey] = asyncChild;
        setAsyncChildrenStatesId((prev) => (++prev));

        asyncChildrenProvider.getChildrenKey(0, 0, progressMonitor).then((currentChildren: GetNodeResult<T>) => {
            const knownChildren: Record<ArgNodeKey, T> = asyncChildrenStates.current[nodeKey]?.knownChildren || {};

            if (Array.isArray(currentChildren)) {
                currentChildren.forEach((child) => {
                    const key = computeNodeKey([...nodePath, child], getNodeKey);
                    knownChildren[key] = child;
                });
            }

            const newAsyncNode = {
                ...asyncChildrenStates.current[nodeKey],
                currentChildren,
                knownChildren,
                progressMonitor: undefined,
            };

            asyncChildrenStates.current[nodeKey] = newAsyncNode;
            setAsyncChildrenStatesId((prev) => (++prev));
        }, (error) => {
            if (progressMonitor.isCancelled) {
                return;
            }

            console.error(error);
        }).finally(() => {
            progressMonitor.done();
        });

        return asyncChild;
    }

    let renderedCount = 0;

    function renderNodes(nodePaths: ArgNodePath<T>[], level: number, found: boolean): ReactNode {
        const ns = nodePaths.map(function ($nodePath) {
            const nodePath = $nodePath;
            const nodeKey = computeNodeKey(nodePath, getNodeKey);

            const opened = openedNodes && openedNodes.indexOf(nodeKey) >= 0 || openAllNodes;
            let hasChildren: boolean | undefined;
            let childrenPaths: (ArgNodePath<T>[]) | undefined = undefined;
            let paginatedChildren = false;
            let hasBody = undefined;
            let body;

            if (hasNodeChildren) {
                hasChildren = hasNodeChildren(nodePath);
            }

            if (hasChildren === undefined || opened) {
                let children;
                let asyncChild: AsyncChildState<T> = asyncChildrenStates.current[nodeKey];
                if (!asyncChild) {
                    if (getNodeChildren) {
                        children = computeNodeChildren(nodePath, getNodeChildren);
                    }

                    if (children instanceof AsyncChildrenProvider) {
                        const asyncChildrenProvider = children as AsyncChildrenProvider<T>;

                        children = undefined;

                        asyncChild = createAsyncChild(asyncChildrenProvider, nodeKey, nodePath);
                    }
                }
                if (asyncChild) {
                    const { progressMonitor } = asyncChild;

                    if (progressMonitor?.isRunning) {
                        hasBody = true;
                        if (opened) {
                            body = <div className={classNames('&-node-body-loading')}>
                                <ThreeDotsLoading />
                            </div>;
                        }
                        children = undefined;
                    } else {
                        children = asyncChild.currentChildren;
                    }
                }

                if (children) {
                    if (typeof (children) === 'object' && (children as PaginatedResult<T>).getCount) {
                        const paginatedResult = children as PaginatedResult<T>;
                        paginatedChildren = true;

                        // Pagination object !
                        let state = paginationState[nodeKey];
                        if (!state) {
                            state = { currentIndex: 0, count: paginatedResult.getCount() };

                            setPaginationState((states) => ({ ...states, [nodeKey]: state }));
                            paginationState[nodeKey] = state;
                        }

                        const childrenKeys = paginatedResult.getChildrenKey(0, 0);
                        childrenPaths = childrenKeys.map((key) => ([...nodePath, paginatedResult.getChild(key)]));
                    } else {
                        childrenPaths = (children as T[]).map((node) => ([...nodePath, node]));
                    }
                } else if (hasChildren && !asyncChild) {
                    console.error('This node must have children !');
                }

                if (childrenPaths?.length ?? 0 > 0) {
                    hasChildren = true;
                }
            }

            if (!hasChildren && hasBody === undefined && hasNodeBody) {
                hasBody = hasNodeBody(nodePath);
            }

            if (!body && hasBody !== false) {
                if (hasBody == undefined && !hasChildren) {
                    body = getNodeBody?.(nodePath, searchedToken);

                    hasBody = !!body;
                } else if (opened) {
                    body = getNodeBody?.(nodePath, searchedToken);
                }
            }

            const label = computeNodeLabel(nodePath, getNodeLabel, searchedToken);
            const _label = renderText(label, messageValues);
            const selected = selectedNodes?.indexOf(nodeKey) >= 0;

            let checkValue: boolean | 'minus' = false;
            if (checkable) {
                const checkedResults = treeUtilities.computeCheckedChildren(nodePath, selectedNodes);
                const { childrenCount, checkedCount } = checkedResults;

                if (childrenCount === 1) {
                    // A leaf
                    checkValue = (checkedCount > 0);
                } else if (checkedCount === 0) {
                    checkValue = false;
                } else if (selected || checkedCount === childrenCount - 1) {
                    checkValue = true;
                } else {
                    checkValue = 'minus';
                }
            }

            let nodeFound = found;
            if (searchedToken && !nodeFound) {
                const ret = treeUtilities.hasSearchedToken(nodePath, _hasNodeSearchedToken!, searchedToken);

                if (ret === 'found') {
                    nodeFound = true;
                } else if (ret === 'notfound') {
                    return null;
                }
            }

            const disabled = isNodeDisabled?.(nodePath, nodeKey);
            const draggable = isNodeDraggable?.(nodePath, nodeKey);
            const uncheckable = isNodeUncheckable?.(nodePath, nodeKey);

            const cls = {
                opened,
                selected,
                disabled,
                draggable,
                checked: checkValue === true,
                unchecked: checkValue === false,
                'has-checked-child': checkValue === undefined,
                'has-children': hasChildren,
                'has-body': hasBody,
                'has-cursor': cursor === nodeKey,
            };

            const ulCls = {};

            const buttonId = `${$id}-${nodeKey}`;
            const checkButtonId = `${buttonId}-check`;

            let nodeClassName;
            if (getNodeClassName) {
                nodeClassName = computeNodeClassName(nodePath, getNodeClassName, searchedToken);
            }

            renderedCount++;

            return <li
                className={classNames('&-node', `&-node-${level}`, cls, nodeClassName)} key={nodeKey}
                data-nodekey={nodeKey}
                data-testid={`node-${nodeKey}`}
                aria-disabled={disabled}
            >
                <div
                    className={classNames('&-node-title', `&-node-title-${level}`)}
                    data-testid={`openNodeTitle-${nodeKey}`}
                    onClick={(event) => handleClickNodeTitle(nodeKey, event)}
                >
                    {(hasChildren || hasBody) && (
                        <ArgButton
                            icon='icon-triangle-down'
                            id={buttonId}
                            disabled={disabled || (!!searchedToken && !nodeFound)}
                            size='node'
                            type='ghost'
                            data-testid={`openNode-${nodeKey}`}
                            className={classNames('&-node-title-expand', `&-node-title-expand-${level}`)}
                            onClick={(event) => handleOpenCloseNode(nodeKey, !opened, event)}
                        />
                    )}

                    {checkable &&
                        <ArgCheckboxMinus
                            size='node'
                            id={checkButtonId}
                            disabled={disabled || uncheckable}
                            className={classNames('&-node-title-checkbox', `&-node-title-checkbox-${level}`)}
                            data-testid={`checkNode-${nodeKey}`}
                            value={checkValue}
                            onChange={(state) => handleCheckNode(nodePath, nodeKey, state)}
                        />
                    }
                    <label
                        className={classNames('&-node-title-label', `&-node-title-label-${level}`)}
                        data-testid={`labelNode-${nodeKey}`}
                        htmlFor={(hasChildren || hasBody) ? buttonId : checkButtonId}
                    >
                        {_label}
                    </label>
                </div>
                {opened && body &&
                    <div key='body' className={classNames('&-node-body', `&-node-body-${level}`)}
                         data-testid={`bodyNode-${nodeKey}`}>
                        {body}
                    </div>}
                {opened && childrenPaths &&
                    <ul
                        key='children'
                        className={classNames('&-node-children', `&-node-children-${level}`, ulCls)}
                        data-testid={`childrenNode-${nodeKey}`}
                    >
                        {renderNodes(childrenPaths, level + 1, nodeFound)}
                    </ul>}
                {paginatedChildren && <div>
                    <ArgPager key='pager' onChange={() => {
                    }} />
                </div>}
            </li>;
        });

        return ns;
    }

    const _root: T[] = Array.isArray(root) ? root : [root];
    const rootPaths: ArgNodePath<T>[] = _root.map((n) => ([n]));

    let nodesComponent;
    if (loading === true || ((loading as ProgressMonitor)?.isRunning)) {
        nodesComponent = <li className={classNames('&-empty')}>
            <FormattedMessage {...messages.loading} />
            <ThreeDotsLoading />
        </li>;
    } else {
        nodesComponent = renderNodes(rootPaths, 1, false);

        if (!renderedCount) {
            if (searchingProgressMonitor?.isRunning) {
                nodesComponent = <li className={classNames('&-empty')}>
                    <FormattedMessage {...messages.searching} />
                    <ThreeDotsLoading />
                </li>;
            } else if (searchedToken) {
                nodesComponent = <li className={classNames('&-empty')}>
                    <FormattedMessage {...messages.noResults} />
                </li>;
            } else {
                nodesComponent = <li className={classNames('&-empty')}>
                    <ArgMessageRenderer message={noDataMessage || messages.noData} />
                </li>;
            }
        }
    }

    return <ul className={classNames('&', className, cls)} data-testid='arg-tree'>
        {nodesComponent}
    </ul>;
}
