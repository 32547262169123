import L, { DivIcon, Icon, LatLngExpression, LeafletMouseEvent } from 'leaflet';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { Marker, useMap, useMapEvent } from 'react-leaflet';

import { latLngExpressionToLatLng } from '../../helpers/latLngExpressionToLatLng';

export interface ArgMapMarkerProps {
    icon?: Icon | DivIcon | undefined;
    position?: LatLngExpression;
    onChange?: (center: LatLngExpression) => void;
}

export function ArgMapMarker(props: ArgMapMarkerProps) {
    const { position, icon, onChange } = props;

    const map = useMap(); // React leaflet Hook providing the Leaflet Map instance
    const markerRef = useRef(null as L.Marker | null);

    useEffect(() => {
        const center = latLngExpressionToLatLng(position);

        if (!map || !center) {
            return;
        }

        try {
            map.flyTo(
                center,
                map.getZoom() < 10 ? 10 : map.getZoom(), //Zoom
                {
                    animate: true,
                    duration: 0.5,
                }
            );
        } catch (error) {
            console.error('leaflet error :-(', error);
        }
    }, [position, map]);

    const eventHandlers = useMemo(() => ({
        dragend() {
            const marker = markerRef.current;

            if (!isNil(marker) && !isNil(onChange)) {
                onChange(marker.getLatLng());
            }
        },
    }), [onChange, markerRef]);

    useMapEvent('click', (event: LeafletMouseEvent) => {
        const target = event.originalEvent?.target as HTMLDivElement;
        // To not trigger when clicking on toolbar buttons
        if (target.closest('button')?.contains(target)) {
            return;
        }

        onChange?.(event.latlng);
    });

    if (!position) {
        return null;
    }

    return (
        <Marker
            icon={icon}
            autoPan={true}
            ref={markerRef}
            draggable={onChange !== undefined}
            position={position}
            eventHandlers={eventHandlers}
        />
    );
}
