import React, {
    CSSProperties,
    DragEvent,
    ReactNode,
    UIEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ScrollDisplayManager } from '../../../utils/scroll-display-manager';
import { VirtualColumn } from './virtual-column';
import {
    ArgTable3OnDragStartHandler,
    ArgTableColumn3,
    ColumnKey,
    HORIZONTAL_SCROLLBAR_HEIGHT,
    RowState,
} from './arg-table3';
import { VirtualColumnHeader } from './virtual-column-header';
import { DataSorter } from '../arg-providers/data-provider';
import { VIRTUAL_COLUMNS_CONTAINER_CLASSNAME } from './shared-classnames';

import './virtual-columns-container.less';

interface VirtualColumnScrollContainerProps<T> {
    rowsCache: Map<number, RowState<T>>;
    rowHeight: number;
    startNode: number;
    visibleNodeCount: number;
    globalScrollTop?: number;
    scrollTop?: number;
    scrollLeft: number;
    totalHeight: number;
    columns: ArgTableColumn3<T>[];
    columnsStartIndex: number;
    columnsEndIndex: number;
    scrollDisplayManager: ScrollDisplayManager;
    bodyRef?: React.MutableRefObject<HTMLDivElement | null>;
    headerBodyRef?: React.MutableRefObject<HTMLDivElement | null>;
    locked?: boolean;
    className?: ClassValue;
    searchValue?: string;
    header?: boolean;
    firstColumn: boolean;
    lastColumn: boolean;
    itemsCount: number;
    noVerticalScroll: boolean;
    headerHeight: number;
    additionalHeaderHeight?: number;
    renderLoadingCell?: (column: ArgTableColumn3<T>, index?: number) => ReactNode;
    renderErrorCell?: (column: ArgTableColumn3<T>, index?: number, error?: Error) => ReactNode;
    sort?: DataSorter;
    leftColumnsWidth?: number,
    onColumnSort: (column: ArgTableColumn3<T>, event: UIEvent) => void,
    onColumnLock: (column: ArgTableColumn3<T>, locked: boolean) => void;
    onColumnVisible: (column: ArgTableColumn3<T>, visible: boolean) => void;
    canLockColumn?: boolean;
    dragColumnTransforms?: Record<ColumnKey, string>;
    draggedColumnKey?: ColumnKey;
    searchScrollTop?: number;
    columnWidths?: Record<ColumnKey, number>;
    onColumnWidthChange?: (column: ArgTableColumn3<T>, width: number) => void;
    onDataLoaded?: (body: HTMLDivElement) => void;
    disabled?: boolean;
    onDragStart?: ArgTable3OnDragStartHandler<T>;
    onDragEnd?: (event: DragEvent) => void;
}

export function VirtualColumnScrollContainer<T>(props: VirtualColumnScrollContainerProps<T>) {
    const {
        rowsCache,
        rowHeight,
        startNode,
        searchValue,
        visibleNodeCount,
        globalScrollTop,
        scrollTop,
        scrollLeft,
        totalHeight,
        columns,
        columnsStartIndex,
        columnsEndIndex,
        scrollDisplayManager,
        bodyRef,
        locked,
        className,
        header,
        firstColumn,
        lastColumn,
        itemsCount,
        noVerticalScroll,
        headerHeight,
        additionalHeaderHeight,
        renderLoadingCell,
        renderErrorCell,
        sort,
        onColumnSort,
        onColumnLock,
        onColumnVisible,
        canLockColumn,
        leftColumnsWidth = 0,
        dragColumnTransforms,
        draggedColumnKey,
        searchScrollTop,
        columnWidths,
        onDataLoaded,
        disabled,
        onDragStart,
        onDragEnd,
    } = props;

    const classNames = useClassNames(VIRTUAL_COLUMNS_CONTAINER_CLASSNAME); // Be careful: also used for screenshot
    const headerRef = useRef<HTMLDivElement>(null);

    const elements = scrollDisplayManager.getViewPortContent(startNode, visibleNodeCount);

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.style.left = `${-scrollLeft}px`;
        }
    }, [scrollLeft]);

    let left = 0;

    const columnComponents: ReactNode[] = [];
    const headerComponents: ReactNode[] = [];

    for (let i = columnsStartIndex; i < columnsEndIndex; i++) {
        const column = columns[i];
        const dragTransform = dragColumnTransforms?.[column.key];
        let dragged;
        if (draggedColumnKey) {
            dragged = (draggedColumnKey === column.key);
        }

        let columnWidth = columnWidths?.[column.key];
        if (columnWidth === undefined) {
            columnWidth = column.width as number;
        }

        const cls = {
            'row-header': column.rowHeader,
        };

        columnComponents.push(
            <VirtualColumn<T>
                className={classNames(cls)}
                column={column}
                rowsCache={rowsCache}
                key={column.key}
                elements={elements}
                totalHeight={totalHeight}
                left={left}
                columnWidth={columnWidth}
                searchValue={searchValue}
                scrollTop={scrollTop}
                first={firstColumn && i === columnsStartIndex}
                last={lastColumn && i === columnsEndIndex - 1}
                itemsCount={itemsCount}
                renderLoadingCell={renderLoadingCell}
                renderErrorCell={renderErrorCell}
                dragTransform={dragTransform}
                dragged={dragged}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            />
        );

        headerComponents.push(<VirtualColumnHeader<T>
                key={column.key}
                column={column}
                locked={locked}
                onColumnSort={onColumnSort}
                onColumnLock={onColumnLock}
                onColumnVisible={onColumnVisible}
                canLockColumn={canLockColumn}
                sort={sort}
                dragTransform={dragTransform}
                dragged={dragged}
                selected={column.selected}
                left={left}
                columnWidth={columnWidth}
                disabled={disabled}
        />
        );

        left += columnWidth;
    }

    const containerBodyStyle: CSSProperties = {};
    const bodyStyle: CSSProperties = {};
    const style: CSSProperties = {
        left: `${leftColumnsWidth}px`,
        visibility: 'visible',
    };
    if (locked) {
        style.minWidth = `${left}px`;
        style.width = `${left}px`;
        //        containerBodyStyle.width = `${left}px`;
    } else {
        bodyStyle.minWidth = `${left}px`;
        bodyStyle.width = `${left}px`;
        //        containerBodyStyle.width = `${left}px`;
    }

    if (noVerticalScroll) {
        style.height = `${totalHeight + headerHeight + (additionalHeaderHeight || 0) + HORIZONTAL_SCROLLBAR_HEIGHT}px`;
    }

    const cls = {
        locked,
    };

    const headerCls = {
        'has-vertical-scroll': globalScrollTop,
    };

    useLayoutEffect(() => {
        if (searchScrollTop !== undefined && bodyRef?.current && bodyRef.current.scrollTop !== searchScrollTop) {
            const scrollTop = bodyRef.current.scrollTop;
            const contentHeight = bodyRef.current.clientHeight;

            if (scrollTop + contentHeight - rowHeight < searchScrollTop) {
                bodyRef.current.scrollTop = (
                    bodyRef.current.scrollTop + (searchScrollTop - (scrollTop + contentHeight)) + rowHeight
                );
            } else if (searchScrollTop <= scrollTop) {
                bodyRef.current.scrollTop = searchScrollTop;
            }
        }
    }, [searchScrollTop, rowHeight, onDataLoaded, bodyRef]);

    const handleBodyRef = useCallback((ref: HTMLDivElement | null) => {
        if (bodyRef) {
            bodyRef.current = ref;
        }

        if (ref) {
            onDataLoaded && onDataLoaded(ref);
        }
    }, [onDataLoaded, bodyRef]);

    // Don't render anything if there is no column to display
    // In that case, we still need the body to be part of the DOM, so that it doesn't lose the scroll event listeners
    style.display = columnsStartIndex >= columnsEndIndex ? 'none' : undefined;

    const body = <div key='body' className={classNames('&-body')} ref={handleBodyRef} style={containerBodyStyle}>
        <div key='body' className={classNames('&-body-scroll')} style={bodyStyle}>
            {columnComponents}
        </div>
    </div>;

    return (
        <div className={classNames('&', className, cls)} style={style}
             data-columngroup={locked ? 'locked' : 'unlocked'}>
            {header !== false && (
                <div key='header' className={classNames('&-header', headerCls)} ref={headerRef}
                     style={{
                         width: left,
                         visibility: 'visible',
                     }}>
                    {headerComponents}
                </div>
            )}
            {body}
        </div>
    );
}
