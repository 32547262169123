const DEFAULT_LOCALE = 'en-150';

//const DEFAULT_OFFLINE_TILE_LAYER_URL = 'https://tile.{mode}.chapsvisiondev.com/tile/{z}/{x}/{y}.png';
//const DEFAULT_OFFLINE_TILE_LAYER_ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
const DEFAULT_VIEW_MODE = 'klokantech-basic';

//const DEFAULT_ONLINE_TILE_LAYER_URL = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}';
//const DEFAULT_ONLINE_TILE_LAYER_ATTRIBUTION = 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012';

const DEFAULT_CHECK_VERSION = 0; // 0=OFF    1000 * 60 * 10= 10mn

interface MsalConfig {
    auth: {
        clientId?: string,
        authority?: string,
        redirectUri?: string,
        postLogoutRedirectUri: string,
    };
    cache: {
        cacheLocation?: string,
        storeAuthStateInCookie?: boolean,
    };
}

interface ApiConfig {
    resourceUri?: string;
    resourceScopes?: [string];
}

interface UI {
    toolbarShortestPathId?: string;
}

interface URLS {
    apiAdmin?: string;
    docAdmin?: string;
    apiSettings?: string;
    docSettings?: string;
    apiExploration?: string;
    apiProceo?: string;
    docExploration?: string;
    apiDataPrep?: string;
    docDataPrep?: string;
    apiDataCollect?: string;
    docDataCollect?: string;
    apiHub?: string;
    apiMessaging?: string;
    apiNotifications?: string;
    api: string;
    rt: string;
    leafletTileLayerUrl: string;
    tilesServerUrl: string;
}

interface Map {
    leafletTileLayerAttribution?: string;
    leafletTileLayerFormat?: string;
    defaultViewMode?: string; // Default map tiles ID
}

interface ExternalURLS {
    latlongMap: string;
    addressMap: string;
}

interface Branding {
    brandingLogoUrl?: string;
    brandingName?: string;
    brandingIconUrl?: string;
    dataExplorationIcon?: string;
    dataExplorationName?: string;
    dataPreparationIcon?: string;
    dataPreparationName?: string;
    proceoIcon?: string;
    proceoName?: string;
}

declare global {
    interface Window {
        argonodeConfig?: {
            version?: {
                build: string;
                hash: string;
            };
            appId: string;
            appName: string;
            urls: URLS;
            externalUrls: ExternalURLS;
            redirectToFolders: boolean;
            redirectTo: string;
            adminSupport: boolean;
            oauth: {
                enabled: string,
                loginUrl: string,
                logoutUrl: string,
            },

            ui: UI;
            map: Map;
            msalConfig: MsalConfig;
            apiConfig: ApiConfig;
            documentListDisplayLimit: number;
            pictureRowCountDisplayLimit: number;
            propertyListDisplayLimit: number;

            // Default LOCALE if the user and navigator does not set a supported language
            defaultLocale: string;

            // Force the LOCALE and ignore all user and navigator configurations
            forcedLocale: string;

            checkVersionMs?: number;

            ckeditorLicenseKey?: string;
            ckeditorBuildId?: string;

            enableRtHubs?: string;
            enableKeycloak?: string;
            branding?: Branding;

            grafanaUrl?: string;
            kibanaUrl?: string;

            environmentName?: string;
        };
    }
}

function getLocalConfig(name: string): string | undefined {
    if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
        return undefined;
    }

    // TODO: localStorage does not seem to be used anymore, validate and rm.
    return window.localStorage?.getItem(name) || process.env[name] || undefined;
}

const apiServerUrl = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LOCAL_SERVER')
        : window.argonodeConfig?.urls?.api
);

const apiServerUrlSettings = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_SETTINGS') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiSettings
);

const apiServerUrlAdministration = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_ADMINISTRATION') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiAdmin
);

const apiServerUrlProceo = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_PROCEO') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiProceo
);

const apiServerUrlExploration = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_EXPLORATION') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiExploration
);

const apiServerUrlDataPrep = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_DATAPREP') || process.env.REACT_APP_LOCAL_SERVER_DATAPREP || apiServerUrl)
        : window.argonodeConfig?.urls?.apiDataPrep
);

const apiServerUrlDataCollect = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_DATACOLLECT') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiDataCollect
);

const apiServerUrlMessaging = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_MESSAGING') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiMessaging
);

const apiServerUrlNotifications = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? (getLocalConfig('REACT_APP_LOCAL_SERVER_NOTIFICATIONS') || apiServerUrl)
        : window.argonodeConfig?.urls?.apiNotifications
);

const apiHubUrl = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? ((getLocalConfig('REACT_APP_LOCAL_SERVER_HUBURL')) ?? '') // /hubs/processmonitoring
        : window.argonodeConfig?.urls?.apiHub // /hubs/processmonitoring';
);

const docServerUrlExploration = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LOCAL_DOC_EXPLORATION')
        : window.argonodeConfig?.urls?.docExploration
);

const docServerUrlDataPrep = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LOCAL_DOC_DATAPREP')
        : window.argonodeConfig?.urls?.docDataPrep
);

const docServerUrlDataCollect = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LOCAL_DOC_DATACOLLECT')
        : window.argonodeConfig?.urls?.docDataCollect
);

const docServerUrlSettings = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LOCAL_DOC_SETTINGS')
        : window.argonodeConfig?.urls?.docSettings
);

const docServerUrlAdministration = normalizeApi(
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LOCAL_DOC_ADMIN')
        : window.argonodeConfig?.urls?.docAdmin
);

const latlongMapExternalURL = normalizeApi(
    ((process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_LATLONG_MAP_URL')
        : window.argonodeConfig?.externalUrls?.latlongMap
    )) ?? 'https://www.openstreetmap.org/?mlat={latitude}&mlon={longitude}';

const addressMapExternalURL = normalizeApi(
    ((process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ? getLocalConfig('REACT_APP_ADDRESS_MAP_URL')
        : window.argonodeConfig?.externalUrls?.addressMap
    )) ?? 'https://www.openstreetmap.org/?query={address}';

export class Environment {
    static apiServerUrl = apiServerUrl;

    static apiServerUrlSettings = apiServerUrlSettings;

    static docServerUrlSettings = docServerUrlSettings;

    static apiServerUrlAdministration = apiServerUrlAdministration;

    static apiServerUrlProceo = apiServerUrlProceo;

    static docServerUrlAdministration = docServerUrlAdministration;

    static apiServerUrlExploration = apiServerUrlExploration;

    static docServerUrlExploration = docServerUrlExploration;

    static apiServerUrlDataPrep = apiServerUrlDataPrep;

    static docServerUrlDataPrep = docServerUrlDataPrep;

    static apiServerUrlDataCollect = apiServerUrlDataCollect;

    static docServerUrlDataCollect = docServerUrlDataCollect;

    static apiServerUrlMessaging = apiServerUrlMessaging;

    static apiServerUrlNotifications = apiServerUrlNotifications;

    static apiHubUrl = apiHubUrl;

    static defaultMapViewMode = (process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEFAULT_MAP_VIEW_MODE
        : window.argonodeConfig?.map?.defaultViewMode)
        || DEFAULT_VIEW_MODE;

    static appName =
        (process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_NAME
            : window.argonodeConfig?.appName) || (getLocalConfig('ANONYMIZE') ? 'Ringo' : 'ChapsVision');

    static appBuildId =
        (process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_BUILD_ID
            : window.argonodeConfig?.version?.build);

    static appId =
        (process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_ID
            : window.argonodeConfig?.appId) || 'argonode-react-app';

    static redirectTo =
        (process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_REDIRECT_TO
            : window.argonodeConfig?.redirectTo);

    static tilesServerGlURL = (
        process.env.NODE_ENV === 'development'
            ? (getLocalConfig('REACT_APP_TILES_SERVER_URL') || process.env.REACT_APP_TILES_SERVER_URL)
            : window.argonodeConfig?.urls?.tilesServerUrl);

    static leafletTileLayerUrl = (process.env.NODE_ENV === 'development'
        ? (getLocalConfig('REACT_APP_LEAFLET_TILE_LAYER_URL') || process.env.REACT_APP_LEAFLET_TILE_LAYER_URL)
        : window.argonodeConfig?.urls?.leafletTileLayerUrl);

    static leafletTileLayerAttribution = (process.env.NODE_ENV === 'development' ?
        process.env.REACT_APP_LEAFLET_TILE_LAYER_ATTRIBUTION :
        window.argonodeConfig?.map?.leafletTileLayerAttribution);

    static leafletTileLayerFormat = (process.env.NODE_ENV === 'development' ?
        process.env.REACT_APP_LEAFLET_TILE_LAYER_FORMAT :
        window.argonodeConfig?.map?.leafletTileLayerFormat);

    static latlongMapExternalURL = latlongMapExternalURL;

    static addressMapExternalURL = addressMapExternalURL;

    static defaultLocale = (process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEFAULT_LOCALE
        : window.argonodeConfig?.defaultLocale
    ) || DEFAULT_LOCALE;

    static forcedLocale = (process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_FORCED_LOCALE
        : window.argonodeConfig?.forcedLocale);

    static checkVersionMs =
        ((process.env.NODE_ENV === 'development' || localStorage.ARG_CHECK_VERSION !== undefined)
            ? (parseInt(window.localStorage?.ARG_CHECK_VERSION || '0'))
            : window.argonodeConfig?.checkVersionMs) ?? DEFAULT_CHECK_VERSION;

    static ckeditorLicenseKey = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_CKEDITOR_LICENSE_KEY
            : window.argonodeConfig?.ckeditorLicenseKey
    );

    static ckeditorBuildId = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_CKEDITOR_BUILD_ID
            : window.argonodeConfig?.ckeditorBuildId
    ) || 'ckargonos-35.4.0+version-001';

    //
    static apiOAuth = (localStorage?.FORCE_OAUTH) ? (localStorage?.FORCE_OAUTH === 'true') :
        (process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_OAUTH === 'true'
            : ((window.argonodeConfig?.oauth?.enabled === 'true') ?? false)
        );

    static authRedirectUri = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_OAUTH_LOGIN_URL
            : window.argonodeConfig?.oauth?.loginUrl ?? undefined
    ) || '{api}/authentication/authorize?redirectUrl={redirectUrl}&locale={locale}';

    static authLogoutUri = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_OAUTH_LOGOUT_URL
            : window.argonodeConfig?.oauth?.logoutUrl ?? undefined
    );

    static enableRtHubs = (
        (window.argonodeConfig?.enableRtHubs !== 'false') ?? undefined
    );

    static isKeyCloackEnabled = (
        (window.argonodeConfig?.enableKeycloak === 'true') ?? undefined
    );

    static dataExplorationIcon = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_DATA_EXPLORATION_ICON
            : window.argonodeConfig?.branding?.dataExplorationIcon
    ) || 'icon-travel_explore';

    static dataExplorationName = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_DATA_EXPLORATION_NAME
            : window.argonodeConfig?.branding?.dataExplorationName
    ) || '';

    static dataPreparationIcon = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_DATA_PREPARATION_ICON
            : window.argonodeConfig?.branding?.dataPreparationIcon
    ) || 'icon-process';

    static dataPreparationName = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_DATA_PREPARATION_NAME
            : window.argonodeConfig?.branding?.dataPreparationName
    ) || '';

    static proceoIcon = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_DATA_PROCEO_ICON
            : window.argonodeConfig?.branding?.proceoIcon
    ) || 'icon-hammer2';

    static proceoName = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_DATA_PROCEO_NAME
            : window.argonodeConfig?.branding?.proceoName
    ) || '';

    static grafanaUrl = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_GRAFANA_URL
            : window.argonodeConfig?.grafanaUrl
    ) || '';

    static kibanaUrl = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_KIBANA_URL
            : window.argonodeConfig?.kibanaUrl
    ) || '';

    static environmentName = (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_ENVIRONMENT_NAME
            : window.argonodeConfig?.environmentName
    ) || '';

    static isDataExplorationEnabled = () => (
        process.env.NODE_ENV === 'development'
            ? (process.env.REACT_APP_EXPLORATION_SUPPORT === 'true' && !!Environment.apiServerUrlExploration)
            : !!Environment.apiServerUrlExploration
    );

    static isDataPreparationEnabled = () => (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_PREPARATION_SUPPORT === 'true' && !!Environment.apiServerUrlDataPrep
            : !!Environment.apiServerUrlDataPrep
    );

    static isAdministrationEnabled = () => (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_ADMIN_SUPPORT === 'true' && !!Environment.apiServerUrlAdministration
            : !!Environment.apiServerUrlAdministration
    );

    static isSettingsEnabled = () => (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_SETTINGS_SUPPORT === 'true' && (!!Environment.apiServerUrlSettings || !!Environment.apiServerUrlAdministration)
            : (!!Environment.apiServerUrlSettings || !!Environment.apiServerUrlAdministration)
    );

    static isDatCollectEnabled = () => (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_COLLECT_SUPPORT === 'true' && !!Environment.apiServerUrlDataCollect
            : !!Environment.apiServerUrlDataCollect
    );

    static isProceoEnabled = () => (
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_PROCEO_SUPPORT === 'true' && !!Environment.apiServerUrlProceo
            : !!Environment.apiServerUrlProceo
    );
}

if (process.env.NODE_ENV === 'development') {
    console.log('Environment :', {
        reactAppApiAuth: process.env.REACT_APP_API_OAUTH,
        reactAppLocalServer: process.env.REACT_APP_LOCAL_SERVER,
        reactAppLocalServerAdmin: process.env.REACT_APP_LOCAL_SERVER_ADMIN,
        reactAppLocalServerExploration: process.env.REACT_APP_LOCAL_SERVER_EXPLORATION,
        apiOAuth: Environment.apiOAuth,
        apiServerUrl: Environment.apiServerUrl,
        apiServerUrlAdministration: Environment.apiServerUrlAdministration,
        apiServerUrlSettings: Environment.apiServerUrlSettings,
        apiServerUrlExploration: Environment.apiServerUrlExploration,
        apiServerUrlDataPrep: Environment.apiServerUrlDataPrep,
        grafanaUrl: Environment.grafanaUrl,
        kibanaUrl: Environment.kibanaUrl,
        environmentName: Environment.environmentName,
    });
}

function normalizeApi(path: string | undefined): string | undefined {
    if (!path) {
        return undefined;
    }

    return path;
}
