import React, { ReactNode, useMemo } from 'react';

import { ArgErrorsMap } from './arg-errors-map';
import { useArgSnackbars } from './use-arg-snackbars';
import { ArgNotificationsContext, initializeGlobalNotifications } from './notifications';
import { useArgNotifications } from './use-arg-notifications';
import { ArgGlobalNotificationType } from './types';

interface ArgNotificationsProviderProps {
    errorMap?: ArgErrorsMap;
    children: ReactNode;
}

export function ArgNotificationsProvider(props: ArgNotificationsProviderProps) {
    const { children, errorMap } = props;

    const [argSnackbars, snackbarsContainer] = useArgSnackbars(errorMap);
    const [argNotifications, notificationsContainer] = useArgNotifications(errorMap);

    const globalNotifications = useMemo<ArgGlobalNotificationType>(() => {
        return {
            ...argSnackbars,
            ...argNotifications,
        };
    }, [argNotifications, argSnackbars]);

    initializeGlobalNotifications(globalNotifications);

    return <ArgNotificationsContext.Provider value={globalNotifications}>
        {children}
        {snackbarsContainer}
        {notificationsContainer}
    </ArgNotificationsContext.Provider>;
}

