/**
 * Like in javascript instruction, but it verifies if the propertyName is known by the type of T
 * Does not work with union type, or unknown type, but it is better than nothing.
 *
 * @param T obj the object
 * @param string propertyName the property name of the object
 */
export function isIn<T extends object, K extends keyof T>(obj: T, propertyName: K): boolean {
    return propertyName in obj;
}

