import { find, get, includes, isNumber, isString, size } from 'lodash';

import { ArgTableRowState, ArgTableDataProviderColumn, DataFilter, DataSorter, OnLoaded, OnLoading } from './data-provider';
import { ArrayDataProvider } from './array-data-provider';
import { normalizeText } from '../utils';

export function getPredicate(searchTerm: string) {
    return (value: any): any => {
        if (isString(value)) {
            return includes(normalizeText(value), normalizeText(searchTerm));
        }
        if (typeof value === 'object') {
            return Object.values(value).find(getPredicate(searchTerm));
        }

        return false;
    };
}

export class SortableArrayDataProvider<T, F extends DataFilter = any> extends ArrayDataProvider<T, F> {
    #forceLoading: boolean | undefined;
    #filteredData: T[] | undefined = undefined;

    #filter: F | undefined;
    #columns: ArgTableDataProviderColumn<T>[] | undefined;
    #sorter: DataSorter | undefined;

    constructor(data: T[], forceLoading?: boolean) {
        super(data);

        this.#forceLoading = forceLoading;
    }

    getRow(rowIndex: number): T | ArgTableRowState {
        const data = this.#filteredData ? this.#filteredData : this.getData();

        if (this.#forceLoading) {
            return ArgTableRowState.Loading;
        }
        if (rowIndex < 0 || rowIndex >= data.length) {
            return ArgTableRowState.Error;
        }

        return this.getFilteredData()[rowIndex];
    }

    filterData(data: T[], filter?: F): T[] {
        return data;
    }

    getFilteredData(): T[] {
        if (this.#filteredData) {
            return this.#filteredData;
        }

        const data = this.getData();
        this.#filteredData = data;
        if (this.#filter) {
            this.#filteredData = this.filterData(this.#filteredData, this.#filter);
        }

        this.emit(OnLoading, 0, this.#filteredData.length - 1);

        const propertySorter = this.#sorter?.propertySorters[0];

        const columnSorter = find(this.#columns, { key: propertySorter?.propertyName })?.sorter;

        if (!propertySorter || columnSorter === 'fromServer') {
            this.updateStateId();
            this.emit(OnLoaded, 0, this.#filteredData.length - 1);

            return this.#filteredData;
        }

        if (this.#filteredData === data) {
            this.#filteredData = [...data];
        }
        const defaultSorter = (a: T, b: T): number => {
            const valueA = get(a, propertySorter.propertyName);
            const valueB = get(b, propertySorter.propertyName);

            if (isNumber(valueA) && isNumber(valueB)) {
                return valueA - valueB;
            }

            if (isString(valueA) && isString(valueB)) {
                return valueA.localeCompare(valueB);
            }

            return size(valueA) - size(valueB);
        };

        this.#filteredData.sort(columnSorter || defaultSorter);

        if (propertySorter.order === 'descending') {
            this.#filteredData.reverse();
        }

        this.updateStateId();
        this.emit(OnLoaded, 0, this.#filteredData.length - 1);

        return this.#filteredData;
    }

    get rowCount() {
        return this.getFilteredData().length;
    }

    get rowCountWithoutFilter() {
        return super.rowCount;
    }

    setFilter(filter?: F, sorter?: DataSorter, columns?: ArgTableDataProviderColumn<T>[]) {
        this.#columns = columns;

        this.#filter = filter;
        this.#filteredData = undefined;

        this.#sorter = sorter;
    }
}
