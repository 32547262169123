import { ReactNode, useCallback, useState } from 'react';

import { ArgButton } from '../arg-button/arg-button';
import { ArgMessageValues, ArgRenderedText, ArgSize } from '../types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { renderText } from '../utils/message-descriptor-formatters';
import { isIn } from '../utils/is-in';

import './arg-collapse.less';

const CLASSNAME = 'arg-collapse';

export interface ArgCollapseProps {
    className?: ClassValue;
    children?: ReactNode;
    defaultCollapsed?: boolean;
    title?: ArgRenderedText;
    messageValues?: ArgMessageValues;
    onCollapse?: (isCollapsed: boolean) => void;
    collapsed?: boolean;
    size?: ArgSize;
}

export function ArgCollapse(props: ArgCollapseProps) {
    const {
        className,
        children,
        defaultCollapsed = false,
        title,
        onCollapse,
        collapsed,
        messageValues,
        size = 'large',
    } = props;
    const classNames = useClassNames(CLASSNAME);
    const useInternalCollapsed = !isIn(props, 'collapsed');

    const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed || defaultCollapsed);

    const _collapsed: boolean = useInternalCollapsed ? isCollapsed : ((collapsed === undefined) ? false : collapsed);

    const handleClickTitle = useCallback(() => {
        if (useInternalCollapsed) {
            setIsCollapsed((prevState: boolean) => {
                return !prevState;
            });

            return;
        }

        onCollapse?.(!collapsed);
    }, [collapsed, onCollapse, useInternalCollapsed]);

    const cls = {
        collapsed: _collapsed,
    };

    return (
        <div className={classNames('&', className, cls)} data-testid='arg-collapse'>
            <div className={classNames('&-title')}>
                <ArgButton
                    type='ghost'
                    label={renderText(title, messageValues)}
                    icon={_collapsed ? 'icon-cheveron-down' : 'icon-cheveron-up'}
                    onClick={handleClickTitle}
                    size={size}
                />
            </div>

            {
                !_collapsed && (
                    <div className={classNames('&-body')}>
                        {children}
                    </div>
                )
            }
        </div>
    );
}
