import { useMemo } from 'react';
import { chain, isEmpty } from 'lodash';

const USE_MEMO = false;

const NO_DATA_PROPS: Record<string, any> = {};

function filterDataProps(props: Record<string, any>) {
    if (isEmpty(props)) {
        return NO_DATA_PROPS;
    }

    const dataProps = chain(props).omitBy((value, key) => {
        if (!key.startsWith('data-')) {
            return true;
        }

        if (key === 'data-testid') {
            return true; // the component must define the data-testid itself
        }

        return false;
    }).value();

    if (isEmpty(dataProps)) {
        return NO_DATA_PROPS;
    }

    return dataProps;
}

function useFilterDataPropsWithMemo(props: Record<string, any>) {
    const dataProps = useMemo<Record<string, any>>(() => {
        const dataProps = filterDataProps(props);

        return dataProps;
    }, [props]);

    return dataProps;
}

export const useFilterDataProps = (USE_MEMO) ? useFilterDataPropsWithMemo : filterDataProps;
