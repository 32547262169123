import { useCallback, useEffect, KeyboardEvent } from 'react';

import { isToolDisabled, isToolVisible, Tool, ToolChanges } from './tool';
import { ToolContext } from './tool-context';
import { useKeyBinding } from '../keybindings/use-keybinding';

const EMPTY_CHANGES: ToolChanges = {};

export function useToolItem(toolContext: ToolContext | undefined, item: Tool, changes: ToolChanges = EMPTY_CHANGES): void {
    useEffect(() => {
        if (!toolContext) {
            return;
        }

        toolContext.addItem(item, (changes !== EMPTY_CHANGES) ? changes : undefined);

        return () => {
            toolContext.removeItem(item);
        };
    }, [toolContext]);

    useEffect(() => {
        if (changes === EMPTY_CHANGES || !toolContext) {
            return;
        }

        toolContext.updateItem(item, changes);
    }, (changes === EMPTY_CHANGES) ? [] : Object.values(changes));

    const onClick = useCallback((event: KeyboardEvent<Element>) => {
        toolContext?.handleKeyBinding(item, event);
    }, [toolContext, item]);

    const updatedItem = (changes === EMPTY_CHANGES) ? item : { ...item, ...changes };

    useKeyBinding(updatedItem.keyBinding, onClick, isToolVisible(updatedItem) && !isToolDisabled(updatedItem));
}
