import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Divider } from 'antd';
import { isFunction } from 'lodash';

import type { ArgComboMenuProps } from './arg-combo-menu';
import { ArgInputSearch } from '../arg-input/arg-input-search';
import { ArgIconCheckbox } from '../arg-checkbox/arg-icon-checkbox';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { useClassNames } from '../arg-hooks/use-classNames';

import './arg-combo-menu-header.less';

const CLASSNAME = 'arg-combo-menu-header';

const messages = defineMessages({
    all: {
        id: 'basic.arg-combo.SelectAll',
        defaultMessage: 'All',
    },
    searchPlaceHolder: {
        id: 'basic.arg-combo.SearchPlaceholder',
        defaultMessage: 'Search',
    },
});

interface ArgComboMenuHeaderProps<T> extends ArgComboMenuProps<T> {
    searchedToken: string | undefined;
    setSearchedToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export function ArgComboMenuHeader<T>(props: ArgComboMenuHeaderProps<T>) {
    const {
        items,
        values,
        onSelectAll,
        setInternalValue,
        onChange,
        zeroOrOneSelection,
        onSearchInputChange,
        topRender,
        enableFilter,
        searchedToken,
        setSearchedToken,
        allCheckButtonStates: allCheckButtonStatesExternal,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const allCheckButtonStates = useMemo(() => {
        if (allCheckButtonStatesExternal !== undefined) {
            return allCheckButtonStatesExternal;
        }

        const allLeafItems = isFunction(items) ? items() : items;
        const allSelected = values?.length === allLeafItems.length;
        const states = allSelected ? true : (values?.length ? 'minus' : false);

        return states;
    }, [items, values, allCheckButtonStatesExternal]);

    const handleSelectAll = useCallback((event: ButtonClickEvent) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        if (onSelectAll) {
            const check = (allCheckButtonStates !== true);
            onSelectAll(check, event);

            return;
        }

        const forceSelection = !(values?.length ?? 0 > 0);

        let itemsList = isFunction(items) ? items() : items;
        if ([true, 'minus'].includes(allCheckButtonStates)) {
            itemsList = [];
        }

        setInternalValue(itemsList);
        onChange?.(itemsList, 'selection', undefined, forceSelection);
    }, [onSelectAll, values?.length, items, onChange, allCheckButtonStates, setInternalValue]);

    const handleSearchTokenChange = useCallback((searchedToken: string) => {
        setSearchedToken(searchedToken);
        onSearchInputChange?.(searchedToken);
    }, [setSearchedToken, onSearchInputChange]);

    if (zeroOrOneSelection) {
        if (!topRender) {
            return null;
        }

        return <>{topRender()}</>;
    }

    return (
        <>
            <div className={classNames('&')}>
                {/* Select all button */}
                <div className={classNames('&-item', '&-select-all')}>
                    <button
                        data-testid='select-all'
                        className={classNames('&-select-all-button', 'arg-menu-item')}
                        onClick={handleSelectAll}
                        type='button'
                    >
                        <ArgIconCheckbox
                            state={allCheckButtonStates}
                            className={classNames('&-select-all-button-checkbox', '&-dropDown-checkbox')}
                        />
                        <span className={classNames('&-select-all-button-title')}>
                            <FormattedMessage {...messages.all} />
                        </span>
                    </button>
                </div>
                {/* Search filter */}
                {enableFilter && (
                    <div className={classNames('&-item', '&-filter')}>
                        <ArgInputSearch
                            className={classNames('&-filter-input')}
                            data-testid='arg-combo-dropdown-filter-input'
                            clearable={true}
                            size='medium'
                            value={searchedToken}
                            onInputChange={handleSearchTokenChange}
                            placeholder={messages.searchPlaceHolder}
                        />
                    </div>
                )}
            </div>
            <Divider className={classNames('&-divider')} />
        </>
    );
}
