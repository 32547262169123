import { useContext, useEffect } from 'react';

import { KeyBindingDescriptor, KeyBindingHandler, KeyBindingOptions } from './keybinding';
import { KeyBindingsContext } from './keybindings-context';

export function useKeyBinding(keyBinding?: KeyBindingDescriptor, handler?: KeyBindingHandler, enabled?: boolean, options?: KeyBindingOptions) {
    const context = useContext(KeyBindingsContext);

    useEffect(() => {
        if (!keyBinding || !handler) {
            return;
        }
        if (!context) {
            console.error('Can not associate key binding if no context is defined !');

            return;
        }

        const _handler = (enabled !== false) ? handler : NOTHING_HANDLER;

        context.addKeyBinding(keyBinding, _handler, options);

        return () => {
            context.removeKeyBinding(keyBinding, _handler);
        };
    }, [enabled, keyBinding, handler]);


    if (enabled === false || !keyBinding || !handler) {
        return undefined;
    }

    const keyCommand = context?.computeKeyCommand(keyBinding);

    return keyCommand;
}

function NOTHING_HANDLER() {
    // Action is disabled !
}
