// @ts-ignore
import _immutableSet from 'immutable-set';
import { get, isArray, isEqual, omit } from 'lodash';
import SeamlessImmutable from 'seamless-immutable';

type Options = Partial<{
    withArrays: boolean;
    equality: (prev: any, next: any) => any;
    safe: boolean;
    sameValue: boolean;
}>

export function immutableSet<T>(value: T, path: string, nodeValue: any, options?: Options): T;
export function immutableSet<T>(value: T, path: (string | number)[], nodeValue: any, options?: Options): T;
export function immutableSet<T>(value: T, path: string | (string | number)[], nodeValue: any, options?: Options): T;

export function immutableSet<T>(value: T, path: string | (string | number)[], nodeValue: any, options?: Options): T {
    const newValue = _immutableSet(value, path, nodeValue, options);

    return newValue;
}

export function immutableUpdate<T>(value: T, path: string | (string | number)[], update: (prev: any) => any, options?: Options): T {
    const nodeValue = get(value, path);
    const newNodeValue = update(nodeValue);

    if (isEqual(newNodeValue, nodeValue)) {
        return value;
    }

    const newValue = immutableSet(value, path, newNodeValue, options);

    return newValue;
}

export function immutableOmit<T>(value: T, path: string | (string | number)[], omitPropertiesName: string | (string | number)[], options?: Options): T {
    const nodeValue = get(value, path);
    const newNodeValue = omit(nodeValue, omitPropertiesName);

    if (isEqual(newNodeValue, nodeValue)) {
        return value;
    }

    const newValue = immutableSet(value, path, newNodeValue, options);

    return newValue;
}

export function immutableAppend<T>(value: T, path: string | (string | number)[], appendValue: any | any[], options?: Options): T {
    const ret = immutableUpdate(value, path, (prev) => {
        if (prev === undefined) {
            return [appendValue];
        }
        if (!isArray(prev)) {
            throw new Error(`Path ${path} is not an array`);
        }
        if (isArray(appendValue)) {
            const ret = [...prev, ...appendValue];

            return ret;
        }

        const ret = [...prev, appendValue];

        return ret;
    }, options);

    return ret;
}

export function immutableRemoveAtIndex<T>(value: T, path: string | (string | number)[], index: number, options?: Options): T {
    const ret = immutableUpdate(value, path, (prev) => {
        if (!isArray(prev)) {
            throw new Error(`Path ${path} is not an array`);
        }

        const ret = [...prev];
        ret.splice(index, 1);

        return ret;
    }, options);

    return ret;
}

export function setImmutable<T>(data: T): Readonly<T> {
    const config = localStorage.getItem('ARG_IMMUTABLE');

    if (process.env.NODE_ENV === 'production' && config !== 'true') {
        return data;
    }

    if (config === 'false') {
        return data;
    }

    const ret = SeamlessImmutable(data);

    return ret as T;
}

const EMPTY_ARRAY: any[] = [];

export function immutableEmptyArray<T>(): Readonly<T[]> {
    return EMPTY_ARRAY as T[];
}

const EMPTY_OBJECT = {};

export function immutableEmptyObject<T>(): Readonly<Record<string, T>> {
    return EMPTY_OBJECT as Record<string, T>;
}
