import { DependencyList, useEffect, useRef } from 'react';

function useDebugEffect__Production(fct: () => void, deps: DependencyList, name?: string): void {
    return useEffect(fct, deps);
}

function useDebugEffect__Debug(fct: () => void, deps: DependencyList, name?: string): void {
    const prev = useRef<DependencyList>();

    useEffect(() => {
        const p = prev.current;
        if (p && name) {
            console.log('UseDebugEffect', name);
            for (let i = 0; i < deps.length; i++) {
                if (p[i] !== deps[i]) {
                    console.log('UseDebugEffect of', name, '  #', i, ' different', 'newValue=', deps[i], 'oldValue=', p[i]);
                }
            }
        }

        prev.current = deps;

        try {
            fct();
        } catch (x) {
            console.error('UseDebugEffect of', name, 'throws error=', x);
        }
    }, deps);
}

export const useDebugEffect = (process.env.NODE_ENV !== 'production') ? useDebugEffect__Debug : useDebugEffect__Production;
