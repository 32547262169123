import { useEffect } from 'react';
import Debug from 'debug';

import { StateId } from '../../../utils/rt-states/basic-state';
import { ToolContext } from './tool-context';
import { useStateEquals } from '../arg-hooks/use-state-equals';

const debug = Debug('common:basic:UseContextUpdate');

export function useContextUpdate(toolContext: ToolContext): StateId {
    const [stateId, setStateId] = useStateEquals(toolContext.stateId);

    useEffect(() => {
        const updateToolbar = () => {
            debug('Update toolbar', toolContext.stateId);
            setStateId(toolContext.stateId);
        };

        toolContext.on('*', updateToolbar);

        return () => {
            //setStateId(UNMOUNTED_STATEID);
            toolContext.removeListener('*', updateToolbar);
        };
    }, [toolContext]);

    return stateId;
}
