import { useBasicState } from '../use-basic-state';
import { UserState } from './user-state';
import { ArgUserId } from '../../../components/basic';
import { getAdministrationRtApi } from '../../../administration/rt-states/rt-apis';


export function userStateFactory(userId: ArgUserId) {
    const api = getAdministrationRtApi();

    return new UserState(api, computeUserStateURL(userId), userId);
}

export function computeUserStateURL(userId: ArgUserId) {
    return `/users/${userId}`;
}

export function useUserState(userId: ArgUserId): UserState;
export function useUserState(userId: ArgUserId | undefined): UserState | undefined;

export function useUserState(userId: ArgUserId | undefined): UserState | undefined {
    const state = useBasicState<UserState>(
        () => userStateFactory(userId!),
        (userId) ? computeUserStateURL(userId) : undefined
    );

    return state;
}
