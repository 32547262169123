import dayjs, { Dayjs, OptionType } from 'dayjs';
import utcDayjs from 'dayjs/plugin/utc';
import arraySupportDayjs from 'dayjs/plugin/arraySupport';
import weekDaysDayjs from 'dayjs/plugin/weekday';
import isSameOrAfterDayjs from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforeDayjs from 'dayjs/plugin/isSameOrBefore';
import localeDataDayjs from 'dayjs/plugin/localeData';
import minMaxDayjs from 'dayjs/plugin/minMax';
import durationDayjs from 'dayjs/plugin/duration';
import relativeTimeDayjs from 'dayjs/plugin/relativeTime';

export interface FromTo {
    from: Dayjs | Date;
    to: Dayjs | Date;
}

export const ISO_8601: OptionType = 'ISO_8601';

dayjs.extend(utcDayjs);
dayjs.extend(arraySupportDayjs);
dayjs.extend(weekDaysDayjs);
dayjs.extend(isSameOrAfterDayjs);
dayjs.extend(isSameOrBeforeDayjs);
dayjs.extend(localeDataDayjs);
dayjs.extend(minMaxDayjs);
dayjs.extend(durationDayjs);
dayjs.extend(relativeTimeDayjs);


export { dayjs };
