import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue } from '../arg-hooks/use-classNames';

export type ArgSelectionChangeReason = 'add' | 'remove' | 'clear' | 'all';
export type ArgOpenChangedReason = 'open' | 'close' | 'closeAll' | 'search';

export type ArgNodeKey = string; // Can not be a number because of Record<ArgNodeKey, any>

export type ArgNodePath<T> = T[];

export interface PaginatedResult<T> {
    getCount(): number | undefined;

    getChildrenKey(index: number, count: number): ArgNodeKey[];

    getChild(key: ArgNodeKey): T;
}

export type GetNodeResult<T> = T[] | null | undefined | PaginatedResult<T>;

export class AsyncChildrenProvider<T> {
    #callback: ((index: number, count: number, progressMonitor: ProgressMonitor) => Promise<GetNodeResult<T>>);

    constructor(callback: ((index: number, count: number, progressMonitor: ProgressMonitor) => Promise<GetNodeResult<T>>)) {
        this.#callback = callback;
    }

    getChildrenKey(index: number, count: number, progressMonitor: ProgressMonitor): Promise<GetNodeResult<T>> {
        return this.#callback(index, count, progressMonitor);
    }
}

export type GetNodeChildren<T> = string | ((node: ArgNodePath<T>) => (GetNodeResult<T> | AsyncChildrenProvider<T>));
export type GetNodeKey<T> = string | ((node: ArgNodePath<T>) => ArgNodeKey);
export type GetNodeClassName<T> = string | ((node: ArgNodePath<T>, searchedToken?: string) => ClassValue);

export type AsyncChildrenStates<T> = Record<ArgNodeKey, AsyncChildState<T>>;

export interface AsyncChildState<T> {
    progressMonitor?: ProgressMonitor;
    knownChildren: Record<ArgNodeKey, T>;
    currentChildren?: GetNodeResult<T>;
}
