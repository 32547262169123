import Debug from 'debug';
import { shouldPolyfill as numberShouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as relativeTimeShouldPolyfill } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as dateTimeShouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as pluralRulesShouldPolyfill } from '@formatjs/intl-pluralrules/should-polyfill';

import { getArgLocale } from './get-locale';
import { en_150 } from './langs/en-150';

const debug = Debug('common:i18n:Polyfill');

export function installFormatJSPolyfills(locale: string): Promise<any> {
    const ps: Promise<any>[] = [];
    debug('start', 'Load polyfill for', locale);

    if (numberShouldPolyfill(locale)) {
        const p = import('@formatjs/intl-numberformat/polyfill').then(() => {
            debug('numberPolyfill', 'Need numberFormat for', locale);

            // Load the polyfill 1st BEFORE loading data  await import('@formatjs/intl-relativetimeformat/polyfill')

            const argLocale = getArgLocale(locale);
            if (argLocale) {
                return argLocale.getNumberPolyfill();
            }

            return en_150.getNumberPolyfill();
        });

        ps.push(p);
    }
    if (relativeTimeShouldPolyfill(locale)) {
        const p = import('@formatjs/intl-relativetimeformat/polyfill').then(() => {
            debug('relativetimePolyfill', 'Need relativetimeformat for', locale);

            // Load the polyfill 1st BEFORE loading data  await import('@formatjs/intl-relativetimeformat/polyfill')

            const argLocale = getArgLocale(locale);
            if (argLocale) {
                return argLocale.getRelativeTimePolyfill();
            }

            return en_150.getRelativeTimePolyfill();
        });

        ps.push(p);
    }
    if (dateTimeShouldPolyfill(locale)) {
        const p = import('@formatjs/intl-datetimeformat/polyfill').then(() => {
            debug('dateTimePolyfill', 'Need datetimeformat for', locale);

            const argLocale = getArgLocale(locale);
            if (argLocale) {
                return argLocale.getDateTimePolyfill();
            }

            return en_150.getDateTimePolyfill();
        });

        ps.push(p);
    }
    if (pluralRulesShouldPolyfill(locale)) {
        const p = import('@formatjs/intl-pluralrules/polyfill').then(() => {
            debug('pluralPolyfill', 'Need pluralrules for', locale);

            const argLocale = getArgLocale(locale);
            if (argLocale) {
                return argLocale.getPluralRulesPolyfill();
            }

            return en_150.getPluralRulesPolyfill();
        });

        ps.push(p);
    }

    return Promise.all(ps).then(() => {
        debug('Promise.all', 'All polyfills for', locale, 'are loaded');
    });
}
