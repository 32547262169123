import { ReactNode } from 'react';

import { HighlightedText } from '../arg-highlighted-search/arg-highlighted-search';

export function processSearch(text: string, token = 'em') {
    const openingTag = `<${token}>`;
    const closingTag = `</${token}>`;

    const regex = new RegExp(`(${openingTag}|${closingTag})`);
    const parts: string[] = text.split(regex);

    const _highlightedText: ReactNode[] = [];

    for (let index = 0; index < parts.length; index++) {
        const currentPart = parts[index];

        if (currentPart === openingTag) {
            const highlightedWord = parts[index + 1];
            _highlightedText.push(<HighlightedText key={index} text={highlightedWord} />);
            index++;
            continue;
        }
        if (currentPart === closingTag) {
            continue;
        }

        _highlightedText.push(currentPart);
    }

    return _highlightedText;
}
