import { ProgressMonitor } from '../../components/basic';
import { Connector, ConnectorRequestInit, RangeResponse } from '../connector';
import { RtApi } from '../rt-states/rt-api';

export class BaseConnector {
    readonly #connector: Connector = Connector.getInstance();

    readonly #name: string;
    readonly #api: string | undefined;
    readonly #progressMonitorRtApi: RtApi | undefined;

    constructor(name: string, api: string | undefined, progressMonitorRtApi?: RtApi) {
        this.#name = name;
        this.#api = api;
        this.#progressMonitorRtApi = progressMonitorRtApi;

        if (!api) {
            //throw new Error('Invalid empty API');
        }
    }

    fillAuthentication(headers: Record<string, any>) {
        this.#connector.fillAuthentication(headers);
    }

    getAccessTokenFactory() {
        return this.#connector.getAccessTokenFactory();
    }

    async rawRequest(
        url: string,
        options?: ConnectorRequestInit,
        progressMonitor?: ProgressMonitor
    ): Promise<any | null> {
        const ret = this.#connector.rawRequest(url, {
            ...options,
            api: options?.api || this.#api,
            progressMonitorRtApi: options?.progressMonitorRtApi || this.#progressMonitorRtApi,
        }, progressMonitor);

        return ret;
    }

    get api(): string {
        if (!this.#api) {
            throw new Error(`URL for connector ${this.#name} is not defined`);
        }

        return this.#api;
    }

    async request(
        url: string,
        options?: ConnectorRequestInit,
        progressMonitor?: ProgressMonitor
    ): Promise<any | null> {
        const ret = this.#connector.request(url, {
            ...options,
            api: options?.api || this.api,
            progressMonitorRtApi: options?.progressMonitorRtApi || this.#progressMonitorRtApi,
        }, progressMonitor);

        return ret;
    }

    async requestResults<T>(
        url: string,
        arrayPath: string,
        options?: ConnectorRequestInit,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<RangeResponse<T>> {
        const ret = this.#connector.requestResults<T>(
            url,
            arrayPath,
            {
                ...options,
                api: options?.api || this.api,
                progressMonitorRtApi: options?.progressMonitorRtApi || this.#progressMonitorRtApi,
            },
            progressMonitor);

        return ret;
    }

    async downloadRequest(
        fileName: string,
        url: string,
        options?: ConnectorRequestInit,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<true> {
        const ret = this.#connector.downloadRequest(fileName, url, {
            ...options,
            api: options?.api || this.api,
            progressMonitorRtApi: options?.progressMonitorRtApi || this.#progressMonitorRtApi,
        }, progressMonitor);

        return ret;
    }

    downloadBlob(
        fileName: string,
        blob: Blob
    ): true {
        return this.#connector.downloadBlob(fileName, blob);
    }

    downloadObject(
        fileName: string,
        object: any
    ): void {
        this.#connector.downloadObject(fileName, object);
    }
}
