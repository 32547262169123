import { ToolTreeNode } from './tool-context';
import { isToolVisible } from './tool';

export type ToolbarItemCallback<T> = (item: ToolTreeNode) => T | undefined;

export function walkNode<T>(root: ToolTreeNode[], callback: ToolbarItemCallback<T>) {
    const stack: ToolTreeNode[] = [...root];

    for (; stack.length;) {
        const node = stack.shift()!;

        if (!isToolVisible(node)) {
            continue;
        }

        const ret = callback(node);

        if (ret !== undefined) {
            return ret;
        }

        if (node.children) {
            stack.unshift(...node.children);
        }
    }
}

export function countVisibleChildren(node: ToolTreeNode): ToolTreeNode[] {
    if (node.children) {
        const visibleChildren = node.children.reduce<ToolTreeNode[]>((acc: ToolTreeNode[], child: ToolTreeNode) => {
            const cs = countVisibleChildren(child);
            if (cs.length) {
                acc.push(child);
            }

            return acc;
        }, []);

        return visibleChildren;
    }

    const isVisible = node.visible === undefined || !!node.visible;

    return (isVisible) ? [node] : [];
}


export function hasOnlyContainer(root: ToolTreeNode[]): boolean {
    const onlyContainer = walkNode(root, (node: ToolTreeNode) => {
        switch (node.type) {
            case 'group':
            case 'marker':
            case 'separator':
                return undefined;

            default:
                return false;
        }
    });

    return onlyContainer !== false;
}
