import React, { DragEvent, ErrorInfo, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { NumberSize, Resizable, ResizeDirection, Size } from 're-resizable';
import { first, isFunction, isNumber } from 'lodash';
import { defineMessages, FormattedMessage } from 'react-intl';
import Debug from 'debug';

import { walkNode } from './utils';
import { isToolDisabled, isToolVisible, Tool } from './tool';
import { ArgToolbar } from './arg-toolbar';
import { ToolContext, ToolTreeNode } from './tool-context';
import { useToolNodes } from './use-tool-nodes';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgErrorCatcher } from '../arg-error/arg-error-catcher';
import { ArgonosError, INVALID_SESSION } from '../utils/argonos-error';
import { ErrorPane } from '../../common/panes/error-pane';
import { toPx } from '../utils';
import { LEFT_ENABLE, RIGHT_ENABLE, TOP_ENABLE } from '../utils/resizable';
import { useLatestCallback } from '../arg-hooks/use-latest-callback';
import { ArgMessageValues, ArgRenderedText, ArgRenderFunction } from '../types';
import { isIn } from '../utils/is-in';
import { ArgFloatingToolbar } from '../arg-floating-toolbar/arg-floating-toolbar';

import './arg-toolbar-layout.less';

const debug = Debug('basic:arg-toolbar:arg-toolbar-layout');

const messages = defineMessages({
    errorInPane: {
        id: 'basic.arg-toolbar-layout.ErrorInPane',
        defaultMessage: 'An error occurred, please close the panel.',
    },
});

const DEFAULT_PANEL_WIDTH = 480;
const DEFAULT_FOOTER_HEIGHT = 250;
const DEFAULT_PANEL_MAX_WIDTH = '50%';

interface PanelSize extends Size {
    undef?: boolean;
    timestamp?: number;
}

interface SideInfo {
    panel?: Tool;
    editor?: Tool;
    editors: Tool[];
}

export interface ArgToolbarLayoutProps {
    className?: ClassValue;
    toolbarClassName?: ClassValue;
    disabled?: boolean;
    toolbarContext: ToolContext;
    bodyClassName?: ClassValue;
    onDragStart?: (event: DragEvent<HTMLDivElement>) => void;
    onDragEnd?: (event: DragEvent<HTMLDivElement>) => void;
    children?: ReactNode;

    floatingToolbar?: ToolContext;
    floatingToolbarTitle?: ArgRenderedText;
    messageValues?: ArgMessageValues;

    setSelectedRightPanel?: (path: string | undefined) => void;
    selectedRightPanel?: string;

    rightPanelMinWidth?: number | string;
    rightPanelMaxWidth?: number | string;
    rightPanelWidth?: number;
    rightPanelDefaultWidth?: number | string;
    onRightPanelWidthChanged?: (width: number) => void;

    setSelectedLeftPanel?: (path: string | undefined) => void;
    selectedLeftPanel?: string;

    leftPanelMinWidth?: number | string;
    leftPanelMaxWidth?: number | string;
    leftPanelWidth?: number;
    leftPanelDefaultWidth?: number | string;
    onLeftPanelWidthChanged?: (width: number) => void;

    footer?: ReactNode | ArgRenderFunction;
    enableFooterResize?: boolean;
    footerPanelMinHeight?: number | string;
    footerPanelMaxHeight?: number | string;
    footerPanelHeight?: number;
    footerPanelDefaultHeight?: number | string;
    onFooterPanelHeightChanged?: (height: number) => void;

    onEditorSelected?: (path: string | undefined) => void;
    selectedEditor?: string;

    defaultEditor?: ReactNode | ArgRenderFunction;
}


export function ArgToolbarLayout(props: ArgToolbarLayoutProps) {
    const {
        className,
        toolbarClassName,
        toolbarContext,
        floatingToolbar,
        floatingToolbarTitle,
        messageValues,
        enableFooterResize,
        disabled,
        children,
        footer,
        bodyClassName,
        onDragStart,
        onDragEnd,
        rightPanelMinWidth = DEFAULT_PANEL_WIDTH,
        rightPanelMaxWidth = DEFAULT_PANEL_MAX_WIDTH,
        rightPanelDefaultWidth,
        rightPanelWidth: externalRightWidth,
        onRightPanelWidthChanged,
        leftPanelMinWidth = DEFAULT_PANEL_WIDTH,
        leftPanelMaxWidth = DEFAULT_PANEL_MAX_WIDTH,
        leftPanelDefaultWidth,
        leftPanelWidth: externalLeftWidth,
        onLeftPanelWidthChanged,
        footerPanelMinHeight,
        footerPanelMaxHeight,
        footerPanelDefaultHeight,
        footerPanelHeight: externalFooterHeight,
        onFooterPanelHeightChanged,
        defaultEditor,

        setSelectedRightPanel,
        selectedRightPanel: externalRightSelectedPanel,

        setSelectedLeftPanel,
        selectedLeftPanel: externalLeftSelectedPanel,

        onEditorSelected,
        selectedEditor: externalSelectedEditor,
    } = props;

    //useCompareProps('ArgToolbarLayout', props);

    const classNames = useClassNames('arg-toolbar-layout');

    const contentRef = useRef<HTMLDivElement>(null);

    const [internalLeftSelectedPanel, setInternalLeftSelectedPanel] = useState<string>();
    const [internalRightSelectedPanel, setInternalRightSelectedPanel] = useState<string>();
    const [internalSelectedEditor, setInternalSelectedEditor] = useState<string>();

    const leftSelectedPanel = isIn(props, 'selectedLeftPanel') ? externalLeftSelectedPanel : internalLeftSelectedPanel;
    const rightSelectedPanel = isIn(props, 'selectedRightPanel') ? externalRightSelectedPanel : internalRightSelectedPanel;

    /*
     * Editor selected by the user,
     * note that this editor implies that a toolItem with this path exists, otherwise a default editor will be selected.
     */
    const selectedEditor = isIn(props, 'selectedEditor') ? externalSelectedEditor : internalSelectedEditor;

    const [toolbarNodesLeft] = useToolNodes(toolbarContext, 'left');
    const [toolbarNodesRight] = useToolNodes(toolbarContext, 'right');
    const [toolbarNodesCenter] = useToolNodes(toolbarContext, 'center');

    const [rightPanelSize, rightPanelDefaultSize] = useSizeDimension('width', rightPanelDefaultWidth ?? DEFAULT_PANEL_WIDTH, externalRightWidth);
    const [leftPanelSize, leftPanelDefaultSize] = useSizeDimension('width', leftPanelDefaultWidth ?? DEFAULT_PANEL_WIDTH, externalLeftWidth);
    const [footerPanelSize, footerPanelDefaultSize] = useSizeDimension('height', footerPanelDefaultHeight ?? DEFAULT_FOOTER_HEIGHT, externalFooterHeight);

    debug('LEFT',
        'leftWidth=', leftPanelSize.width,
        'leftPanelDefaultSize=', leftPanelDefaultSize,
        'leftPanelDefaultWidth=', leftPanelDefaultWidth,
        'selectedLeftPanel=', leftSelectedPanel
    );
    debug('RIGHT',
        'rightWidth=', rightPanelSize.width,
        'rightPanelDefaultSize=', rightPanelDefaultSize,
        'rightPanelDefaultWidth=', rightPanelDefaultWidth,
        'selectedRightPanel=', rightSelectedPanel
    );
    debug('FOOTER',
        'footerHeight=', footerPanelSize.height,
        'footerPanelDefaultSize=', footerPanelDefaultSize,
        'footerPanelDefaultHeight=', footerPanelDefaultHeight
    );

    const handleRightPanelSelection = useCallback((path: string | undefined) => {
        let selectedPath = path;
        if (rightSelectedPanel === selectedPath) {
            selectedPath = undefined;
        }
        setInternalRightSelectedPanel(selectedPath);
        setSelectedRightPanel?.(selectedPath);
    }, [setSelectedRightPanel, rightSelectedPanel]);

    const handleLeftPanelSelection = useCallback((path: string | undefined) => {
        let selectedPath = path;
        if (leftSelectedPanel === selectedPath) {
            selectedPath = undefined;
        }
        setInternalLeftSelectedPanel(selectedPath);
        setSelectedLeftPanel?.(selectedPath);
    }, [leftSelectedPanel, setSelectedLeftPanel]);

    const handleEditorSelection = useCallback((path: string | undefined) => {
        setInternalSelectedEditor(path);
        onEditorSelected?.(path);
    }, [onEditorSelected]);

    const { panel: leftPanel, editor: leftEditor, editors: leftEditors } = useMemo<SideInfo>(() => {
        const ret = computeToolbarNodes(toolbarNodesLeft, leftSelectedPanel, selectedEditor);

        debug('computeToolbarNodesLeft', 'ret=', ret);

        return ret;
    }, [leftSelectedPanel, selectedEditor, toolbarNodesLeft]);

    const { panel: rightPanel, editor: rightEditor, editors: rightEditors } = useMemo<SideInfo>(() => {
        const ret = computeToolbarNodes(toolbarNodesRight, rightSelectedPanel, selectedEditor);

        debug('computeToolbarNodesRight', 'ret=', ret);

        return ret;
    }, [rightSelectedPanel, selectedEditor, toolbarNodesRight]);

    const { editor: centerEditor, editors: centerEditors } = useMemo<SideInfo>(() => {
        const ret = computeToolbarNodes(toolbarNodesCenter, undefined, selectedEditor);

        debug('computeToolbarNodesCenter', 'ret=', ret);

        return ret;
    }, [toolbarNodesCenter, selectedEditor]);

    let editorTool = leftEditor || centerEditor || rightEditor;
    if (!editorTool) {
        editorTool = first([...leftEditors, ...centerEditors, ...rightEditors]);
    }

    const handleRightPanelResized = useLatestCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        debug('handleRightPanelResized', 'delta=', delta);

        let newWidth: number;
        if (isNumber(rightPanelSize.width)) {
            newWidth = rightPanelSize.width + delta.width;
        } else {
            newWidth = elementRef.offsetWidth;
        }

        rightPanelSize.width = newWidth;
        rightPanelSize.timestamp = Date.now();

        onRightPanelWidthChanged?.(newWidth);
    });

    const handleLeftPanelResized = useLatestCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        debug('handleLeftPanelResized', 'delta=', delta);

        let newWidth: number;
        if (isNumber(leftPanelSize.width)) {
            newWidth = leftPanelSize.width + delta.width;
        } else {
            newWidth = elementRef.offsetWidth;
        }

        leftPanelSize.width = newWidth;
        leftPanelSize.timestamp = Date.now();

        onLeftPanelWidthChanged?.(newWidth);
    });

    const handleFooterResized = useLatestCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        debug('handleFooterResized', 'delta=', delta);

        let newHeight: number;
        if (isNumber(footerPanelSize.height)) {
            newHeight = footerPanelSize.height + delta.height;
        } else {
            newHeight = elementRef.offsetHeight;
        }

        footerPanelSize.height = newHeight;
        footerPanelSize.timestamp = Date.now();

        onFooterPanelHeightChanged?.(newHeight);
    });

    const handleRenderError = useCallback((error: Error, errorInfo?: ErrorInfo) => {
        console.error('----------------------------------');
        console.error('error=', error);
        console.error('errorInfo=', errorInfo);

        if ((error as ArgonosError)?.code === INVALID_SESSION) {
            return <div className={classNames('&-content-error')}>
                <ErrorPane error={error} />
            </div>;
        }

        return <div className={classNames('&-content-error')} data-testid='error-pane'>
            <ErrorPane error={error}>
                <FormattedMessage {...messages.errorInPane} />
            </ErrorPane>
        </div>;
    }, [classNames]);

    const computeStyleWidth = (
        attribute: keyof Size,
        size: PanelSize | undefined,
        minSize: number | string | undefined,
        defaultSize: Size | undefined,
        defaultValue: number
    ) => {
        const s = isNumber(size && size[attribute])
            ? size![attribute]
            : (isNumber(defaultSize && defaultSize[attribute])
                ? defaultSize![attribute]
                : defaultValue
            )
        ;

        const ret = `${Math.max(
            isNumber(minSize) ? minSize : 0,
            toPx(s)
        )}px`;

        //console.log('size=', size?.[attribute], defaultSize?.[attribute], defaultValue, '=>', ret);

        return ret;
    };

    const style: any = {
        '--left-width': computeStyleWidth(
            'width',
            leftPanelSize,
            leftPanelMinWidth,
            leftPanelDefaultSize,
            DEFAULT_PANEL_WIDTH
        ),
        '--right-width': computeStyleWidth(
            'width',
            rightPanelSize,
            rightPanelMinWidth,
            rightPanelDefaultSize,
            DEFAULT_PANEL_WIDTH
        ),
        '--footer-height': computeStyleWidth(
            'height',
            footerPanelSize,
            footerPanelMinHeight,
            footerPanelDefaultSize,
            DEFAULT_FOOTER_HEIGHT
        ),
    };

    let footerContainer: ReactNode = null;
    if (footer) {
        const footerComponent = <ArgErrorCatcher
            renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
        >
            {isFunction(footer) ? footer() : footer}
        </ArgErrorCatcher>;

        if (enableFooterResize) {
            footerContainer = <Resizable
                key='footer-resizable'
                className={classNames('&-footer')}
                enable={TOP_ENABLE}
                size={footerPanelSize}
                minHeight={footerPanelMinHeight}
                maxHeight={footerPanelMaxHeight}
                onResizeStop={handleFooterResized}
            >
                {footerComponent}
            </Resizable>;
        } else {
            footerContainer = (
                <div
                    key='footer'
                    className={classNames('&-footer')}
                    data-testid='footer-container'
                >
                    {footerComponent}
                </div>
            );
        }
    }

    let body: ReactNode = editorTool?.panelRender?.(editorTool);
    if (!body && defaultEditor) {
        body = isFunction(defaultEditor) ? defaultEditor() : defaultEditor;
    }

    const hideEditor = (leftEditors.length + centerEditors.length + rightEditors.length) < 2 && localStorage.ARG_SHOW_EDITOR !== 'true';

    return (
        <div
            className={classNames('&', className)}
            style={style}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            {/* Toolbar */}
            <div key='toolbar' className={classNames('&-toolbar', toolbarClassName)}>
                <ArgToolbar
                    key='left'
                    className={classNames('&-toolbar-left')}
                    prefix='left'
                    disabled={disabled}
                    toolbarContext={toolbarContext}
                    selectedEditor={editorTool?.path}
                    onEditorSelection={onEditorSelected}
                    selectedPanel={leftSelectedPanel}
                    onPanelSelection={handleLeftPanelSelection}
                    hideEditor={hideEditor}
                />
                <div className={classNames('&-toolbar-space')} />
                <ArgToolbar
                    key='center'
                    className={classNames('&-toolbar-center')}
                    prefix='center'
                    disabled={disabled}
                    toolbarContext={toolbarContext}
                    selectedEditor={editorTool?.path}
                    onEditorSelection={handleEditorSelection}
                    hideEditor={hideEditor}
                />
                <div className={classNames('&-toolbar-space')} />
                <ArgToolbar
                    key='right'
                    className={classNames('&-toolbar-right')}
                    prefix='right'
                    disabled={disabled}
                    toolbarContext={toolbarContext}
                    selectedEditor={editorTool?.path}
                    onEditorSelection={onEditorSelected}
                    selectedPanel={rightSelectedPanel}
                    onPanelSelection={handleRightPanelSelection}
                    hideEditor={hideEditor}
                />
            </div>

            {/* Body */}
            <div key='body' className={classNames('&-body')}>
                {/* Left Panel */}
                {leftPanel?.panelRender && (
                    <Resizable
                        key='left-panel'
                        className={classNames('&-body-left-panel')}
                        enable={LEFT_ENABLE}
                        size={leftPanelSize}
                        minWidth={leftPanelMinWidth}
                        maxWidth={leftPanelMaxWidth}
                        onResizeStop={handleLeftPanelResized}
                    >
                        <div className={classNames('&-body-left-panel-content')} data-testid='left-panel-content'>
                            <ArgErrorCatcher
                                renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
                            >
                                {leftPanel.panelRender(leftPanel)}
                            </ArgErrorCatcher>
                        </div>
                    </Resizable>
                )}

                {/* Content */}
                <div key='content' ref={contentRef} className={classNames('&-body-content', bodyClassName)}>
                    <ArgErrorCatcher renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}>
                        {body}
                        {children}

                        {floatingToolbar &&
                            <ArgFloatingToolbar
                                toolbarContext={floatingToolbar}
                                title={floatingToolbarTitle}
                                messageValues={messageValues}
                            />}
                    </ArgErrorCatcher>
                </div>

                {/* Right panel */}
                {rightPanel?.panelRender && (
                    <Resizable
                        key='right-panel'
                        className={classNames('&-body-right-panel')}
                        data-testid='right-panel-content-resizable'
                        enable={RIGHT_ENABLE}
                        size={rightPanelSize}
                        minWidth={rightPanelMinWidth}
                        maxWidth={rightPanelMaxWidth}
                        onResizeStop={handleRightPanelResized}
                    >
                        <div className={classNames('&-body-right-panel-content')} data-testid='right-panel-content'>
                            <ArgErrorCatcher
                                renderError={(error, errorInfo) => handleRenderError(error, errorInfo)}
                            >
                                {rightPanel.panelRender(rightPanel)}
                            </ArgErrorCatcher>
                        </div>
                    </Resizable>
                )}
            </div>

            {/* Footer */}
            {footerContainer}
        </div>
    );
}

function useSizeDimension(field: keyof Size, defaultDimension: number | string, externalDimension: number | undefined): [PanelSize, Size | undefined] {
    const defaultSizeRef = useRef<Size>();
    const panelSizeRef = useRef<PanelSize>();

    let size = panelSizeRef.current;

    if (isNumber(externalDimension)) {
        if (!size) {
            size = {
                width: 'auto',
                height: 'auto',
            };
            panelSizeRef.current = size;
        }
        size[field] = externalDimension;

        return [size, undefined];
    }

    if (size) {
        return [size, undefined];
    }

    defaultSizeRef.current = {
        width: 'auto',
        height: 'auto',
    };
    defaultSizeRef.current[field] = defaultDimension;

    size = {
        ...defaultSizeRef.current,
    };

    return [size, defaultSizeRef.current];
}

function computeToolbarNodes(
    toolbarNodesRight: ToolTreeNode[],
    selectedPanelPath?: string,
    selectedEditorPath?: string
): SideInfo {
    let panel: Tool | undefined = undefined;
    let editor: Tool | undefined = undefined;
    const editors: Tool[] = [];

    walkNode(toolbarNodesRight, (node) => {
        if (isToolDisabled(node) || !isToolVisible(node)) {
            return;
        }

        if (node.type === 'panel' && node.path === selectedPanelPath) {
            panel = node;

            return;
        }
        if (node.type === 'editor') {
            editors.push(node);

            if (node.path === selectedEditorPath) {
                editor = node;
            }

            return;
        }
    });

    const ret: SideInfo = { panel, editor, editors };

    return ret;
}
