import { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';

import { ArgButton, ArgInputText, useClassNames, useArgNotifications } from 'src/components/basic';
import { User } from 'src/model/user';
import usersAdminConnector from 'src/utils/connectors/users-admin-connector';

import './change-password.less';


export const messages = defineMessages({
    title: {
        id: 'settings.change-password.title',
        defaultMessage: 'Change password',
    },
    validate: {
        id: 'settings.change-password.buttons.validate',
        defaultMessage: 'Validate',
    },
    changePasswordErrorMsg: {
        id: 'settings.change-password.error-message',
        defaultMessage: 'Something went wrong while updating the password',
    },
    changePasswordErrorMsgNoMatch: {
        id: 'settings.change-password.error-message-nomatch',
        defaultMessage: 'The passwords do not match',
    },
    passwordPlaceholder: {
        id: 'settings.change-password.placeholder',
        defaultMessage: 'New password',
    },
    confirmPasswordPlaceholder: {
        id: 'settings.change-password.confirm',
        defaultMessage: 'Confirm new password',
    },
    changeSuccessful: {
        id: 'settings.change-password-modal.change-successful',
        defaultMessage: 'The password has been changed',
    },
});

interface FormFields {
    newPassword: string;
    confirmNewPassword: string;
}

type FormFieldsErrors = Partial<Record<keyof FormFields, boolean>>;

interface ChangePasswordProps {
    user: User;
}

export function ChangePassword(props: ChangePasswordProps) {
    const {
        user,
    } = props;

    const classNames = useClassNames('change-password');
    const [loading, setLoading] = useState(false);
    const notifications = useArgNotifications();

    const submitForm = async (values: FormFields, { resetForm }: FormikHelpers<FormFields>) => {
        if (values.newPassword == '') {
            notifications.snackError({ message: messages.changePasswordErrorMsg });
            setLoading(false);

            return;
        }

        setLoading(true);

        try {
            await usersAdminConnector.changePassword(user.id, values.newPassword);
            resetForm();
            notifications.snackSuccess({
                message: messages.changeSuccessful,
            });
        } catch (error) {
            notifications.snackError({ message: messages.changePasswordErrorMsgNoMatch }, error as Error);
        }
        setLoading(false);
    };

    const validateFrom = (values: FormFields) => {
        const errors: FormFieldsErrors = {};
        if (!values.newPassword) {
            errors.newPassword = true;
            errors.confirmNewPassword = true;
        }

        return errors;
    };

    const {
        handleSubmit,
        handleChange,
        values: formValues,
        errors: formErrors,
    } = useFormik<FormFields>({
        initialValues: {
            newPassword: '', confirmNewPassword: '',
        },
        validateOnChange: false,
        validate: validateFrom,
        onSubmit: submitForm,
    });

    return (
        <div className={classNames('&')}>
            <form onSubmit={() => handleSubmit()} className={classNames('&-form')}>
                <div className={classNames('&-header')}>
                    <h1 className={classNames('&-header-title-container')}>
                        <span className={classNames('&-header-title')}>
                            <FormattedMessage
                                {...messages.title}
                            />
                        </span>
                    </h1>
                </div>
                {/* Password */}
                <div className={classNames('&-item')}>
                    <ArgInputText
                        size='large'
                        inputType='password'
                        autoFocus={true}
                        value={formValues.newPassword}
                        state={formErrors.newPassword ? 'invalid' : undefined}
                        onInputChange={handleChange('newPassword')}
                        placeholder={messages.passwordPlaceholder}
                        className={classNames(
                            '&-item-input',
                            '&-item-input-password'
                        )}
                    />
                </div>
                {/* Confirm password */}
                <div className={classNames('&-item')}>
                    <ArgInputText
                        size='large'
                        inputType='password'
                        value={formValues.confirmNewPassword}
                        state={formErrors.confirmNewPassword ? 'invalid' : undefined}
                        onInputChange={handleChange('confirmNewPassword')}
                        placeholder={messages.confirmPasswordPlaceholder}
                        className={classNames(
                            '&-item-input',
                            '&-item-input-password'
                        )}
                    />
                </div>

                <div className={classNames('&-item', '&-submit')}>
                    <ArgButton
                        className={classNames('&-footer-button')}
                        type='primary'
                        label={messages.validate}
                        data-testid='change'
                        disabled={loading}
                        onClick={() => handleSubmit()}
                    />
                </div>
            </form>
        </div>
    );
}
