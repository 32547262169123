import { ReactNode } from 'react';

import { useMemoAsync } from '../arg-hooks/use-memo-async';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';

interface PromiseComponentProps {
    promise: Promise<ReactNode>;
}

export function PromiseComponent(props: PromiseComponentProps) {
    const { promise } = props;

    const [component, loading, error] = useMemoAsync<ReactNode>(async () => {
        const ret = await promise;

        return ret;
    }, [promise]);

    if (loading) {
        return <ThreeDotsLoading />;
    }
    if (error) {
        return <span>Error !</span>;
    }

    return <>{component}</>;
}

export function isPromise<T = any>(promise: any): promise is Promise<T> {
    if (promise?.then) {
        return true;
    }

    return false;
}
