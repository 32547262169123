import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { IAceEditorProps } from 'react-ace';
import { IAceEditor } from 'react-ace/lib/types';
import { Ace } from 'ace-builds';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-language_tools';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgInputText, ArgInputTextProps } from './arg-input-text';
import { ArgInputCustomComponentProps } from './arg-input';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgAceEditorInput, ArgAceEditorInputError, ArgAceLanguage } from './arg-input-expression-editor';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { computeText } from '../utils/message-descriptor-formatters';
import { InformationText } from './arg-input-expression-information-panel';
import { ArgChangeReason, ArgPlaceholderText } from '../types';
import { ArgInputExpressionCompleter } from './arg-input-expression-completer';
import { Snippet, SnippetsRepository } from './types';
import { ArgInputExpressionModal } from './arg-input-expression-modal';
import { ToolContext } from '../arg-toolbar/tool-context';
import { useArgModalManager } from '../arg-modal/use-arg-modal-manager';
import { TestResult } from './arg-input-expression-expander';

import './arg-input-expression.less';

const DEFAULT_MAX_LINES = 4;

const messages = defineMessages({
    btnExpandEditor: {
        id: 'basic.arg-input-expression.Expand',
        defaultMessage: 'Expand editor',
    },
});

export interface ArgInputExpressionProps extends ArgInputTextProps {
    expandable?: boolean;

    testDisabled?: boolean;
    testTooltip?: MessageDescriptor;

    language: string | ArgAceLanguage;
    completers?: ArgInputExpressionCompleter | ArgInputExpressionCompleter[];
    acceptedFiles?: string[];

    placeholder?: ArgPlaceholderText;
    aceProps?: IAceEditorProps;

    maxLines?: number;

    snippetsRepository?: SnippetsRepository;
    externalSnippets?: Snippet[];

    information?: InformationText;
    toolContext?: ToolContext;
    menuContext?: ToolContext;

    expandableDisabled?: boolean;
    onClickExpand?: (event: ButtonClickEvent) => void;

    onImport?: (blob: Blob) => Promise<string>;
    onExport?: (value: string) => void;
    onModalValueChange?: (value: string | null, reason?: ArgChangeReason) => void;
    onSave?: (value: string, language: string | ArgAceLanguage, cursor?: Ace.Point) => Promise<ArgAceEditorInputError[] | null | undefined>;
    onStopTest?: () => Promise<void>;
    onTest?: (progressMonitor: ProgressMonitor, value: string) => Promise<TestResult | undefined>;
    onSelectionChange?: (value?: string) => void;
    onCancel?: () => void;
}

export function ArgInputExpression(props: ArgInputExpressionProps) {
    const {
        expandable,
        aceProps,
        placeholder,
        toolContext,
        menuContext,
        testDisabled,
        testTooltip,
        language,
        size,
        debounce,
        className,
        externalSnippets,
        value,
        acceptedFiles,
        autoFocus,
        maxLines = DEFAULT_MAX_LINES,
        onCancel,
        onSelectionChange,
        snippetsRepository,
        information,
        onImport,
        onExport,
        onSave,
        onModalValueChange,
        onTest,
        onStopTest,
        onClickExpand,
        expandableDisabled = false,
        completers,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('arg-input-expression');

    const [expandEditor, setExpandEditor] = useState<boolean>(false);

    const inputRef = useRef<IAceEditor | null>(null);
    const cursorRef = useRef<Ace.Point | undefined>(undefined);

    const _placeholder = computeText(intl, placeholder);

    const handleExpandClick = useCallback((evt: ButtonClickEvent) => {
        onClickExpand?.(evt);

        if (evt.defaultPrevented) {
            return;
        }

        setExpandEditor((expandEditor) => !expandEditor);
    }, [onClickExpand]);

    const handleCancel = useCallback(() => {
        setExpandEditor(false);

        setTimeout(() => {
            inputRef.current?.focus();
            if (cursorRef.current) {
                inputRef.current?.moveCursorTo(cursorRef.current.row, cursorRef.current.column);
            }
        }, 200);

        onCancel?.();
    }, [onCancel]);

    const handleSaveExpression = useCallback(async (editorValue: string, language: string | ArgAceLanguage, cursor?: Ace.Point) => {
        setExpandEditor(false);

        const newCursor = cursor || cursorRef.current;

        if (newCursor) {
            inputRef.current?.moveCursorTo(newCursor.row, newCursor.column);
        }

        onSave?.(editorValue, language as string, cursor);
    }, [onSave]);

    const handleCursorChange = useCallback((selection: any, event: any) => {
        cursorRef.current = selection.cursor.getPosition();
    }, []);

    const myAceProps = useMemo<IAceEditorProps>(() => ({
        showGutter: false,
        fontSize: size === 'small' ? 12 : 14,
        highlightActiveLine: false,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        maxLines: 1,
        onCursorChange: handleCursorChange,
        showPrintMargin: false,
        ...aceProps,
    }), [aceProps, handleCursorChange, size]);

    const acePropsForModal = useMemo<IAceEditorProps>(() => {
        return {
            ...myAceProps,
            showGutter: true,
            highlightActiveLine: true,
            maxLines: undefined,
        };
    }, [myAceProps]);

    const renderInputComponent = useCallback((inputProps: ArgInputCustomComponentProps<string>) => {
        let aceProps = myAceProps;
        let _maxLines = 1;
        if (inputProps.internalInputValue && inputProps.internalInputValue.indexOf('\n') >= 0) {
            _maxLines = maxLines;
            aceProps = { ...acePropsForModal, showGutter: false };
        }

        return <ArgAceEditorInput
            aceProps={aceProps}
            cursorStart={cursorRef.current}
            language={language}
            inputRef={inputRef}
            maxLines={_maxLines}
            focus={autoFocus}
            placeholder={inputProps.placeholder}
            onChange={inputProps.onChange}
            onBlur={inputProps.onBlur}
            onFocus={inputProps.onFocus}
            value={inputProps.internalInputValue}
            onUnmount={inputProps.onUnmount}
            className={classNames('&-ace-inline', 'arg-input-editor')}
            completers={completers}
        />;
    }, [myAceProps, language, autoFocus, completers, classNames, maxLines, acePropsForModal]);

    const expressionModal = useArgModalManager((close) => {
        return (
            <ArgInputExpressionModal
                valueEditor={value}
                onSelectionChange={onSelectionChange}
                language={language}
                completers={completers}
                testDisabled={testDisabled}
                testTooltip={testTooltip}
                externalSnippets={externalSnippets}
                information={information}
                aceProps={acePropsForModal}
                cursorStart={cursorRef.current}
                snippetsRepository={snippetsRepository}
                toolContext={toolContext}
                menuContext={menuContext}
                onChange={onModalValueChange}
                onSave={handleSaveExpression}
                onTest={onTest}
                onStopTest={onStopTest}
                onCancel={(cursor) => {
                    cursorRef.current = cursor;
                    handleCancel();
                    close();
                }}
                acceptedFiles={acceptedFiles}
                onImport={onImport}
                onExport={onExport}
            />
        );
    }, [onModalValueChange, testDisabled, testTooltip, externalSnippets, acceptedFiles, value, language, completers, information, acePropsForModal, snippetsRepository, toolContext, onTest, onStopTest, handleCancel, onImport, onExport, onSelectionChange]);

    useEffect(() => {
        if (!expandEditor) {
            return;
        }

        expressionModal.open();
    }, [expandEditor]);

    return (
        <ArgInputText
            renderInputComponent={renderInputComponent}
            right={expandable && <ArgButton
                key='expand'
                size={size}
                type='ghost'
                className={classNames('&-expand', 'arg-input-right')}
                icon='icon-expand'
                tooltip={messages.btnExpandEditor}
                tooltipPlacement='top'
                onClick={handleExpandClick}
                disabled={expandableDisabled}
                data-testid='expand-button'
            />}
            debounce={debounce}
            {...props}
            autoFocus={autoFocus}
            placeholder={_placeholder}
            className={classNames('&', className)} //keep classname after props
        />
    );
}

