import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { UsersCache, useUserById } from '../../caches/users-repository';
import { ArgUser, ArgUserProps } from './arg-user';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgUserId, ArgUserInfo } from './arg-user-id';
import { useGetMe } from '../../../contexts/user-context';
const FORCE_LOADING = false;

const messages = defineMessages({
    unknown: {
        id: 'basic.arg-user-id.Unknown',
        defaultMessage: 'Unknown',
    },
});

export interface ArgUserByIdProps extends Omit<ArgUserProps, 'user'> {
    userId?: ArgUserId | ArgUserInfo;
    userIdsCache?: UsersCache;
}

export function ArgUserById(props: ArgUserByIdProps) {
    const {
        className,
        userId,
        userIdsCache,
        size,
        label,
        ...otherProps
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('arg-user-id');
    const { me } = useGetMe();

    const [userInfo, haveDisplayName] = useMemo(() => {
        const isUserInfo = typeof (userId) === 'object';
        const _userId = isUserInfo ? userId.id : userId as ArgUserId;

        if (_userId === me.id) {
            return [me as ArgUserInfo, true];
        }

        const user = {
            id: _userId,
            displayName: isUserInfo
                ? userId.displayName
                : intl.formatMessage(messages.unknown),
        };

        return [user, isUserInfo];
    }, [userId, intl, me]);

    const [user] = useUserById(userInfo.id, userIdsCache);

    // User visual (except for isOnline) depends of the displayName, so we can already display it if whe have displayName
    const loading = FORCE_LOADING || (!haveDisplayName && user === undefined);

    return <ArgUser
        {...otherProps}
        loading={loading}
        user={user ?? userInfo}
        size={size}
        label={label}
        className={classNames('&', className)}
    />;
}
