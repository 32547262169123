import { useCallback, useEffect, useRef } from 'react';

export function useSetInterval(delay: number): [((start: () => void) => void), () => void] {
    const intervalIdRef = useRef<ReturnType<typeof setInterval>>();

    const startInterval = useCallback((afterDelayCallback: () => void) => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
        intervalIdRef.current = setInterval(afterDelayCallback, delay);
    }, [delay]);

    const stopInterval = useCallback(() => {
        if (!intervalIdRef.current) {
            return;
        }
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = undefined;
    }, []);

    useEffect(() => {
        return () => {
            stopInterval();
        };
    }, []);

    return [startInterval, stopInterval];
}
