import React, { useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { Props } from 'react-intl/src/components/relative';

import { computeRelativeTime } from '../../../utils/compute-relative-time';
import { DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';

// import './arg-relative-time.less';

const messages = defineMessages({
    tooltip: {
        id: 'basic.arg-relative-time.tooltip',
        defaultMessage: '{date, date, full} at {date, time, medium}',
    },
});

interface ArgRelativeTimeProps extends Props {
    date?: Date;
    className?: ClassValue;
    skipDateTime?: boolean;

    messageValues?: ArgMessageValues;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;
    onTooltipVisibleChange?: (visible: boolean) => void;
}

export function ArgRelativeTime(props: ArgRelativeTimeProps) {
    const {
        numeric,
        style,
        className,
        date,
        skipDateTime,

        tooltip,
        tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT,
        tooltipClassName,
        onTooltipVisibleChange,

        messageValues,
    } = props;

    const classNames = useClassNames('arg-relative-time');

    const spanRef = useRef<HTMLSpanElement>(null);

    const relativeTime = computeRelativeTime(date, undefined, skipDateTime);

    const renderDateTooltip = useCallback(() => {
        if (!date) {
            return null;
        }

        return <FormattedMessage {...messages.tooltip} values={{ date }} />;
    }, [date]);

    if (!relativeTime) {
        return null;
    }

    let comp = <span data-testid='relative-time' className={classNames('&', className)} ref={spanRef}>
        <FormattedRelativeTime
            value={relativeTime[0]}
            style={style}
            numeric={numeric}
            unit={relativeTime[1]}
            updateIntervalInSeconds={(relativeTime[1] === 'minute' || relativeTime[1] === 'hour') ? 60 : undefined} //updateIntervalInSeconds cannot be enabled for unit longer than hour
        />
    </span>;

    const tooltipContent = (tooltip === true) ? renderDateTooltip : tooltip;

    if (tooltipContent) {
        comp =
            <ArgTooltip2
                key='tooltip'
                className={classNames('&-tooltip', tooltipClassName)}
                title={tooltipContent}
                messageValues={messageValues}
                placement={tooltipPlacement}
                onOpenChange={onTooltipVisibleChange}
            >
                {comp}
            </ArgTooltip2>;
    }

    return comp;
}
