import { useBasicState } from '../use-basic-state';
import { getAdministrationRtApi } from '../../../administration/rt-states/rt-apis';
import { UserSettingsState } from './user-settings-state';


export function userStateFactory(url: string) {
    const api = getAdministrationRtApi();

    return new UserSettingsState(api, url);
}

export function computeUserSettingsStateURL() {
    return '/users/me/settings';
}

export function useUserSettingsState(): UserSettingsState {
    const state = useBasicState<UserSettingsState>(
        (url) => userStateFactory(url),
        computeUserSettingsStateURL()
    );

    return state!;
}
