import { map, pull, pullAll, size } from 'lodash';
import Debug from 'debug';

import { ProgressMonitor, SubProgressMonitor } from '../progress-monitors/progress-monitor';
import { $yield } from '../utils/yield';

export type LoadBulkFunction<K, R> = (group: string | undefined, infos: K[], progressMonitor: ProgressMonitor) => Promise<(R | null)[]>;

const debug = Debug('basic:cache-repositories:BulkRequests');

const DEFAULT_DELAY_MS = 250;

const UNDEFINED_KEY = `****UNDEFINED-KEY****${Date.now()}`;

interface Waiting<K, R> {
    infos: K;
    resolve: (value: R | null) => void;
    reject: (error: Error) => void;
}

export class BulkRequests<K, R> {
    readonly #name: string;
    readonly #loadBulk: LoadBulkFunction<K, R>;
    readonly #delayMs: number;
    readonly #runningProgressMonitors: ProgressMonitor[] = [];

    #timerId?: ReturnType<typeof setTimeout>;
    #infos: Record<string, Waiting<K, R>[]> = {};
    #disposed = false;

    constructor(name: string, loadBulk: LoadBulkFunction<K, R>, delayMs = DEFAULT_DELAY_MS) {
        this.#name = name;
        this.#loadBulk = loadBulk;
        this.#delayMs = delayMs;
    }

    get name() {
        return this.#name;
    }

    async get(groupName: string = UNDEFINED_KEY, infos: K, immediate = false): Promise<R | null> {
        debug('get', 'groupName=', groupName, 'infos=', infos, 'immediate=', immediate);

        if (this.#disposed) {
            throw new Error('Bulk request engine is disposed');
        }

        const p = new Promise<R | null>((resolve, reject) => {
            let group = this.#infos[groupName];
            if (!group) {
                group = [];
                this.#infos[groupName] = group;
            }

            group.push({
                infos,
                resolve,
                reject,
            });
        });

        if (immediate) {
            if (this.#timerId) {
                clearTimeout(this.#timerId);
                this.#timerId = undefined;
            }

            $yield(this.#process);

            return p;
        }

        if (this.#timerId) {
            return p;
        }

        debug('get', 'launch timer');
        this.#timerId = setTimeout(this.#process, this.#delayMs);

        return p;
    }

    #process = async () => {
        debug('#process', 'infos=', this.#infos);

        if (this.#disposed) {
            throw new Error('Bulk is disposed');
        }

        const infos = this.#infos;
        this.#infos = {};
        this.#timerId = undefined;

        const progressMonitor = new ProgressMonitor(`BulkRequest:${this.#name}`, size(infos));
        this.#runningProgressMonitors.push(progressMonitor);
        try {
            const ps = map(infos, (groupInfos, groupName) => {
                const groupProgressMonitor = new SubProgressMonitor(progressMonitor, 1);

                const promise = this.#processGroup(
                    groupName === UNDEFINED_KEY ? undefined : groupName,
                    groupInfos,
                    groupProgressMonitor
                );

                return promise;
            });

            await Promise.all(ps);

            debug('#process', 'terminated');
        } finally {
            pull(this.#runningProgressMonitors, progressMonitor);
        }
    };

    async #processGroup(groupName: string | undefined, groupInfos: Waiting<K, R>[], progressMonitor: ProgressMonitor) {
        debug('#processGroup', 'groupName=', groupName, 'groupInfos=', groupInfos);

        if (this.#disposed) {
            throw new Error('Bulk is disposed');
        }

        const loadBulk = this.#loadBulk;
        const infosByGroupName = groupInfos.map((g) => g.infos);

        try {
            const results = await loadBulk(
                groupName === UNDEFINED_KEY ? undefined : groupName,
                infosByGroupName,
                progressMonitor
            );

            progressMonitor.verifyCancelled();

            debug('#processGroup', 'results=', results);

            groupInfos.forEach((gi, index) => {
                progressMonitor.verifyCancelled();

                const result = results[index];
                if (result === undefined) {
                    console.warn(`Response of bulk ${this.name} response #${index} must not de be undefined`);
                    gi.resolve(null);

                    return;
                }

                gi.resolve(result);
            });
        } catch (error) {
            console.error(error);

            groupInfos.forEach((gi) => {
                progressMonitor.verifyCancelled();

                gi.reject(error as Error);
            });
        }
    }

    dispose() {
        debug('dispose');

        if (this.#disposed) {
            return;
        }
        this.#disposed = true;

        if (this.#timerId) {
            clearTimeout(this.#timerId);
            this.#timerId = undefined;
        }

        this.#runningProgressMonitors.forEach((progressMonitor: ProgressMonitor) => {
            progressMonitor.cancel();
        });
        pullAll(this.#runningProgressMonitors);
    }
}
