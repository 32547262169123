import React, { MutableRefObject, ReactNode, SyntheticEvent, useCallback, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ConfigProvider } from 'antd';
import Debug from 'debug';

import { ClassValue } from '../arg-hooks/use-classNames';
import { DisableDndContainer } from '../arg-dnd/disable-dnd-container';
import { copyStylesOfDocument } from './utils';
import { $yield } from '../utils/yield';

const debug = Debug('basic:ArgPortalIFrame');

interface ArgIframeProps {
    className?: ClassValue;

    iframeRef?: MutableRefObject<HTMLIFrameElement | null>;

    onReady?: () => void;

    bodyClassName?: ClassValue;
    containerClassName?: ClassValue;

    children?: ReactNode;

    blockDrop?: boolean;
}

export function ArgIframePortal(props: ArgIframeProps) {
    const {
        className,
        onReady,
        children,
        bodyClassName,
        containerClassName,
        blockDrop,
        iframeRef,
    } = props;

    const [container, setContainer] = useState<Element>();

    const handleLoad = useCallback((event: SyntheticEvent) => {
        debug('handleLoad', 'Iframe loaded');

        const iframe = event.nativeEvent.target as HTMLIFrameElement;

        const body = iframe.contentDocument!.body;
        for (; body?.firstChild;) {
            body.removeChild(body.firstChild);
        }

        if (bodyClassName) {
            body.className = classNames(bodyClassName);
        }

        const container = body.ownerDocument.createElement('div');
        body.appendChild(container);

        if (containerClassName) {
            container.className = classNames(containerClassName);
        }

        setContainer(container);

        //console.log('[iframeLoaded] container=', container, iframe);

        // If specified, copy styles from parent window's document.
        $yield(() => copyStylesOfDocument(document, iframe.contentDocument!));

        if (iframeRef) {
            iframeRef.current = iframe;
        }

        onReady?.();
    }, [bodyClassName, containerClassName, iframeRef, onReady]);

    const handleGetPopupContainer = useCallback((node?: HTMLElement): HTMLElement => {
        if (node) {
            const body: HTMLElement | undefined = node.ownerDocument?.body;
            if (body) {
                return body;
            }
        }

        const body = container?.ownerDocument?.body;
        if (body) {
            return body;
        }

        return document.body;
    }, [container]);

    let _children = children;
    if (container) {
        if (blockDrop) {
            _children = <DisableDndContainer document={container.ownerDocument}>
                {children}
            </DisableDndContainer>;
        }

        _children = <ConfigProvider getPopupContainer={handleGetPopupContainer}>
            {_children}
        </ConfigProvider>;
    }

    // workaround to make it work on several environnement (expecially linux/chromium)
    useLayoutEffect(() => {
        if (iframeRef?.current) {
            iframeRef.current.src = 'about:blank';
        }
    }, []);

    return <>
        <iframe
            className={classNames(className)}
            ref={iframeRef}
            onLoad={handleLoad}
        />
        {container && ReactDOM.createPortal(_children, container)}
    </>;
}
