import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import { Avatar } from 'antd';
import { defineMessages, useIntl } from 'react-intl';
import { isFunction, isObject, isUndefined } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgSize } from '../types';
import { ArgButton } from '../arg-button/arg-button';
import { ArgUserId, ArgUserInfo } from './arg-user-id';
import { UsersCache, UsersCacheContext, UsersCacheScope } from '../../caches/users-repository';
import { ArgUserById } from './arg-user-by-id';

import './arg-users.less';

const MAX_USERS = 4;
const ANIMATION_TIMEOUT = 500;

const messages = defineMessages({
    moreUsers: {
        id: 'basic.arg-users.MoreUsers',
        defaultMessage: 'More users',
    },
});

export interface ArgUsersProps {
    userIdOrInfos: (ArgUserId | ArgUserInfo)[];
    usersCount?: number;
    maxUsers?: number;
    hidden?: boolean;
    displayUserLabel?: boolean;
    size: ArgSize | 'tiny';
    className?: ClassValue;
    onClick?: (user: ArgUserId | undefined) => void;

    trackedUserId?: ArgUserId;

    left?: ReactNode;
    right?: ReactNode;

    userIdsCache?: UsersCache;

    renderUser?: (userIdOrInfo: ArgUserId | ArgUserInfo, className: ClassValue) => ReactNode;

    draggable?: boolean;
}

export function ArgUsers(props: ArgUsersProps) {
    const {
        hidden,
        className,
        onClick,
        trackedUserId,
        displayUserLabel,
        left,
        right,
        size,
        userIdOrInfos,
        maxUsers = MAX_USERS,
        userIdsCache: userIdsCacheByParam,
        renderUser,
        draggable,
    } = props;
    const classNames = useClassNames('arg-users');
    const intl = useIntl();

    const userIdsCacheByContext = useContext(UsersCacheContext);

    const handleUserClick = useCallback((userId: ArgUserId) => {
        onClick?.(userId);
    }, [onClick]);

    const {
        users: _users,
        hasMoreUsers,
    } = useMemo(() => {
        let _users = userIdOrInfos;

        let hasMoreUsers = 0;
        if (userIdOrInfos.length > maxUsers) {
            hasMoreUsers = userIdOrInfos.length - maxUsers + 1;
            _users = userIdOrInfos.slice(0, maxUsers - 1);
        }

        return {
            users: _users,
            hasMoreUsers,
        };
    }, [userIdOrInfos, maxUsers]);

    const handleMoreUsersTooltip = useCallback(() => {
        const left = userIdOrInfos.slice(maxUsers - 1);

        const className = classNames('&-users-list-item-user');

        return <div className={classNames('&-users-list')}>
            {left.map((userId: ArgUserId | ArgUserInfo) => {
                const _userId = isObject(userId) ? userId.id : userId;

                let comp: ReactNode;
                if (isFunction(renderUser)) {
                    comp = renderUser(userId, className);
                } else {
                    comp = <ArgUserById
                        size={size}
                        className={className}
                        userId={userId}
                        tooltip={false}
                        userIdsCache={userIdsCacheByParam}
                        label={displayUserLabel}
                        onClick={() => handleUserClick(_userId)}
                        draggable={draggable}
                    />;
                }

                return (
                    <div key={_userId} className={classNames('&-users-list-item')}>
                        {comp}
                    </div>
                );
            })}
        </div>;
    }, [userIdOrInfos, maxUsers, classNames, renderUser, size, userIdsCacheByParam, handleUserClick]);

    if (hidden) {
        return null;
    }

    const usersList = _users.map((userId: ArgUserId | ArgUserInfo) => {
        const _userId = isObject(userId) ? userId.id : userId;

        const tracked = (_userId === trackedUserId);
        const clicked = !isUndefined(onClick);

        const className = classNames('&-user', { tracked }, { clicked });

        let comp: ReactNode;
        if (isFunction(renderUser)) {
            comp = renderUser(userId, className);
        } else {
            comp = <ArgUserById
                size={size}
                className={className}
                userId={userId}
                userIdsCache={userIdsCacheByParam}
                label={false}
                onClick={() => handleUserClick(_userId)}
                draggable={draggable}
            />;
        }

        return (
            <CSSTransition
                key={_userId}
                timeout={ANIMATION_TIMEOUT}
                classNames={classNames('&-anim')}
            >
                {comp}
            </CSSTransition>
        );
    });

    const moreUsers = (hasMoreUsers > 0 && size !== 'tiny') ? (
        <CSSTransition
            key='more-users'
            timeout={ANIMATION_TIMEOUT}
            classNames={classNames('&-anim')}
        >
            <ArgButton
                data-testid='more-users'
                type='ghost'
                size={size}
                className={classNames('&-more-users', 'arg-user')}
                tooltip={handleMoreUsersTooltip}
                tooltipClassName={classNames('&-more-users-tooltip')}
                icon={(
                    <Avatar
                        key='avatar'
                        className={classNames('&-avatar', '&-has-more-users')}
                        alt={intl.formatMessage(messages.moreUsers)}
                    >+{hasMoreUsers}
                    </Avatar>
                )}
            />
        </CSSTransition>
    ) : null;

    const cls = {
        tiny: size === 'tiny',
    };

    let comp = (
        <TransitionGroup className={classNames('&', className, cls)}>
            {left}
            {usersList}
            {moreUsers}
            {right}
        </TransitionGroup>
    );

    if (!userIdsCacheByParam && !userIdsCacheByContext) {
        comp = (
            <UsersCacheScope name='arg-users'>
                {comp}
            </UsersCacheScope>
        );
    }

    return comp;
}
