import { computeUserName } from '../../utils/digram';
import { USER_DND_TYPE, UserDataTransfer } from '../../model/dnd-types';
import { ArgUserInfo } from '../basic';

const USER_FIELDS = ['id', 'displayName'];

export function fillFromUser(dataTransfer: DataTransfer, user: ArgUserInfo) {
    const userParams: UserDataTransfer = {};
    USER_FIELDS.forEach((k) => {
        const v = (user as any)[k];
        if (v !== undefined) {
            userParams[k] = v;
        }
    });

    const params = JSON.stringify(userParams);

    dataTransfer.setData(USER_DND_TYPE, params);
    dataTransfer.setData('text/plain', computeUserName(user) || '');

    return params;
}

export function fillUrlInDataTransfer(dataTransfer: DataTransfer, url: string, name?: string) {
    dataTransfer.setData('text/x-moz-url', `${url}\r\n${name || url}\r\n`);
    dataTransfer.setData('text/uri-list', `${url}\r\n`);
    dataTransfer.setData('text/html', `<a href=\"${url}\">${url}</a>`);
    dataTransfer.setData('text/plain', name || '');
}
