import React from 'react';

import { ArgSize } from '../types';
import { DEFAULT_SIZE } from '../defaults';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './arg-icon-checkbox.less';

export type ArgIconCheckboxStates = false | true | 'minus' | undefined;

interface ArgIconCheckboxProps {
    className?: ClassValue;
    state: ArgIconCheckboxStates;
    size?: ArgSize | 'node';
}

export function ArgIconCheckbox(props: ArgIconCheckboxProps) {
    const {
        state,
        className,
        size = DEFAULT_SIZE,
    } = props;

    const classNames = useClassNames('arg-icon-checkbox');

    const cls = {
        'state-true': state === true,
        'state-false': state === false,
        'state-minus': state === 'minus',
        [`size-${size}`]: true,
    };

    return (
        <div className={classNames('&', className, cls)}
             data-testid={`checkbox-${state === 'minus' ? 'minus' : state ? 'checked' : 'unchecked'}-state`}>
            {state === true && <i className='icon-validate' />}
            {state === 'minus' && <i className='icon-minus' />}
        </div>
    );
}
