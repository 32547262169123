import { ElementProps, FloatingContext, ReferenceType } from '@floating-ui/react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export interface UseLongClickProps {
    enabled?: boolean;
    delay?: number;
}

// A hook that open floating ui on long click.
//
// Note: This hook follows the floating-ui hook interaction pattern and is be used with the useInteractions().
export function useLongClick<RT extends ReferenceType = ReferenceType>(context: FloatingContext<RT>, props: UseLongClickProps = {}): ElementProps {
    const {
        enabled = true,
        delay = 500,
    } = props;
    const {
        onOpenChange,
    } = context;
    const unmountedRef = useRef<boolean>();
    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    const start = useCallback((el: Element) => {
        const timeoutId = setTimeout(() => {
            if (unmountedRef.current) {
                return;
            }
            cleanup();
            onOpenChange?.(true);
        }, delay);

        function cleanup() {
            clearTimeout(timeoutId);
            el.removeEventListener('mouseup', cleanup);
            el.removeEventListener('mouseleave', cleanup);
        }

        el.addEventListener('mouseup', cleanup);
        el.addEventListener('mouseleave', cleanup);
    }, [onOpenChange, delay]);

    return useMemo(() => {
        if (!enabled) {
            return {};
        }

        return {
            reference: {
                onMouseDown(event) {
                    if (event.target instanceof Element && enabled) {
                        start(event.target);
                    }
                },
            },
        };
    }, [enabled, start]);
}
