import { isNumber, isString } from 'lodash';

import { ArgonosError } from './argonos-error';

const RESPONSE_ERROR = Symbol('Webservice error');

export interface ResponseProblemDetails {
    type: string;
    title: string;
    status: number;

    instance?: string;
    detail?: string;

    [key: string]: any;
}

export class ResponseError extends ArgonosError {
    readonly #url: string;
    readonly #response: Response | undefined;
    readonly #json: any | undefined;

    constructor(message: string, url: string, reason?: Error, response?: Response, json?: any) {
        super(message, RESPONSE_ERROR, reason);

        this.#url = url;
        this.#response = response;
        this.#json = json;
    }

    get json(): any | undefined {
        return this.#json;
    }

    get problemDetails(): ResponseProblemDetails | undefined {
        const json = this.json;
        if (!json) {
            return undefined;
        }

        if (!isNumber(json.status) || !isString(json.type) || !isString(json.title)) {
            return undefined;
        }

        return json as ResponseProblemDetails;
    }

    get problemDetailsType(): string | undefined {
        const details = this.problemDetails;
        if (!details) {
            return undefined;
        }

        return details.type;
    }

    get problemDetailsStatus(): number | undefined {
        const details = this.problemDetails;
        if (!details) {
            return undefined;
        }

        return details.status;
    }

    get url(): string {
        return this.#url;
    }

    get response(): Response | undefined {
        return this.#response;
    }

    get responseStatus(): number | undefined {
        return this.#response?.status;
    }
}

export function isResponseError(error: any): error is ResponseError {
    if (!(error instanceof ResponseError)) {
        return false;
    }

    return true;
}

export function isCorsError(error: any): boolean {
    if (!isResponseError(error)) {
        return false;
    }

    const ret = !error.response;

    return ret;
}

export function isResponse500(error: any): error is ResponseError {
    return isResponseCode(error, 500);
}

export function isResponse401(error: any): error is ResponseError {
    return isResponseCode(error, 401);
}

export function isResponse404(error: any): error is ResponseError {
    return isResponseCode(error, 404);
}

export function isResponse403(error: any): error is ResponseError {
    return isResponseCode(error, 403);
}

export function isResponse409(error: any): error is ResponseError {
    return isResponseCode(error, 409);
}

function isResponseCode(error: any, responseCode: number): error is ResponseError {
    if (!isResponseError(error)) {
        return false;
    }

    if (error.response?.status === responseCode) {
        return true;
    }

    return false;
}

export function getProblemDetailsStatus(error: any): number | undefined {
    if (!isResponseError(error)) {
        return undefined;
    }

    const ret = error.problemDetailsStatus;

    return ret;
}

export function getProblemDetailsType(error: any): string | undefined {
    if (!isResponseError(error)) {
        return undefined;
    }

    const ret = error.problemDetailsType;

    return ret;
}

