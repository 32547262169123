import { ApplicationDescriptor, ApplicationType } from './application-descriptor';
import { ProgressMonitor, useMemoAsync } from '../basic';
import SettingsConnector from '../../settings/connectors/settings-connector';
import { Environment } from '../../utils/environment';
import { isResponse404 } from '../basic/utils/response-error';

const DISABLE_APPLICATION_DESCRIPTOR = false;

export function useGetApplicationDescriptor(applicationType: undefined): [undefined, undefined];
export function useGetApplicationDescriptor(applicationType: ApplicationType | undefined): [ApplicationDescriptor | undefined, ProgressMonitor | undefined];

export function useGetApplicationDescriptor(applicationType: ApplicationType | undefined): [ApplicationDescriptor | undefined, ProgressMonitor | undefined] {
    const [descriptor, progressMonitor] = useMemoAsync<ApplicationDescriptor | undefined>(async (progressMonitor: ProgressMonitor) => {
        return getApplicationDescriptor(applicationType, progressMonitor);
    }, [applicationType]);

    return [descriptor, progressMonitor];
}

export async function getApplicationDescriptor(
    applicationType: ApplicationType | undefined,
    progressMonitor: ProgressMonitor
): Promise<ApplicationDescriptor | undefined> {
    if (!applicationType || DISABLE_APPLICATION_DESCRIPTOR) {
        return undefined;
    }

    const appSettingsKeys = (applicationType !== 'Application') ? `${Environment.appId}-${applicationType}` : Environment.appId;

    let descriptor;
    try {
        descriptor = await SettingsConnector.getAppSettingsJSON(appSettingsKeys, progressMonitor);
    } catch (x) {
        if (isResponse404(x)) {
            return undefined;
        }
        console.error('Can not get app settings', x);

        throw x;
    }

    const ret: ApplicationDescriptor = {
        ...descriptor,
        type: applicationType,
        logoURL: descriptor?.hasLogo && SettingsConnector.computeAppSettingsURL(`${appSettingsKeys}-logo`),
        iconURL: descriptor?.hasIcon && SettingsConnector.computeAppSettingsURL(`${appSettingsKeys}-icon`),
    };

    return ret;
}
