import { useCallback } from 'react';

import { ArgInputNumber } from '../arg-input/arg-input-number';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgSliderCommonProps } from './types';
import { ArgChangeReason } from '../types';
import { ArgSlider } from './arg-slider';

import './arg-slider.less';

export interface ArgSliderInputProps extends ArgSliderCommonProps<number> {
    unitSymbol?: string;
}

export function ArgSliderInput(props: ArgSliderInputProps) {
    const {
        className,
        min,
        max,
        value,
        unitSymbol,
        onChange,
        tooltip,
        step,
        disabled,
        size,
    } = props;

    const classNames = useClassNames('arg-slider');

    const onChangeInput = useCallback((value: number | null, reason: ArgChangeReason) => {
        onChange(value ?? 0, reason);
    }, [onChange]);

    return (
        <div className={classNames('&-container', className)}>
            <ArgSlider
                className={classNames('&-slider')}
                min={min}
                max={max}
                value={value}
                onChange={onChange}
                tooltip={tooltip}
                step={step}
                disabled={disabled}
            />
            <ArgInputNumber
                className={classNames('&-input')}
                min={min}
                max={max}
                value={value}
                displayRightControl={false}
                clearable={false}
                suffix={unitSymbol}
                onChange={onChangeInput}
                size={size}
            />
        </div>
    );
}
