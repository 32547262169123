import { useEffect, useState } from 'react';
import { isNil } from 'lodash';

export type ArgDraggableType = 'selection' | boolean | undefined;

export function useDraggableKey(
    draggable?: ArgDraggableType,
    draggableModifierKey?: 'Control'
): boolean {
    const [draggingIsEnabled, setDraggingEnabled] = useState<boolean>(false);

    // Handle key modifier to DND
    useEffect(() => {
        // Handle only 'selection' and true values for "draggable"
        if (isNil(draggable)) {
            return;
        }

        // Considers modifier is pressed when no modifier needed
        if (isNil(draggableModifierKey)) {
            setDraggingEnabled(true);

            return;
        }

        const handler = (event: KeyboardEvent) => {
            if (draggableModifierKey === 'Control') {
                setDraggingEnabled(event.type === 'keydown' && (event.ctrlKey || event.metaKey));
            }
        };

        window.addEventListener('keydown', handler, { capture: true });
        window.addEventListener('keyup', handler, { capture: true });

        return () => {
            window.removeEventListener('keydown', handler, { capture: true });
            window.removeEventListener('keyup', handler, { capture: true });
        };
    }, [draggable, draggableModifierKey]);

    return draggingIsEnabled;
}
