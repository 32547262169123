import React, { Fragment, ReactNode, useCallback, useState } from 'react';
import { isEmpty, isEqual, isFunction, map, omit } from 'lodash';

import { useToolNodes } from './use-tool-nodes';
import { ArgToolbarItem } from './arg-toolbar-item';
import { ArgToolbarCombo } from './arg-toolbar-combo';
import { ArgToolbarDivider } from './arg-toolbar-divider';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgRenderedText } from '../types';
import { ToolContext, ToolTreeContext, ToolTreeNode } from './tool-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { countVisibleChildren } from './utils';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { Tool } from './tool';
import { useRenderToolMenuFromNodes } from './arg-tool-menu';
import { preventContextMenu } from '../../../utils/prevent-context-menu';
import { ArgToolBarLabel } from './arg-toolbar-label';

import './arg-toolbar.less';


export interface ArgToolbarProps {
    prefix?: string;
    className?: ClassValue;
    toolbarContext: ToolContext;
    disabled?: boolean;

    selectedPanel?: string
    onPanelSelection?: (path: string) => void;

    selectedEditor?: string;
    onEditorSelection?: (path: string) => void;
    hideEditor?: boolean;
}

export function ArgToolbar(props: ArgToolbarProps) {
    const {
        prefix,
        className,
        toolbarContext,
        disabled,
        selectedPanel,
        onPanelSelection,
        selectedEditor,
        onEditorSelection,
        hideEditor,
    } = props;

    const [toolbarNodes, toolTreeContext] = useToolNodes(toolbarContext, prefix, hideEditor);
    const classNames = useClassNames('arg-toolbar');

    let shouldRenderDivider = false;
    const renderNodes = (nodes: ToolTreeNode[]): ReactNode => {
        return map(nodes, (node: ToolTreeNode) => {
            let popover: ArgRenderedText;
            if (node.type === 'group') {
                const visibleChildren = countVisibleChildren(node);
                if (!visibleChildren?.length) {
                    return;
                }

                const addDivider = visibleChildren?.length > 0 && shouldRenderDivider;
                shouldRenderDivider = true;

                return (
                    <Fragment key={node.path}>
                        {addDivider && (
                            <ArgToolbarDivider className={classNames('&-divider')} key='divider' data-path={node.path} />
                        )}

                        {renderNodes(visibleChildren)}
                    </Fragment>
                );
            } else if (node.type === 'panel') {
                const oldOnClick = node.onClick;
                node = {
                    ...node,
                    onClick(tool: Tool, event?: ButtonClickEvent) {
                        onPanelSelection?.(node.path);

                        oldOnClick?.(tool, event);
                    },
                    selected: (node.path === selectedPanel),
                };
            } else if (node.type === 'editor') {
                const oldOnClick = node.onClick;
                node = {
                    ...node,
                    onClick(tool: Tool, event?: ButtonClickEvent) {
                        onEditorSelection?.(node.path);

                        oldOnClick?.(tool, event);
                    },

                    selected: (node.path === selectedEditor),
                };
            } else if (node.type === 'combo') {
                const _tooltip = isFunction(node.tooltip) ? node.tooltip(node) : node.tooltip;

                return <ArgToolbarCombo
                    {...node}
                    disabled={disabled || node.disabled}
                    tooltip={_tooltip}
                    key={node.path}
                    className={classNames('&-item', node.className)}
                    data-path={node.path}
                />;
            } else if (node.type === 'label') {
                const _tooltip = isFunction(node.tooltip) ? node.tooltip(node) : node.tooltip;

                return <ArgToolBarLabel
                    label={node.label}
                    tooltip={_tooltip}
                    testid={node.testid}
                    customRender={node.customRender}
                    visible={node.visible}
                />;
            } else if (node.type === 'separator') {
                shouldRenderDivider = false;

                return <ArgToolbarDivider
                    className={classNames('&-divider')}
                    key={`divider-${node.path}`}
                    data-path={node.path}
                />;
            }

            if (node.type === 'button' || node.type === 'editor' || node.type === 'custom') {
                if (node.children) {
                    popover = <ArgToolbarItemMenu
                        node={node}
                        toolTreeContext={toolTreeContext}
                    />;
                }
            }

            shouldRenderDivider = true;
            const _restProps = omit(node, 'children');
            const _tooltip = isFunction(node.tooltip) ? node.tooltip(node) : node.tooltip;

            const triggerPopover = popover ? 'contextMenu' : undefined;

            const testid = node.testid && !isEmpty(node.testid) ? `arg-toolbar-item ${node.testid}` : 'arg-toolbar-item';

            return (
                <ArgToolbarItem
                    {..._restProps}
                    testid={testid}
                    popover={popover}
                    popoverPlacement='bottomLeft'
                    popoverTrigger={triggerPopover}
                    tooltip={_tooltip}
                    disabled={disabled || _restProps.disabled}
                    key={node.path}
                    icon={node.icon}
                    label={node.label}
                    className={classNames('&-item', node.className)}
                    data-path={node.path}
                />
            );
        });
    };

    const renderedNodes = renderNodes(toolbarNodes);

    const cls = {
        disabled,
    };

    return (
        <div
            className={classNames('&', (prefix) ? `&-${prefix}` : undefined, className, cls)}
            data-tool-context={toolbarContext.id}
        >
            {renderedNodes}
        </div>
    );
}

interface ArgToolbarItemMenuProps {
    className?: ClassValue;
    node: ToolTreeNode;
    toolTreeContext: ToolTreeContext;
    getPopupContainer?: (node: HTMLElement) => HTMLElement;
}

function ArgToolbarItemMenu(props: ArgToolbarItemMenuProps) {
    const {
        className,
        node,
        toolTreeContext,
        getPopupContainer,
    } = props;

    const [activeKey, setActiveKey] = useState<string>();

    const onCloseMenu = useCallback(() => {

    }, []);

    const renderedNodes = useRenderToolMenuFromNodes(
        node.children!,
        toolTreeContext,
        onCloseMenu,
        setActiveKey
    );

    return (
        <ArgMenu
            className={className}
            openKeys={activeKey ? [activeKey] : undefined}
            onContextMenu={preventContextMenu}
            getPopupContainer={getPopupContainer}
            data-path={node.path}
        >
            {renderedNodes}
        </ArgMenu>
    );
}
