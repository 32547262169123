import { useEffect } from 'react';

import { AppRoutes } from './app-routes';
import { useClassNames } from './components/basic';
import { AppGlobalProvider } from './providers/app-global-provider';
import { BuildVersionWrapper } from './components/containers/build-version-wrapper';

import './styles/application.less';

export function Application() {
    const classNames = useClassNames('arg-application');

    useEffect(() => {
        if (window.argonodeConfig || process.env.NODE_ENV !== 'production') {
            return;
        }
        alert('PANIC: The configuration is not loaded ! Verify the config.js file content.');
    }, []);

    return (
        <BuildVersionWrapper builVersionClassName={classNames('&-version')}>
            <AppGlobalProvider>
                <AppRoutes />
            </AppGlobalProvider>
        </BuildVersionWrapper>
    );
}

