import { ReactNode } from 'react';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';

const ANTD_THEME = {
    token: {
        colorPrimary: '#39939E',
    },
};

interface AntdConfigProviderProps {
    antdLocale: Locale;

    children: ReactNode;
}

export function AntdConfigProvider(props: AntdConfigProviderProps) {
    const {
        antdLocale,
        children,
    } = props;

    return (
        <ConfigProvider
            locale={antdLocale}
            theme={ANTD_THEME}
        >
            {children}
        </ConfigProvider>
    );
}
