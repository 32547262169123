import Debug from 'debug';

import { getArgLocale } from './get-locale';

const debug = Debug('common:i18n:DayjsLocale');

export async function installDayjs(localeName: string): Promise<void> {
    const locale = getArgLocale(localeName);

    if (!locale) {
        return;
    }

    const dayjsLocale = locale.getDayjsLocale;

    const promise = dayjsLocale?.();
    if (!promise) {
        return;
    }

    try {
        await promise;
        debug('loadLocale', 'Locale', localeName, 'loaded');
    } catch (error) {
        console.error('Can not load dayjs locale=', localeName, 'error=', error);
        throw error;
    }
}
