import { every, some } from 'lodash';

import { useGetMyPermissions } from './user-permissions-context';
import { UserPermissionId, UserPermissions } from '../model/user';

export function useHasPermission<T>(permissionId: keyof T): boolean {
    const { permissions } = useGetMyPermissions();

    const ret = hasPermission(permissionId, permissions);

    return ret;
}

export function hasPermission<T>(permissionId: keyof T, permissions?: UserPermissions): boolean {
    const ret = !!permissions?.[permissionId as UserPermissionId];

    return ret;
}

export function useHasEveryPermissions<T>(...permissionsId: (keyof T)[]): boolean {
    const { permissions } = useGetMyPermissions();

    const ret = every(permissionsId, (permissionId) => permissions[permissionId as UserPermissionId]);

    return ret;
}

export function useHasAnyPermissions<T>(...permissionsId: (keyof T)[]): boolean {
    const { permissions } = useGetMyPermissions();

    const ret = hasAnyPermissions(permissions, ...permissionsId);

    return ret;
}

export function hasAnyPermissions<T>(permissions?: UserPermissions, ...permissionsId: (keyof T)[]): boolean {
    const ret = !!(permissions && (!permissionsId.length || some(permissionsId, (permissionId) => !!permissions[permissionId as UserPermissionId])));

    return ret;
}

export function useCountPermissions<T>(...permissionsId: (keyof T)[]): number {
    const { permissions } = useGetMyPermissions();

    const ret = countPermissions(permissions, ...permissionsId);

    return ret;
}

export function countPermissions<T>(permissions?: UserPermissions, ...permissionsId: (keyof T)[]): number {
    const ret = permissions ? permissionsId.reduce((acc, permissionId) => {
        if (permissions[permissionId as UserPermissionId]) {
            return acc + 1;
        }

        return acc;
    }, 0) : 0;

    return ret;
}
