import React, { ComponentType, ReactNode, useCallback } from 'react';
import { isValidElementType } from 'react-is';
import { isFunction } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton, ArgButtonProps, ButtonClickEvent } from '../arg-button/arg-button';
import { Tool, ToolType } from './tool';
import { ArgButtonType, ArgSize } from '../types';
import { ArgPopoverRenderer } from '../arg-popover/types';

import './arg-toolbar-item.less';

export interface ArgToolItemRenderContext {
    onCloseMenu?(): void;

    menuItemClassName?: ClassValue;
}

export type ArgToolItemRenderFunction = (props: Tool) => ReactNode;
export type ArgToolItemRenderWithContextFunction = (props: Tool, context: ArgToolItemRenderContext, popoverRender?: ArgPopoverRenderer) => ReactNode;

export interface ArgToolItemProps extends Omit<ArgButtonProps, 'type' | 'size' | 'disabled' | 'onClick' | 'onShiftClick' | 'onAltClick' | 'onCtrlClick'> {
    type?: ToolType;
    size?: ArgSize;
    buttonType?: ArgButtonType;
    selected?: boolean;
    visible?: boolean | (() => boolean);
    disabled?: boolean | (() => boolean);
    testid?: string;
    componentType?: ComponentType;
    customRender?: ArgToolItemRenderWithContextFunction;
    menuItemCustomRender?: boolean;
    onClick?: (props: Tool, event: ButtonClickEvent) => void,
    onShiftClick?: (props: Tool, event: ButtonClickEvent) => void,
    onAltClick?: (props: Tool, event: ButtonClickEvent) => void,
    onCtrlClick?: (props: Tool, event: ButtonClickEvent) => void,
}

export function ArgToolbarItem(props: ArgToolItemProps) {
    const {
        type,
        hidden,
        size = 'medium',
        testid,
        selected,
        className,
        customRender,
        componentType,
        visible = true,
        disabled,
        onClick,
        onShiftClick,
        onAltClick,
        onCtrlClick,
        tooltip,
        keyBinding,
        buttonType = 'ghost',
        popover,
        ...restProps
    } = props;

    const classNames = useClassNames('arg-toolbar-item');

    const toolProps = props as Tool; // @TODO improve perf ?

    const handleClick = useCallback((event: ButtonClickEvent) => {
        onClick?.(toolProps, event);
    }, [onClick, toolProps]);

    const handleShiftClick = useCallback((event: ButtonClickEvent) => {
        onShiftClick?.(toolProps, event);
    }, [onShiftClick, toolProps]);

    const handleAltClick = useCallback((event: ButtonClickEvent) => {
        onAltClick?.(toolProps, event);
    }, [onAltClick, toolProps]);

    const handleCtrlClick = useCallback((event: ButtonClickEvent) => {
        onCtrlClick?.(toolProps, event);
    }, [onCtrlClick, toolProps]);

    if (hidden) {
        return null;
    }
    if (visible === false || (isFunction(visible) && visible() === false)) {
        return null;
    }

    const _disabled = (disabled === true) || (isFunction(disabled) && disabled() === true);

    if (isFunction(customRender)) {
        const context = {};

        return <>
            {customRender(toolProps, context, popover)}
        </>;
    }

    const cls = {
        selected,
    };

    let Component: ComponentType<ArgButtonProps> = ArgButton;

    if (isValidElementType(componentType)) {
        Component = componentType as ComponentType<ArgButtonProps>;
    }

    let _tooltip = tooltip;
    if (tooltip === undefined && keyBinding) {
        _tooltip = keyBinding.name;
    }

    return (
        <Component
            className={classNames('&', `&-${type}`, className, cls)}
            {...restProps}
            onClick={handleClick}
            onShiftClick={handleShiftClick}
            onAltClick={handleAltClick}
            onCtrlClick={handleCtrlClick}
            disabled={_disabled}
            type={type === 'menu' ? 'primary-menu' : buttonType}
            size={size}
            tooltipClassName={classNames('&-tooltip')}
            tooltip={_tooltip}
            keyBinding={keyBinding}
            data-testid={testid}
            data-path={toolProps.path}
            popover={popover}
        />
    );
}
