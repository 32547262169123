import { defaultTo } from 'lodash';
import { LatLngExpression, LatLngLiteral, Map } from 'leaflet';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';

import '../../../../../utils/leaflet-draw/leaflet-draw';
import { getDataTestIdFromProps } from '../../../utils';
import { GeoLocationValue } from '../../common/model/geolocation-value';
import { ClassValue } from '../../../arg-hooks/use-classNames';
import { ArgMap } from '../../common/map/arg-map';
import { latLngExpressionToLatLng } from '../../common/helpers/latLngExpressionToLatLng';
import { ArgGeolocationToolbar } from './arg-geolocation-toolbar';


export interface ArgGeolocationMapProps {
    initialZoom?: number;
    initialCenter?: LatLngLiteral;
    forwardRef?: MutableRefObject<Map | undefined>;
    className?: ClassValue;
    value?: GeoLocationValue | undefined;
    onChange?: (value: GeoLocationValue | undefined) => void;
}

export function ArgGeolocationMap(props: ArgGeolocationMapProps) {
    const {
        className,
        onChange,
        forwardRef,
        value: externalValue,

        // Initial values
        initialZoom,
        initialCenter,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const useInternalValue = !('value' in props);
    const [internalValue, setInternalValue] = useState<GeoLocationValue | undefined>();

    const value = useInternalValue ? internalValue : externalValue;
    const center = useMemo(() => defaultTo(value?.coordinates, initialCenter), [initialCenter, value]);

    const handleCenterChange = useCallback((center: LatLngExpression) => {
        const newGeoValue: GeoLocationValue = {
            coordinates: latLngExpressionToLatLng(center),
        };

        setInternalValue(newGeoValue);
        onChange?.(newGeoValue);
    }, [onChange]);

    return (
        <ArgMap
            className={className}
            forwardRef={forwardRef}
            data-testid={dataTestId}
            initialZoom={initialZoom}
            onCenterChange={handleCenterChange}
            center={center}
            toolbar={<ArgGeolocationToolbar />}
        />
    );
}
