import { useCallback } from 'react';
import { Slider } from 'antd';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgSliderCommonProps } from './types';

import './arg-slider.less';

export type ArgSliderProps = ArgSliderCommonProps<number>;

export function ArgSlider(props: ArgSliderProps) {
    const {
        min,
        max,
        value,
        onChange,
        disabled,
        tooltip,
        step,
        defaultValue,
    } = props;

    const onSliderChange = useCallback((value: number) => {
        onChange(value, 'selection');
    }, [onChange]);

    const classNames = useClassNames('arg-slider');

    return (
        <Slider
            className={classNames('&-slider')}
            value={value}
            defaultValue={defaultValue}
            min={min}
            max={max}
            onChange={onSliderChange}
            tooltip={tooltip}
            step={step}
            disabled={disabled}
        />
    );
}
