import classNames, { Argument } from 'classnames';
import { useCallback } from 'react';

export type ClassValue = Argument;
export type ClassNamesFn = typeof classNames;

export const useClassNames = (primaryClassName: string): ClassNamesFn => {
    return useCallback((...localClasses: ClassValue[]): string => {
        const s = classNames(localClasses);

        const replacedClassNames = s.replace(/\&/g, primaryClassName);

        return replacedClassNames;
    }, [primaryClassName]);
};
