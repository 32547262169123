import { useEffect, useState } from 'react';

import { DataProvider } from './data-provider';
import { StateId } from '../../../utils/rt-states/basic-state';

const EMPTY: StateId = { id: 0, url: 'dataProvider:unknown' };

export function useDataProvider<T>(dataProvider?: DataProvider<T>): StateId {
    const [stateId, setStateId] = useState<StateId>(EMPTY);

    useEffect(() => {
        if (!dataProvider) {
            setStateId(EMPTY);

            return;
        }

        const fct = () => {
            setStateId(dataProvider.stateId);
        };

        dataProvider.on('Loaded', fct);
        dataProvider.on('Cleared', fct);

        return () => {
            dataProvider.off('Loaded', fct);
            dataProvider.off('Cleared', fct);
        };
    }, [dataProvider]);

    return stateId;
}
