import React, { useCallback, useState } from 'react';

import { ArgButton, ArgButtonProps, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgChangeReason } from '../types';
import { useClassNames } from '../arg-hooks/use-classNames';
import { isIn } from '../utils/is-in';

import './arg-toggle-button.less';

export interface ArgToggleButtonProps<T> extends ArgButtonProps {
    value?: T;
    initialValue?: T;
    unselectedValue?: T;
    selectedValue: T;
    onChange?: (newValue: T, reason: ArgChangeReason) => void;
}

export function ArgToggleButton<T>(props: ArgToggleButtonProps<T>) {
    const {
        className,
        onChange,
        value: externalValue,
        initialValue,
        unselectedValue,
        selectedValue,
        onClick,
        ...otherProps
    } = props;
    const classNames = useClassNames('arg-toggle-button');

    const useInternalValue = !isIn(props, 'value');
    const noUnselectValue = !isIn(props, 'unselectedValue');

    const [internalValue, setInternalValue] = useState<T | undefined>(() => {
        return (initialValue !== undefined) ? initialValue : unselectedValue;
    });

    const value: T | undefined = useInternalValue ? internalValue : ((externalValue === undefined) ? unselectedValue : externalValue);

    const handleClick = useCallback((event: ButtonClickEvent) => {
        onClick?.(event);

        if (event.defaultPrevented) {
            // The click handler can stop the flow
            return;
        }

        const newValue = (value === selectedValue) ? unselectedValue : selectedValue;

        setInternalValue(newValue);

        onChange?.(newValue!, 'selection');
    }, [onClick, value, selectedValue, unselectedValue, onChange]);

    let cls;

    if (noUnselectValue) {
        cls = {
            selected: value === selectedValue,
            unselected: value !== selectedValue,
        };
    } else {
        cls = {
            selected: value !== unselectedValue,
            unselected: value === unselectedValue,
        };
    }

    return <ArgButton
        {...otherProps}
        onClick={handleClick}
        className={classNames('&', className, cls)}
    />;
}

export type ArgBooleanToggleButtonProps = Omit<ArgToggleButtonProps<boolean>, 'unselectedValue' | 'selectedValue'>;

export function ArgBooleanToggleButton(props: ArgBooleanToggleButtonProps) {
    const {
        className,
        ...otherProps
    } = props;
    const classNames = useClassNames('arg-boolean-toggle-button');

    return <ArgToggleButton<boolean>
        {...otherProps}
        unselectedValue={false}
        selectedValue={true}
        className={classNames('&', className)}
    />;
}
