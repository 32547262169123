import React from 'react';
import Debug from 'debug';

import { AbstractConfigurations, Configuration, ProgressMonitor } from '../components/basic';
import { UsersSettingsConnector } from '../utils/connectors/users-settings-connector';

const debug = Debug('common:contexts:UserConfigurations');

const PATCH_NOT_SUPPORTED = true;

export class UserConfigurations extends AbstractConfigurations {
    #usersConnector: UsersSettingsConnector;

    constructor(
        usersConnector: UsersSettingsConnector,
        onFetchError?: (error: Error) => void,
        onSaveError?: (error: Error) => void) {
        super('UserConfigurations',
            {
                onFetchError,
                onSaveError,
            });

        this.#usersConnector = usersConnector;
    }

    protected async fetchConnector(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Configuration | undefined> {
        const ret = await this.#usersConnector.getAppSettings(progressMonitor);

        return ret;
    }

    protected async storeConnector(configuration: Configuration, previousConfiguration: Configuration, progressMonitor: ProgressMonitor): Promise<void> {
        debug('storeConnector', 'configuration=', configuration);

        if (!previousConfiguration || PATCH_NOT_SUPPORTED) {
            await this.#usersConnector.updateAppSettings(configuration, progressMonitor);

            return;
        }

        await this.#usersConnector.patchAppSettings(configuration, progressMonitor);
    }
}

export const UserConfigurationsContext = React.createContext<UserConfigurations | undefined>(undefined);
