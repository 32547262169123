import { ElementProps, FloatingContext, ReferenceType } from '@floating-ui/react';
import { MouseEvent, useMemo } from 'react';

export interface UseRightClickProps {
    enabled?: boolean;
}

// A hook that open floating element on a context menu click.
//
// Note: This hook follows the floating-ui hook interaction pattern and is be used with the useInteractions().
export function useRightClick<RT extends ReferenceType = ReferenceType>(context: FloatingContext<RT>, props: UseRightClickProps = {}): ElementProps {
    const {
        enabled = true,
    } = props;
    const {
        onOpenChange,
        open,
    } = context;

    const ret = useMemo(() => {
        if (!enabled) {
            return {};
        }

        return {
            reference: {
                onContextMenu(event: MouseEvent) {
                    if (open) {
                        onOpenChange(false, event.nativeEvent);

                        return;
                    }

                    // prevent stealing focus from the floating element
                    event.preventDefault();
                    onOpenChange(true, event.nativeEvent);
                },
                onMouseDown(event: MouseEvent) {
                    // Let's close when user click on the trigger button (useDismiss will close but only for click outside the trigger)
                    if (open) {
                        onOpenChange(false, event.nativeEvent);
                    }
                },
            },
        };
    }, [enabled, open, onOpenChange]);

    return ret;
}
