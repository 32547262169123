import { useEffect, useRef } from 'react';

/**
 *
 */
export function useTrigger<T>(effect: (prev: T | undefined) => void, trigger: T): void {
    const prevRef = useRef<T>();

    useEffect(() => {
        const prev = prevRef.current;
        prevRef.current = trigger;

        effect(prev);
    }, [trigger]);
}
