import React, {
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { first, isArray, isEmpty, isFunction, map, size } from 'lodash';
import useResizeObserver from '@react-hook/resize-observer';

import { ArgTabTitle } from './arg-tab-title';
import { ArgTabsList } from './arg-tabs-list';
import { ArgButton } from '../arg-button/arg-button';
import { ArgTab, ArgTabAction, ArgTabKey } from './arg-tabs-types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { KeyBindingIdentifier } from '../keybindings/keybinding';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { KeyBindingHandler } from '../keybindings/keybinding-handler';
import { KeyBindingsScope } from '../keybindings/keybindings-context';
import { useSetTimeout } from '../arg-hooks/use-set-timeout';
import { useImmediateDebounce } from '../arg-hooks/use-immediate-debounce';
import { DragDropContext } from '../arg-dnd/drag-drop-context';

import './arg-tabs.less';

const SHORT_WIDTH = 128;
const ICON_WIDTH = 64;
const ICON_MIN_WIDTH = 32;

export const DND_TAB_PROPERTY_NAME = 'application/arg-tab';

export interface ArgTabMove {
    key: ArgTabKey;
    beforeIndex?: number;
}

export interface ArgTabInfos {
    key: ArgTabKey;
    renderDate?: Date;
    icon?: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    closeDate?: Date;
    respawn?: () => void;
}

export interface ArgTabsProps {
    className?: ClassValue;
    titleClassName?: ClassValue;
    titlePositionClassName?: ClassValue;

    overviewTabs?: ArgTab | ArgTab[];
    tabs?: ArgTab[];

    addTab?: ArgRenderedText;

    activeTabKey?: ArgTabKey;
    defaultActiveTabKey?: ArgTabKey;

    onChange: (tabKey: ArgTabKey | undefined, action: ArgTabAction, targetIndex?: number) => void;

    closeCurrentTabKeyBinding?: KeyBindingIdentifier;
    closeOtherTabsKeyBinding?: KeyBindingIdentifier;
    addTabKeyBinding?: KeyBindingIdentifier;
    nextTabKeyBinding?: KeyBindingIdentifier;
    previousTabKeyBinding?: KeyBindingIdentifier;

    messageValues?: ArgMessageValues;

    children?: ReactNode;

    showListTabs?: boolean;

    refreshTab?: boolean;
}

export function ArgTabs(props: ArgTabsProps) {
    const {
        className,
        titleClassName,
        titlePositionClassName,
        overviewTabs: _overviewTab,
        messageValues,
        tabs = [],
        activeTabKey: _activeTabKey,
        children,
        onChange,
        addTab,
        defaultActiveTabKey,
        showListTabs = true,
        refreshTab = false,
    } = props;

    const classNames = useClassNames('arg-tabs');

    const dragDropContext = useContext(DragDropContext);

    const tabContainerRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);

    const [ renderedTabs, setRenderedTabs ] = useState<Record<ArgTabKey, ArgTabInfos>>({});
    const [ renderedTabsCount, setRenderedTabsCount ] = useState<number>(0);

    const activeTabKeyRef = useRef<ArgTabKey>();

    const [ popoverListVisible, setPopoverListVisible ] = useState(false);

    const movingTabInfoRef = useRef<ArgTabMove>();

    const dragEnterRef = useRef<number>(0);

    const overviewTabs: ArgTab[] | undefined = (isArray(_overviewTab)
        ? _overviewTab
        : ((_overviewTab)
            ? [ _overviewTab ]
            : undefined)
    );

    let activeTabKey: ArgTabKey | undefined = _activeTabKey;
    if (activeTabKey !== undefined) {
        if (!overviewTabs?.find((tab) => tab.key === activeTabKey) && !tabs.find((tab) => tab.key === activeTabKey)) {
            activeTabKey = undefined;
        }
    }

    if (activeTabKey === undefined && defaultActiveTabKey !== undefined) {
        activeTabKey = defaultActiveTabKey;
        if (!overviewTabs?.find((tab) => tab.key === activeTabKey) && !tabs.find((tab) => tab.key === activeTabKey)) {
            activeTabKey = undefined;
        }
    }

    if (activeTabKey === undefined) {
        activeTabKey = first(overviewTabs)?.key;
        if (activeTabKey === undefined) {
            activeTabKey = tabs[0]?.key;
        }
    }

    let externalDragging = dragDropContext.dragging;
    if (externalDragging) {
        if (dragDropContext?.draggingEvent?.types?.includes(DND_TAB_PROPERTY_NAME)) {
            externalDragging = false;
        }
    }

    useEffect(() => {
        activeTabKeyRef.current = activeTabKey;

        if (!activeTabKey) {
            return;
        }

        const tabKey: ArgTabKey = activeTabKey;
        if (!renderedTabs[activeTabKey]) {
            setRenderedTabs((prev) => ({
                ...prev,
                [tabKey]: {
                    key: tabKey,
                },
            }));
            setRenderedTabsCount((prev) => (++prev));
        }

        if (!renderedTabs[activeTabKey]?.renderDate) {
            setRenderedTabs((prev) => ({
                ...prev,
                [tabKey]: {
                    ...prev[tabKey],
                    renderDate: new Date(),
                },
            }));
        }
    }, [ activeTabKey, renderedTabs ]);

    let addTabComponent: ReactNode = null;
    if (addTab) {
        addTabComponent = renderText(addTab);
    }

    const handleTabChange = useCallback((tabKey: ArgTabKey | undefined, action: ArgTabAction) => {
        if (action === ArgTabAction.Show || action === ArgTabAction.Add) {
            setPopoverListVisible(false);
        }

        if (action === ArgTabAction.Destroy && tabKey) {
            setPopoverListVisible(false);
            setRenderedTabs((prev) => {
                delete prev[tabKey];

                return prev;
            });

            setRenderedTabsCount(size(renderedTabs));
        }

        if (action === ArgTabAction.Close && tabKey) {
            if (renderedTabs[tabKey]) {
                setRenderedTabs((prev) => ({
                    ...prev,
                    [tabKey]: {
                        ...prev[tabKey],
                        closeDate: new Date(),
                    },
                }));
            }
            const tab = tabs.find((t) => t.key === tabKey);
            if (tab?.respawn) {
                setRenderedTabs((prev) => ({
                    ...prev,
                    [tabKey]: {
                        ...prev[tabKey],
                        respawn: tab.respawn,
                    },
                }));
            }
        }

        onChange(tabKey, action);
    }, [ onChange, tabs, renderedTabs ]);

    const handleRenderList = useCallback(() => {
        return <ArgTabsList
            tabs={ tabs }
            selectedTabKey={ activeTabKey }
            tabInfos={ renderedTabs }
            onChange={ handleTabChange }
        />;
    }, [ tabs, activeTabKey, handleTabChange, renderedTabs ]);

    let listTabsComponent: ReactNode = null;
    if (showListTabs) {
        listTabsComponent = <ArgButton
            className={ classNames('&-list-button') }
            icon='icon-triangle-down'
            data-testid='list-tabs'
            type='ghost'
            size='medium'
            popover={ handleRenderList }
            popoverTrigger='click'
            popoverVisible={ popoverListVisible }
            popoverClassName={ classNames('&-list-popover') }
            onPopoverVisibleChange={ setPopoverListVisible }
            popoverPlacement='bottomRight'
        />;
    }

    const handleGetBodyElement = useCallback((tabKey: ArgTabKey): HTMLElement | undefined => {
        let bodyElement = tabContainerRef.current?.querySelector(`[data-tabbody=${CSS.escape(tabKey)}]`);
        if (!bodyElement) {
            return undefined;
        }

        bodyElement = bodyElement.firstElementChild || undefined;

        return bodyElement as HTMLElement | undefined;
    }, []);

    const activeTab = useCallback((tabKey: ArgTabKey): void => {
        onChange?.(tabKey, ArgTabAction.Show);
    }, [ onChange ]);

    const renderTabTitle = (tab: ArgTab, closable?: boolean): ReactNode => {
        const selected = tab.key === activeTabKey;

        if (tab.titleWrapper) {
            const wrappedTitleComponent = <React.Fragment key={ tab.key }>
                { tab.titleWrapper(tab, closable === undefined ? true : closable, selected, externalDragging || false, handleTabChange, handleGetBodyElement) }
            </React.Fragment>;

            return wrappedTitleComponent;
        }

        let comp = <ArgTabTitle
            key={ tab.key }
            tab={ tab }
            closable={ closable }
            title={ tab.title }
            additional={ tab.additional }
            icon={ tab.icon }
            selected={ selected }
            onChange={ handleTabChange }
            menu={ tab.menu }
            getBodyElement={ handleGetBodyElement }
            dropAction={ externalDragging ? tab.dropAction : undefined }
            underlineColor={ tab.underlineColor }
            tooltip={ tab.tooltip }
            messageValues={ messageValues }
            activeKeyBinding={ tab.activeKeyBinding }
        />;


        if (tab.activeKeyBinding) {
            comp =
                <KeyBindingHandler
                    key={ tab.key }
                    keyBinding={ tab.activeKeyBinding }
                    onKeyHandler={ () => activeTab(tab.key) }
                >
                    { comp }
                </KeyBindingHandler>;
        }

        return comp;
    };

    const renderTabBody = (tabKey: ArgTabKey, tab: ArgTab | undefined): ReactNode => {
        const visible = (tabKey === activeTabKey);

        if (refreshTab && !visible) {
            return null;
        }

        const cls = {
            hidden: !visible,
            visible,
        };

        let body = tab?.children;
        if (isFunction(body)) {
            body = body(visible);
        }

        if (tab?.keyBindingsScope) {
            body = <KeyBindingsScope scope={ tab.keyBindingsScope } enabled={ activeTabKey === tabKey }>
                { body }
            </KeyBindingsScope>;
        }

        return <div
            key={ tabKey }
            className={ classNames('&-tab-body', cls) }
            data-tabbody={ tabKey }
            data-testid='tab-body'
        >
            { body }
        </div>;
    };

    const tabcontext = useMemo<ArgTabContextInterface>(() => {
        const register = (tabKey: ArgTabKey, icon: ReactNode, title: ReactNode, description: ReactNode) => {
            let tabInfos: ArgTabInfos = renderedTabs[tabKey];
            if (!tabInfos) {
                tabInfos = {
                    key: tabKey,
                };
                setRenderedTabs((prev) => ({
                    ...prev,
                    [tabKey]: tabInfos,
                }));
            }

            tabInfos.icon = icon;
            tabInfos.title = title;
            tabInfos.description = description;
        };

        const ret: ArgTabContextInterface = {
            registerTab: register,
        };

        return ret;
    }, [ renderedTabs ]);

    useEffect(() => {
        if (!isEmpty(tabs) && renderedTabsCount > 0) {
            return;
        }

        setPopoverListVisible(false);
    }, [ tabs ]);

    const computeTabs = (tabs: ArgTab[], movingTabInfo?: ArgTabMove): ArgTab[] => {
        if (!movingTabInfo) {
            return tabs;
        }

        const movingTagIndex = tabs.findIndex((t) => t.key === movingTabInfo.key);

        if (movingTagIndex < 0) {
            return tabs;
        }

        const _tabs = [ ...tabs ];
        const movingTab = _tabs.splice(movingTagIndex, 1)[0];

        if (movingTabInfo.beforeIndex === undefined) {
            _tabs.push(movingTab);
        } else {
            _tabs.splice(movingTabInfo.beforeIndex, 0, movingTab);
        }

        return _tabs;
    };

    const handleLayoutTitle = (movedTabs: ArgTab[]) => {
        const bounds = titleRef.current?.getBoundingClientRect();
        if (!bounds) {
            return;
        }

        const right = rightRef.current!;
        let rightWidth = right?.getBoundingClientRect()?.width ?? 0;
        rightWidth += 200;

        const tabs = titleRef.current!.querySelectorAll('[data-tabs]');

        const tabsByKey: Record<ArgTabKey, HTMLDivElement> = {};
        for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i] as HTMLDivElement;
            const tabKey = tab.getAttribute('data-tabs');

            tabsByKey[tabKey!] = tab;
        }

        const width = Math.max(Math.min((bounds.width - rightWidth) / tabs.length, 224), ICON_MIN_WIDTH);

        let x = 0;
        let minWidth = 0;

        for (let i = 0; i < movedTabs.length; i++) {
            const tab = movedTabs[i];
            const tabElement = tabsByKey[tab.key];
            if (!tabElement) {
                continue;
            }

            tabElement.style.left = `${x}px`;
            tabElement.style.width = `${width}px`;
            if (width < ICON_WIDTH) {
                tabElement.classList.add('size-icon');
            } else {
                tabElement.classList.remove('size-icon');
            }
            if (width < SHORT_WIDTH) {
                tabElement.classList.add('size-short');
            } else {
                tabElement.classList.remove('size-short');
            }
            x += width;
            minWidth += ICON_MIN_WIDTH;
        }

        const bar = titleRef.current!.firstElementChild! as HTMLDivElement;
        bar.style.width = `${x}px`;
        bar.style.minWidth = `${minWidth}px`;

        rightRef.current!.style.left = `${x}px`;
    };

    const [ layoutDebounce, cancelLayoutDebounce ] = useImmediateDebounce(300);

    useResizeObserver(titleRef.current, () => {
        layoutDebounce(() => {
            const movedTabs = computeTabs(tabs, movingTabInfoRef.current);
            handleLayoutTitle(movedTabs);
        });
    });

    useResizeObserver(rightRef.current, () => {
        layoutDebounce(() => {
            const movedTabs = computeTabs(tabs, movingTabInfoRef.current);
            handleLayoutTitle(movedTabs);
        });
    });

    useLayoutEffect(() => {
        cancelLayoutDebounce?.();

        if (movingTabInfoRef.current) {
            movingTabInfoRef.current = undefined;
        }

        const movedTabs = computeTabs(tabs, movingTabInfoRef.current);
        handleLayoutTitle(movedTabs);
    }, [ tabs, overviewTabs, addTab ]);

    const handleTabDragOver = useCallback((dragEvent: React.DragEvent) => {
        if (dragEvent.defaultPrevented) {
            return;
        }
        dragEvent.preventDefault();

        const tabElement: Element | undefined = titleRef.current?.querySelector('[data-tabs]') || undefined;

        const dndTabKey = localStorage.getItem(DND_TAB_PROPERTY_NAME);

        if (!tabElement || !dndTabKey) {
            dragEvent.dataTransfer!.dropEffect = 'none';

            return;
        }

        const tabBounds = tabElement.getBoundingClientRect();
        const parentBounds = tabElement.parentElement!.getBoundingClientRect();

        const beforeIndex = Math.floor((dragEvent.clientX - parentBounds.x) / tabBounds.width);
        dragEvent.dataTransfer!.dropEffect = 'move';

        movingTabInfoRef.current = {
            key: dndTabKey,
            beforeIndex,
        };

        layoutDebounce(() => {
            const movedTabs = computeTabs(tabs, movingTabInfoRef.current);
            handleLayoutTitle(movedTabs);
        });
    }, [ layoutDebounce, tabs ]);

    const handleTabDragEnter = useCallback((event: React.DragEvent) => {
        event.stopPropagation();
        event.preventDefault();

        dragEnterRef.current++;

        if (dragEnterRef.current === 1) {
            tabContainerRef.current!.classList.add('dragging');
        }
    }, []);

    const dropTimeout = useSetTimeout(300);

    const handleTabDrop = useCallback(() => {
        dragEnterRef.current = 0;
        tabContainerRef.current!.classList.remove('dragging');

        const index = movingTabInfoRef.current?.beforeIndex;

        const dndTabKey = localStorage.getItem(DND_TAB_PROPERTY_NAME);

        onChange?.(dndTabKey!, ArgTabAction.Move, index);
        dropTimeout(() => {
            onChange?.(dndTabKey!, ArgTabAction.Show);
        });
    }, [ dropTimeout, onChange ]);

    const handleTabDragExit = useCallback((event: React.DragEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if ((event.target as HTMLElement)?.getAttribute('data-droppable')) {
            dragEnterRef.current = 1;
        }

        dragEnterRef.current--;

        if (dragEnterRef.current > 0) {
            return;
        }

        dragEnterRef.current = 0;
        tabContainerRef.current!.classList.remove('dragging');

        movingTabInfoRef.current = undefined;
        layoutDebounce(() => {
            const movedTabs = computeTabs(tabs, undefined);
            handleLayoutTitle(movedTabs);
        });
    }, [ layoutDebounce, tabs ]);

    const cls = {};

    return (
        <div className={ classNames('&', className, cls) } ref={ tabContainerRef } data-testid='tab-container'>
            <div className={ classNames('&-title', titleClassName) }>
                <ArgTabsContext.Provider value={ tabcontext }>
                    { !!overviewTabs?.length && <div className={ classNames('&-title-left') } key='overviewTabs'>
                        <div
                            className={ classNames('&-title-left-tabs', '&-title-tabs') }
                            onDragEnter={ !externalDragging ? handleTabDragEnter : undefined }
                            onDrop={ !externalDragging ? handleTabDrop : undefined }
                            onDragLeave={ !externalDragging ? handleTabDragExit : undefined }
                            onDragOver={ !externalDragging ? handleTabDragOver : undefined }
                            data-droppable={ true }
                            data-testid='title-tabs'
                        >
                            { overviewTabs.map((overviewTab) => {
                                return renderTabTitle(overviewTab, false);
                            }) }
                            <b className={ classNames('&-title-tabs-round-left') } />
                            <b className={ classNames('&-title-tabs-round-right') } />
                        </div>
                    </div> }
                    { (tabs.length > 0 || addTabComponent) &&
                        <div className={ classNames('&-title-center', titlePositionClassName) } ref={ titleRef } key='tabs'>
                            { tabs.length > 0 && <div
                                className={ classNames('&-title-center-tabs', '&-title-tabs') }
                                onDragEnter={ !externalDragging ? handleTabDragEnter : undefined }
                                onDrop={ !externalDragging ? handleTabDrop : undefined }
                                onDragLeave={ !externalDragging ? handleTabDragExit : undefined }
                                onDragOver={ !externalDragging ? handleTabDragOver : undefined }
                                data-droppable={ true }
                                data-testid='title-tabs'
                            >
                                { tabs.map((tab: ArgTab) => {
                                    const comp = renderTabTitle(tab, tab.closable);

                                    return comp;
                                }) }
                                <b className={ classNames('&-title-tabs-round-left') } />
                                <b className={ classNames('&-title-tabs-round-right') } />
                            </div> }
                            <div className={ classNames('&-title-right') } ref={ rightRef } data-testid='add-tab'>
                                { addTabComponent }
                            </div>
                        </div> }
                    { isEmpty(tabs) && <div className={ classNames('&-title-divider') } data-testid='title-divider' /> }
                    { renderedTabsCount > 0 &&
                        <div className={ classNames('&-title-right2') }>
                            { listTabsComponent }
                        </div> }
                </ArgTabsContext.Provider>
            </div>
            { map(renderedTabs, (tabInfos: ArgTabInfos, tabKey: ArgTabKey) => {
                if (!tabInfos?.renderDate) {
                    return null;
                }

                let tab: ArgTab | undefined = overviewTabs?.find((t) => t.key === tabKey);
                if (!tab) {
                    tab = tabs.find((t) => t.key === tabKey);
                }

                const comp = renderTabBody(tabKey, tab);

                return comp;
            }) }

            { children }
        </div>
    );
}

export interface ArgTabContextInterface {
    registerTab: (tabKey: ArgTabKey, icon: ReactNode, title: ReactNode, description: ReactNode) => void;
}

export const ArgTabsContext = React.createContext<ArgTabContextInterface | undefined>(undefined);
