import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ConfigProvider } from 'antd';
import Debug from 'debug';
import { isString } from 'lodash';

import { ClassValue } from '../arg-hooks/use-classNames';
import { DisableDndContainer } from '../arg-dnd/disable-dnd-container';
import { copyStylesOfDocument, toWindowFeatures } from './utils';

const DEFAULT_CONTAINER_ID = 'arg-window-container';

const debug = Debug('basic:ArgPortalWindow');

export interface ArgWindowFeatures {
    left?: number;
    top?: number;
    width: number;
    height: number;
}

interface Props {
    url?: string;
    name?: string;
    title?: string;
    features: ArgWindowFeatures,
    onBlock?: () => void;
    onOpen?: (window: Window) => void;
    onUnload?: () => void;
    center?: 'parent' | 'screen';

    containerId?: string;
    containerClassName?: ClassValue;

    visible?: boolean;

    children?: React.ReactNode;

    blockDrop?: boolean;
}

export function ArgWindowPortal(props: Props) {
    const {
        url = '',
        name = 'react-window-portal',
        title = '',
        features = {
            width: 600,
            height: 640,
        },
        center = 'parent',
        onBlock,
        onOpen,
        onUnload,
        children,
        visible,
        containerId = DEFAULT_CONTAINER_ID,
        containerClassName,
        blockDrop,
    } = props;
    const windowRef = useRef<Window>();
    const [container, setContainer] = useState<Element>();
    const releasedRef = useRef<boolean>();
    const windowCheckerIntervalRef = useRef<any>();

    const release = useCallback((event?: Event) => {
        debug('relase', 'Released=', releasedRef.current, event);
        if (releasedRef.current) {
            return;
        }
        releasedRef.current = true;

        if (windowCheckerIntervalRef.current) {
            // Remove checker interval.
            clearInterval(windowCheckerIntervalRef.current);
            windowCheckerIntervalRef.current = undefined;
        }

        // Call any function bound to the `onUnload` prop.
        onUnload?.();
    }, [/*onUnload*/]);

    const windowLoaded = useCallback(() => {
        const win = windowRef.current;
        debug('windowLoaded', 'Window Loaded=', win);

        if (!win) {
            return;
        }

        win.removeEventListener('load', windowLoaded);

        debug('windowLoaded', 'Set domain of document to', document.location.hostname);

        // Check if the new window was successfully opened.
        if (title) {
            win.document.title = title;
        }

        const body = win.document.body;
        for (; body.firstChild;) {
            body.removeChild(body.firstChild!);
        }

        const container = win.document.createElement('div');
        container.setAttribute('id', containerId);
        body.appendChild(container);

        if (containerClassName) {
            container.className = classNames(containerClassName);
        }
        setContainer(container);

        debug('windowLoaded', 'container=', container, win);

        // If specified, copy styles from parent window's document.
        setTimeout(() => copyStylesOfDocument(document, win.document), 0);

        onOpen?.(win);
    }, [onOpen]);

    /**
     * Create the new window when NewWindow component mount.
     */
    const openChild = useCallback(() => {
        const _features: any = { ...features };

        // Prepare position of the new window to be centered against the 'parent' window or 'screen'.
        if (
            isString(center) &&
            (features.width === undefined || features.height === undefined)
        ) {
            console.warn(
                'width and height window features must be present when a center prop is provided'
            );
        } else if (center === 'parent') {
            // @ts-ignore
            _features.left = window.top.outerWidth / 2 + window.top.screenX - features.width / 2;
            // @ts-ignore
            _features.top = window.top.outerHeight / 2 + window.top.screenY - features.height / 2;
        } else if (center === 'screen') {
            const screenLeft = window.screenLeft;
            const screenTop = window.screenTop;

            const width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                    ? document.documentElement.clientWidth
                    : window.screen.width;
            const height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                    ? document.documentElement.clientHeight
                    : window.screen.height;

            features.left = width / 2 - features.width / 2 + screenLeft;
            features.top = height / 2 - features.height / 2 + screenTop;
        }

        const fs = toWindowFeatures(features);
        const win = window.open(url, name, fs) || undefined;

        debug('open', 'url=', url, 'fs=', fs, '=>', win);

        windowRef.current = win;
        if (!win) {
            onBlock?.();

            return;
        }

        // When a new window use content from a cross-origin there's no way we can attach event
        // to it. Therefore, we need to detect in a interval when the new window was destroyed
        // or was closed.
        if (url !== 'about:blank') {
            windowCheckerIntervalRef.current = setInterval(() => {
                if (windowRef.current && !windowRef.current.closed) {
                    return;
                }

                release(undefined);

                if (windowCheckerIntervalRef.current) {
                    clearInterval(windowCheckerIntervalRef.current);
                }
            }, 50);
        }

        setContainer(undefined);

        windowLoaded();

        // Release anything bound to this component before the new window unload.
        win.addEventListener('beforeunload', release);
    }, [features, center, url, name, windowLoaded, release, onBlock]);

    useEffect(() => {
        if (!visible) {
            return;
        }
        openChild();
    }, [visible]);

    useEffect(() => {
        return () => {
            const win = windowRef.current;
            if (!win) {
                return;
            }
            win.removeEventListener('beforeunload', release);
            windowRef.current = undefined;

            win.close();
        };
    }, []);

    const handleGetPopupContainer = useCallback((node?: HTMLElement): HTMLElement => {
        if (node) {
            const body: HTMLElement | undefined = node.ownerDocument?.body;
            if (body) {
                return body;
            }
        }

        const body = windowRef.current?.document?.body;
        if (body) {
            return body;
        }

        return document.body;
    }, []);

    /**
     * Render the NewWindow component.
     */
    if (!visible || !container) {
        return null;
    }

    let _children = children;
    if (blockDrop) {
        _children = <DisableDndContainer document={container.ownerDocument}>
            {children}
        </DisableDndContainer>;
    }

    _children = <ConfigProvider getPopupContainer={handleGetPopupContainer}>
        {_children}
    </ConfigProvider>;

    /*
        _children = <Notifications>
            {_children}
        </Notifications>;
    */
    return ReactDOM.createPortal(_children, container);
}

