import { DependencyList, useRef } from 'react';
import { isEqual } from 'lodash';

import { useDebugMemo } from './use-debug-memo';

// hooks that works like `useMemo` but returns the same value as long as results compares equal.
export function useMemoDeepEquals<T>(factory: () => T, deps: DependencyList, debugName?: string): T {
    const valueRef = useRef<T>();

    const memo = useDebugMemo<T>(factory, deps, debugName);
    if (isEqual(memo, valueRef.current)) {
        return valueRef.current!;
    }

    valueRef.current = memo;

    return memo;
}
