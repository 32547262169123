import React from 'react';
import { defineMessages } from 'react-intl';

import { useClassNames } from '../../basic';
import { BasicPaneI18n, BasicPaneI18nProps } from './basic-pane-i18n';

//import './empty-pane.less';

const messages = defineMessages({
    noData: {
        id: 'common.empty-pane.NoData',
        defaultMessage: 'No data',
    },
});

type EmptyPaneProps = BasicPaneI18nProps

export function EmptyPane(props: EmptyPaneProps) {
    const {
        className,
        children,
        message,
        ...otherProps
    } = props;

    const classNames = useClassNames('empty-pane');

    let _message = message || children;
    if (!_message) {
        _message = messages.noData;
    }

    return (
        <BasicPaneI18n
            className={classNames('&', className)}
            message={_message}
            {...otherProps}
        />
    );
}
