import { UserState } from './user-state';
import { USER_SETTINGS_EVENT_NAMES } from '../events';
import { RtApi } from '../rt-api';


export class UserSettingsState extends UserState {
    constructor(api: RtApi, url: string) {
        super(api, url, 'me', USER_SETTINGS_EVENT_NAMES);
    }

    protected isRtDisabled(): boolean {
        return true;
    }
}
