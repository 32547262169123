import React from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './top-bar-progress.less';

interface TopBarProgressProps {
    className: ClassValue;
    visible?: boolean;
}

export function TopBarProgress(props: TopBarProgressProps) {
    const { className, visible } = props;

    const classNames = useClassNames('top-bar-progress');

    const cls = {
        visible,
        hidden: !visible,
    };

    return <div className={classNames('&', className, cls)}>
        <div className={classNames('&-bar')} />
    </div>;
}
