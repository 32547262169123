import EventEmitter from 'eventemitter3';
import Debug from 'debug';
import { MessageDescriptor } from 'react-intl';
import { isNumber, isObject } from 'lodash';

import { ArgonosError } from '../utils/argonos-error';
import { ArgMessageValues } from '../types';

const debug = Debug('argonode:utils:progress-monitor');

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProgressMonitorOptions {
    global?: boolean;
    messageValues?: ArgMessageValues;
}

export const GLOBAL_PM: ProgressMonitorOptions = {
    global: true,
};

export type ProgressMonitorName = string | MessageDescriptor;

export interface ProgressMonitorEventTypes {
    Begin: (progressMonitor: ProgressMonitor) => void;
    Cancel: (progressMonitor: ProgressMonitor) => void;
    Done: (progressMonitor: ProgressMonitor) => void;
    Update: (progressMonitor: ProgressMonitor) => void;
    NameChanged: (progressMonitor: ProgressMonitor, newName: ProgressMonitorName | undefined, messageValues?: ArgMessageValues) => void;
    Worked: (progressMonitor: ProgressMonitor, worked: number, total: number) => void;
}

let _idFactory = 1;

export class ProgressMonitor extends EventEmitter<ProgressMonitorEventTypes | any> {
    readonly id: number;

    readonly #parentTaskCount: number;
    readonly #options: ProgressMonitorOptions;
    #taskCount = 1;
    #parentTaskSent = 0;

    _subId: number;

    _taskName: string | MessageDescriptor | undefined;
    _messageValues: ArgMessageValues = {};

    #cancelled = false;
    #done = false;

    constructor(taskName: string | MessageDescriptor | undefined, parentTaskCount: number, options: ProgressMonitorOptions = {}) {
        super();

        this._subId = 0;
        this.id = _idFactory++;
        this._taskName = taskName;
        this.#parentTaskCount = parentTaskCount;
        this.#options = options;
        this._messageValues = options.messageValues ?? {};
    }

    get isDone(): boolean {
        return this.#done;
    }

    get isCancelled(): boolean {
        return this.#cancelled;
    }

    get aborted(): boolean {
        return this.#cancelled;
    }

    get isRunning(): boolean {
        return !this.#done && !this.#cancelled;
    }

    get taskName(): string | MessageDescriptor | undefined {
        return this._taskName;
    }

    get messageValues(): ArgMessageValues {
        return this._messageValues;
    }

    setTaskName(taskName: string | MessageDescriptor, messageValues?: ArgMessageValues) {
        this._taskName = taskName;
        if (messageValues) {
            this._messageValues = { ...this._messageValues, ...messageValues };
        }

        this.emit('NameChanged', this, taskName, messageValues);
    }

    beginTask(taskName: string | MessageDescriptor | undefined, taskCount?: number, messagesValues?: ArgMessageValues): void {
        if (taskName) {
            this.setTaskName(taskName, messagesValues);
        }

        if (isNumber(taskCount)) {
            this.#taskCount = taskCount;
        }

        this.emit('Begin', this);
    }

    cancel(): void {
        if (this.#cancelled || this.#done) {
            return;
        }

        this.#cancelled = true;

        // Fire CANCEL EVENT
        this.emit('Cancel', this);
    }

    done(): void {
        if (this.#cancelled || this.#done) {
            return;
        }

        this.#done = true;

        // Fire DONE EVENT
        this._fireDone();
    }

    protected _fireDone(): void {
        this.emit('Done', this);
    }

    verifyCancelled(): void {
        if (!this.isCancelled) {
            return;
        }

        throw new CancelledWorkError(this);
    }

    toString(): string {
        return `[ProgressMonitor id=${this.id} done=${this.#done}} cancelled=${this.#cancelled}}]`;
    }

    static empty(): ProgressMonitor {
        return new ProgressMonitor('empty', 0);
    }

    get workedTicks(): number {
        return this.#parentTaskSent;
    }

    worked(tick: number) {
        const t = (tick / this.#taskCount) * this.#parentTaskCount;
        const min = Math.min(t, this.#parentTaskCount - this.#parentTaskSent);

        //console.log('WORKED tick=', tick, 't=', t, 'min=', min, 'parentTaskSent=', this.#parentTaskSent, 'parentTaskCount=', this.#parentTaskCount);
        if (min <= 0) {
            return;
        }

        this.#parentTaskSent += min;

        this._workProgressed(min, this.#parentTaskSent);

        this.emit('Worked', this, min, this.#parentTaskSent);
    }

    protected _workProgressed(worked: number, total: number) {

    }
}

export class CancelledWorkError extends ArgonosError {
    static CancelReason = Symbol('Cancelled');

    #progressMonitor: ProgressMonitor;

    constructor(progressMonitor: ProgressMonitor) {
        super('Cancelled', CancelledWorkError.CancelReason);

        this.#progressMonitor = progressMonitor;
    }

    get progressMonitor(): ProgressMonitor {
        return this.#progressMonitor;
    }

    static is(error: any): boolean {
        return error instanceof CancelledWorkError;
    }
}

export class SubProgressMonitor extends ProgressMonitor {
    #parent: ProgressMonitor;

    constructor(parent: ProgressMonitor, taskCount: number, options?: ProgressMonitorOptions) {
        super(`${parent.id}_${parent._subId++}`, taskCount, options);

        this.#parent = parent;
        const thiz = this as any;
        thiz.addListener = this.#parent.addListener.bind(this.#parent);
        thiz.removeListener = this.#parent.removeListener.bind(this.#parent);
        thiz.on = this.#parent.on.bind(this.#parent);
        thiz.off = this.#parent.off.bind(this.#parent);
        thiz.once = this.#parent.once.bind(this.#parent);
        thiz.emit = this.#parent.emit.bind(this.#parent);
    }

    setTaskName(taskName: string | MessageDescriptor, messageValues?: ArgMessageValues) {
        this.#parent.setTaskName(taskName, messageValues);
    }

    get taskName(): string | MessageDescriptor | undefined {
        return this.#parent.taskName;
    }

    get isCancelled(): boolean {
        return this.#parent.isCancelled;
    }

    get isDone(): boolean {
        return super.isDone || this.#parent.isDone;
    }

    get isRunning(): boolean {
        if (this.isDone) {
            return false;
        }

        return this.#parent.isRunning;
    }

    cancel(): void {
        this.#parent.cancel();
    }

    _workProgressed(worked: number, total: number) {
        this.#parent.worked(worked);
    }

    _fireDone() {
        // Do not propagate done event to the parent
    }
}

export function isProgressMonitor(object: any): object is ProgressMonitor {
    if (!object || !isObject(object)) {
        return false;
    }

    const ret = object instanceof ProgressMonitor;

    return ret;
}
