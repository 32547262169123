import React from 'react';
import { defineMessages } from 'react-intl';

import { ArgonosError, INVALID_SESSION, renderText, useClassNames } from '../../basic';
import { BasicPaneI18n, BasicPaneI18nProps } from './basic-pane-i18n';
import { isResponse500 } from '../../basic/utils/response-error';

import './error-pane.less';
import { ReactComponent as Error500SVG } from './internal-server-error.svg';

const DEFAULT_ICON = 'icon-invalid';

const messages = defineMessages({
    invalidSession: {
        id: 'common.errorPage.InvalidMessage',
        defaultMessage: 'You have been disconnected, please login.',
    },
    defaultMessage: {
        id: 'common.errorPage.Message',
        defaultMessage: 'Oops, something went wrong,{newline} please retry in few minutes.',
    },
    error500: {
        id: 'common.errorPage.error500',
        defaultMessage: '<p><b>Internal Error</b></p><p>The page you\'re looking for is temporarily unavailable.</p><p>Our teams are mobilized to restore your workspace as quickly as possible.</p>',
    },
});

interface ErrorPaneProps extends BasicPaneI18nProps {
    error?: Error;
    showInternalServerError?: boolean;
}

export function ErrorPane(props: ErrorPaneProps) {
    const {
        className,
        error,
        children,
        message,
        icon = DEFAULT_ICON,
        showInternalServerError,
        ...otherProps
    } = props;

    const classNames = useClassNames('error-pane');

    let _message;
    if (children) {
        _message = children;
    } else if ((error as ArgonosError)?.code === INVALID_SESSION) {
        _message = messages.invalidSession;
    } else if (isResponse500(error) || showInternalServerError) {
        return <div className={classNames('&-500')}>
            <div className={classNames('&-500-image')}>
                <Error500SVG />
            </div>
            <div className={classNames('&-500-message')}>
                {renderText(message || messages.error500)}
            </div>
        </div>;
    } else if (message) {
        _message = message;
    } else {
        _message = messages.defaultMessage;
    }

    return (
        <BasicPaneI18n
            className={classNames('&', className)}
            message={_message}
            icon={icon}
            {...otherProps}
        />
    );
}
