import { useCallback, useEffect, useRef } from 'react';
import Debug from 'debug';

import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { useProgressMonitors } from '../progress-monitors/use-progress-monitors';

const debug = Debug('common:basic:utils:UseFactChainedActions');

const EMPTY = Symbol('EMPTY');

interface Task<T> {
    parameter: T;
    taskProcessor: (parameter: T, progressMonitor: ProgressMonitor) => void;
}

let _progressMonitorId = 0;

export function useChainedActions<T = any>(name: string, taskProcessor: (parameter: T, progressMonitor: ProgressMonitor) => Promise<void>, catchError?: (error: Error, parameter: T) => Promise<void>): [(value: T) => void] {
    const processingRef = useRef<boolean>(false);
    const unmountedRef = useRef<boolean>(false);

    const [, progressMonitorFactory] = useProgressMonitors();
    const waitingParamRef = useRef<Task<T> | symbol>();

    useEffect(() => {
        return () => {
            debug(`[${name}]`, 'Unmount');
            unmountedRef.current = true;
        };
    }, []);

    //
    const processTask = useCallback(async (task: Task<T>): Promise<void> => {
        const {
            taskProcessor,
            parameter,
        } = task;

        const progressMonitor = progressMonitorFactory(`chain_${_progressMonitorId++}`);

        debug(`[${name}]`, 'Call', parameter);
        try {
            await taskProcessor(parameter, progressMonitor);
        } catch (error) {
            console.error(error);

            if (unmountedRef.current) {
                return;
            }

            if (!catchError) {
                return;
            }

            if (progressMonitor.isCancelled) {
                console.log('CANCELED PM');

                return;
            }

            try {
                await catchError(error as Error, parameter);
            } catch (error) {
                console.error(error);
            }
        } finally {
            progressMonitor.done();
        }
        debug(`[${name}]`, 'End of process');
    }, [catchError, name]);

    const tasksProcessLoop = useCallback(async () => {
        processingRef.current = true;

        try {
            for (; ;) {
                const next = waitingParamRef.current;
                if (next === EMPTY) {
                    break;
                }
                waitingParamRef.current = EMPTY;

                if (unmountedRef.current) {
                    return;
                }

                debug(`[${name}]`, 'Process=', next);
                await processTask(next as Task<T>);
            }

            debug(`[${name}]`, 'No more process');
        } finally {
            processingRef.current = false;
        }
    }, [processTask, name]);

    const addTask = useCallback(async (parameter: T) => {
        debug(`[${name}]`, 'New Task=', parameter);
        waitingParamRef.current = {
            taskProcessor,
            parameter,
        };

        if (processingRef.current) {
            debug(`[${name}]`, 'Already processing');

            return;
        }
        try {
            await tasksProcessLoop();
        } catch (error) {
            console.error(error);
        }
    }, [tasksProcessLoop, name, taskProcessor]);

    return [addTask];
}
