import { useEffect, useState } from 'react';

import { $yield } from './yield';

export function useIsDragging(): boolean {
    const [dragging, setDragging] = useState<boolean>(false);

    useEffect(() => {
        function handleDragEvent() {
            //            console.log('Handle drag start');
            $yield(() => {
                setDragging(true);
            });
        }

        function handleDragEndEvent() {
            //            console.log('Handle drag end');
            $yield(() => {
                setDragging(false);
            });
        }

        document.addEventListener('drag', handleDragEvent, { capture: true });
        document.addEventListener('dragend', handleDragEndEvent, { capture: true });

        return () => {
            document.removeEventListener('drag', handleDragEvent, { capture: true });
            document.removeEventListener('dragend', handleDragEndEvent, { capture: true });
        };
    }, []);

    return dragging;
}
