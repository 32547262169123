import { ArgUserInfo } from '../components/basic';
import { DataType } from '../components/common/data-types';

export type UserProfileFieldId = string;

export interface UserProfileField {
    id: UserProfileFieldId;

    displayName: string;
    description?: string;
    isMandatory: boolean;
    clientMetadata?: Record<string, any>;
    baseType?: DataType;
    customDataType?: DataType;
    createdBy: ArgUserInfo;
    createdDate: Date;
    lastUpdatedBy: ArgUserInfo;
    lastUpdatedDate: Date;
    typeCompatibilityCode?: string;
    isMultivalued?: boolean;
    possibleValues?: string[];
    inputMode?: UserInputType;
    type?: DataType;
}

export type UserMetadataKey = string;
export type UserMetadataValue = string | number | boolean | string[];
export type UserMetadata = Record<UserMetadataKey, UserMetadataValue>;

export enum UserInputType {
    freeInput = 'freeInput',
    singleSelect = 'singleSelect',
    multiSelect = 'multiSelect'
}
