import React from 'react';
import Debug from 'debug';
import { pull } from 'lodash';

import { BasicState } from './basic-state';

const debug = Debug('argonode:utils:states:StatesRegistry');

const LOGS = false;

const stateRecords: StateRecord<any>[] = [];
if (process.env.NODE_ENV === 'development') {
    (window as any).stateRecords = stateRecords;
}

interface StateWithCount {
    state: BasicState;
    count: number;
}

export interface StateRecord<T> {
    state: T;
    unregister: () => Promise<void>;
    stack?: Error;
}

export class StatesRegistry {
    #registry: Record<string, StateWithCount> = {};

    constructor() {
    }

    get<T extends BasicState>(url: string, factory: (url: string) => T): [StateRecord<T>, Promise<StateRecord<T>>] {
        const s = this.#registry[url];

        if (LOGS) {
            console.log('GET url=', url, s);
        }
        if (s) {
            s.count++;
            if (LOGS) {
                console.log('  new count=', s.count);
            }

            const stateRecord = {
                state: s.state as T,
                unregister: async (): Promise<void> => {
                    await this._unregister(url);

                    if (process.env.NODE_ENV === 'development') {
                        pull(stateRecords, stateRecord);
                    }
                },
                stack: (process.env.NODE_ENV === 'development' ? new Error() : undefined),
            };

            if (process.env.NODE_ENV === 'development') {
                stateRecords.push(stateRecord);
            }

            return [stateRecord, Promise.resolve(stateRecord)];
        }

        debug('get', 'REGISTER state for url=', url);

        const newState: T = factory(url);
        this.#registry[url] = {
            count: 1,
            state: newState,
        };

        if (LOGS) {
            console.log('  create');
        }

        const p1 = newState.initialize();

        const stateRecord: StateRecord<T> = {
            state: newState,
            unregister: async (): Promise<void> => {
                await this._unregister(url);

                if (process.env.NODE_ENV === 'development') {
                    pull(stateRecords, stateRecord);
                }
            },
            stack: (process.env.NODE_ENV === 'development' ? new Error() : undefined),
        };

        const p = p1.then(async () => {
            await newState.connect();

            return stateRecord;
        }, (error) => {
            console.error(error);

            return Promise.reject(error);
        });

        if (process.env.NODE_ENV === 'development') {
            stateRecords.push(stateRecord);
        }

        return [stateRecord, p];
    }

    async _unregister(url: string): Promise<void> {
        debug('unregister', 'UNREGISTER state for url=', url);

        const old = this.#registry[url];
        if (LOGS) {
            console.log('UNREGISTER url=', url, 's=', old);
        }
        if (!old) {
            if (LOGS) {
                console.log('  panic');
            }
            console.error('**** PANIC unregister unknown url=', url);

            return;
        }
        old.count--;
        if (old.count > 0) {
            if (LOGS) {
                console.log('  keep count');
            }

            return;
        }

        if (LOGS) {
            console.log('  release');
        }
        delete this.#registry[url];

        const oldState = old.state;

        await oldState.disconnect();

        await oldState.release();
    }
}

export const StatesRegistryContext = React.createContext<StatesRegistry | undefined>(undefined);

