import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, IntlContext, useIntl } from 'react-intl';

import { ArgMessageValues } from '../types';
import { ArgNotificationsContext, DEFAULT_NOTIFICATIONS } from './notifications';
import { renderText } from '../utils/message-descriptor-formatters';
import { getProblemDetailsStatus, getProblemDetailsType, isResponseError } from '../utils/response-error';
import {
    ErrorNotificationFunction,
    GlobalNotificationDescription,
    MessageNotificationFunction,
    NotificationKey,
} from './types';
import { ArgErrorsMap } from './arg-errors-map';
import { handle403Error, handle404Error } from 'src/utils/notify-error';
import { ArgSnackbar, ArgSnackbarProps } from './arg-snackbar';
import { useClassNames } from '../arg-hooks/use-classNames';

import './use-arg-snackbars.less';

const NO_DURATION = localStorage.NO_DURATION === 'true';

const DEFAULT_DISPLAYED_DURATION = 5000;

const messages = defineMessages({
    undefinedMessage: {
        id: 'basic.use-arg-snackbars.UndefinedMessage',
        defaultMessage: 'Unknown error',
    },
});

let snackbarId = 0;

export type ArgSnackbarDescription = GlobalNotificationDescription

export interface ArgSnackbarsType {
    snackInfo: MessageNotificationFunction<ArgSnackbarDescription>;
    snackError: ErrorNotificationFunction<ArgSnackbarDescription>;
    snackWarning: MessageNotificationFunction<ArgSnackbarDescription>;
    snackSuccess: MessageNotificationFunction<ArgSnackbarDescription>;
    snackOpen: MessageNotificationFunction<ArgSnackbarDescription>;

    snackClose: (key: NotificationKey) => void;
}

export type ArgSnackbarParams = Omit<ArgSnackbarProps, 'type'>

export type ArgSnackbarsReturnType = [ArgSnackbarsType, ReactNode]


export function useArgSnackbars(errorMap?: ArgErrorsMap): ArgSnackbarsReturnType {
    const intl = useIntl();

    const classNames = useClassNames('arg-snackbars-container');
    const parent = useContext(ArgNotificationsContext);

    const [snackbars, setSnackbars] = useState<ArgSnackbarProps[]>([]);

    const handleCloseNotification = useCallback((snackbarKey: NotificationKey) => {
        if (NO_DURATION) {
            return;
        }

        setSnackbars((prev) => {
            return prev.filter(p => p.key !== snackbarKey);
        });
    }, []);

    const handleAutomaticClose = useCallback((snackbarKey: NotificationKey, duration?: number | null) => {
        setTimeout(() => {
            handleCloseNotification(snackbarKey);
        }, duration || DEFAULT_DISPLAYED_DURATION);
    }, [handleCloseNotification]);

    const argSnackbars = useMemo<ArgSnackbarsType>(() => {
        if (parent && parent !== DEFAULT_NOTIFICATIONS) {
            if (!errorMap) {
                return parent;
            }

            const error = (description?: ArgSnackbarDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey => {
                const problemDetailType = getProblemDetailsType(error);

                if (problemDetailType) {
                    const errorByType = errorMap[problemDetailType];
                    if (errorByType) {
                        description = {
                            ...description,
                            message: errorByType.title,
                            description: errorByType.description,
                        };
                    }

                    if (isResponseError(error)) {
                        messageValues = {
                            ...error.json,
                            ...messageValues,
                        };
                    }
                }

                const ret = parent.snackError(description, error, messageValues);

                return ret;
            };

            const ret: ArgSnackbarsType = {
                ...parent,
                snackError: error,
            };

            return ret;
        }

        function createParams(snackbarDescription: ArgSnackbarDescription, messageValues?: ArgMessageValues): ArgSnackbarParams {
            const {
                message,
                description,
                icon,
                key,
                duration,
                className,
            } = snackbarDescription;

            const _key = key || `useSnackbars-${snackbarId++}`;

            const ret: ArgSnackbarParams = {
                key: _key,
                message: (message) ? <IntlContext.Provider value={intl}>
                    {renderText(message, messageValues)}
                </IntlContext.Provider> : undefined,
                description: (description) ? <IntlContext.Provider value={intl}>
                    {renderText(description, messageValues)}
                </IntlContext.Provider> : undefined,
                icon: icon,
                messageValues: messageValues,
                className: className,
                onClose: () => handleCloseNotification(_key),
                duration: NO_DURATION ? 0 : duration,
            };

            return ret;
        }

        const ret: ArgSnackbarsType = {
            snackInfo(description: ArgSnackbarDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const snackbarKey: NotificationKey = args.key!.toString();

                const newNotification: ArgSnackbarProps = { ...args, type: 'info' };

                setSnackbars((prev) => {
                    return [...prev, newNotification];
                });

                return snackbarKey;
            },
            snackError(description?: ArgSnackbarDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey {
                const errorStatus = getProblemDetailsStatus(error);

                if (!description) {
                    description = {
                        message: error?.name || messages.undefinedMessage,
                    };
                }
                const args = createParams(description, messageValues);
                const snackbarKey: NotificationKey = args.key!.toString();

                if (errorStatus === 403) {
                    handle403Error();

                    return snackbarKey;
                }

                if (errorStatus === 404) {
                    handle404Error();

                    return snackbarKey;
                }

                const newNotification: ArgSnackbarProps = { ...args, type: 'error' };

                setSnackbars((prev) => {
                    return [...prev, newNotification];
                });

                return snackbarKey;
            },
            snackWarning(description: ArgSnackbarDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const snackbarKey: NotificationKey = args.key!.toString();

                const newNotification: ArgSnackbarProps = { ...args, type: 'warning' };

                setSnackbars((prev) => {
                    return [...prev, newNotification];
                });

                return snackbarKey;
            },
            snackSuccess(description: ArgSnackbarDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const snackbarKey: NotificationKey = args.key!.toString();

                const newNotification: ArgSnackbarProps = ({ ...args, type: 'success' });

                setSnackbars((prev) => {
                    return [...prev, newNotification];
                });

                return snackbarKey;
            },
            snackOpen(description: ArgSnackbarDescription, messageValues?: ArgMessageValues): NotificationKey {
                const args = createParams(description, messageValues);
                const snackbarKey: NotificationKey = args.key!.toString();

                const newNotification: ArgSnackbarProps = { ...args, type: 'info' };

                setSnackbars((prev) => {
                    return [...prev, newNotification];
                });

                return snackbarKey;
            },

            snackClose(snackbarKey: NotificationKey) {
                handleCloseNotification(snackbarKey);
            },
        };

        return ret;
    }, [errorMap, handleCloseNotification, intl, parent]);

    const currentSnackbar = useMemo(() => {
        if (!snackbars.length) {
            return undefined;
        }

        return snackbars[0];
    }, [snackbars]);

    useEffect(() => {
        if (!currentSnackbar) {
            return;
        }

        handleAutomaticClose(currentSnackbar.key, currentSnackbar.duration);
    }, [currentSnackbar]);

    let container: ReactNode;

    if (currentSnackbar) {
        container = (
            <div className={classNames('&')}>
                <ArgSnackbar {...currentSnackbar} />
            </div>
        );
    }

    return [argSnackbars, container];
}
