import React, { DragEvent, DragEventHandler, ReactNode, useCallback, useState } from 'react';

import { DragDropContext, DraggingEvent } from './drag-drop-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { useDebounce } from '../arg-hooks/use-debounce';

import './dropping-zone.less';

const DISABLE_DROP = false;

const DRAGGING_TIME_OUT_MS = 1000;

export interface DraggingContextEvent {
    dragging: boolean;
    draggingEvent?: DraggingEvent;
}

const DRAGGING_FALSE: DraggingContextEvent = {
    dragging: false,
};

export interface DragDropContextProviderProps {
    className?: ClassValue;
    children?: ReactNode;
}

export function DroppingZone(props: DragDropContextProviderProps) {
    const {
        children,
        className,
    } = props;

    const classNames = useClassNames('arg-drop-zone');

    const [draggingState, setDraggingStateBase] = useState<DraggingContextEvent>(DRAGGING_FALSE);

    const [draggingDebounce, cancelDraggingDebounce] = useDebounce(DRAGGING_TIME_OUT_MS);

    const setDragging = useCallback((event?: DragEvent) => {
        cancelDraggingDebounce();

        if (!event) {
            //console.log('DraggingState=', DRAGGING_FALSE);
            setDraggingStateBase(DRAGGING_FALSE);

            return;
        }

        if (!draggingState.dragging) {
            const draggingState = {
                dragging: true,
                draggingEvent: createDraggingEvent(event),
            };

            //console.log('DraggingState=', draggingState);

            setDraggingStateBase(draggingState);
        }

        draggingDebounce(() => {
            //console.log('DraggingState=', DRAGGING_FALSE);

            setDraggingStateBase(DRAGGING_FALSE);
        });
    }, [cancelDraggingDebounce, draggingDebounce, draggingState.dragging]);

    const handleDragEnter = useCallback<DragEventHandler>((event) => {
        setDragging(event);
    }, [setDragging]);

    const handleDragOver = useCallback<DragEventHandler>((event) => {
        setDragging(event);
    }, [setDragging]);

    const handleDrop = useCallback<DragEventHandler>(() => {
        setDragging(undefined);
    }, [setDragging]);

    const handleDragEnd = useCallback<DragEventHandler>(() => {
        setDragging(undefined);
    }, [setDragging]);

    const cls = {
        'is-dragging': draggingState,
    };


    if (DISABLE_DROP) {
        return <>
            {children}
        </>;
    }


    return (
        <DragDropContext.Provider value={draggingState}>
            <div
                className={classNames('&', className, cls)}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop}
            >
                {children}
            </div>
        </DragDropContext.Provider>
    );
}

function createDraggingEvent(event: DragEvent): DraggingEvent {
    const draggingEvent: DraggingEvent = {
        types: event.dataTransfer.types,
    };

    return draggingEvent;
}
