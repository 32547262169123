import { useCallback, useEffect, useRef } from 'react';

export function useSetTimeout(delay: number): ((callback: (() => void) | undefined) => void) {
    const timerIdRef = useRef<ReturnType<typeof setTimeout>>();
    useEffect(() => {
        return () => {
            if (!timerIdRef.current) {
                return;
            }

            clearTimeout(timerIdRef.current);
            timerIdRef.current = undefined;
        };
    }, []);

    const ret = useCallback((afterDelayCallback: (() => void) | undefined): void => {
        if (timerIdRef.current) {
            clearTimeout(timerIdRef.current);
            timerIdRef.current = undefined;
        }
        if (afterDelayCallback) {
            timerIdRef.current = setTimeout(afterDelayCallback, delay);
        }
    }, [delay]);

    return ret;
}
