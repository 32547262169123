import { RtMessageEventList } from './events';
import { ApplicationType } from '../../components/application/application-descriptor';

export enum RtApiSubType {
    Entities = 'Entities',
    Folders = 'Folders',
    Users = 'Users',
    Editors = 'Editors',
    Universes = 'Universes',
    ProgressMonitors = 'ProgressMonitors',
}

export interface RtApi {
    type: ApplicationType;

    subType: RtApiSubType | string;

    api: string;

    defaultServiceUrl: string;

    events: Readonly<RtMessageEventList>;
}
