import { defineMessages } from 'react-intl';
import { ReactNode, useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';
import { isString } from 'lodash';

import { ClassValue, useClassNames } from 'src/components/basic';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-input-expression-information-panel.less';

const CLASSNAME = 'arg-input-expression-information-panel';
const messages = defineMessages({});

const SANITIZE_RULES = {
    allowedTags: [
        'i', 'br', 'b', 'u', 'em', 'strong',
        'span', 'img', 'p',
        'ul', 'ol', 'li',
        'table', 'tbody', 'thead', 'tr', 'td', 'th',
        'h1', 'h2', 'h3', 'h4', 'h5',
    ],
    allowedAttributes: {
        '*': ['class', 'data-*'],
        img: ['src', 'data:'],
    },
    allowedSchemesByTag: {
        img: ['data'],
    },
    allowedSchemes: [],
};

export interface InformationText {
    content?: ArgRenderedText;
    messageValues?: ArgMessageValues;
}

export interface ArgInputExpressionInformationPanelProps {
    className?: ClassValue;
    information: InformationText;
}

export function ArgInputExpressionInformationPanel(props: ArgInputExpressionInformationPanelProps) {
    const {
        className,
        information,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const content = useMemo<ReactNode>(() => {
        const { content, messageValues } = information;

        if (!isString(content)) {
            const ret = renderText(content, messageValues);

            return ret;
        }

        const reg = /^<html>(.*)$/.exec(content);
        if (!reg) {
            const ret = renderText(content, messageValues);

            return ret;
        }

        const html = sanitizeHtml(reg[1], SANITIZE_RULES);

        return html;
    }, [information]);

    return (
        <div className={classNames('&', className)} data-testid='arg-input-expression-information-panel'>
            {content}
        </div>
    );
}
