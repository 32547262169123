// @ts-ignore TODO: PBI-1854 Create a typescript definition for this package
import nearestColor from 'nearest-color';
import { forEach, isArray, isEmpty, reduce, toString } from 'lodash';
import Debug from 'debug';

const debug = Debug('utils:ColorUtils');

export interface Gradient {
    colors: string[],
}

// Hash any string into an integer value then we'll use the int and convert to hex.
export function hashCode(buffer = '') {
    let hash = 0;

    forEach(buffer, (char, index) => {
        hash = (buffer.charCodeAt(index) ^ (hash * 163)) & 0xFFFFFF;
    });

    return hash;
}

// Convert an int to hexadecimal with a max length of six characters.
function intToRGB(integer = 0): string {
    let hex =
        ((integer >> 24) & 0xff).toString(16) +
        ((integer >> 16) & 0xff).toString(16) +
        ((integer >> 8) & 0xff).toString(16) +
        (integer & 0xff).toString(16);

    // Sometimes the string returned will be too short so we
    // add zeros to pad it out, which later get removed if
    // the length is greater than six.
    hex += '000000';

    return hex.substring(0, 6);
}

// https://gist.github.com/renancouto/4675192
export function lightenColor(color: string, percent: number) {
    const num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = (num >> 8 & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;

    return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)}`;
}

// Converts the given string to a color
export default function stringToColor(string = '', colorRange: Record<string, string> | string[]): string {
    const color = `#${intToRGB(hashCode(string))}`;

    debug('stringToColor', 'Name=', string, 'color=', color);

    if (isEmpty(colorRange)) {
        return color;
    }

    if (isArray(colorRange)) {
        colorRange = reduce(colorRange, (obj, value, index) => {
            obj[index] = value;

            return obj;
        }, {} as Record<string, string>);
    }

    const matcher = nearestColor.from(colorRange);

    const computedColor = matcher(color);

    debug('stringToColor', 'computedColor=', computedColor);

    return computedColor.value;
}
