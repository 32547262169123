/**
 * Copy styles from a source document to a target.
 * @param {Object} source
 * @param {Object} target
 * @private
 */

export function copyStylesOfDocument(source: Document, target: Document) {
    // Store style tags, avoid reflow in the loop
    const headFrag = target.createDocumentFragment();

    Array.from(source.styleSheets).forEach((styleSheet) => {
        // For <style> elements
        let rules;
        try {
            rules = styleSheet.cssRules;
        } catch (err) {
            console.error(err);
        }
        if (rules) {
            // IE11 is very slow for appendChild, so use plain string here
            const ruleText: string[] = [];

            // Write the text of each rule into the body of the style element
            Array.from(styleSheet.cssRules).forEach(cssRule => {
                const { type } = cssRule;

                // Skip unknown rules
                //if (type === CSSRule.UNKNOWN_RULE) {
                //   return;
                //}

                let returnText = '';

                if (type === CSSRule.KEYFRAMES_RULE) {
                    // IE11 will throw error when trying to access cssText property, so we
                    // need to assemble them
                    ruleText.push(renderKeyFrameText(cssRule as CSSKeyframesRule));
                } else if (
                    [CSSRule.IMPORT_RULE, CSSRule.FONT_FACE_RULE].includes(type)
                ) {
                    // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5)
                    // to handle local imports on a about:blank page
                    // '/custom.css' turns to 'http://my-site.com/custom.css'
                    returnText = fixUrlForRule(cssRule);
                } else {
                    returnText = cssRule.cssText;
                }
                ruleText.push(returnText);
            });

            const newStyleEl = target.createElement('style');
            newStyleEl.textContent = ruleText.join('\n');
            headFrag.appendChild(newStyleEl);
        } else if (styleSheet.href) {
            // for <link> elements loading CSS from a URL
            const newLinkEl = target.createElement('link');

            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            headFrag.appendChild(newLinkEl);
        }
    });

    target.head.appendChild(headFrag);
}

/**
 * Make keyframe rules.
 * @param {CSSRule} cssRule
 * @return {String}
 * @private
 */

function renderKeyFrameText(cssRule: CSSKeyframesRule) {
    const tokens: string[] = [];
    tokens.push('@keyframes', cssRule.name, '{');
    Array.from(cssRule.cssRules).forEach(cssRule => {
        const ck = cssRule as CSSKeyframeRule;
        // type === CSSRule.KEYFRAME_RULE should always be true
        tokens.push(ck.keyText, '{', ck.style.cssText, '}');
    });
    tokens.push('}');

    return tokens.join(' ');
}

/**
 * Handle local import urls.
 * @param {CSSRule} cssRule
 * @return {String}
 * @private
 */

function fixUrlForRule(cssRule: CSSRule) {
    return cssRule.cssText
        .split('url(')
        .map(line => {
            if (line[1] === '/') {
                return `${line.slice(0, 1)}${window.location.origin}${line.slice(1)}`;
            }

            return line;
        })
        .join('url(');
}

/**
 * Convert features props to window features format (name=value,other=value).
 * @param {Object} obj
 * @return {String}
 * @private
 */

export function toWindowFeatures(obj: any): string {
    return Object.keys(obj)
        .reduce((features, name) => {
            const value = obj[name];
            if (typeof value === 'boolean') {
                features.push(`${name}=${value ? 'yes' : 'no'}`);

                return features;
            }

            features.push(`${name}=${value}`);

            return features;
        }, [] as string[])
        .join(',');
}
