import { useEffect, useState } from 'react';

import { SelectionProvider } from './selection-provider';

export function useSharedSelectionProvider<T>(init: (() => SelectionProvider<T>)): SelectionProvider<T> {
    const [selectionProvider] = useState<SelectionProvider<T>>(init);

    useEffect(() => {
        selectionProvider.start();

        return () => {
            selectionProvider.stop();
        };
    }, [selectionProvider]);

    return selectionProvider;
}
