import React from 'react';

import { ClassValue, useClassNames } from 'src/components/basic/arg-hooks/use-classNames';

import './arg-map-toolbar.less';

interface ArgMapToolbarProps {
    children: React.ReactNode;
    className?: ClassValue;
}

export function ArgMapToolbar(props: ArgMapToolbarProps) {
    const {
        className,
        children,
    } = props;

    const classNames = useClassNames('arg-map-toolbar');

    return (
        <div className={classNames('&', className)}>
            {children}
        </div>
    );
}
