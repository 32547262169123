import React, { ReactNode, useContext, useEffect, useMemo } from 'react';

import { KeyBindingScopeDescriptor, KeyBindingsScopeDefs } from './keybinding';

export const KeyBindingsContext = React.createContext<KeyBindingsScopeDefs | undefined>(undefined);


interface KeyBindingScopeProps {
    children: ReactNode;
    enabled?: boolean;
    scope: KeyBindingScopeDescriptor;
    inherit?: boolean;
}

export function KeyBindingsScope(props: KeyBindingScopeProps) {
    const {
        children,
        enabled = true,
        scope,
        inherit,
    } = props;

    const parent = useContext(KeyBindingsContext);

    const scopeDefs = useMemo<KeyBindingsScopeDefs | undefined>(() => {
        if (!parent) {
            throw new Error('Scope component can not fin a parent of type KeyBindingsEngine');
        }

        return new KeyBindingsScopeDefs(scope, parent, undefined, inherit);
    }, [scope, parent, inherit]);

    useEffect(() => {
        if (enabled === false || !parent || !scopeDefs) {
            return;
        }

        parent.addChild(scopeDefs);

        return () => {
            parent.removeChild(scopeDefs);
        };
    }, [enabled, scopeDefs]);

    return <KeyBindingsContext.Provider value={scopeDefs}>
        {children}
    </KeyBindingsContext.Provider>;
}
