import { isArray, isDate, isFunction, isObject } from 'lodash';

export enum DifferenceType {
    VALUE_CREATED = 'created',
    VALUE_UPDATED = 'updated',
    VALUE_DELETED = 'deleted',
    VALUE_UNCHANGED = 'unchanged',
}

export interface Difference {
    type: DifferenceType;
    data: any;
}

function isValue(a: any) {
    return !isObject(a) && !isArray(a);
}

export function deepDifference(obj1: any, obj2: any, depth = 0): Record<string, Difference> | Difference | undefined {
    if (isFunction(obj1) || isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.';
    }

    if (isValue(obj1) || isValue(obj2)) {
        const type = compareValues(obj1, obj2);
        if (!type) {
            return undefined;
        }

        return {
            type,
            data: obj1 === undefined ? obj2 : obj1,
        };
    }

    if (depth < 1) {
        const type = compareValues(obj1, obj2);
        if (!type) {
            return undefined;
        }

        return {
            type,
            data: obj1 === undefined ? obj2 : obj1,
        };
    }

    let diff: Record<string, any> | undefined = undefined;
    for (const key in obj1) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        let d;
        if (isFunction(value1) || isFunction(value2)) {
            d = compareValues(value1, value2);
        } else {
            d = deepDifference(value1, value2, depth - 1);
        }

        if (!d) {
            continue;
        }

        if (!diff) {
            diff = {};
        }
        diff[key] = d;
    }
    for (const key in obj2) {
        const value1 = obj1[key];
        const value2 = obj2[key];
        if (value1 !== undefined) {
            continue;
        }

        const d = compareValues(value1, value2);
        if (!d) {
            continue;
        }

        if (!diff) {
            diff = {};
        }
        diff[key] = d;
    }

    return diff;
}

function compareValues(value1: any, value2: any): DifferenceType | undefined {
    if (value1 === value2) {
        return undefined;
    }
    if (isDate(value1) && isDate(value2) && value1.getTime() === value2.getTime()) {
        return undefined;
    }
    if (value1 === undefined) {
        return DifferenceType.VALUE_CREATED;
    }
    if (value2 === undefined) {
        return DifferenceType.VALUE_DELETED;
    }

    return DifferenceType.VALUE_UPDATED;
}
