import { SetStateAction, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { uniqBy } from 'lodash';

import {
    ArgNodeKey,
    ArgNodePath,
    ArgTree,
    ClassValue,
    MESSAGE_DESCRIPTOR_FORMATTERS, normalizeText,
    useClassNames,
} from 'src/components/basic';
import { ArgInputSearch } from './arg-input-search';
import { Snippet } from './types';
import { ArgInputExpressionSnippet } from './arg-input-expression-snippet';
import { stringSorter } from '../../../utils/sorter';

import './arg-input-expression-snippets-list.less';

const CLASSNAME = 'arg-input-expression-snippets-list';

const messages = defineMessages({
    snippetsPanelTitle: {
        id: 'basic.arg-input-expression-snippets-list.Title',
        defaultMessage: 'Code snippets',
    },
    categoryTitle: {
        id: 'basic.arg-input-expression-snippets-list.CategoryTitle',
        defaultMessage: '{category} <grey>({count})</grey>',
    },
});

export interface ArgInputExpressionSnippetsListProps {
    className?: ClassValue;
    snippets: Snippet[];
    onViewSnippet?: (snippet: Snippet) => void;
    onDoubleClickSnippet?: (snippet: Snippet) => void;
}

const getSnippetCategories = (snippets: Snippet[]): Snippet[] => {
    const ret = uniqBy(snippets, 'category').sort((a, b) => {
        return stringSorter<Snippet>(a, b, item => item.category);
    });

    return ret;
};

export function ArgInputExpressionSnippetsList(props: ArgInputExpressionSnippetsListProps) {
    const {
        className,
        snippets,
        onViewSnippet,
        onDoubleClickSnippet,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const [openedNodes, setOpenedNodes] = useState<ArgNodeKey[]>(() => {
        const sortedSnippets = getSnippetCategories(snippets);

        const snippetsId = sortedSnippets.map((s) => s.id);

        return snippetsId;
    });
    const [rawSearch, setSearch] = useState<string>('');

    const search = normalizeText(rawSearch);

    const handleGetNodeKey = useCallback((path: ArgNodePath<Snippet>) => {
        const node = path[path.length - 1];

        return node.id;
    }, []);

    const handleGetNodeChildren = useCallback((path: ArgNodePath<Snippet>) => {
        if (path.length !== 1) {
            return;
        }

        const node = path[0];

        const ret = [...snippets]
            .filter((s) => {
                const isSameCategory = s.category === node.category;

                if (!search) {
                    return isSameCategory;
                }

                const isSearched = normalizeText(s.title).includes(search);

                return isSameCategory && isSearched;
            }).sort((a, b) => {
                return stringSorter<Snippet>(a, b, item => item.title);
            });

        return ret;
    }, [search, snippets]);

    const handleGetNodeLabel = useCallback((path: ArgNodePath<Snippet>) => {
        const node = path[path.length - 1];

        if (path.length === 1) {
            const children = handleGetNodeChildren(path);

            return (
                <FormattedMessage
                    {...messages.categoryTitle}
                    values={{
                        ...MESSAGE_DESCRIPTOR_FORMATTERS,
                        category: node.category,
                        count: children?.length || 0,
                    }}
                />
            );
        }

        return (
            <ArgInputExpressionSnippet
                key={node.id}
                className={classNames('&-body-list-item')}
                snippet={node}
                search={search}
                onViewSnippet={onViewSnippet ? () => onViewSnippet(node) : undefined}
                onDoubleCLick={onDoubleClickSnippet ? () => onDoubleClickSnippet(node) : undefined}
            />
        );
    }, [classNames, handleGetNodeChildren, onDoubleClickSnippet, onViewSnippet, search]);

    const handleHasNodeSearchedToken = useCallback((path: ArgNodePath<Snippet>) => {
        const children = handleGetNodeChildren(path);

        return !!children && children.length > 0;
    }, [handleGetNodeChildren]);

    const handleOpenNodes = useCallback((nodes: SetStateAction<ArgNodeKey[]>) => {
        setOpenedNodes(nodes);
    }, []);

    const root = getSnippetCategories(snippets);

    return (
        <div className={classNames('&', className)}
             data-testid='arg-input-expression-snippets-list'
        >
            <div className={classNames('&-title')}>
                <FormattedMessage {...messages.snippetsPanelTitle} />
            </div>
            <ArgInputSearch
                onInputChange={setSearch}
                value={search}
                className={classNames('&-search')}
                data-testid='search-input'
            />
            <div className={classNames('&-body')}>
                <ArgTree<Snippet>
                    root={root}
                    openedNodes={openedNodes}
                    searchedToken={search}
                    getNodeKey={handleGetNodeKey}
                    getNodeLabel={handleGetNodeLabel}
                    getNodeChildren={handleGetNodeChildren}
                    onOpen={handleOpenNodes}
                    hasNodeSearchedToken={handleHasNodeSearchedToken}
                />
            </div>
        </div>
    );
}
