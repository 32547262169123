import { LatLng, latLng, LatLngExpression } from 'leaflet';
import { isArray, isEmpty, isNil } from 'lodash';

export const latLngExpressionToLatLng = (value?: LatLngExpression): LatLng | undefined => {
    if (isNil(value) || isEmpty(value)) {
        return;
    }

    if (isArray(value)) {
        const [lat, lng] = value;

        if (isNaN(lat) || isNaN(lng)) {
            return;
        }
    } else {
        const { lat, lng } = value;

        if (isNaN(lat) || isNaN(lng)) {
            return;
        }
    }

    return latLng(value);
};
