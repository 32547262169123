export function getFileIcon(contentType: string | undefined): string | undefined {
    if (!contentType) {
        return undefined;
    }

    if (contentType.startsWith('image/')) {
        return 'icon-file-picture';
    }

    if (contentType.startsWith('video/')) {
        return 'icon-file-video';
    }

    if (contentType.startsWith('audio/')) {
        return 'icon-file-music';
    }

    if (contentType.startsWith('audio/')) {
        return 'icon-file-music';
    }

    if (contentType.startsWith('text/')) {
        return 'icon-file-text2';
    }

    if (contentType === 'application/zip'
        || contentType === 'application/vnd.rar'
        || contentType === 'application/x-7z-compressed'
        || contentType === 'application/x-bzip'
        || contentType === 'application/x-bzip2'
        || contentType === 'application/gzip'
    ) {
        return 'icon-file-zip';
    }

    if (contentType === 'application/msword'
        || contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
        return 'icon-file-word';
    }

    if (contentType === 'application/pdf') {
        return 'icon-file-pdf';
    }

    if (contentType === 'application/vnd.ms-excel'
        || contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
        return 'icon-file-excel';
    }

    return undefined;
}

