import { MouseEvent } from 'react';
import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from 'src/components/basic';

import './arg-steps.less';

const CLASSNAME = 'arg-steps';
const messages = defineMessages({});

export interface ArgStep {
    className?: ClassValue;
    onClick?: (event: MouseEvent) => void;
}


export interface ArgStepsProps {
    className?: ClassValue;
    current?: number;
    items: ArgStep[];
}

export function ArgSteps(props: ArgStepsProps) {
    const { className, current, items } = props;
    const classNames = useClassNames(CLASSNAME);

    return (
        <div className={classNames('&', className)}>
            {items.map((item, index) => {
                const cls = {
                    '&-item': true,
                    '&-item-active': index === current,
                };

                return (
                    <div className={classNames(cls, item.className)} key={index} onClick={item.onClick} />
                );
            })}
        </div>
    );
}
