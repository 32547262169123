import { useContext, useEffect, useRef, useState } from 'react';

import { GlobalProgressContext } from './global-progress-context';
import { $yield } from '../utils/yield';

export function useGlobalProgress(): boolean {
    const globalProgress = useContext(GlobalProgressContext);
    const [show, setShow] = useState<boolean>(false);
    const unmounted = useRef<boolean>();

    useEffect(() => {
        function handleChange(show: boolean) {
            $yield(() => {
                if (unmounted.current) {
                    return;
                }
                setShow(show);
            });
        }

        globalProgress.on('Change', handleChange);

        return () => {
            unmounted.current = true;
            globalProgress.off('Change', handleChange);
        };
    }, [globalProgress]);

    return show;
}
