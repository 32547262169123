import { useEffect, useMemo, useState } from 'react';
import Debug from 'debug';
import { isString } from 'lodash';

import { CacheOptions, IDataCacheRepository } from './data-cache-repository';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { StateId } from 'src/utils/rt-states/basic-state';

const debug = Debug('basic:cache-repositories:UseDataCacheRepository');

export type DataCacheRepositoryResponse<T> = [T | undefined | null, Error | undefined | null, ProgressMonitor | undefined];

const UNDEFINED_DATA: DataCacheRepositoryResponse<any> = [undefined, undefined, undefined];

export function useDataCacheRepository<T extends object, K = string>(
    infos: K | string | undefined,
    stateId: StateId | undefined,
    dataRepository: IDataCacheRepository<T>,
    cacheOptions?: CacheOptions
): DataCacheRepositoryResponse<T> | undefined {
    const [dataOrError, setDataOrError] = useState<DataCacheRepositoryResponse<T | null>>();

    const key: string | undefined = useMemo(() => {
        if (!infos) {
            return undefined;
        }
        if (isString(infos)) {
            return infos;
        }

        return JSON.stringify(infos);
    }, [infos]);

    useEffect(() => {
        debug('useDataCacheRepository', 'dataRepository=', dataRepository, 'key=', key);
        if (!dataRepository || !key) {
            // Loading manually
            return;
        }

        const _key = key;
        const loadedEventKey = `loaded:${key}`;

        let linked = false;

        function handleDataLoaded(data?: T | null, error?: Error | null) {
            debug('useDataCacheRepository', 'dataLoaded key=', _key, 'data=', data, 'error=', error);

            setDataOrError([data, error, undefined]);

            if (linked) {
                throw new Error('*** PANIC, ALREADY LINKED');
            }

            linked = true;
            dataRepository.link(_key);
        }

        const [data, error, progressMonitor] = dataRepository.load(key, infos, stateId, cacheOptions);
        debug('useDataCacheRepository', 'load() key=', _key, 'data=', data, 'error=', error, 'progressMonitor=', progressMonitor);

        if (progressMonitor) {
            dataRepository.once(loadedEventKey, handleDataLoaded);

            setDataOrError([undefined, undefined, progressMonitor]);
        } else if (data !== undefined || error) {
            handleDataLoaded(data, error);
        } else {
            dataRepository.once(loadedEventKey, handleDataLoaded);

            setDataOrError(undefined);
        }

        return () => {
            debug('useDataCacheRepository', 'release key=', _key, 'linked=', linked);

            dataRepository.off(loadedEventKey, handleDataLoaded);

            if (linked) {
                linked = false;
                dataRepository.unlink(_key);
            }
        };
    }, [key, dataRepository, stateId]);

    if (!dataRepository) {
        return undefined;
    }

    if (dataOrError) {
        return dataOrError;
    }

    return UNDEFINED_DATA;
}

/*


function useCacheRepository<K = string>(
    infos: K | string | undefined,
    stateId: StateId | undefined,
    dataRepository: IDataCacheRepository<any>,
): DataCacheRepositoryResponse<Types> | undefined {
    const [dataOrError, setDataOrError] = useState<DataCacheRepositoryResponse<Types>>();

    const key: string | undefined = useMemo(() => {
        if (!infos) {
            return undefined;
        }
        if (isString(infos)) {
            return infos;
        }

        return JSON.stringify(infos);
    }, [infos]);

    useEffect(() => {
        debug('useDataCacheRepository', 'dataRepository=', dataRepository, 'key=', key);
        if (!dataRepository || !key) {
            // Loading manually
            return;
        }

        let loaded = false;

        function handleDataLoaded(data?: Types, error?: Error) {
            debug('useDataCacheRepository', 'dataLoaded key=', key, 'data=', data, 'error=', error);

            if (error) {
                setDataOrError([null, error, undefined]);

                return;
            }

            loaded = true;
            dataRepository!.link(key!);
            setDataOrError([data, null, undefined]);
        }

        dataRepository.once(`loaded:${key}`, handleDataLoaded);

        const dataOrError = dataRepository.load(key, infos, stateId);
        debug('useDataCacheRepository', 'load() key=', key, 'ret=', dataOrError);
        if (dataOrError) {
            loaded = true;
            setDataOrError(dataOrError);
            dataRepository.link(key);
        }

        return () => {
            debug('useDataCacheRepository', 'release key=', key, 'loaded=', loaded);
            setDataOrError(undefined);

            dataRepository.off(`loaded:${key}`, handleDataLoaded);
            if (loaded) {
                dataRepository.unlink(key);
            }
        };
    }, [key, stateId, dataRepository]);

    if (!dataRepository) {
        return undefined;
    }

    return dataOrError || UNDEFINED_DATA;
}


 */

/*

    const key: string | undefined = useMemo(() => {
        if (!infos) {
            return undefined;
        }

        if (isString(infos)) {
            return infos;
        }

        return JSON.stringify(infos);
    }, [infos]);

    useEffect(() => {
        debug('useDataCacheRepository', 'dataRepository=', dataRepository, 'key=', key);
        if (!dataRepository || !key) {
            // Loading manually
            return;
        }

        const _key = key;

        let linked = false;

        function handleDataLoaded(data?: Types | null, error?: Error | null) {
            debug('useDataCacheRepository', 'dataLoaded key=', _key, 'data=', data, 'error=', error);

            setDataOrError([data, error, undefined]);

            if (linked) {
                console.error('*** PANIC, ALREADY LINKED');
            }
            linked = true;
            dataRepository.link(_key);
        }

        const [data, error, progressMonitor] = dataRepository.load(_key, infos, stateId);
        debug('useDataCacheRepository', 'load() key=', _key, 'data=', data, 'error=', error, 'progressMonitor=', progressMonitor);

        if (progressMonitor) {
            dataRepository.once(`loaded:${_key}`, handleDataLoaded);

            setDataOrError([undefined, undefined, progressMonitor]);
        } else if (data !== undefined || error) {
            handleDataLoaded(data, error);
        } else {
            dataRepository.once(`loaded:${_key}`, handleDataLoaded);

            setDataOrError(undefined);
        }

        return () => {
            debug('useDataCacheRepository', 'release key=', _key, 'linked=', linked);

            dataRepository.off(`loaded:${_key}`, handleDataLoaded);

            if (linked) {
                dataRepository.unlink(_key);
            }
        };
    }, [key, stateId, dataRepository]);

    if (!dataRepository) {
        return undefined;
    }

    if (dataOrError) {
        return dataOrError;
    }

    return UNDEFINED_DATA;

 */
