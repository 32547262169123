import React, {
    CSSProperties,
    DragEvent,
    ReactNode,
    UIEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
} from 'react';
import { groupBy } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ScrollDisplayManager } from '../../../utils/scroll-display-manager';
import { VirtualColumn } from './virtual-column';
import {
    ArgTable4OnDragStartHandler,
    ArgTable4Column,
    ArgTable4ColumnKey,
    ARG_TABLE_4_HORIZONTAL_SCROLLBAR_HEIGHT,
    ArgTable4RowStateInfo,
    ArgTable4AdditionalRow,
} from './arg-table4';
import { VirtualColumnHeader } from './virtual-column-header';
import { DataSorter } from '../arg-providers/data-provider';
import { VIRTUAL_COLUMNS_CONTAINER_CLASSNAME } from './shared-classnames';
import { ArgTable4CellIndex, ArgTable4Cursor } from './types';
import { ArgTable4SelectionManager } from './arg-table4-selection-manager';

import './virtual-columns-container.less';

interface VirtualColumnScrollContainerProps<T> {
    rowsCache: Map<number, ArgTable4RowStateInfo<T>>;
    rowHeight: number;
    startNode: number;
    visibleNodeCount: number;
    globalScrollTop?: number;
    scrollTop?: number;
    scrollLeft: number;
    totalHeight: number;
    columns: ArgTable4Column<T>[];
    columnsStartIndex: number;
    columnsEndIndex: number;
    scrollDisplayManager: ScrollDisplayManager;
    bodyRef?: React.MutableRefObject<HTMLDivElement | null>;
    headerBodyRef?: React.MutableRefObject<HTMLDivElement | null>;
    headerScrollContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
    locked?: boolean;
    className?: ClassValue;
    header?: boolean;
    firstColumn: boolean;
    lastColumn: boolean;
    itemsCount: number;
    noVerticalScroll: boolean;
    headerHeight: number;
    additionalHeaderHeight?: number;
    renderLoadingCell?: (column: ArgTable4Column<T>, index?: number) => ReactNode;
    renderErrorCell?: (column: ArgTable4Column<T>, index?: number, error?: Error) => ReactNode;
    sort?: DataSorter;
    leftColumnsWidth?: number,
    onColumnSort: (column: ArgTable4Column<T>, order: 'ascending' | 'descending' | undefined, replace: boolean) => void;
    onColumnLock: (column: ArgTable4Column<T>, locked: boolean) => void;
    onColumnVisible: (column: ArgTable4Column<T>, visible: boolean) => void;
    canLockColumn?: boolean;
    dragColumnTransforms?: Record<ArgTable4ColumnKey, string>;
    draggedColumnKey?: ArgTable4ColumnKey;
    searchScrollTop?: number;
    columnWidths?: Record<ArgTable4ColumnKey, number>;
    onColumnWidthChange?: (column: ArgTable4Column<T>, width: number) => void;
    onDataLoaded?: (body: HTMLDivElement) => void;
    disabled?: boolean;
    onDragStart?: ArgTable4OnDragStartHandler<T>;
    onDragEnd?: (event: DragEvent) => void;
    additionalRows?: ArgTable4AdditionalRow[];
    onCursorChange?: (cursor: ArgTable4Cursor) => void;
    cursor?: ArgTable4Cursor;
    selectionManager?: ArgTable4SelectionManager;
    hoverCellIndex?: ArgTable4CellIndex;
}

export function VirtualColumnScrollContainer<T>({
    rowsCache,
    rowHeight,
    startNode,
    visibleNodeCount,
    globalScrollTop,
    scrollTop,
    scrollLeft,
    totalHeight,
    columns,
    columnsStartIndex,
    columnsEndIndex,
    scrollDisplayManager,
    headerBodyRef,
    bodyRef,
    locked,
    className,
    header,
    firstColumn,
    lastColumn,
    itemsCount,
    noVerticalScroll,
    headerHeight,
    additionalHeaderHeight,
    renderLoadingCell,
    renderErrorCell,
    sort,
    onColumnSort,
    onColumnLock,
    onColumnVisible,
    canLockColumn,
    leftColumnsWidth = 0,
    dragColumnTransforms,
    draggedColumnKey,
    searchScrollTop,
    columnWidths,
    onDataLoaded,
    disabled,
    onDragStart,
    onDragEnd,
    additionalRows,
    onCursorChange,
    cursor,
    selectionManager,
    hoverCellIndex,
    headerScrollContainerRef,
}: VirtualColumnScrollContainerProps<T>) {
    const classNames = useClassNames(VIRTUAL_COLUMNS_CONTAINER_CLASSNAME); // Be careful: also used for screenshot
    let headerRef = useRef<HTMLDivElement>(null);
    if (headerBodyRef){
        headerRef = headerBodyRef;
    }

    // TODO: this happens in all virtualColumnContainer, hence moves up to art-table4 to optimize.
    const additionalRowsByRowIndex = useMemo<Record<number, ArgTable4AdditionalRow[]>>(() => {
        return groupBy(additionalRows, 'index');
    }, [additionalRows]);

    const elements = scrollDisplayManager.getViewPortContent(startNode, visibleNodeCount, additionalRows);

    useEffect(() => {
        if (headerRef.current) {
            headerRef.current.style.left = `${-scrollLeft}px`;
        }
    }, [scrollLeft]);

    let left = 0;

    const columnComponents: ReactNode[] = [];
    const headerComponents: ReactNode[] = [];

    for (let i = columnsStartIndex; i < columnsEndIndex; i++) {
        const column = columns[i];
        const dragTransform = dragColumnTransforms?.[column.key];
        let dragged;
        if (draggedColumnKey) {
            dragged = (draggedColumnKey === column.key);
        }

        let columnWidth = columnWidths?.[column.key];
        if (columnWidth === undefined) {
            columnWidth = column.width as number;
        }

        const cls = {
            'row-header': column.rowHeader,
        };

        columnComponents.push(
            <VirtualColumn<T>
                className={classNames(cls)}
                column={column}
                rowsCache={rowsCache}
                key={column.key}
                elements={elements}
                totalHeight={totalHeight}
                left={left}
                columnWidth={columnWidth}
                scrollTop={scrollTop}
                first={firstColumn && i === columnsStartIndex}
                last={lastColumn && i === columnsEndIndex - 1}
                itemsCount={itemsCount}
                renderLoadingCell={renderLoadingCell}
                renderErrorCell={renderErrorCell}
                dragTransform={dragTransform}
                dragged={dragged}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                columnIndex={i}
                onCursorChange={onCursorChange}
                cursor={cursor}
                selectionManager={selectionManager}
                hovered={hoverCellIndex?.columnIndex === i}
                rowHeight={rowHeight}
                additionalRowsByRowIndex={additionalRowsByRowIndex}
            />
        );

        headerComponents.push(<VirtualColumnHeader<T>
                key={column.key}
                column={column}
                locked={locked}
                onColumnSort={onColumnSort}
                onColumnLock={onColumnLock}
                onColumnVisible={onColumnVisible}
                canLockColumn={canLockColumn}
                sort={sort}
                dragTransform={dragTransform}
                dragged={dragged}
                left={left}
                columnWidth={columnWidth}
                disabled={disabled}
                columnIndex={i}
                selectionManager={selectionManager}
                onCursorChange={onCursorChange}
                cursor={cursor}
                hovered={hoverCellIndex?.columnIndex === i}
        />
        );

        left += columnWidth;
    }

    const containerBodyStyle: CSSProperties = {};
    const bodyStyle: CSSProperties = {};
    const style: CSSProperties = {
        left: `${leftColumnsWidth}px`,
        visibility: 'visible',
    };
    if (locked) {
        style.minWidth = `${left}px`;
        style.width = `${left}px`;
        //        containerBodyStyle.width = `${left}px`;
    } else {
        bodyStyle.minWidth = `${left}px`;
        bodyStyle.width = `${left}px`;
        //        containerBodyStyle.width = `${left}px`;
    }

    if (noVerticalScroll) {
        style.height = `${totalHeight + headerHeight + (additionalHeaderHeight || 0) + ARG_TABLE_4_HORIZONTAL_SCROLLBAR_HEIGHT}px`;
    }

    const cls = {
        locked,
    };

    const headerCls = {
        'has-vertical-scroll': globalScrollTop,
    };

    useLayoutEffect(() => {
        if (searchScrollTop !== undefined && bodyRef?.current && bodyRef.current.scrollTop !== searchScrollTop) {
            const scrollTop = bodyRef.current.scrollTop;
            const contentHeight = bodyRef.current.clientHeight;

            if (scrollTop + contentHeight - rowHeight < searchScrollTop) {
                bodyRef.current.scrollTop = (
                    bodyRef.current.scrollTop + (searchScrollTop - (scrollTop + contentHeight)) + rowHeight
                );
            } else if (searchScrollTop <= scrollTop) {
                bodyRef.current.scrollTop = searchScrollTop;
            }
        }
    }, [searchScrollTop, rowHeight, onDataLoaded, bodyRef]);

    const handleBodyRef = useCallback((ref: HTMLDivElement | null) => {
        if (bodyRef) {
            bodyRef.current = ref;
        }

        if (ref) {
            onDataLoaded && onDataLoaded(ref);
        }
    }, [onDataLoaded, bodyRef]);

    if (columnsStartIndex >= columnsEndIndex) {
        return null;
    }

    const body = <div key='body' className={classNames('&-body')} ref={handleBodyRef} style={containerBodyStyle}>
        <div key='body' className={classNames('&-body-scroll')} style={bodyStyle}>
            {columnComponents}
        </div>
    </div>;

    return (
        <div className={classNames('&', className, cls)} style={style}
             ref={headerScrollContainerRef}
             data-columngroup={locked ? 'locked' : 'unlocked'}>
            {header !== false && (
                <div key='header' className={classNames('&-header', headerCls)} ref={headerRef}
                     style={{
                         visibility: 'visible',
                     }}>
                    {headerComponents}
                </div>
            )}
            {body}
        </div>
    );
}
