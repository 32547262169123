import React, {
    CSSProperties,
    forwardRef,
    MouseEventHandler,
    ReactNode,
    Ref,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { MenuInfo, MenuMode } from 'rc-menu/lib/interface';
import Debug from 'debug';
import { MenuRef } from 'antd/es/menu';
import { isFunction } from 'lodash';

import { getDataTestIdFromProps } from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './arg-menu.less';
import '../arg-popover/arg-popover.less';

const debug = Debug('basic:arg-menu:ArgMenu');

export type ArgMenuRef = MenuRef;

export type ArgMenuInfo = MenuInfo;

export interface ArgMenuProps {
    id?: string;
    className?: ClassValue;
    children: ReactNode;
    activeKey?: string;
    selectedKeys?: string[];
    openKeys?: string[];
    style?: CSSProperties;
    defaultActiveFirst?: boolean;
    defaultSelectedKeys?: string[];
    forceSubMenuRender?: boolean;
    onClick?: (menuItem: ArgMenuInfo) => void;
    onContextMenu?: MouseEventHandler;
    getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | RefObject<HTMLElement>;
    mode?: MenuMode;
    defaultOpenKeys?: string[];
}

function _ArgMenu(props: ArgMenuProps, ref: Ref<ArgMenuRef>) {
    const {
        id,
        className,
        onClick,
        children,
        forceSubMenuRender,
        selectedKeys,
        activeKey,
        openKeys,
        style,
        onContextMenu,
        getPopupContainer,
        defaultActiveFirst,
        defaultSelectedKeys,
        mode,
        defaultOpenKeys,
        ...otherProps
    } = props;

    let argMenuPrimaryClassName = 'arg-menu';
    if (mode && mode !== 'vertical') {
        argMenuPrimaryClassName += `-${mode}`;
    }
    const classNames = useClassNames(argMenuPrimaryClassName);

    const htmlRef = useRef<HTMLElement | null>(null);

    const dataTestId = getDataTestIdFromProps(props);

    const optionalProps: MenuProps | undefined = openKeys ? {
        openKeys: openKeys, /* set property only when filled because of unexpected menu behavior when this
        property is declared, even with undefined value*/
    } : undefined;

    const isInternalOpenKeys = 'openKeys' in props;

    const [internalOpenKeys, setInternalOpenKeys] = useState<string[]>(() => {
        return defaultOpenKeys || [];
    });

    const computePopoverContainer = useCallback((triggerNode: HTMLElement) => {
        if (isFunction(getPopupContainer)) {
            const ret = (getPopupContainer as (triggerNode: HTMLElement) => HTMLElement)(triggerNode);

            return ret;
        }

        if (getPopupContainer?.current) {
            return getPopupContainer?.current!;
        }

        if (htmlRef.current) {
            return htmlRef.current.ownerDocument.body;
        }

        return document.body;
    }, [getPopupContainer]);

    // inform contextual menu of the update
    useEffect(() => {
        let element: HTMLElement;
        if (isFunction(computePopoverContainer)) {
            element = computePopoverContainer(htmlRef.current || document.body);
        } else {
            element = computePopoverContainer;
        }

        if (element) {
            debug('render', 'Send event', element);
            element.dispatchEvent(new CustomEvent('arg-menu-update'));
        }
    });

    const handleOpenChange = useCallback((openKeys: string[]) => {
        //console.log('Open', openKeys, isInternalOpenKeys, internalOpenKeys);
        setInternalOpenKeys(openKeys);
    }, []);

    const handleClick = useCallback((info: MenuInfo) => {
        debug('handleClick', 'domEvent=', info.domEvent.defaultPrevented);

        if (!onClick) {
            return;
        }

        if (info.domEvent.defaultPrevented) {
            return;
        }

        info.domEvent.preventDefault();

        onClick(info);
    }, [onClick]);

    return (
        <Menu
            id={id}
            ref={ref}
            className={classNames('&', className)}
            onClick={handleClick}
            activeKey={activeKey}
            selectedKeys={selectedKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            forceSubMenuRender={forceSubMenuRender}
            data-testid={dataTestId}
            style={style}
            onContextMenu={onContextMenu}
            defaultActiveFirst={defaultActiveFirst}
            getPopupContainer={computePopoverContainer}
            mode={mode}
            openKeys={isInternalOpenKeys ? internalOpenKeys : openKeys}
            onOpenChange={handleOpenChange}
            defaultOpenKeys={defaultOpenKeys}
            {...optionalProps}
            {...otherProps}
        >
            {children}

            <span ref={htmlRef} className={classNames('&-htmlref')} />
        </Menu>
    );
}

export const ArgMenu = forwardRef<ArgMenuRef, ArgMenuProps>(_ArgMenu);
