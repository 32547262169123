import React from 'react';
import { defineMessages } from 'react-intl';

import { ArgRenderedText, ProgressMonitor, useClassNames } from '../../basic';
import { BasicPaneI18n, BasicPaneI18nProps } from './basic-pane-i18n';

const DEFAULT_BACKGROUND_ANIMATION = 'wave';
const DEFAULT_ICON = 'icon-loading';

const messages = defineMessages({
    loading: {
        id: 'common.loadingPage.Message',
        defaultMessage: 'Loading {threeDotsLoading}',
    },
});

interface LoadingPaneProps extends BasicPaneI18nProps {
    progressMonitor?: ProgressMonitor;
}

export function LoadingPane(props: LoadingPaneProps) {
    const {
        className,
        children,
        progressMonitor,
        message,
        backgroundAnimation = DEFAULT_BACKGROUND_ANIMATION,
        icon = DEFAULT_ICON,
        ...otherProps
    } = props;

    const classNames = useClassNames('loading-pane');

    let _message: ArgRenderedText;
    let _messageValues: Record<string, any> | undefined = undefined;
    if (children) {
        _message = children;
    } else if (progressMonitor) {
        _message = progressMonitor.taskName;
        _messageValues = progressMonitor.messageValues;
        if (_messageValues) {
            _messageValues = {
                ..._messageValues,
                workedTicks: progressMonitor.workedTicks,
            };
        }
    } else {
        _message = message;
    }

    if (_message === undefined) {
        _message = messages.loading;
    }

    return <BasicPaneI18n
        className={classNames('&', className)}
        message={_message}
        messageValues={_messageValues}
        icon={icon}
        backgroundAnimation={backgroundAnimation}
        {...otherProps}
    />;
}
