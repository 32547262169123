import { forEach, isEmpty, last } from 'lodash';
import { ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
    ArgNodeKey,
    ArgNodePath,
    AsyncChildrenProvider,
    AsyncChildState,
    GetNodeChildren, GetNodeClassName,
    GetNodeKey,
    GetNodeResult,
} from './types';
import { highlightSplit } from '../utils';
import { ProgressMonitor, SubProgressMonitor } from '../progress-monitors/progress-monitor';
import { ClassValue } from '../arg-hooks/use-classNames';

export function computeNodeChildren<T>(nodePath: ArgNodePath<T>, getNodeChildren: GetNodeChildren<T>): (GetNodeResult<T> | AsyncChildrenProvider<T>) {
    if (typeof (getNodeChildren) === 'string') {
        const v = (last(nodePath) as any)[getNodeChildren];
        if (!v) {
            return null;
        }
        if (Array.isArray(v)) {
            return v as T[];
        }
        throw new Error(`Invalid property ${getNodeChildren}`);
    }

    const children = getNodeChildren(nodePath);

    return children;
}

export function computeNodeLabel<T>(nodePath: ArgNodePath<T>, getNodeLabel: string | ((nodePath: ArgNodePath<T>, searchedToken?: string) => (ReactNode | MessageDescriptor)), searchedToken?: string): (ReactNode | MessageDescriptor) {
    if (typeof (getNodeLabel) === 'string') {
        let v = (last(nodePath) as any)[getNodeLabel];
        if (!v) {
            return null;
        }

        if (searchedToken && typeof (v) === 'string') {
            v = highlightSplit(v, searchedToken);
        }

        return v;
    }

    const label = getNodeLabel(nodePath, searchedToken);

    return label;
}


export function computeNodeKey<T>(nodePath: ArgNodePath<T>, getNodeKey: GetNodeKey<T>): ArgNodeKey {
    if (typeof (getNodeKey) === 'string') {
        const v = (last(nodePath) as any)[getNodeKey];
        if (!v) {
            throw new Error('Key can not be null or empty');
        }

        return v;
    }

    const key = getNodeKey(nodePath);

    return key;
}

export function computeNodeClassName<T>(nodePath: ArgNodePath<T>, getNodeClassName: GetNodeClassName<T>, searchedToken?: string): ClassValue {
    if (typeof (getNodeClassName) === 'string') {
        const v = (last(nodePath) as any)[getNodeClassName];

        return v;
    }

    const key = getNodeClassName(nodePath, searchedToken);

    return key;
}

function walkChildren<T, U = void>(nodePath: ArgNodePath<T>, getNodeChildren: GetNodeChildren<T>, getNodeKey: GetNodeKey<T>, asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined, fct: (nodePath: ArgNodePath<T>) => (U | undefined)): (U | undefined) {
    const stack: ArgNodePath<T>[] = [nodePath];

    const hasAsyncChildren = !isEmpty(asyncChildStates);

    for (; stack.length;) {
        const nodePath = stack.shift()!;

        const ret = fct(nodePath);
        if (ret !== undefined) {
            return ret;
        }

        if (hasAsyncChildren) {
            const key = computeNodeKey(nodePath, getNodeKey);

            const asyncChildState = asyncChildStates![key];
            if (asyncChildState) {
                const knownChildren = asyncChildState.knownChildren;

                forEach(knownChildren, (child) => stack.push([...nodePath, child]));
                continue;
            }
        }

        const children = computeNodeChildren(nodePath, getNodeChildren);
        if (children instanceof AsyncChildrenProvider) {
            // Ignore unknown async nodes
            continue;
        }

        if (Array.isArray(children) && children.length) {
            children.forEach((child) => {
                stack.push([...nodePath, child]);
            });
        }
    }

    return undefined;
}

async function asyncWalkChildren<T, U = void>(nodePath: ArgNodePath<T>,
    getNodeChildren: GetNodeChildren<T>,
    getNodeKey: GetNodeKey<T>,
    asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined,
    fct: (nodePath: ArgNodePath<T>) => (U | undefined),
    getAsyncChildren: (asyncProvider: AsyncChildrenProvider<T>, nodeKey: ArgNodeKey, nodePath: ArgNodePath<T>) => Promise<AsyncChildState<T>>,
    progressMonitor: ProgressMonitor): Promise<U | undefined> {
    const stack: ArgNodePath<T>[] = [nodePath];

    const hasAsyncChildren = !isEmpty(asyncChildStates);

    for (; stack.length;) {
        progressMonitor.verifyCancelled();

        const nodePath = stack.shift()!;

        const ret = fct(nodePath);
        if (ret !== undefined) {
            return ret;
        }

        const nodeKey = computeNodeKey(nodePath, getNodeKey);

        if (hasAsyncChildren) {
            const asyncChildState = asyncChildStates![nodeKey];
            if (asyncChildState) {
                const knownChildren = asyncChildState.knownChildren;

                forEach(knownChildren, (child) => stack.push([...nodePath, child]));
                continue;
            }
        }

        const children = computeNodeChildren(nodePath, getNodeChildren);
        if (children instanceof AsyncChildrenProvider) {
            const asyncProvider = children as AsyncChildrenProvider<T>;

            const sub = new SubProgressMonitor(progressMonitor, 1);
            try {
                const asyncChildState = await getAsyncChildren(asyncProvider, nodeKey, nodePath);

                const knownChildren = asyncChildState.knownChildren;

                forEach(knownChildren, (child) => stack.push([...nodePath, child]));
                continue;
            } finally {
                sub.done();
            }
        }

        if (Array.isArray(children) && children.length) {
            children.forEach((child) => {
                stack.push([...nodePath, child]);
            });
        }
    }

    return undefined;
}

export interface CheckedChildrenResult<T> {

    checkedCount: number;
    checkedPaths: ArgNodePath<T>[];
    checkedKeys: ArgNodeKey[];

    childrenCount: number;
    childrenPaths: ArgNodePath<T>[];
    childrenKeys: ArgNodeKey[];
}

function computeCheckedChildren<T>(rootPath: ArgNodePath<T>, getNodeChildren: GetNodeChildren<T> | undefined, getNodeKey: GetNodeKey<T>, asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined, checkedNodes?: ArgNodeKey[]): CheckedChildrenResult<T> {
    let checkedCount = 0;
    const checkedPaths: ArgNodePath<T>[] = [];
    const checkedKeys: ArgNodeKey[] = [];

    let childrenCount = 0;
    const childrenPaths: ArgNodePath<T>[] = [];
    const childrenKeys: ArgNodeKey[] = [];

    if (getNodeChildren) {
        walkChildren<T>(rootPath, getNodeChildren, getNodeKey, asyncChildStates, (nodePath) => {
            const key = computeNodeKey(nodePath, getNodeKey);

            childrenCount++;
            childrenPaths.push(nodePath);
            childrenKeys.push(key);

            if (checkedNodes && checkedNodes.indexOf(key) >= 0) {
                checkedCount++;
                checkedPaths.push(nodePath);
                checkedKeys.push(key);
            }
        });
    }

    return { childrenCount, checkedCount, checkedPaths, checkedKeys, childrenPaths, childrenKeys };
}

function findNodePathByKey<T>(root: T | T[], nodeKey: ArgNodeKey, getNodeChildren: GetNodeChildren<T> | undefined, getNodeKey: GetNodeKey<T>, asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined): ArgNodePath<T> | undefined {
    if (Array.isArray(root)) {
        for (let i = 0; i < root.length; i++) {
            const nodePath = findNodePathByKey(root[i], nodeKey, getNodeChildren, getNodeKey, asyncChildStates);
            if (nodePath) {
                return nodePath;
            }
        }

        return undefined;
    }

    if (!getNodeChildren) {
        return undefined;
    }

    const nodePath = walkChildren<T, ArgNodePath<T>>([root], getNodeChildren, getNodeKey, asyncChildStates, (nodePath: ArgNodePath<T>): ArgNodePath<T> | undefined => {
        const key = computeNodeKey(nodePath, getNodeKey);
        if (key === nodeKey) {
            return nodePath;
        }
    });

    return nodePath;
}

export type SearchedTokenResponse = 'found' | 'notfound' | 'inherit';

function hasSearchedToken<T>(rootPath: ArgNodePath<T>, getNodeChildren: GetNodeChildren<T>, getNodeKey: GetNodeKey<T>, asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined, searchFct: (node: ArgNodePath<T>, token: string) => boolean, searchedToken: string): SearchedTokenResponse {
    const found = walkChildren<T, SearchedTokenResponse>(rootPath, getNodeChildren, getNodeKey, asyncChildStates, (nodePath) => {
        if (searchFct(nodePath, searchedToken)) {
            if (rootPath === nodePath) {
                return 'found';
            }

            return 'inherit';
        }
    });

    return found || 'notfound';
}


export interface ArgTreeUtilities<T> {
    computeCheckedChildren(rootPath: ArgNodePath<T>, checkedNodes?: ArgNodeKey[]): CheckedChildrenResult<T>;

    findNodePathByKey(root: T | T[], nodeKey: ArgNodeKey): ArgNodePath<T> | undefined;

    walkChildren<U = void>(nodePath: ArgNodePath<T>, fct: (nodePath: ArgNodePath<T>) => (U | undefined)): (U | undefined);
}

export class ArgTreeUtilitiesImpl<T> implements ArgTreeUtilities<T> {
    readonly #getNodeChildren: GetNodeChildren<T> | undefined;
    readonly #getNodeKey: GetNodeKey<T>;
    readonly #asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined;

    constructor(getNodeChildren: GetNodeChildren<T> | undefined, getNodeKey: GetNodeKey<T>, asyncChildStates?: Record<ArgNodeKey, AsyncChildState<T>>) {
        this.#getNodeChildren = getNodeChildren;
        this.#getNodeKey = getNodeKey;
        this.#asyncChildStates = asyncChildStates;
    }

    computeCheckedChildren(rootPath: ArgNodePath<T>, checkedNodes?: ArgNodeKey[]): CheckedChildrenResult<T> {
        const ret = computeCheckedChildren<T>(rootPath, this.#getNodeChildren, this.#getNodeKey, this.#asyncChildStates, checkedNodes);

        return ret;
    }

    findNodePathByKey(node: T | T[], nodeKey: ArgNodeKey): ArgNodePath<T> | undefined {
        // (root: T | T[], nodeKey: ArgNodeKey, getNodeChildren: GetNodeChildren<T>, getNodeKey: GetNodeKey<T>, asyncChildStates: Record<ArgNodeKey, AsyncChildState<T>> | undefined)
        const ret = findNodePathByKey<T>(node, nodeKey, this.#getNodeChildren, this.#getNodeKey, this.#asyncChildStates);

        return ret;
    }

    walkChildren<U = void>(nodePath: ArgNodePath<T>, fct: (nodePath: ArgNodePath<T>) => (U | undefined)): (U | undefined) {
        if (!this.#getNodeChildren) {
            return undefined;
        }

        const ret = walkChildren<T, U>(nodePath, this.#getNodeChildren, this.#getNodeKey, this.#asyncChildStates, fct);

        return ret;
    }

    hasSearchedToken(nodePath: ArgNodePath<T>, searchFct: (node: ArgNodePath<T>, token: string) => boolean, searchedToken: string): SearchedTokenResponse {
        if (!this.#getNodeChildren) {
            return 'notfound';
        }

        const ret = hasSearchedToken<T>(nodePath, this.#getNodeChildren, this.#getNodeKey, this.#asyncChildStates, searchFct, searchedToken);

        return ret;
    }

    async asyncWalkChildren<U = void>(nodePath: ArgNodePath<T>,
        fct: (nodePath: ArgNodePath<T>) => (U | undefined),
        getAsyncChildren: (asyncProvider: AsyncChildrenProvider<T>, nodeKey: ArgNodeKey, nodePath: ArgNodePath<T>) => Promise<AsyncChildState<T>>,
        progressMonitor: ProgressMonitor): Promise<U | undefined> {
        if (!this.#getNodeChildren) {
            return undefined;
        }

        const ret = asyncWalkChildren<T, U>(nodePath, this.#getNodeChildren, this.#getNodeKey, this.#asyncChildStates, fct, getAsyncChildren, progressMonitor);

        return ret;
    }
}

