import { isNil } from 'lodash';

import { DEFAULT_RADIUS_UNIT, RadiusUnit } from '../model/geolocation-value';

// Format radius value to the current unit
// n.b : value is meant to be meters initially
export const formatRadius = (radius?: number | null, radiusUnit?: RadiusUnit) => {
    const unit: RadiusUnit = radiusUnit || DEFAULT_RADIUS_UNIT;

    return !isNil(radius) ?
        unit === RadiusUnit.Kilometer
            ? radius / 1000
            : radius
        : undefined;
};
