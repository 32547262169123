/**
 *
 * A component to show a popover next to a trigger element.
 *
 * Contrary to tooltip popover support wider variety of trigger event
 * and support display interactive content.
 *
 * Usage:
 * const trigger = (
 *   <ArgPopover content='Popover' trigger='click'>
 *     <div>The Trigger</div>
 *   </ArgPopover>
 * )
 *
 * MISC:
 * - the trigger will receive a 'arg-popover-open' class when popover is
 *     opened, usefull if the trigger needs to the customized
 *
 * CAVEATS:
 *   - If trigger is not a react element, the component will add a wrapper.
 *
 **/

import {
    cloneElement,
    CSSProperties,
    forwardRef,
    isValidElement,
    ReactElement,
    ReactNode,
    Ref,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    arrow,
    autoUpdate,
    ElementProps,
    flip,
    FloatingArrow,
    FloatingPortal,
    offset,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useMergeRefs,
} from '@floating-ui/react';
import { isFunction, isString, isUndefined } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { TooltipPlacement } from '../arg-tooltip/arg-tooltip2';
import { DEFAULT_POPOVER_DELAY, DEFAULT_TOOLTIP_PLACEMENT } from '../defaults';
import { useLongClick } from './use-long-click';
import { useRightClick } from './use-right-click';
import { toFloatingUIPlacement } from '../arg-tooltip/utils';
import { ArgPopoverController, TriggerType } from './types';

import './arg-popover.less';

const DEFAULT_POPOVER_OFFSET = { mainAxis: 7, alignmentAxis: -8 };

/**
 *  OffsetValue is an interface from @floating-ui, copied here for convenience.
 *  @link: https://github.com/floating-ui/floating-ui/blob/master/packages/core/src/middleware/offset.ts#L5
 */
export type OffsetValue = number | Partial<{
    /**
     * The axis that runs along the side of the floating element. Represents
     * the distance (gutter or margin) between the reference and floating
     * element.
     * @default 0
     */
    mainAxis: number;
    /**
     * The axis that runs along the alignment of the floating element.
     * Represents the skidding between the reference and floating element.
     * @default 0
     */
    crossAxis: number;
    /**
     * The same axis as `crossAxis` but applies only to aligned placements
     * and inverts the `end` alignment. When set to a number, it overrides the
     * `crossAxis` value.
     *
     * A positive number will move the floating element in the direction of
     * the opposite edge to the one that is aligned, while a negative number
     * the reverse.
     * @default null
     */
    alignmentAxis: number | null;
}>;

export interface ArgPopoverProps {
    open?: boolean;
    onOpenChange?: (visible: boolean) => void;
    trigger?: TriggerType | TriggerType[] | false;
    content?: ReactNode | ((ctl: ArgPopoverController) => ReactNode);

    // the popover className (note: use triggerClassName to add className to the trigger element)
    className?: ClassValue;

    triggerClassName?: ClassValue;
    placement?: TooltipPlacement;
    popoverRef?: Ref<HTMLElement>;
    popoverStyle?: CSSProperties;
    offset?: OffsetValue;

    // When true, expand popover to match width with trigger. Children must forward ref for this to work.
    fitWidth?: boolean;

    /**
     * wait before the specified time to open the tooltip (time in seconds)
     */
    mouseEnterDelay?: number;
}

type ArgPopoverPropsWithChildren = ArgPopoverProps & {
    children: ReactNode,
};

export const ArgPopover = forwardRef(function ArgPopover(props: ArgPopoverPropsWithChildren, ref) {
    const {
        content,
        children,
        open: externalOpen,
        onOpenChange: setExternalOpen,
        placement = DEFAULT_TOOLTIP_PLACEMENT,
        mouseEnterDelay = DEFAULT_POPOVER_DELAY,
        popoverRef,
        className,
        offset: offsetValue = DEFAULT_POPOVER_OFFSET,
        popoverStyle,
        triggerClassName,
        fitWidth,
    } = props;

    const triggerMode = isString(props.trigger) ? [props.trigger] : (isUndefined(props.trigger) ? ['click'] : (props.trigger || []));

    const childReactElement: ReactElement | undefined = useMemo(() => (
        isValidElement(children) ? children : undefined
    ), [children]);

    const classNames = useClassNames('arg-popover');

    const arrowRef = useRef<SVGSVGElement>(null);

    const [internalOpen, setInternalOpen] = useState(false);

    const open = externalOpen ?? internalOpen;

    const handleOpenChange = useCallback((value: boolean) => {
        setInternalOpen(value);
        setExternalOpen?.(value);
    }, [setExternalOpen]);

    const data = useFloating({
        placement: toFloatingUIPlacement(placement),
        open,
        onOpenChange: handleOpenChange,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(offsetValue),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: 5,
            }),
            shift({ padding: 5 }),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const hover = useHover(data.context, {
        enabled: triggerMode.includes('hover'),
        handleClose: safePolygon(),
        delay: {
            open: mouseEnterDelay * 1000,
        },
    });

    const click = useClick(data.context, {
        enabled: triggerMode.includes('click'),
        keyboardHandlers: false,
    });

    const focus = useFocus(data.context, {
        enabled: triggerMode.includes('focus'),
    });

    const longClick = useLongClick(data.context, {
        enabled: triggerMode.includes('longClick'),
    });

    const dismiss = useDismiss(data.context, {
        outsidePress: true,
    });

    const rightClick = useRightClick(data.context, {
        enabled: triggerMode.includes('contextMenu'),
    });

    const preventDefault = useMemo<ElementProps>(() => ({
        reference: {
            onClick(event) {
                if (triggerMode.includes('click')) {
                    event.preventDefault();
                }
            },
        },
    }), []);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        // Position matters: preventDefault should be 1st!
        preventDefault,
        hover,
        click,
        focus,
        longClick,
        dismiss,
        rightClick,
    ]);

    const triggerRef = useRef<HTMLElement>(null);
    // Whouch, completely undocumented but seems like you can use children.ref to still ref from children. Seen it used in floating-ui's official examples (and I want to trust them as serious reacts
    // folks)
    const childrenRef = (children as any)?.ref;

    const popoverTriggerRef = useMergeRefs([
        data.refs.setReference,
        triggerRef,
        childrenRef,
        ref,
    ]);

    const popoverContentRef = useMergeRefs([
        data.refs.setFloating,
        popoverRef,
    ]);

    let trigger: ReactNode;
    const triggerCls = { 'arg-popover-open': open };
    if (childReactElement) {
        trigger = cloneElement(
            childReactElement,
            {
                ...getReferenceProps({
                    ...childReactElement.props,
                }),
                ref: popoverTriggerRef,
                className: classNames(
                    childReactElement.props.className,
                    triggerCls,
                    triggerClassName
                ),
            }
        );
    } else {
        trigger = (
            <div
                className={classNames('&-wrapper', triggerCls, triggerClassName)}
                ref={popoverTriggerRef}
                {...getReferenceProps()}
            >
                {children}
            </div>
        );
    }

    const ctl = useMemo(() => ({
        close: () => handleOpenChange(false),
    }), [handleOpenChange]);


    const body = useMemo(() => (
        open && (isFunction(content) ? content(ctl) : content)
    ), [content, open, ctl]);

    const getPopoverStyle = useCallback(() => {
        if (!fitWidth) {
            return undefined;
        }
        const width = `${triggerRef.current?.getBoundingClientRect().width}px`;

        return { minWidth: width, maxWidth: width, width };
    }, [fitWidth]);

    return (
        <>
            {/* popover */}
            {open && content && (
                <FloatingPortal>
                    <div
                        ref={popoverContentRef}
                        className={classNames('&-content', className)}
                        role='popover'
                        style={{ ...popoverStyle, ...getPopoverStyle(), ...data.floatingStyles }}
                        data-testid='popover'
                        {...getFloatingProps()}>
                        <FloatingArrow
                            ref={arrowRef}
                            context={data.context}
                            className={classNames('&-arrow')} />
                        <div className={classNames('&-inner')}>
                            <div className={classNames('&-inner-content')}>
                                {body}
                            </div>
                        </div>
                    </div>
                </FloatingPortal>
            )}

            {/* trigger */}
            {trigger}
        </>
    );
});
