import { ReactNode } from 'react';

interface BuildVersionWrapperProps {
    builVersionClassName?: string;
    children: ReactNode;
}

export const BuildVersionWrapper = (props: BuildVersionWrapperProps) => {
    const { children, builVersionClassName } = props;

    return (
        <>
            {children}
            {localStorage?.SHOW_VERSION && window.argonodeConfig?.version?.build &&
                <div className={builVersionClassName}>
                    {window.argonodeConfig.version.build}
                </div>
            }
        </>
    );
};
