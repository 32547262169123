import React, { ReactNode, useContext } from 'react';
import { isEmpty } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { KeyBindingDescriptor, KeyBindingScopeDescriptor } from './keybinding';
import { mapSpecialCommand, parseKeyCommand } from './utils';
import { KeyBindingsContext } from './keybindings-context';

import './keybinding-keys.less';

interface KeyBindingKeysProps {
    className?: ClassValue;
    keyBindingDescriptor: KeyBindingDescriptor;
    noKeysRender?: ReactNode | (() => ReactNode);
    scope?: KeyBindingScopeDescriptor;
}

export function KeyBindingKeys(props: KeyBindingKeysProps) {
    const {
        className,
        keyBindingDescriptor,
        noKeysRender,
        scope,
    } = props;

    const classNames = useClassNames('keybindings-keys');

    const context = useContext(KeyBindingsContext);

    const keyCommand = context?.computeKeyCommand(keyBindingDescriptor, scope);

    const keys = parseKeyCommand(keyCommand);
    let _noKeysRender: ReactNode = null;
    if (isEmpty(keys) && noKeysRender) {
        if (typeof (noKeysRender) === 'function') {
            _noKeysRender = noKeysRender();
        } else {
            _noKeysRender = noKeysRender;
        }
    }

    return (
        <div className={classNames('&', className)}>
            {keys.map((key, index) => {
                const _key = mapSpecialCommand(key);

                return <span key={index} className={classNames('&-key')}>{_key}</span>;
            })}
            {_noKeysRender}
        </div>
    );
}
