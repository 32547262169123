
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { ArgButton } from '../../../arg-button/arg-button';
import { ArgMapToolbar } from '../../common/map/toolbar/arg-map-toolbar';


interface ArgGeolocationToolbarProps {
    className?: ClassValue;
}

export function ArgGeolocationToolbar(props: ArgGeolocationToolbarProps) {
    const {
        className,
    } = props;

    const classNames = useClassNames('arg-map-toolbar');

    return (
        <ArgMapToolbar className={className}>
            <ArgButton
                type='ghost'
                icon='icon-cursor'
                className={classNames('&-item', '&-item-active')}
                data-testid='hand-mode'
            />
        </ArgMapToolbar>
    );
}
