import { CSSProperties, MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { filter, isEmpty, isString } from 'lodash';
import useResizeObserver from '@react-hook/resize-observer';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgTab, ArgTabAction, ArgTabKey } from './arg-tabs-types';
import { ArgTabInfos } from './arg-tabs';
import { ArgInputSearch } from '../arg-input/arg-input-search';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { highlightSplit, normalizeText } from '../utils';
import { computeRelativeTime } from '../../../utils/compute-relative-time';
import { ArgIcon } from '../arg-icon/arg-icon';
import { renderText } from '../utils/message-descriptor-formatters';

import './arg-tabs-list.less';

const messages = defineMessages({
    openedTabs: {
        id: 'basic.arg-tabs.OpenedTabs',
        defaultMessage: 'Opened tabs',
    },
    closedTabs: {
        id: 'basic.arg-tabs.RecentlyClosedTabs',
        defaultMessage: 'Recently closed tabs',
    },
    noResults: {
        id: 'basic.arg-tabs.NoResults',
        defaultMessage: 'No results',
    },
});

export interface ArgTabsListProps {
    className?: ClassValue;

    tabs: ArgTab[];

    selectedTabKey?: ArgTabKey;

    tabInfos: Record<ArgTabKey, ArgTabInfos>;

    onChange: (tabKey: ArgTabKey | undefined, action: ArgTabAction) => void;
}

export function ArgTabsList(props: ArgTabsListProps) {
    const {
        className,
        tabs,
        selectedTabKey,
        tabInfos,
        onChange,
    } = props;

    const classNames = useClassNames('arg-tabs-list');

    const listContainerRef = useRef<HTMLDivElement>(null);

    const handleOpenTab = useCallback((event: MouseEvent, tabKey: ArgTabKey, tabInfo: ArgTabInfos) => {
        if (event.defaultPrevented) {
            return;
        }

        if (tabInfo?.closeDate) {
            tabInfo?.respawn?.();

            onChange(tabInfo.key, ArgTabAction.Destroy);

            return;
        }

        onChange(tabKey, ArgTabAction.Show);
    }, [onChange]);

    const handleCloseTab = useCallback((event: ButtonClickEvent, tab: ArgTab) => {
        event.preventDefault();
        onChange(tab.key, ArgTabAction.Close);
    }, [onChange]);

    const [search, setSearch] = useState<string>();

    const handleSearchChange = useCallback((value: string | null) => {
        setSearch(value || undefined);
    }, []);

    const [openedTabs, closedTabs] = useMemo(() => {
        const closedTabs = filter(tabInfos, (tabInfo) => {
            if (!tabInfo.closeDate || !tabInfo.respawn) {
                return false;
            }

            return true;
        });
        closedTabs.sort((t1, t2) => {
            return t2.closeDate!.getTime() - t1.closeDate!.getTime();
        });

        if (!search) {
            return [tabs, closedTabs];
        }

        const token = normalizeText(search);

        const openedTabs = tabs.filter((tab) => {
            const info = tabInfos[tab.key];
            const title = tab.title || info.title;
            if (!isString(title)) {
                return false;
            }

            if (normalizeText(title).indexOf(token) < 0) {
                return false;
            }

            return true;
        });

        const filteredClosedTabs = closedTabs.filter((tabInfos) => {
            const title = tabInfos.title;
            if (!isString(title)) {
                return false;
            }

            if (normalizeText(title).indexOf(token) < 0) {
                return false;
            }

            return true;
        });

        return [openedTabs, filteredClosedTabs];
    }, [tabs, search]);

    const [listStyle, setListStyle] = useState<CSSProperties>();

    useResizeObserver(listContainerRef, (entry) => {
        const newListContainerRect = entry.target.getBoundingClientRect();

        setListStyle({ maxHeight: `calc(100vh - ${newListContainerRect.top}px` });
    });

    function renderTab(tabKey: ArgTabKey, tab?: ArgTab) {
        const selected = tabKey === selectedTabKey;

        const cls = {
            selected,
            'can-be-closed': !!tab,
        };

        const tabInfo: ArgTabInfos | undefined = tabInfos[tabKey];
        let icon = tab?.icon;
        let title = tab?.title;

        if (!icon) {
            icon = tabInfo?.icon;
        }
        if (!title) {
            title = tabInfo?.title;
        }

        if (search && isString(title)) {
            title = highlightSplit(title, search);
        }

        let relativeDate: ReactNode = null;
        if (tabInfo) {
            const relative = computeRelativeTime(tabInfo.closeDate || tabInfo.renderDate);
            if (relative) {
                const update = (relative[1] === 'second' || relative[1] === 'minute' || relative[1] === 'hour');

                relativeDate = <FormattedRelativeTime
                    value={relative[0]}
                    unit={relative[1]}
                    updateIntervalInSeconds={(update) ? 5 : undefined}
                />;
            }
        }

        let _icon: ReactNode = icon;
        if (isString(icon)) {
            _icon = <ArgIcon
                className={classNames('&-tab-icon')}
                name={icon}
            />;
        }

        return <div
            key={tabKey}
            className={classNames('&-tab', cls)}
            data-testid='tab'
            onClick={!selected ? ((event) => handleOpenTab(event, tabKey, tabInfo)) : undefined}
        >
            <div className={classNames('&-tab-iconContainer')}>
                {_icon}
            </div>
            <div className={classNames('&-tab-title')}>
                <span className={classNames('&-tab-title-label')}>
                    {renderText(title)}
                </span>
                <span className={classNames('&-tab-title-relative')}>
                    {relativeDate}
                </span>
            </div>
            {tab && <div className={classNames('&-tab-close')}>
                <ArgButton
                    className={classNames('&-tab-close-button')}
                    data-testid='tab-close-button'
                    type='ghost'
                    size='medium'
                    onClick={(event) => handleCloseTab(event, tab)}
                    icon='icon-cross'
                />
            </div>}
        </div>;
    }

    return (
        <div className={classNames('&', className)} ref={listContainerRef} style={listStyle}>
            <div className={classNames('&-search')}>
                <ArgInputSearch
                    className={classNames('&-search-input')}
                    data-testid='search-input'
                    autoFocus={true}
                    onChange={handleSearchChange}
                    debounce={10}
                />
            </div>
            <div className={classNames('&-scroll')}>
                <div className={classNames('&-body')}>
                    {!isEmpty(openedTabs) && <>
                        <div className={classNames('&-opened-title')} data-testid='opened-tabs-title'>
                            <FormattedMessage {...messages.openedTabs} />
                        </div>
                        {
                            openedTabs.map((tab: ArgTab) => {
                                return renderTab(tab.key, tab);
                            })
                        }
                    </>}
                    {!isEmpty(closedTabs) && <>
                        <div className={classNames('&-closed-title')} data-testid='closed-tabs-title'>
                            <FormattedMessage {...messages.closedTabs} />
                        </div>
                        {
                            closedTabs.map((tab: ArgTabInfos) => {
                                return renderTab(tab.key);
                            })
                        }
                    </>}
                    {isEmpty(openedTabs) && isEmpty(closedTabs) && <div className={classNames('&-no-results')} data-testid='no-results'>
                        <FormattedMessage {...messages.noResults} />
                    </div>}
                </div>
            </div>
        </div>
    );
}
