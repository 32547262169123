//
export class StringUtils {
    static upperStart(word: string): string {
        const trimmedWord = word.trim();
        if (trimmedWord.length === 0) {
            return word;
        }

        return trimmedWord[0].toUpperCase() + trimmedWord.substring(1);
    }

    static upperStartOfSentenceWords(sentence: string): string {
        const trimmedSentence = sentence.trim();

        return trimmedSentence
            .split(' ')
            .map(word => StringUtils.upperStart(word))
            .join(' ');
    }

    static escapeRegExp(str: string): string {
        return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static replaceAll(str: string, find: string, replace: string): string {
        return str.replace(new RegExp(StringUtils.escapeRegExp(find), 'g'), replace);
    }
}
