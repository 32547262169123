import React, { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { User } from '../../../model/user';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgObjectTooltip, ArgObjectTooltipProperty } from '../arg-tooltip/arg-object-tooltip';
import { ArgUserInfo } from './arg-user-id';

//import './arg-user-tooltip.less';

const messages = defineMessages({
    firstName: {
        id: 'basic.arg-user-tooltip.FirstName',
        defaultMessage: 'First name',
    },
    lastName: {
        id: 'basic.arg-user-tooltip.LastName',
        defaultMessage: 'Last name',
    },
    userName: {
        id: 'basic.arg-user-tooltip.Email',
        defaultMessage: 'Email',
    },
});

interface ArgUserTooltipProps {
    className?: ClassValue;

    user: ArgUserInfo;
}

export function ArgUserTooltip(props: ArgUserTooltipProps) {
    const { className, user } = props;

    const classNames = useClassNames('arg-user-tooltip');

    const properties = useMemo<ArgObjectTooltipProperty[]>(() => {
        let fields = [
            {
                name: messages.userName,
                value: (user as User).fullName || (user as User).userName || user.displayName,
            },
            {
                name: messages.firstName,
                value: (user as User).firstName,
            },
            {
                name: messages.lastName,
                value: (user as User).lastName,
            },
        ];

        fields = fields.filter((f) => f.value);

        return fields;
    }, [user]);

    return <ArgObjectTooltip
        className={classNames('&', className)}
        title={user.displayName}
        properties={properties}
        hideEmptyValues={true}
    />;
}
