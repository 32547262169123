import React, { MouseEventHandler, ReactNode, useMemo, useRef } from 'react';
import { MessageDescriptor } from 'react-intl';
import { RenderFunction } from 'antd/lib/_util/getRenderPropValue';
import { escapeRegExp, filter, forEach, includes, isString, omit } from 'lodash';
import classNames from 'classnames';

import { getDataTestIdFromProps } from '../utils';
import { getExtension } from '../../../utils/file';
import stringToColor from '../../../utils/color-utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { TopBarProgress } from '../arg-loading/top-bar-progress';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgButton } from '../arg-button/arg-button';
import { ArgMessageValues } from '../types';
import { getFileIcon } from './arg-file-icons';
import { ArgTooltip2, TooltipPlacement } from '../arg-tooltip/arg-tooltip2';

import './arg-file-icon.less';

// List of possible colors
const ICON_FILE_COLORS = [
    // Fallback
    'fallback',

    // Specific
    'pdf', 'doc', 'slides', 'calc',

    // Generic colors
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
];

const DEFAULT_SHOW_LABEL = true;

let GLOBAL_COLORS: Record<string | number, string> | undefined = undefined;

export type ArgFileIconState = 'done' | 'cancelled' | 'failed' | 'uploading' | 'waiting' | undefined;

export interface ArgFileIconProps {
    htmlTitle?: string;
    fileName: string;
    contentType?: string;
    showLabel?: boolean;
    className?: ClassValue;
    showExtension?: boolean;
    state?: ArgFileIconState;
    onIconClick?: () => void;
    progressMonitor?: ProgressMonitor;
    onClick?: MouseEventHandler<HTMLElement>;

    tooltip?: boolean | ReactNode | MessageDescriptor | RenderFunction;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    messageValues?: ArgMessageValues;
}

export function ArgFileIcon(props: ArgFileIconProps) {
    const {
        state,
        onClick,
        fileName,
        htmlTitle,
        className,
        onIconClick,
        showExtension,
        progressMonitor,
        showLabel = DEFAULT_SHOW_LABEL,
        tooltip,
        tooltipClassName,
        tooltipPlacement,
        messageValues,
        contentType,
    } = props;

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-file-icon');

    // Parse the file extension
    const ext = getExtension(fileName);

    // Parse the file name (remove the extension from the whole filename)
    const name = !showExtension ? fileName.replace(new RegExp(`\\.${escapeRegExp(ext)}$`), '') : fileName;

    const isFailed = useMemo(() => state === 'failed', [state]);
    const isLoading = useMemo(() => includes(['waiting'], state) || progressMonitor?.isRunning, [progressMonitor, state]);

    const containerRef = useRef<HTMLDivElement>(null);

    const cls = {
        'is-uploading': state === 'uploading',
    };

    // Compute colors object
    const colors = useMemo<Record<string | number, string>>(() => {
        if (GLOBAL_COLORS) {
            return GLOBAL_COLORS;
        }

        GLOBAL_COLORS = computeColors();

        return GLOBAL_COLORS;
    }, [classNames]);

    let icon: ReactNode = null;

    if (!isLoading) {
        if (contentType) {
            const iconName = getFileIcon(contentType);
            if (iconName) {
                icon = <span className={classNames('&-extension')} title={ext}>
                    <ArgIcon
                        name={iconName}
                        color={colors.fallback}
                        className={classNames('&-extension-icon', cls)}
                    />
                </span>;
            }
        }

        if (!icon) {
            let color = 'error';

            if (!isFailed) {
                // Transform the string to a color in the range
                color = (
                    !ext ? colors.fallback // Fallback when no extension is specified
                        : ext === 'pdf' ? colors.pdf // Manage PDF to be red
                            : ext.match(/^doc.*/) || includes(['odt', 'txt'], ext) ? colors.doc // Manage doc to be blue
                                : ext.match(/^xls.*/) || includes(['ods'], ext) ? colors.calc // Manage calc to be green
                                    : ext.match(/^ppt.*/) || includes(['odp'], ext) ? colors.slides // Manage slides to be orange
                                        : stringToColor(ext, omit(colors, filter(ICON_FILE_COLORS, isString))) // Else match a color from the extension string
                );
            }

            icon = (
                <span className={classNames('&-extension')} title={ext}>
                    <span className={classNames('&-extension-value')}>
                        {ext.substring(0, 3)}
                    </span>

                    <ArgIcon
                        name={isFailed ? 'icon-invalid' : 'icon-blank-file'}
                        color={color}
                        className={classNames('&-extension-icon', cls)}
                    />
                </span>
            );
        }
    } else {
        icon = <ThreeDotsLoading />;
    }

    let component = (
        <div
            onClick={onClick}
            title={htmlTitle}
            className={classNames('&', className)}
            data-testid={dataTestId}
            ref={containerRef}
        >
            {icon}

            {showLabel && (
                <div className={classNames('&-file')}>
                    <div className={classNames('&-file-name')}>
                        <label className={classNames('&-file-name-label', cls)}>
                            {name}
                        </label>
                        {state === 'uploading' && (
                            <ArgButton
                                size='small'
                                type='ghost'
                                icon='icon-cross'
                                onClick={onIconClick}
                            />
                        )}
                    </div>
                    {state === 'uploading' && (
                        <TopBarProgress className={classNames('&-file-progress')} visible={true} />
                    )}
                </div>
            )}
        </div>
    );

    if (tooltip) {
        component =
            <ArgTooltip2
                key='tooltip'
                className={classNames('&-tooltip', tooltipClassName)}
                title={tooltip}
                messageValues={messageValues}
                data-testid='tooltip'
                placement={tooltipPlacement}
            >
                {component}
            </ArgTooltip2>;
    }

    return component;
}

function computeColors() {
    function getColor(index: number | string): string | undefined {
        const colorSpan = document.createElement('span');

        colorSpan.className = classNames(`arg-file-icon-colors-item-${index}`, 'arg-file-icon-colors-item');

        document.body.appendChild(colorSpan);

        const color = window.getComputedStyle(colorSpan).getPropertyValue('color');

        colorSpan.remove();

        // If color is not set from css (transparent)
        if (color === 'rgba(0, 0, 0, 0)') {
            colorSpan.remove();

            return undefined;
        }

        return color;
    }

    const colors: Record<string | number, string> = {};

    // Limit of 50 is a security, we wont have more than 50 colors
    forEach(ICON_FILE_COLORS, (index) => {
        const color = getColor(index);

        if (!color) {
            return;
        }

        colors[index] = color;
    });

    return colors;
}
