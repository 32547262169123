import React, { useContext } from 'react';

import { UserPermissions } from '../model/user';

export interface UseGetMePermissionsReturnType {
    permissions: UserPermissions
}

export const UserPermissionsContext = React.createContext<UseGetMePermissionsReturnType>({ permissions: {} });

export function useGetMyPermissions(): UseGetMePermissionsReturnType {
    const ret = useContext(UserPermissionsContext)!;

    return ret;
}
