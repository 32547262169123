import { useState } from 'react';

let anonymousId = 0;

export const useId = (id?: string): string => {
    const [$id] = useState(() => {
        if (id) {
            return id;
        }
        id = `$$$DontUseThisAnonymousId$${anonymousId++}`;

        return id;
    });

    return $id;
};
