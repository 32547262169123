import { get, isEqual, isError, isFunction } from 'lodash';
import Debug from 'debug';
import Semaphore from 'semaphore-async-await';

import { isResponse404 } from './response-error';
import { EventEmitter } from './event-emitter';
import { ArgGlobalNotificationType } from '../arg-notifications/types';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { immutableEmptyObject, immutableSet, setImmutable } from './immutable-set';
import { reuseNodes } from '../../../preparation/utils/connectors/reuse-nodes';

const debug = Debug('common:contexts:Configuration');

export type Configuration = Record<string, any>;

export type ConfigurationPath = string;

export enum ConfigurationChangedReason {
    Set, Sync,
}

interface UpdateEvents {
    path: string;
    newValue: any;
}

interface AbstractConfigurationsProps {
    initialConfiguration?: Configuration;
    /**
     * If true, configuration will not be stored.
     * @default false
     */
    isReadonly?: boolean;
    onFetchError?: (error: Error) => void;
    onSaveError?: (error: Error) => void;
}

export interface AbstractConfigurationsEventTypes {
    Loaded: () => void;
    Changed: (id: string, value: any, reason: ConfigurationChangedReason) => void;
    ToBeStored: () => void;
}

export const EMPTY_CONFIGURATION: Configuration = immutableEmptyObject();

export abstract class AbstractConfigurations extends EventEmitter<AbstractConfigurationsEventTypes> {
    readonly #name: string;
    readonly #initialConfiguration: Configuration;
    readonly #isReadonly: boolean;
    readonly #onFetchError?: (error: Error) => void;
    readonly #onSaveError?: (error: Error) => void;
    readonly #semaphore: Semaphore;

    #configuration: Configuration;
    #storedConfiguration?: Configuration;
    #isLoaded: boolean;

    constructor(name: string, props?: AbstractConfigurationsProps) {
        super({ url: `configuration:${name}`, id: 0 });

        this.#name = name;
        this.#semaphore = new Semaphore(1);
        this.#initialConfiguration = props?.initialConfiguration || EMPTY_CONFIGURATION;
        this.#onFetchError = props?.onFetchError;
        this.#onSaveError = props?.onSaveError;
        this.#isReadonly = !!props?.isReadonly;
        this.#configuration = this.#initialConfiguration;
        this.#isLoaded = false;
    }

    get name(): string {
        return this.#name;
    }

    protected syncConfiguration(configuration: Configuration): UpdateEvents[] | undefined {
        if (isEqual(this.#configuration, configuration)) {
            if (debug.enabled) {
                debug(`syncConfiguration(${this.name})`, 'same');
            }

            return undefined;
        }

        const events: UpdateEvents[] = [];

        getDiffFromSource(events, this.#configuration, configuration, '');

        this.#configuration = configuration;
        this.#storedConfiguration = configuration;

        if (debug.enabled) {
            debug(`syncConfiguration(${this.name})`, 'update', 'events=', events);
        }

        return events;
    }

    async sync(notifications: ArgGlobalNotificationType, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        let events: UpdateEvents[] | undefined;
        await this.#semaphore.acquire();
        try {
            let configuration: Configuration | undefined = await this.fetchConnector(progressMonitor) || EMPTY_CONFIGURATION;

            if (this.#storedConfiguration) {
                configuration = reuseNodes(configuration, this.#storedConfiguration);
            }

            configuration = setImmutable(configuration);

            this.#storedConfiguration = configuration;

            events = this.syncConfiguration(configuration);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return undefined;
            }

            if (this.#onFetchError && isError(error)) {
                this.#onFetchError(error);

                return;
            }

            console.error(error);
            notifications.snackError(undefined, error as Error);

            throw error;
        } finally {
            this.#semaphore.release();
        }

        if (!events) {
            return;
        }

        // events.length===0 => the new configuration can have new fields !

        this.updateStateId();

        events.forEach(({ path, newValue }) => {
            this.emit('Changed', path, newValue, ConfigurationChangedReason.Sync);
        });
    }

    installConfiguration(configuration: Configuration) {
        this.#isLoaded = true;
        this.#configuration = configuration;
        this.#storedConfiguration = configuration;
    }

    protected abstract fetchConnector(progressMonitor: ProgressMonitor): Promise<Configuration | undefined>;

    // Fetch the remote to retrieve the configuration
    async fetch(notifications: ArgGlobalNotificationType, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Configuration | undefined> {
        let configuration: Configuration | undefined;

        await this.#semaphore.acquire();
        try {
            configuration = await this.fetchConnector(progressMonitor) || EMPTY_CONFIGURATION;

            if (debug.enabled) {
                debug(`fetchConfiguration(${this.name})`, 'configuration=', configuration);
            }

            configuration = setImmutable(configuration);

            this.installConfiguration(configuration);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return undefined;
            }

            // Create the configuration if this not exists
            if (isResponse404(error)) {
                this.installConfiguration(this.initialConfiguration);

                return this.configuration;
            }

            if (this.#onFetchError && isError(error)) {
                this.#onFetchError(error);

                return;
            }

            console.error(error);
            notifications.snackError(undefined, error as Error);

            throw error;
        } finally {
            this.#semaphore.release();
        }

        this.updateStateId();

        this.emit('Loaded', ConfigurationChangedReason.Sync);

        return configuration;
    }

    protected abstract storeConnector(configuration: Configuration, previousConfiguration: Configuration | undefined, progressMonitor: ProgressMonitor): Promise<void>;

    async store(notifications: ArgGlobalNotificationType, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        await this.#semaphore.acquire();
        try {
            const configuration = this.configuration;

            if (this.#storedConfiguration && isEqual(this.#storedConfiguration, configuration)) {
                return;
            }

            if (debug.enabled) {
                debug(`store(${this.name})`);
            }

            await this.storeConnector(configuration, this.#storedConfiguration, progressMonitor);

            this.#storedConfiguration = configuration;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }

            if (this.#onSaveError && isError(error)) {
                this.#onSaveError(error);

                return;
            }

            console.error(error);
            notifications.snackError(undefined, error as Error);

            throw error;
        } finally {
            this.#semaphore.release();
        }
    }

    async set(path: ConfigurationPath | undefined, value: ((currentValue: any) => any) | any, isReadonly?: boolean): Promise<void> {
        let newConfigurations: Configuration;
        let newValue: any;

        const prevValue = (path) ? get(this.#configuration, path) : this.#configuration;

        newValue = value;
        if (isFunction(value)) {
            newValue = value(prevValue);
        }

        if (path) {
            newConfigurations = immutableSet(this.#configuration, path, newValue);
        } else {
            newConfigurations = newValue;
        }

        if (isEqual(newConfigurations, this.#configuration)) {
            if (debug.enabled) {
                debug(`set(${this.name})`, 'path=', path, 'newValue=', newValue, 'SAME VALUE');
            }

            return;
        }

        this.#configuration = newConfigurations;

        this.updateStateId();

        if (debug.enabled) {
            debug(`set(${this.name})`, 'path=', path, 'newValue=', newValue);
        }

        this.emit('Changed', path || '', newValue, ConfigurationChangedReason.Set);
        if (!isReadonly && !this.#isReadonly) {
            this.emit('ToBeStored');
        }
    }

    get initialConfiguration(): Configuration {
        return this.#initialConfiguration;
    }

    get configuration(): Configuration {
        return this.#configuration;
    }

    get isLoaded(): boolean {
        return this.#isLoaded;
    }

    get(id: ConfigurationPath): any {
        return get(this.#configuration, id);
    }
}

function getDiffFromSource(events: UpdateEvents[], source: any, target: any, path: string): void {
    for (const k in source) {
        const v1 = source[k];
        const v2 = target[k];

        if (v1 === v2) {
            continue;
        }

        const fieldPath = (path) ? `${path}.${k}` : k;

        if (v1 && v2 && typeof (v1) === 'object' && typeof (v2) === 'object') {
            getDiffFromSource(events, v1, v2, fieldPath);
            continue;
        }

        events.push({
            path: fieldPath,
            newValue: v2,
        });
    }
}

export function isConfiguration(configuration: any): configuration is Configuration {
    if (!configuration || typeof (configuration) !== 'object') {
        return false;
    }

    return true;
}
