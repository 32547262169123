import { Slider } from 'antd';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgSliderCommonProps } from './types';

import './arg-slider.less';

const enabledDraggableTrack = { draggableTrack: true };
const getRange = (isDraggableTrack?: boolean) => (isDraggableTrack ? enabledDraggableTrack : true);

export interface ArgSliderRangeProps extends ArgSliderCommonProps<[number, number]> {
    draggableTrack?: boolean;
}

export function ArgSliderRange(props: ArgSliderRangeProps) {
    const {
        className,
        min,
        max,
        step,
        disabled,
        value,
        onChange,
        tooltip,
        draggableTrack,
    } = props;

    const classNames = useClassNames('arg-slider');
    const range = getRange(draggableTrack);

    return (
        <Slider
            className={classNames('&-slider', className)}
            range={range}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            disabled={disabled}
            tooltip={tooltip}
        />
    );
}
