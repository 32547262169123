import useResizeObserver from '@react-hook/resize-observer';
import { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { sortBy, sumBy } from 'lodash';

import { ScrollDisplayManager } from '../utils/scroll-display-manager';

const EMPTY_DOM_RECT: DOMRect = {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    toJSON: () => {
    },
};

const INITIAL_SCROLL_POSITION = [0, 0] as [number, number];

const scrollContainers = new WeakSet<Element>();

interface AdditionalRow {
    index: number;
    height: number;
}

export type ScrollInfo<T> = {
    totalHeight: number,
    visibleNodeCount: number,
    startNode: number,
    scrollDisplayManager: ScrollDisplayManager,
    scrollPosition: [number, number], // [scrollLeft: number, scrollTop: number],
    containerHeight: number,
    containerWidth: number
};

export const useScroll = <T extends HTMLElement>(
    itemsCount: number,
    rowHeight: number,
    containerRef: RefObject<T>,
    bodyRef: RefObject<T>,
    headerBodyRef: RefObject<T> | undefined,
    lockedBodyRef: RefObject<T> | undefined,
    additionalRowsBodyRef: RefObject<T> | undefined = undefined,
    additionalRows: AdditionalRow[] = []

): ScrollInfo<T> => {
    const [scrollPosition, setScrollPosition] = useState<[number, number]>(INITIAL_SCROLL_POSITION);
    const [size, setSize] = useState<DOMRect>(EMPTY_DOM_RECT);

    // Rebuilding scrollDisplayManager could get expensive, hence we want to be sure it happens ONLY when rowHeight changes, hence useState is preferred over useMemo.
    const [scrollDisplayManager, setScrollDisplayManager] = useState<ScrollDisplayManager>(() => new ScrollDisplayManager(rowHeight));
    useEffect(() => {
        setScrollDisplayManager(new ScrollDisplayManager(rowHeight));
    }, [rowHeight]);

    const sortedAdditionalRows = useMemo<AdditionalRow[]>(() => {
        return sortBy(additionalRows, 'index');
    }, [additionalRows]);

    useResizeObserver(containerRef, (entry: ResizeObserverEntry) => {
        setSize(entry.contentRect as DOMRect);
    });

    useLayoutEffect(() => {
        const scrollContainer = bodyRef.current;
        const headerScrollContainer = headerBodyRef?.current;
        const lockedScrollContainer = lockedBodyRef?.current;
        const additionalRowsScrollContainer = additionalRowsBodyRef?.current;

        if (scrollContainer) {
            setScrollPosition([scrollContainer.scrollLeft, scrollContainer.scrollTop]);

            let ticking = false;
            const onScroll = (e: Event) => {
                //Native Throttling: https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
                if (!ticking) {
                    requestAnimationFrame(() => {
                        ticking = false;

                        const targetElem = e.target as T;
                        setScrollPosition([scrollContainer.scrollLeft, targetElem.scrollTop]);
                    });
                    ticking = true;
                }
            };

            const onHeaderScroll = (e: Event) => {
                console.log('Header scroll=', e);
                if (!ticking) {
                    requestAnimationFrame(() => {
                        ticking = false;

                        const targetElem = e.target as T;
                        setScrollPosition([targetElem.scrollLeft, targetElem.scrollTop]);
                    });
                    ticking = true;
                }
            };


            const onWheel = (e: WheelEvent) => {
                //Native Throttling: https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
                if (!ticking) {
                    if (isScrollingInnerScrollable(e, scrollContainer)) {
                        return;
                    }

                    requestAnimationFrame(() => {
                        ticking = false;

                        scrollContainer.scrollTop += e.deltaY;
                        setScrollPosition([scrollContainer.scrollLeft, scrollContainer.scrollTop]);
                    });
                    ticking = true;
                }
            };

            headerScrollContainer?.addEventListener('scroll', onHeaderScroll);
            scrollContainer.addEventListener('scroll', onScroll);
            scrollContainers.add(scrollContainer);

            lockedScrollContainer?.addEventListener('wheel', onWheel);
            additionalRowsScrollContainer?.addEventListener('wheel', onWheel);
            bodyRef.current && setSize(bodyRef.current.getBoundingClientRect());


            return () => {
                headerScrollContainer?.removeEventListener('scroll', onHeaderScroll);
                scrollContainer.removeEventListener('scroll', onScroll);
                lockedScrollContainer?.removeEventListener('wheel', onWheel);
                additionalRowsScrollContainer?.removeEventListener('wheel', onWheel);
                scrollContainers.delete(scrollContainer);
            };
        }
    }, []);

    const computeItemCount = useCallback((toHeight: number) => {
        let remainingHeight = toHeight;
        let items = 0;
        let lastIndex = 0;

        for (let i = 0; i < sortedAdditionalRows.length; i++) {
            const item = sortedAdditionalRows[i];
            const height = (item.index - lastIndex + 1) * rowHeight;
            if (remainingHeight >= height) {
                remainingHeight -= height;
                remainingHeight -= item.height;
                items += item.index - lastIndex + 1;
                lastIndex = item.index + 1;
            } else {
                break;
            }
        }
        items += Math.max(0, Math.floor(remainingHeight / rowHeight));

        return Math.min(items, itemsCount);
    }, [rowHeight, itemsCount, sortedAdditionalRows]);

    const startNode = useMemo(() => {
        const count = computeItemCount(scrollPosition[1]);

        // For additional rows to re-render properly, we need to force inclusion of the parent item even when it's offscreen. Starting at the node before is one simple easy way to achieve it.
        return Math.max(count - 1, 0);
    }, [computeItemCount, scrollPosition[1]]);

    const visibleNodeCount = useMemo(() => {
        const endNode = computeItemCount(scrollPosition[1] + size.height);

        return endNode - startNode;
    }, [computeItemCount, scrollPosition[1], size.height, startNode]);

    const totalHeight = itemsCount * rowHeight + sumBy(additionalRows, 'height');

    return {
        totalHeight,
        visibleNodeCount,
        startNode,
        scrollDisplayManager,
        scrollPosition,
        containerHeight: size.height,
        containerWidth: size.width,
    };
};

// Returns true if user is scrolling an inner scrollable element. Only works if the inner scrolling element is also using useScroll hook.
function isScrollingInnerScrollable(e: WheelEvent, currentContainer: Element) {
    let targetEl: Element | null = e.target as Element;
    const deltaY = e.deltaY;

    // Scroll containers might not scroll to the last pixel, hence this threshold.
    const threshold = Math.abs(deltaY) * 0.1;

    while (targetEl && targetEl !== currentContainer) {
        if (scrollContainers.has(targetEl)) {
            const scrollBottom = targetEl.scrollHeight - targetEl.scrollTop - targetEl.clientHeight;
            const scrollTop = targetEl.scrollTop;
            if (deltaY > 0 && scrollBottom > threshold) {
                return true;
            }
            if (deltaY < 0 && scrollTop > threshold) {
                return true;
            }
        }
        targetEl = targetEl.parentElement;
    }

    return false;
}
