import { useEffect, useState } from 'react';
import { ValidEventTypes } from 'eventemitter3';

import { $yield } from './yield';
import { EventEmitter } from './event-emitter';

// A hook that triggers a re-render on any events emitted by eventEmitter.
export function useStateId<EventTypes extends ValidEventTypes = string | symbol>(eventEmitter?: EventEmitter<EventTypes>): number {
    const [stateId, setStateId] = useState<number>(0);

    useEffect(() => {
        if (!eventEmitter) {
            return;
        }
        const $eventEmitter = eventEmitter;

        function stateChanged() {
            $yield(() => {
                setStateId((prev) => (prev + 1));
            });
        }

        $eventEmitter.addListener('*', stateChanged);

        return () => {
            $eventEmitter.removeListener('*', stateChanged);
        };
    }, [eventEmitter]);

    return stateId;
}
