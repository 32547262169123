import React, { useCallback } from 'react';
import { Resizable } from 're-resizable';
import { Map } from 'leaflet';

import { ClassValue, useClassNames } from '../../arg-hooks/use-classNames';

import './arg-resizable-map-container.less';

const POPOVER_MIN_WIDTH = '420px';
const POPOVER_MAX_WIDTH = '630px';
const POPOVER_DEFAULT_WIDTH = '416px';

const POPOVER_MIN_HEIGHT = '277px';
const POPOVER_MAX_HEIGHT = '416px';
const POPOVER_DEFAULT_HEIGHT = '630px';

interface ArgResizableMapContainerProps {
    className?: ClassValue;
    map: React.MutableRefObject<Map | undefined>;
    children: React.ReactNode,
}

export function ArgResizableMapContainer(props: ArgResizableMapContainerProps) {
    const {
        className,
        map,
        children,
    } = props;

    const classNames = useClassNames('arg-resizable-map-container');

    const handleResize = useCallback(() => {
        map.current?.invalidateSize();
    }, [map]);

    return (
        <Resizable
            lockAspectRatio={true}
            onResize={handleResize}
            onResizeStop={handleResize}
            onResizeStart={handleResize}
            minWidth={POPOVER_MIN_WIDTH}
            maxWidth={POPOVER_MAX_WIDTH}
            minHeight={POPOVER_MIN_HEIGHT}
            maxHeight={POPOVER_MAX_HEIGHT}
            defaultSize={{
                height: POPOVER_DEFAULT_WIDTH,
                width: POPOVER_DEFAULT_HEIGHT,
            }}
            className={classNames('&', className)}
            enable={{
                bottom: true,
                left: true,
                bottomLeft: true,
            }}
        >
            {children}
        </Resizable>
    );
}
