import { ReactNode, useMemo } from 'react';

import { processSearch } from '../utils/highlight';

export interface HighlightedTextProps {
    text: string;
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({ text }) => (
    <span className='search-highlight-other-result'>{text}</span>
);

export interface HighlightedSearchProps {
    text: string;
    token?: string;
}

export const HighlightedSearchResult = ({ text, token = 'em' }: HighlightedSearchProps) => {
    const highlightedText = useMemo<ReactNode>(() => {
        const processedSearch = processSearch(text, token);

        return processedSearch;
    }, [text, token]);

    return <span>{highlightedText}</span>;
};
