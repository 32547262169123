import { isFunction } from 'lodash';
import { useRef } from 'react';

const INIT_SYMBOL = Symbol('init');

// Returns a reference holding initialValue. If initialValue is a function, it is treated as an initializer function. Like the initialier function in useState it should be pure, should take no
// argument and should return a value of any type. This argument is ignored after the first render.
// Useful to replicate the initial state behaviour of the useState hook for hooks that needs an intial value.
export function useInitialValue<T>(initialValue: T | (() => T)): T {
    const defaultValueRef = useRef<any|symbol>(INIT_SYMBOL);

    if (defaultValueRef.current === INIT_SYMBOL) {
        defaultValueRef.current = isFunction(initialValue) ? initialValue() : initialValue;
    }

    return defaultValueRef.current;
}
