import { isDate, isEqual, isObject, set } from 'lodash';
import Debug from 'debug';

const debug = Debug('common:utils:Reuse');

interface Context<T extends object> {
    previous: T;
    target: T;

    objectsCount: number;
    arraysCount: number;
    othersCount: number;
    reuseNodesCount: number;
    diffs?: string[];
}

function reuse<T extends object>(context: Context<T>, path: (string | number)[], targetNode: any, previousNode: any): boolean {
    if (Array.isArray(targetNode)) {
        context.arraysCount++;

        if (!Array.isArray(previousNode)) {
            debug('reuse', 'source not an array', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode);

            if (context.diffs) {
                context.diffs.push(path.join('.'));
            }

            return true;
        }

        const minIndex = Math.min(targetNode.length, previousNode.length);
        const newPath = [ ...path, 0 ];
        let diff = (targetNode.length !== previousNode.length);
        for (let i = 0; i < minIndex; i++) {
            newPath[newPath.length - 1] = i;
            if (reuse(context, newPath, targetNode[i], previousNode[i])) {
                diff = true;
            }
        }

        debug('reuse', 'array', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode, 'diff=>', diff);

        if (!diff && path.length) {
            set(context.target, path, previousNode);
            context.reuseNodesCount++;
        }
        if (diff && context.diffs) {
            context.diffs.push(path.join('.'));
        }

        return diff;
    }

    if (targetNode && isObject(targetNode)) {
        context.objectsCount++;

        if (!previousNode || !isObject(previousNode)) {
            debug('reuse', 'source not an object', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode);
            if (context.diffs) {
                context.diffs.push(path.join('.'));
            }

            return true;
        }

        if (isDate(targetNode)) {
            if (!isDate(previousNode)) {
                debug('reuse', 'source not a Date', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode);

                if (context.diffs) {
                    context.diffs.push(path.join('.'));
                }

                return true;
            }

            if (targetNode.getTime() === previousNode.getTime()) {
                debug('reuse', 'same Date', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode);

                set(context.target, path, previousNode);

                return false;
            }

            debug('reuse', 'not same Date', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode);

            if (context.diffs) {
                context.diffs.push(path.join('.'));
            }

            return true;
        }

        const targetKeys = Object.entries(targetNode);
        let diff = targetKeys.length !== Object.keys(previousNode).length;
        const newPath = [ ...path, 'x' ];

        for (const [ targetKey, targetField ] of targetKeys) {
            if (!(targetKey in previousNode)) {
                diff = true;
                continue;
            }

            newPath[newPath.length - 1] = targetKey;
            const previousField: any = (previousNode as any)[targetKey];
            if (reuse(context, newPath, targetField, previousField)) {
                diff = true;
            }
        }

        debug('reuse', 'object', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode, 'diff=>', diff);

        if (!diff && path.length) {
            set(context.target, path, previousNode);
            context.reuseNodesCount++;
        }
        if (diff && context.diffs) {
            context.diffs.push(path.join('.'));
        }

        return diff;
    }

    context.othersCount++;

    const diff = !isEqual(previousNode, targetNode);

    debug('reuse', 'equals', 'path=', path, 'targetNode=', targetNode, 'previousNode=', previousNode, 'diff=>', diff);

    if (diff && context.diffs) {
        context.diffs.push(path.join('.'));
    }

    return diff;
}

export function reuseNodes<T extends object>(target: T, previous: T): T {
    if (localStorage.ARG_DISABLE_REUSE === 'true') {
        return target;
    }

    let now = window.performance.now();

    const context: Context<T> = {
        previous,
        target,
        objectsCount: 0,
        arraysCount: 0,
        othersCount: 0,
        reuseNodesCount: 0,
        diffs: (localStorage.ARG_LOG_REUSE) ? [] : undefined,
    };

    const diff = reuse(context, [], target, previous);

    now = window.performance.now() - now;

    debug('reuseNodes', 'target=', target, 'source=', previous, 'diff=>', diff);

    if (localStorage.ARG_LOG_REUSE === 'console') {
        console.log(`REUSE in ${Math.floor(now * 1000) / 1000}ms for ${context.objectsCount} objects and ${context.arraysCount} arrays => ${context.reuseNodesCount} nodes reused  (${Math.floor(context.reuseNodesCount / (context.arraysCount + context.objectsCount) * 100)}%)`);
        if (context.diffs) {
            console.log('  diffs=', context.diffs);
        }
    }

    if (diff) {
        return target;
    }

    return previous;
}
