import { useEffect, useState } from 'react';

export type DatesDayType = 'today' | 'yesterday' | 'weekDay' | 'other';

export interface DatesDay {
    today: Date;
    yesterday: Date;
    weekDay: Date;

    getDateType: (date: Date) => DatesDayType;
}

export function useToday(): DatesDay {
    const [today, setToday] = useState<DatesDay>(() => {
        return computeDates();
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedToday = new Date();
            updatedToday.setHours(0, 0, 0, 0);

            setToday((prev) => {
                if (prev.today.getTime() === updatedToday.getTime()) {
                    return prev;
                }

                return computeDates();
            });
        }, 1000 * 60);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return today;
}

function computeDates(): DatesDay {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const weekDay = new Date(today);
    weekDay.setDate(today.getDate() - 7);
    weekDay.setHours(0, 0, 0, 0);

    return {
        today,
        yesterday,
        weekDay,
        getDateType: function getDateType(this: DatesDay, date) {
            const time = date.getTime();

            if (this.today.getTime() === time) {
                return 'today';
            }
            if (this.yesterday.getTime() === time) {
                return 'yesterday';
            }
            if (this.weekDay.getTime() <= time) {
                return 'weekDay';
            }

            return 'other';
        },
    };
}
