import { LoadingPane } from '../common/panes/loading-pane';
import { ClassValue, useClassNames } from '../basic';

import './loading-suspense.less';

interface LoadingSuspenseProps {
    className?: ClassValue;
}

export function LoadingSuspense(props: LoadingSuspenseProps) {
    const { className } = props;

    const classNames = useClassNames('common-loading-suspense');

    return (
        <div className={classNames('&', className)}>
            <LoadingPane className={classNames('&-loading')} />
        </div>
    );
}
