import Debug from 'debug';
import React from 'react';

import { AbstractConfigurations, Configuration, ProgressMonitor } from '../components/basic';
import { SettingsConnector } from '../settings/connectors/settings-connector';

const debug = Debug('common:contexts:ApplicationConfigurations');

const DEFAULT_KEY = 'configuration';

const UNSUPPORTED_PATCH = true;

export class ApplicationConfigurations extends AbstractConfigurations {
    #settingsConnector: SettingsConnector;

    constructor(
        settingsConnector: SettingsConnector,
        onFetchError?: (error: Error) => void,
        onSaveError?: (error: Error) => void) {
        super('ApplicationConfigurations',
            {
                onFetchError,
                onSaveError,
            });

        this.#settingsConnector = settingsConnector;
    }

    protected async fetchConnector(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Configuration | undefined> {
        const ret = await this.#settingsConnector.getAppSettingsJSON(DEFAULT_KEY, progressMonitor);

        return ret;
    }

    protected async storeConnector(configuration: Configuration, previousConfiguration: Configuration, progressMonitor: ProgressMonitor): Promise<void> {
        debug('storeConnector', 'configuration=', configuration);

        if (!previousConfiguration || UNSUPPORTED_PATCH) {
            await this.#settingsConnector.setAppSettings(DEFAULT_KEY, configuration, progressMonitor);

            return;
        }

        await this.#settingsConnector.patchAppSettings(DEFAULT_KEY, configuration, previousConfiguration, progressMonitor);
    }
}

export const ApplicationConfigurationsContext = React.createContext<ApplicationConfigurations | undefined>(undefined);

