import _ from 'lodash';
import Debug from 'debug';
import SyncWorkerImplementation from 'worker-loader?worker=SharedWorker!./sync-worker-implementation.ts';

import { SyncWorkerData } from './sync-worker-data';
import { SyncWorkerMessage } from './sync-worker-message';


type WorkerHandler = (payload: any, id?: string, index?: number, origin?: string) => void;
const debug = Debug('workers:SyncWorker');

export class SyncWorker {
    private static instance: SyncWorker;
    #origin: string;
    private worker: SyncWorkerImplementation;
    private handlers: { [message in SyncWorkerMessage]: WorkerHandler[] };

    constructor() {
        this.#origin = `origin-${Date.now()}`;
        this.worker = new SyncWorkerImplementation();
        window.addEventListener('beforeunload', () => {
            this.sendMessage(SyncWorkerMessage.Disconnect, undefined);
        });
        this.worker.port.onmessage = e => this.handleMessage(e);
        const handlers: { [message in SyncWorkerMessage]?: WorkerHandler[] } = {};
        for (const message of Object.values(SyncWorkerMessage)) {
            if (typeof message === 'string') {
                continue;
            }

            handlers[message] = [];
        }
        this.handlers = handlers as any;
        debug('New worker created');
    }

    static getInstance(): SyncWorker {
        if (!SyncWorker.instance) {
            SyncWorker.instance = new SyncWorker();
        }

        return SyncWorker.instance;
    }

    private handleMessage(event: MessageEvent) {
        const data: SyncWorkerData = event.data;
        debug('Message received: %j', data);
        const messageHandlers = this.handlers[data.message];

        for (const messageHandler of messageHandlers) {
            messageHandler(data.payload, data.id, data.index, data.origin);
        }
    }

    registerHandler(message: SyncWorkerMessage, handler: WorkerHandler) {
        this.handlers[message].push(handler);
        debug('New handler for message %s (total: %d)', SyncWorkerMessage[message], this.handlers[message].length);

        return () => this.unregisterHandler(message, handler);
    }

    unregisterHandler(message: SyncWorkerMessage, handler: WorkerHandler) {
        _.remove(this.handlers[message], h => h === handler);
        debug('Removed handler for message %s (total: %d)', SyncWorkerMessage[message], this.handlers[message].length);
    }

    sendMessage(message: SyncWorkerMessage, payload: any, id?: string) {
        const data: SyncWorkerData = {
            message: message,
            id: id,
            payload: payload,
            origin: this.#origin,
        };

        this.worker.port.postMessage(data);
    }

    get origin(): string {
        return this.#origin;
    }
}

export default SyncWorker.getInstance();
