import { useMemo } from 'react';
import Debug from 'debug';

import { AbstractConfigurations, ConfigurationPath } from '../utils/configurations';
import { ConfigurationOption, useConfiguration } from '../../../hooks/use-configuration';
import { ArgToolbarLayoutProps } from './arg-toolbar-layout';

const debug = Debug('basic:toolbar:use-toolbar-panels-configuration');

const NO_PANEL = '***NO PANEL***';
const NO_EDITOR = '***NO EDITOR***';

export interface ArgPanelsConfiguration {
    leftSelectedPanelConfigurationName: ConfigurationPath;
    rightSelectedPanelConfigurationName: ConfigurationPath;
    leftPanelWidthConfigurationName: ConfigurationPath;
    rightPanelWidthConfigurationName: ConfigurationPath;
    selectedEditorConfigurationName: ConfigurationPath;
    footerPanelHeightConfigurationName: ConfigurationPath;

    defaultSelectedEditor?: string,
    defaultLeftSelectedPanel?: string,
    defaultRightSelectedPanel?: string,

    defaultLeftPanelWidth?: number | string;
    defaultRightPanelWidth?: number | string;
    defaultFooterPanelHeight?: number | string;

    minLeftPanelWidth?: number | string;
    minRightPanelWidth?: number | string;
    minFooterPanelHeight?: number | string;

    maxLeftPanelWidth?: number | string;
    maxRightPanelWidth?: number | string;
    maxFooterPanelHeight?: number | string;
}

export function useToolbarPanelsConfiguration(
    configurations: AbstractConfigurations,
    panelsConfiguration: ArgPanelsConfiguration,
    configurationOption?: ConfigurationOption //NO_REALTIME_UPDATE
): Partial<ArgToolbarLayoutProps> {
    const [selectedRightPanel, onRightPanelSelected] = useConfiguration<string | undefined>(
        configurations,
        panelsConfiguration.rightSelectedPanelConfigurationName,
        panelsConfiguration.defaultRightSelectedPanel,
        configurationOption
    );
    const [rightPanelWidth, onRightPanelWidthChanged] = useConfiguration<number | undefined>(
        configurations,
        panelsConfiguration.rightPanelWidthConfigurationName,
        undefined,
        configurationOption
    );
    debug('useToolbarPanelsConfiguration',
        'selectedRightPanel=',
        selectedRightPanel,
        'rightSelectedPanelConfigurationName=',
        panelsConfiguration.rightSelectedPanelConfigurationName,
        'defaultRightSelectedPanel=',
        panelsConfiguration.defaultRightSelectedPanel,
        'rightPanelWidthConfigurationName=',
        panelsConfiguration.rightPanelWidthConfigurationName,
        'configurationOption=',
        configurationOption
    );

    const [selectedLeftPanel, onLeftPanelSelected] = useConfiguration<string | undefined>(
        configurations,
        panelsConfiguration.leftSelectedPanelConfigurationName,
        panelsConfiguration.defaultLeftSelectedPanel,
        configurationOption
    );
    const [leftPanelWidth, onLeftPanelWidthChanged] = useConfiguration<number | undefined>(
        configurations,
        panelsConfiguration.leftPanelWidthConfigurationName,
        undefined,
        configurationOption
    );

    debug('useToolbarPanelsConfiguration',
        'selectedLeftPanel=',
        selectedLeftPanel,
        'leftSelectedPanelConfigurationName=',
        panelsConfiguration.leftSelectedPanelConfigurationName,
        'defaultLeftSelectedPanel=',
        panelsConfiguration.defaultLeftSelectedPanel,
        'leftPanelWidthConfigurationName=',
        panelsConfiguration.leftPanelWidthConfigurationName,
        'configurationOption=',
        configurationOption
    );

    const [selectedEditor, _onEditorSelected] = useConfiguration<string | undefined>(
        configurations,
        panelsConfiguration.selectedEditorConfigurationName,
        panelsConfiguration.defaultSelectedEditor,
        configurationOption
    );

    const [footerPanelHeight, onFooterPanelHeightChanged] = useConfiguration<number | undefined>(
        configurations,
        panelsConfiguration.footerPanelHeightConfigurationName,
        undefined,
        configurationOption
    );

    const props = useMemo<Partial<ArgToolbarLayoutProps>>(() => {
        const ret: Partial<ArgToolbarLayoutProps> = {
            rightPanelWidth,
            onRightPanelWidthChanged,
            rightPanelDefaultWidth: panelsConfiguration?.defaultRightPanelWidth,
            rightPanelMaxWidth: panelsConfiguration?.maxRightPanelWidth,
            rightPanelMinWidth: panelsConfiguration?.minRightPanelWidth,

            leftPanelWidth,
            onLeftPanelWidthChanged,
            leftPanelDefaultWidth: panelsConfiguration?.defaultLeftPanelWidth,
            leftPanelMaxWidth: panelsConfiguration?.maxLeftPanelWidth,
            leftPanelMinWidth: panelsConfiguration?.minLeftPanelWidth,

            selectedLeftPanel: selectedLeftPanel !== NO_PANEL ? selectedLeftPanel : undefined,
            setSelectedLeftPanel: (path) => {
                onLeftPanelSelected(path ? path : NO_PANEL);
            },
            selectedRightPanel: selectedRightPanel !== NO_PANEL ? selectedRightPanel : undefined,
            setSelectedRightPanel: (path) => {
                onRightPanelSelected(path ? path : NO_PANEL);
            },

            selectedEditor: selectedEditor !== NO_EDITOR ? selectedEditor : undefined,
            onEditorSelected: (path) => {
                _onEditorSelected(path ? path : NO_EDITOR);
            },

            footerPanelHeight,
            onFooterPanelHeightChanged,
            footerPanelDefaultHeight: panelsConfiguration?.defaultFooterPanelHeight,
            footerPanelMaxHeight: panelsConfiguration?.maxFooterPanelHeight,
            footerPanelMinHeight: panelsConfiguration?.minFooterPanelHeight,
        };

        debug('memo', 'config=', ret, 'configuration=', configurations);

        return ret;
    }, [
        rightPanelWidth,
        onRightPanelWidthChanged,
        panelsConfiguration?.defaultRightPanelWidth,
        panelsConfiguration?.maxRightPanelWidth,
        panelsConfiguration?.minRightPanelWidth,
        panelsConfiguration?.defaultLeftPanelWidth,
        panelsConfiguration?.maxLeftPanelWidth,
        panelsConfiguration?.minLeftPanelWidth,
        panelsConfiguration?.defaultFooterPanelHeight,
        panelsConfiguration?.maxFooterPanelHeight,
        panelsConfiguration?.minFooterPanelHeight,
        leftPanelWidth,
        onLeftPanelWidthChanged,
        selectedLeftPanel,
        selectedRightPanel,
        selectedEditor,
        footerPanelHeight,
        onFooterPanelHeightChanged,
        configurations,
        onLeftPanelSelected,
        onRightPanelSelected,
        _onEditorSelected,
    ]);

    return props;
}
