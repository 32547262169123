import { MessageDescriptor } from 'react-intl';

export interface ArgErrorMessageDetail {
    title: MessageDescriptor;
    description?: MessageDescriptor;
}

export type ArgErrorsMap = Record<string, ArgErrorMessageDetail>

export const sharedErrorsMap: Readonly<ArgErrorsMap> = {};
