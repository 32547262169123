import React, { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgCombo, ArgComboProps } from './arg-combo';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';

import './arg-combo-line.less';

const messages = defineMessages({
    hiddenLine: {
        id: 'basic.arg-combo-line.hidden',
        defaultMessage: 'Hidden',
    },
});

type LineStyle = 'solid' | 'dotted' | 'dashed' | 'hidden';
type AllowHidden = { allowHidden?: boolean };
type ComboWithoutItems = Omit<ArgComboProps<LineStyle>, 'items'>
type ArgComboLineProps = ComboWithoutItems & AllowHidden;

const BASE_LINESTYLES: LineStyle[] = ['solid', 'dashed', 'dotted'];

export const ArgComboLine = ({
    className,
    value,
    onChange,
    popoverClassName,
    renderItem,
    renderInput,
    disabled,
    allowHidden,
    ...props

}: ArgComboLineProps) => {
    const classNames = useClassNames('arg-combo-line');

    const lineStyles = useMemo<LineStyle[]>(() => {
        if (allowHidden) {
            return [...BASE_LINESTYLES, 'hidden'];
        }

        return BASE_LINESTYLES;
    }, [allowHidden]);

    const renderLineStyleItem = (item: LineStyle) => {
        if (item === 'hidden') {
            return (
                <ArgMessageRenderer
                    message={messages.hiddenLine}
                    className={classNames('&-popover-item-style', item)}
                />
            );
        }

        return <hr className={classNames('&-popover-item-style', item)} />;
    };

    return (
        <ArgCombo<LineStyle>
            className={classNames('&', className)}
            items={lineStyles}
            size='medium'
            cardinality='optional'
            clearable={false}
            value={value}
            onChange={onChange}
            popoverClassName={classNames('&-popover', popoverClassName)}
            renderItem={renderItem ? renderItem : renderLineStyleItem}
            renderInput={renderInput ? renderInput : (props) => props.value && renderLineStyleItem(props.value)}
            disabled={disabled}
            {...props}
        />
    );
};
