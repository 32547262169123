import { max } from 'lodash';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import { useLatestCallback } from '../arg-hooks/use-latest-callback';
import { useClassNames } from '../arg-hooks/use-classNames';

import './use-horizontal-scroll.less';

const CLASS_NAME = 'arg-table4-horizontal-scroll';

interface ScrollInfo {
    maxScrollWidth?: number,
    hasScrollbar: boolean,
}

export function useHorizontalScroll(
    scrollables: Array<Element | null>,
    deps: React.DependencyList
): ReactNode {
    const classNames = useClassNames(CLASS_NAME);
    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollInfo = useRef<ScrollInfo>();

    const update = useLatestCallback(() => {
        if (scrollRef.current) {
            const scrollLeft = scrollRef.current?.scrollLeft;
            // TODO: there is perf issue leading to unpleasant trembling while scrolling caused by the below line. Carefully using requestAnimationFrame and throttling clearing is not enough.
            scrollables.forEach(el => {
                if (el) {
                    el.scrollLeft = scrollLeft;
                }
            });
        }
    });

    useEffect(() => {
        update();
    }, [...scrollables]);

    useEffect(() => {
        let ticking = false;
        const onScroll = () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    ticking = false;

                    update();
                });
                ticking = true;
            }
        };

        scrollRef.current?.addEventListener('scroll', onScroll);

        return () => {
            scrollRef.current?.removeEventListener('scroll', onScroll);
        };
    }, []);

    const computeScrollInfo = useCallback(() => {
        const maxScrollWidth = max(scrollables.map(el => el?.scrollWidth));
        const hasScrollbar = scrollables.some(el => {
            if (!el || !el.scrollWidth) {
                return false;
            }

            return el.scrollWidth > el.clientWidth;
        });
        scrollInfo.current = { maxScrollWidth, hasScrollbar };
    }, [deps, ...scrollables]);

    useEffect(computeScrollInfo, [computeScrollInfo]);

    const style = {
        width: scrollInfo.current?.maxScrollWidth,
    };

    const cls = {
        hide: !scrollInfo.current?.hasScrollbar,
    };

    const component = (
        <div
            className={classNames('&', cls)}
            ref={scrollRef}
        >
            <div style={style} />
        </div>
    );

    return component;
}
