import { ArgUserInfo } from '../../components/basic';

export interface ExternalComponent {
    name?: string;
    key: string;
    getDefinitionUrl: string;
    category?: string;
    type?: ExternalComponentType;
    status?: ExternalComponentStatus;
    description?: string;
    isEnabled?: boolean;
    lastUpdatedBy?: ArgUserInfo;
    lastUpdatedDate?: Date;
}

export interface ExternalComponentDto {
    name?: string;
    key: string;
    category?: string;
    remoteComponentURL: string;
    remoteComponentStatus: ExternalComponentStatus;
    remoteComponentType: number;
    description?: string;
    isEnabled?: boolean;
    lastUpdatedBy?: ArgUserInfo;
    lastUpdatedDate?: string;
}

export enum ExternalComponentType {
    Connector,
    Module,
    Injector,
    Invalid
}

export enum ExternalComponentStatus {
    ConnectionError = 'ConnectionError',
    InvalidDefinitionError = 'InvalidDefinitionError',
    Working = 'Working',
}

export type ExternalComponentKey = string;
