import { useEffect } from 'react';

import connector from '../../utils/connector';
import { ProgressMonitor, SubProgressMonitor } from '../basic/progress-monitors/progress-monitor';

const PAGE_ACCESS_PROPERTY = 'arg-page-access';
const TRY_PAGE_ACCESS_PROPERTY = 'arg-page-access-try';

export interface PageToTest {
    api: string;
    testURL: string;

    gotoURL: string;
}

export function useRecordPageAccess(page?: string) {
    useEffect(() => {
        window.localStorage.setItem(PAGE_ACCESS_PROPERTY, (page !== undefined) ? page : document.location.toString());
    }, [page]);
}

export function getRecordPageAccess(): string | undefined {
    const path = window.localStorage.getItem(PAGE_ACCESS_PROPERTY);
    if (path === null) {
        return undefined;
    }

    return path;
}

export function authorizedPageAccessed() {
    window.localStorage.removeItem(TRY_PAGE_ACCESS_PROPERTY);
}

export function unauthorizedPageAccessed() {
    // The page is no longer accessible
    window.localStorage.removeItem(PAGE_ACCESS_PROPERTY);
}

export async function computePageAccess(pages: PageToTest[], progressMonitor: ProgressMonitor): Promise<string | undefined> {
    if (!window.localStorage.getItem(TRY_PAGE_ACCESS_PROPERTY)) {
        window.localStorage.setItem(TRY_PAGE_ACCESS_PROPERTY, 'true');

        const path = getRecordPageAccess();
        if (path) {
            return path;
        }
    }

    window.localStorage.setItem(TRY_PAGE_ACCESS_PROPERTY, 'true');

    for (let i = 0; i < pages.length; i++) {
        progressMonitor.verifyCancelled();

        const test = pages[i];
        if (!test.api) {
            continue;
        }

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        try {
            await connector.request(test.testURL, {
                verifyJSONResponse: true,
                noRedirectIfUnauthorized: true,
                api: test.api,
            }, sub1);
        } catch (ex) {
            console.error(ex);
            continue;
        }

        window.localStorage.removeItem(TRY_PAGE_ACCESS_PROPERTY);

        return test.gotoURL;
    }
}
