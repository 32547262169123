import Debug from 'debug';
import { union } from 'lodash';

import type { User, UserPermissionId, UserPermissions } from '../../model/user';
import { ProgressMonitor } from '../../components/basic';
import { BaseConnector } from './base-connector';
import { mapDate } from './mappers';
import { mapUserProfileField } from 'src/settings/connectors/mappers';
import { UserProfileField, UserProfileFieldId } from '../../model/user-metadata';
import { getAdministrationApi, getDataExplorationApi, getDataPreparationApi, getProceoApi } from './api-url';
import { MODULES } from 'src/components/application/modules';
import { ResponseError } from '../../components/basic/utils/response-error';
import { AddEditUserProfileField } from '../../settings/models/dtoApi';

const debug = Debug('common:utils:Connector');

const FORCE_500_SERVER_ERROR = false;

export type PermissionsScope = 'data_exploration' | 'data_preparation' | 'proceo';

export class UsersConnector extends BaseConnector {
    private static instance: UsersConnector;

    static getInstance(): UsersConnector {
        if (!UsersConnector.instance) {
            UsersConnector.instance = new UsersConnector('utils.users', getAdministrationApi());
        }

        return UsersConnector.instance;
    }

    static getPermissionScopeApi(permissionScope: PermissionsScope | undefined): string {
        let api: string | undefined = undefined;


        if (permissionScope === 'data_exploration') {
            api = getDataExplorationApi();
        } else if (permissionScope === 'data_preparation') {
            api = getDataPreparationApi();
        } else if (permissionScope === 'proceo') {
            api = getProceoApi();
        }

        if (api) {
            return api;
        }

        throw new Error(`API URL for permission scope for ${permissionScope} is undefined`);
    }

    async myUserDetails(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<User> {
        if (FORCE_500_SERVER_ERROR) {
            const response = {
                status: 500,
            } as Response;

            const error = new ResponseError(
                'FORCE-500',
                'FORCE-500',
                undefined,
                response
            );

            throw error;
        }

        const result: User = await this.request('/users/me', {
            verifyJSONResponse: true,
        }, progressMonitor);

        debug('myUserDetails', 'result=', result);

        if (!result) {
            throw new Error('Invalid /users/me WS result');
        }

        const ret = mapMeToUser(result);

        return ret;
    }

    async myUserPermissions(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UserPermissions> {
        const result = await this.request('/users/me/permissions', {
            verifyJSONResponse: true,
        }, progressMonitor);
        debug('myUserPermissions', 'result=', result);
        const ret = mapUserPermission(result);

        return ret;
    }

    async getMyUserPermissions(progressMonitor: ProgressMonitor = ProgressMonitor.empty(), scope?: PermissionsScope): Promise<string[]> {
        const api = UsersConnector.getPermissionScopeApi(scope);
        const result = await this.request('/users/me/permissions', {
            verifyJSONResponse: true,
            api,
        }, progressMonitor);

        debug('myUserPermissions', 'result=', result);

        return result;
    }

    async getAllMyUserPermissions(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<UserPermissions> {
        const apiCalls: Promise<string[]>[] = [];

        if (MODULES.Proceo.enabled) {
            apiCalls.push(this.getMyUserPermissions(progressMonitor, 'proceo'));
        }
        if (MODULES.DataExploration.enabled) {
            apiCalls.push(this.getMyUserPermissions(progressMonitor, 'data_exploration'));
        }
        if (MODULES.DataPreparation.enabled) {
            apiCalls.push(this.getMyUserPermissions(progressMonitor, 'data_preparation'));
        }

        const response = await Promise.all(apiCalls);

        const ret = mapUserPermission(union(...response));

        return ret;
    }


    async getUserProfileFields(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<UserProfileField[]> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        const ret = (result?.fields || []).map((raw: any) => {
            const ret = mapUserProfileField(raw);

            return ret;
        });

        return ret;
    }

    async createUserProfileField(newProperty: AddEditUserProfileField, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<any> {
        const url = '/user-profiles/fields';
        const options = {
            method: 'POST',
            json: newProperty,
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }

    async editUserProfileField(propertyId: UserProfileFieldId, updatedProperty: AddEditUserProfileField, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<any> {
        const url = `/user-profiles/fields/${encodeURIComponent(propertyId)}`;
        const options = {
            method: 'PUT',
            json: updatedProperty,
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }

    async deleteUserProfileField(propertyId: UserProfileFieldId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<any> {
        const url = `/user-profiles/fields/${encodeURIComponent(propertyId)}`;
        const options = {
            method: 'DELETE',
        };

        const result = await this.request(url, options, progressMonitor);

        return result;
    }
}

export default UsersConnector.getInstance();

export function mapUser(userInfo: any): User {
    const user: User = {
        ...userInfo,
        displayName: userInfo.displayName || userInfo.fullName || userInfo.userName,
        createdDate: mapDate(userInfo.createdDate),
        lastUpdatedDate: mapDate(userInfo.lastUpdatedDate),
    };

    return user;
}

export function mapMeToUser(basicUser: User): User {
    const user = mapUser(basicUser);

    return { ...user };
}

export function mapUserPermission(permissions: UserPermissionId[] | null): UserPermissions {
    const ret: Record<string, true> = {};

    permissions?.forEach((permission) => {
        ret[permission] = true;
    });

    return ret;
}
