import React from 'react';
import { defineMessages, IntlShape, MessageDescriptor, useIntl } from 'react-intl';

const DEFAULT_UNIT = 'second';
const DEFAULT_STYLE = 'dhms';

const messages = defineMessages({
    dhms: {
        id: 'basic.formatted-date.DHMS',
        defaultMessage: '{days, number}d {hours, number, ::00}h {minutes, number, ::00}m {seconds, number, ::00}s',
    },
    dhm: {
        id: 'basic.formatted-date.DHM',
        defaultMessage: '{days, number}d {hours, number, ::00}h {minutes, number, ::00}m',
    },
    hms: {
        id: 'basic.formatted-date.HMS',
        defaultMessage: '{hours, number, ::00}h {minutes, number, ::00}m {seconds, number, ::00}s',
    },
    hm: {
        id: 'basic.formatted-date.HM',
        defaultMessage: '{hours, number, ::00}h {minutes, number, ::00}m',
    },
    ms: {
        id: 'basic.formatted-date.MS',
        defaultMessage: '{minutes, number, ::00}m {seconds, number, ::00}s',
    },
    s: {
        id: 'basic.formatted-date.S',
        defaultMessage: '{seconds, number, ::00}s',
    },
    milliseconds: {
        id: 'basic.formatted-date.Milliseconds',
        defaultMessage: '{seconds, plural, =0 {} other {{seconds, number, ::0}s }} {milliseconds, number}ms',
    },
});

export type FormattedDelayStyle = 'dhms' | 'dhm' | 'hm' | 'hms' | 'ms' | 's' | 'milliseconds';

export type FormattedDelayUnit = 'day' | 'hour' | 'minute' | 'second' | 'millisecond';

export interface FormattedDelayProps {
    value: number;
    unit?: FormattedDelayUnit;

    style?: FormattedDelayStyle;
}

export function FormatDelay(props: FormattedDelayProps) {
    const {
        value,
        unit,
        style,
    } = props;

    const intl = useIntl();

    const message = formatDelay(intl, value, unit, style);

    return <>{message}</>;
}

export function formatDelay(intl: IntlShape, value: number, unit: FormattedDelayUnit = DEFAULT_UNIT, style: FormattedDelayStyle = DEFAULT_STYLE) {
    let milliseconds = 0;
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    let days = 0;

    let _unit = unit;
    let _value = value;
    if (_unit === 'millisecond') {
        milliseconds = _value;
        _value = Math.floor(_value / 1000);
        milliseconds -= _value * 1000;
        _unit = 'second';
    }
    if (_unit === 'second') {
        seconds = _value % 60;
        _value = Math.floor(_value / 60);
        _unit = 'minute';
    }
    if (_unit === 'minute') {
        minutes = _value % 60;
        _value = Math.floor(_value / 60);
        _unit = 'hour';
    }
    if (_unit === 'hour') {
        hours = _value % 24;
        _value = Math.floor(_value / 24);
        _unit = 'day';
    }
    if (_unit === 'day') {
        days = _value;
    }

    let _style: string;
    if (days > 0 || style === 'dhms' || style === 'dhm') {
        if (style === 'dhm' || style === 'hm') {
            _style = 'dhm';
        } else {
            _style = 'dhms';
        }
    } else if (hours > 0 || style === 'hms' || style === 'hm') {
        if (style === 'hm') {
            _style = 'hm';
        } else {
            _style = 'hms';
        }
    } else if (minutes > 0 || style === 'ms') {
        _style = 'ms';
    } else {
        _style = 's';

        if (style === 'milliseconds') {
            _style = 'milliseconds';
        }
    }

    const messageDescriptor = (messages as any)[_style] as MessageDescriptor;

    const formattedMessage = intl.formatMessage(messageDescriptor, {
        milliseconds,
        seconds,
        minutes,
        hours,
        days,
    });

    return formattedMessage;
}
