import { Key, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { BreadcrumbSeparator, BreadcrumbSeparatorProps } from './arg-breadcrumb-separator';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { ArgRenderedIcon, ArgRenderedText } from '../types';

import './arg-breadcrumb-item.less';

export interface ArgBreadcrumbItemProps extends BreadcrumbSeparatorProps {
    key: Key;
    icon?: ArgRenderedIcon;
    onClick?: (evt: ButtonClickEvent) => void;
    className?: ClassValue;
    name: ArgRenderedText;
    path?: string;
}

export function ArgBreadcrumbItem(props: ArgBreadcrumbItemProps) {
    const {
        icon,
        onClick,
        className,
        key,
        path,
        name,
        ...separatorProps
    } = props;

    const classNames = useClassNames('arg-breadcrumb-item');
    const navigate = useNavigate();
    const handleClick = useCallback((evt: ButtonClickEvent) => {
        onClick?.(evt);

        if ((onClick && evt.isDefaultPrevented()) || !path) {
            return;
        }

        navigate(path);
    }, [onClick, path, navigate]);

    return (
        <div key={key} className={classNames('&', className)}>
            <BreadcrumbSeparator {...separatorProps} />
            <ArgButton
                type='ghost'
                className={classNames('&-label')}
                onClick={onClick ? handleClick : undefined}
                icon={typeof icon === 'string' ? <ArgIcon name={icon} /> : icon}
                label={name}
            />
        </div>
    );
}
