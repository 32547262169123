import { defineMessages } from 'react-intl';

import { NavItem } from '../models/navigation';

export const VISUAL_IDENTITY_PATH = '/settings/visual-identity';

export const messages = defineMessages({
    visualIdentity: {
        id: 'settings.visual-identity.menu.title',
        defaultMessage: 'Visual identity',
    },
});

export const visualIdentityNavItem: NavItem[] = [
    {
        path: VISUAL_IDENTITY_PATH,
        label: messages.visualIdentity,
        icon: 'icon-travel_explore',
        iconSize: 18,
        children: [],
    } as NavItem,
];

