import { DependencyList, useMemo, useRef } from 'react';

function useDebugMemo__Production<T>(fct: () => T, deps: DependencyList, name?: string): T {
    return useMemo<T>(fct, deps);
}

function useDebugMemo__Debug<T>(fct: () => T, deps: DependencyList, name?: string): T {
    const prev = useRef<DependencyList>();

    const ret = useMemo(() => {
        const p = prev.current;
        if (p && name) {
            console.log('UseDebugMemo', name);
            for (let i = 0; i < deps.length; i++) {
                if (p[i] !== deps[i]) {
                    console.log('UseDebugMemo', name, '  #', i, ' different', 'newValue=', deps[i], 'oldValue=', p[i]);
                }
            }
        }

        prev.current = deps;

        let ret;
        try {
            ret = fct();
        } catch (x) {
            console.error('UseDebugMemo of', name, 'throws error=', x);
            throw x;
        }

        return ret;
    }, deps);

    return ret;
}

export const useDebugMemo = (process.env.NODE_ENV !== 'production') ? useDebugMemo__Debug : useDebugMemo__Production;
