import React, { DragEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import className from 'classnames';
import Debug from 'debug';

import { ArgRenderedIcon, ArgRenderedText, ArgSize } from '../types';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { hashCode } from '../../../utils/color-utils';
import { computeDigram, computeUserName } from '../../../utils/digram';
import { ArgBadge } from '../arg-badge/arg-badge';
import { ArgUserTooltip } from './arg-user-tooltip';
import { BasicTag, BasicTagProps } from '../arg-tag/basic-tag';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';
import { fillFromUser } from '../../common/data-transfer';
import { USER_DND_TYPE } from '../../../model/dnd-types';
import { DragAndDropEffects } from '../arg-dnd/drag-drop';
import { ArgUserInfo } from './arg-user-id';
import { $yield } from '../utils/yield';
import { useIsDragging } from '../utils/use-is-dragging';

import './arg-user.less';

const debug = Debug('basic:arg-users:ArgUser');

const DEFAULT_DRAG_EFFECTS: DragAndDropEffects = 'copyMove';

//
const FORCE_ONLINE = false;

export interface ArgUserProps extends Omit<BasicTagProps, 'size' | 'icon' | 'label'> {
    hidden?: boolean;
    user: ArgUserInfo;
    color?: string;
    size?: ArgSize | 'tiny'; // | 'node';
    //    isOnline?: boolean;
    renderLeft?: (color: string) => ReactNode;
    label?: false | ArgRenderedText;
    icon?: false | ArgRenderedIcon;
    draggable?: boolean;
    dragEffects?: DragAndDropEffects;
    isOnline?: boolean;
}

export function ArgUser(props: ArgUserProps) {
    const {
        hidden,
        user,
        className,
        tooltip,
        tooltipPlacement,
        size,
        label,
        icon,
        draggable,
        dragEffects = DEFAULT_DRAG_EFFECTS,
        isOnline,
        loading,
        ...otherProps
    } = props;

    const classNames = useClassNames('arg-user');

    const username = computeUserName(user);

    const isAnythingDragging = useIsDragging();

    debug('render', 'user=', user, '=> username=', username);

    const [dragging, setDragging] = useState<boolean>(false);

    const color = useMemo<string>(() => {
        const colors = computeUserColors();

        const userHashCode = hashCode(username || String(user.id));

        const color = colors[userHashCode % (colors.length)];

        debug('computeUserColors', 'Username=', username, 'color=', color);

        return color;
    }, [user, username]);

    const digram = useMemo<ReactNode>(() => {
        if ((user as ArgUserInfo).category === 'batch') {
            return <ArgIcon name='icon-computer-laptop' />;
        }

        const digram = computeDigram(username!);

        return digram;
    }, [user, username]);

    const userTooltipRender = useCallback((): ReactNode => {
        return <ArgUserTooltip user={user} />;
    }, [user]);

    const handleDragStart = useCallback((event: DragEvent): void => {
        $yield(() => {
            setDragging(true);
        });

        const params = fillFromUser(event.dataTransfer, user);

        localStorage.setItem(USER_DND_TYPE, params);
        event.dataTransfer.effectAllowed = dragEffects;
    }, [dragEffects, user]);

    const handleDragEnd = useCallback((): void => {
        $yield(() => {
            setDragging(false);
        });

        localStorage.removeItem(USER_DND_TYPE);
    }, []);


    if (hidden) {
        return null;
    }

    const displayName = (user as ArgUserInfo)?.displayName || username;

    let _label = label;
    if (_label === true) {
        _label = displayName;
    }
    if (loading) {
        // Force label to true or false to correclty trigger loading skeleton
        _label = !!_label;
    }

    let _tooltipPlacement = tooltipPlacement;
    let _tooltip: ArgRenderedText = tooltip;
    if (!_tooltip && _tooltip !== false) {
        _tooltip = userTooltipRender();
        if (!_tooltipPlacement) {
            _tooltipPlacement = 'bottomLeft';
        }
    }

    if (size === 'tiny') {
        let comp = <div
            style={{ color }}
            className={classNames(className, '&', '&-tiny')}
        />;

        if (_tooltip && !isAnythingDragging) {
            comp = <ArgTooltip2 title={_tooltip} placement={_tooltipPlacement}>
                {comp}
            </ArgTooltip2>;
        }

        return comp;
    }

    const canBeDragged = draggable;

    const badges: ReactNode[] = [];
    if (isOnline || FORCE_ONLINE) {
        badges.push(<ArgBadge
            position='bottomRight'
            className={classNames('&-badge-online')}
            icon='dot'
            color='green'
            key='online'
            size='large'
        />);
    }

    const cls = {
        dragging,
        draggable: canBeDragged,
    };

    return <BasicTag
        className={classNames(className, '&', cls)}
        icon={icon}
        label={_label}
        tooltip={_tooltip}
        size={size}
        iconBadges={badges}
        avatarDigram={digram}
        iconColor='white'
        backgroundColor={color}
        tooltipPlacement={_tooltipPlacement}
        draggable={canBeDragged}
        onDragStart={canBeDragged && handleDragStart || undefined}
        onDragEnd={canBeDragged && handleDragEnd || undefined}
        loading={loading}
        {...otherProps}
    />;
}

let GLOBAL_USER_COLORS: string[] | undefined = undefined;

export function computeUserColors(): string[] {
    // Compute colors object
    if (GLOBAL_USER_COLORS) {
        return GLOBAL_USER_COLORS;
    }

    function getColor(index: number): string | undefined {
        const colorSpan = document.createElement('span');

        colorSpan.className = className(`user-color-${index + 1}`, 'user-color');

        document.body.appendChild(colorSpan);

        const color = window.getComputedStyle(colorSpan).getPropertyValue('color');

        colorSpan.remove();

        // If color is not set from css (transparent)
        if (color === 'rgba(0, 0, 0, 0)') {
            colorSpan.remove();

            return undefined;
        }

        return color;
    }

    const colors = [];

    // Limit of 50 is a security, we wont have more than 50 colors
    for (let index = 0; index < 50; index++) {
        const color = getColor(index);

        if (!color) {
            break;
        }

        colors[index] = color;
    }

    GLOBAL_USER_COLORS = colors;

    return GLOBAL_USER_COLORS;
}
