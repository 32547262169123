import { MouseEvent } from 'react';
import { pull } from 'lodash';

import { ArgTableColumn3 } from './arg-table3';

const DOUBLE_CLICK_DELAY = 300;
const TRIGGER_MOVE_DELTA = 10;

let isDragging = false;
let doubleClickCount = 0;
let doubleClickTimeout: any;

export function performColumnMove<T>(
    container: HTMLElement,
    column: ArgTableColumn3<T>,
    columns: ArgTableColumn3<T>[],
    otherColumns: ArgTableColumn3<T>[],
    lockedColumns: boolean,
    movableColumn: boolean,
    setColumnsTransforms: (transforms: Record<string, string> | undefined) => void,
    setDragged: (columnKey: string | undefined) => void,
    onDoubleClick: (column: ArgTableColumn3<T>, event: Event) => void,
    event: MouseEvent,
    setColumnIds: (columnIds: string[]) => void,
    onClick?: (column: ArgTableColumn3<T>, event: Event) => void) {
    if (event.button !== 0) {
        return;
    }

    event.preventDefault();

    const startDate = Date.now();

    const columnComponent = container.querySelector(`[data-column="${column.key}"]`) as HTMLElement;
    if (!columnComponent) {
        return;
    }
    const headerComponent = container.querySelector(`[data-header="${column.key}"]`) as HTMLElement;
    if (!headerComponent) {
        return;
    }

    const columnRect = columnComponent.getBoundingClientRect();

    let transforms: Record<string, string> = {};

    const scrollContainer = columnComponent.parentElement as HTMLElement;
    if (!scrollContainer) {
        return;
    }
    const parent = scrollContainer.parentElement as HTMLElement;
    if (!parent) {
        return;
    }
    const parentRect = parent.getBoundingClientRect();

    let beginClientX = event.clientX;

    let timeoutId: any;

    function handleColumnMove(event: Event) {
        const dx = (event as any).clientX - beginClientX;

        if (Math.abs(dx) < TRIGGER_MOVE_DELTA) {
            isDragging = false;

            return;
        }

        setDragged(column.key);

        isDragging = true;

        if (lockedColumns && columns.filter(col => !col.rowHeader).length <= 1 || movableColumn === false) {
            return;
        }

        const newRect = columnComponent.getBoundingClientRect();

        if (newRect.left < parentRect.left && parent.scrollLeft > 0) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                const prev = parent.scrollLeft;
                parent.scrollLeft -= 64;
                beginClientX += prev - parent.scrollLeft;
                handleColumnMove(event);
            }, 150);
        } else if (newRect.right >= parentRect.right && parent.scrollLeft < (scrollContainer.scrollWidth - parentRect.width)) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                const prev = parent.scrollLeft;
                parent.scrollLeft += 64;
                beginClientX += prev - parent.scrollLeft;
                handleColumnMove(event);
            }, 150);
        }

        transforms = {};

        columns.forEach((c) => {
            transforms[c.key] = 'translate(0, 0)';
        });

        transforms[column.key] = `translate(${dx}px, 0)`;

        let newIdx = columns.indexOf(column);
        if (dx < 0) {
            let x = dx;
            const minIdx = (lockedColumns) ? columns.reduce((acc, c) => (acc + (c.rowHeader ? 1 : 0)), 0) : 0;

            for (newIdx--; newIdx >= minIdx && x < -columnRect.width / 3; newIdx--) {
                const c = columns[newIdx];
                const cComponent = container.querySelector(`[data-column="${c.key}"]`);
                if (!cComponent) {
                    break;
                }

                const rect = cComponent.getBoundingClientRect();
                x += rect.width;
                transforms[c.key] = `translate(${columnRect.width}px, 0)`;
            }
        } else if (dx > columnRect.width / 3) {
            let x = dx - columnRect.width / 3;
            for (newIdx++; newIdx < columns.length && x > 0; newIdx++) {
                const c = columns[newIdx];
                const cComponent = container.querySelector(`[data-column="${c.key}"]`);
                if (!cComponent) {
                    break;
                }

                const rect = cComponent.getBoundingClientRect();
                x -= rect.width;

                transforms[c.key] = `translate(${-columnRect.width}px, 0)`;
            }
        }

        setColumnsTransforms(transforms);
    }

    function handleColumnMouseUp(event: Event) {
        event.preventDefault();
        //        event.stopPropagation();

        window.removeEventListener('mousemove', handleColumnMove, true);
        window.removeEventListener('mouseup', handleColumnMouseUp, true);

        const newRect = columnComponent.getBoundingClientRect();

        let insertedColumnIdx = 0;
        let afterColumn = false;
        for (; insertedColumnIdx < columns.length; insertedColumnIdx++) {
            const col = columns[insertedColumnIdx];
            if (col.rowHeader) {
                continue;
            }

            const cid = col.key;
            if (cid === column.key) {
                afterColumn = true;
                continue;
            }

            const cidElement = container.querySelector(`[data-column="${cid}"]`) as Element;
            if (cidElement) {
                const cr = cidElement.getBoundingClientRect();

                if (cr.left < newRect.left) {
                    continue;
                }
                break;
            }
        }

        insertedColumnIdx += (afterColumn) ? -1 : 0;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setColumnsTransforms(undefined);
        setDragged(undefined);

        if (!isDragging) {
            const dt = Date.now() - startDate;
            if (dt < 500) {
                if (doubleClickTimeout) {
                    doubleClickCount++;

                    return;
                }

                doubleClickCount = 1;

                doubleClickTimeout = setTimeout(() => {
                    doubleClickTimeout = undefined;

                    if (doubleClickCount === 1) {
                        onClick && onClick(column, event);

                        return;
                    }

                    onDoubleClick && onDoubleClick(column, event);
                }, DOUBLE_CLICK_DELAY);

                return;
            }
        }

        isDragging = false;

        if (columns[insertedColumnIdx].key === column.key) {
            return;
        }

        let cids: string[] = pull(columns.map((c) => c.key), column.key);
        cids.splice(insertedColumnIdx, 0, column.key);

        if (lockedColumns) {
            cids = [...cids, ...otherColumns.map((c) => c.key)];
        } else {
            cids = [...otherColumns.map((c) => c.key), ...cids];
        }

        setColumnIds(cids);
    }

    window.addEventListener('mousemove', handleColumnMove, true);
    window.addEventListener('mouseup', handleColumnMouseUp, true);
}
