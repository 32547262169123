import { EventEmitter } from '../utils/event-emitter';
import { ArgTableColumn3 } from '../arg-table3/arg-table3';
import { ArgTableRowState } from './arg-table-row-state';

export interface DataProviderEventTypes<T> {
    '*': (stateId: number) => void;
    Loading: (first: number, last: number) => void;
    Loaded: (first: number, last: number, rows: T[]) => void;
    Destroy: (first: number, last: number) => void;
    Cleared: () => void;
    Deleted: (count: number) => void;
    RowCountComputed: (count: number) => void;
    RowCountChanged: (count: number) => void;
    Refresh: () => void;
}

export const OnLoaded = 'Loaded';
export const OnLoading = 'Loading';
export const OnDestroy = 'Destroy';
export const OnCleared = 'Cleared';
export const OnDeleted = 'Deleted';
export const OnRowCountComputed = 'RowCountComputed';
export const OnRowCountChanged = 'RowCountChanged';
export const OnRefresh = 'Refresh';

export interface DataFilter {
    [key: string]: any;
}

export type PropertySorterOrder = 'ascending' | 'descending';

export interface PropertySorter {
    propertyName: string;
    order: PropertySorterOrder;
}

export interface DataSorter {
    propertySorters: PropertySorter[];
}

export interface ArgTableDataProviderColumn<T> {
    key: string;
    sorter?: ((a: T, b: T) => number) | 'fromServer';
}

export { ArgTableRowState } from './arg-table-row-state';

export class DataProvider<T, F extends DataFilter = any> extends EventEmitter<DataProviderEventTypes<T>> {
    #id: string;

    constructor(id: string) {
        super({
            url: `dataProvider:${id}`,
            id: 0,
        });

        this.#id = id;
    }

    get id() {
        return this.#id;
    }

    setFilter(filter?: F, sorter?: DataSorter, columns?: ArgTableDataProviderColumn<T>[]) {

    }

    setSearchTerm(searchTerm: string | undefined) {
    }

    getRow(rowIndex: number): T | ArgTableRowState {
        throw new Error('Not implemented');
    }

    setViewPort(first: number, rowCount: number): void {

    }

    get rowCount(): number | undefined {
        return undefined;
    }
}
