import React, { ReactNode, useContext, useEffect } from 'react';
import Debug from 'debug';

import { PageContext } from './arg-page-context';
import { cloneConfig, PageConfig } from './page-config';

const debug = Debug('argonode:components:ArgPageConfig');

interface ArgsPageConfigProps extends PageConfig {
    children?: ReactNode;
    enabled?: boolean;
}

export function ArgPageConfig(props: ArgsPageConfigProps) {
    const {
        children,
        pageTitle,
        pageSubTitle,
        iconURL,
        subTitlePosition,
        language,
        enabled = true,
    } = props;

    const parentConfigurator = useContext(PageContext);

    useEffect(() => {
        if (!enabled) {
            return;
        }

        const config = cloneConfig(props);

        parentConfigurator.push(config);
        debug('PUSH config', config);

        return () => {
            debug('POP config', config);
            parentConfigurator.pop(config);
        };
    }, [enabled, pageTitle, pageSubTitle, iconURL, subTitlePosition, language]);

    return <React.Fragment>
        {children}
    </React.Fragment>;
}
