import React, { ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as Bowser from 'bowser';

import { KeyCommand } from './keybinding';
import { ArgIcon } from '../arg-icon/arg-icon';

const browser = Bowser.getParser(window.navigator.userAgent);
export const IS_MAC_OS_ENVIRONMENT = browser.getOS()?.name === 'macOS';

if (process.env.NODE_ENV === 'development') {
    console.log('OS name=', browser.getOS()?.name, 'versionName=', browser.getOS()?.versionName, 'version=', browser.getOS().version, 'isMac=', IS_MAC_OS_ENVIRONMENT);
}

export const UNSUPPORTED_COMMAND_KEYS = [
    // Windows reserved
    'Ctrl+C',
    'Ctrl+V',
    'Ctrl+X',

    'Alt+F4',
    'Ctrl+Alt+Delete',

    // Chrome reserved key bindings
    'Ctrl+N',
    'Ctrl+T',
    'Ctrl+W',
];

const messages = defineMessages({
    escape: {
        id: 'basic.keybindings.Escape',
        defaultMessage: 'Escape',
    },
});

export function createKeyCommandFromEvent(event: KeyboardEvent) {
    let keys = '';

    switch (event.key) {
        case 'Control':
        case 'Shift':
        case 'Alt':
        case 'AltGraph':
        case 'Meta':
            return event.key;
        case undefined:
        case null:
            // Occurs when form autocomplete entry is clicked on
            return keys;
    }

    if (event.altKey) {
        keys += 'alt+';
    }
    if (event.ctrlKey || event.metaKey) {
        keys += 'ctrl+';
    }

    if (event.shiftKey) {
        keys += 'shift+';
    }

    let keyName = IS_MAC_OS_ENVIRONMENT ? event.code.replace('Key', '') : event.key;

    if (keyName.length === 1) {
        keyName = keyName.toUpperCase();
    }

    keys += keyName;


    return keys;
}

export function parseKeyCommand(commandKey: KeyCommand | undefined): string[] {
    if (!commandKey) {
        return [];
    }

    if (commandKey.length === 1) {
        return [commandKey];
    }

    if (commandKey.endsWith('+')) {
        return [...parseKeyCommand(commandKey.substring(0, commandKey.length - 2)), '+'];
    }

    return commandKey.split('+').map((key) => {
        switch (key) {
            case 'ArrowLeft':
                return '←';
            case 'ArrowRight':
                return '→';
            case 'ArrowUp':
                return '↑';
            case 'ArrowDown':
                return '↓';
            case 'PageUp':
                return '⇞';
            case 'PageDown':
                return '⇟';
            case 'Home':
                return '↖';
            case 'End':
                return 'End';
            case 'MediaTrackPrevious':
                return '⏮';
            case 'MediaPlayPause':
                return '⏯';
            case 'MediaTrackNext':
                return '⏭';
        }

        return key;
    });
}

export function isExcludedForInput(commandKey: KeyCommand): boolean {
    const cmds = parseKeyCommand(commandKey);

    // Exclude Control to support Ctrl+C, Ctrl+X or Ctrl+V
    if (cmds.includes('alt') || cmds.includes('meta')) {
        return false;
    }

    return true;
}

export function mapSpecialCommand(key: string): ReactNode {
    switch (key) {
        case '🖰':
            return <ArgIcon className='keybinding-mouse' name='icon-mouse' />;

        case 'alt':
        case 'Alt':
            if (IS_MAC_OS_ENVIRONMENT) {
                return <ArgIcon className='keybinding-alt' name='icon-mac-option' />;
            }

            return 'Alt';

        case 'backspace':
        case 'Backspace':
            if (IS_MAC_OS_ENVIRONMENT) {
                return <ArgIcon className='keybinding-backspace' name='icon-mac-delete' />;
            }

            return <ArgIcon className='keybinding-backspace' name='icon-arrow-left' />;

        case 'enter':
        case 'Enter':
            if (IS_MAC_OS_ENVIRONMENT) {
                return <ArgIcon className='keybinding-enter' name='icon-mac-enter' />;
            }

            return <ArgIcon className='keybinding-enter' name='icon-windows-enter' />;

        case 'escape':
        case 'Escape':
            return <FormattedMessage {...messages.escape} />;

        case 'ctrl':
        case 'Ctrl':
        case 'meta':
        case 'Meta':
            if (IS_MAC_OS_ENVIRONMENT) {
                return <ArgIcon className='keybinding-ctrl' name='icon-mac-command' />;
            }

            return 'Ctrl';

        case 'shift':
        case 'Shift':
            return <ArgIcon className='keybinding-shift' name='icon-shift' data-testid='icon-shift' />;
    }

    return key;
}
