import { useRef } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton } from 'src/components/basic/arg-button/arg-button';
import { useCallbackAsync } from 'src/components/basic/arg-hooks/use-callback-async';
import { ClassValue, useClassNames } from 'src/components/basic/arg-hooks/use-classNames';
import { ArgInputText } from 'src/components/basic/arg-input/arg-input-text';
import { useArgNotifications } from 'src/components/basic/arg-notifications/notifications';
import { ProgressMonitor } from 'src/components/basic/progress-monitors/progress-monitor';
import { ArgChangeReason } from 'src/components/basic/types';
import { LatitudeAndLongitudeResponse } from '../../common/model/geolocation-json';
import { GeoLocationValue } from '../../common/model/geolocation-value';
import { ArgGeolocationMap } from './arg-geolocation-map';
import { ArgResizableMapContainer } from '../../common/arg-resizable-map-container';
import { geolocationMessages } from '../../common/common';

import './arg-geolocation-form.less';


const messages = defineMessages({
    addressPlaceholder: {
        id: 'basic.arg-geo-picker.gelocation-picker.form.addressPlaceholder',
        defaultMessage: 'Search a place',
    },
});

interface ArgGeolocationFormProps {
    className?: ClassValue;
    value?: GeoLocationValue;
    onChange?: (value: GeoLocationValue | undefined) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
}

export function ArgGeolocationForm(props: ArgGeolocationFormProps) {
    const {
        value,
        className,
        onChange,
        getAddressCoordinates,
    } = props;

    const classNames = useClassNames('arg-geolocation-form');
    const map = useRef(undefined as L.Map | undefined);

    const notifications = useArgNotifications();

    const [handleAddressChange] = useCallbackAsync(async (progressMonitor: ProgressMonitor, address: string | null, reason?: ArgChangeReason): Promise<void> => {
        if (!address) {
            return;
        }

        if (reason === 'clear') {
            onChange?.(undefined);

            return;
        }

        if (reason === 'blur' || value?.address === address) {
            return;
        }

        try {
            const addressCoordinates = await getAddressCoordinates(address, progressMonitor);
            if (!addressCoordinates) {
                return;
            }

            onChange?.({
                address,
                coordinates: {
                    lat: addressCoordinates.latitude,
                    lng: addressCoordinates.longitude,
                },
            });
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw Error;
            }
            notifications.snackError({ message: geolocationMessages.loadAddressError }, error as Error);
            throw Error;
        }
    }, [getAddressCoordinates, notifications, onChange, value?.address]);

    return (
        <ArgResizableMapContainer map={map} className={className}>
            <div className={classNames('&-header')}>
                {/* Address input */}
                <ArgInputText
                    autoFocus={true}
                    value={value?.address || ''}
                    placeholder={messages.addressPlaceholder}
                    right={
                        <ArgButton
                            type='ghost'
                            icon='icon-map-marker'
                            className={classNames('&-header-input-right')}
                        />
                    }
                    onChange={handleAddressChange}
                    data-testid='arg-geolocation-form-address-input'
                    className={classNames('&-header-input', '&-header-input-address')}
                />
            </div>
            <div className={classNames('&-body')} data-testid='arg-geolocation-form-map-container'>
                <ArgGeolocationMap
                    value={value}
                    forwardRef={map}
                    onChange={onChange}
                    className={classNames('&-body-map')}
                />
            </div>
        </ArgResizableMapContainer>
    );
}
