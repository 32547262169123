import React, { useCallback, useRef, useState } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgButton, ArgButtonProps } from '../arg-button/arg-button';
import { DEFAULT_SIZE } from '../defaults';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgRenderedText } from '../types';
import { ArgPopover } from '../arg-popover/arg-popover';

import './arg-action-dropdown-button.less';

export interface ArgActionDropdownButtonAction {
    key: string;
    label: ArgRenderedText;
    isDisabled?: boolean;
    tooltip?: ArgRenderedText;
    onClick?: (event: MenuInfo) => void;
}

export interface ArgActionDropdownButtonProps extends ArgButtonProps {
    className?: ClassValue;
    isDropDownButtonDisabled?: boolean;
    actions: ArgActionDropdownButtonAction[];
}

export function ArgActionDropdownButton(props: ArgActionDropdownButtonProps) {
    const {
        className,
        popoverClassName,
        actions,
        size = DEFAULT_SIZE,
        disabled,
        isDropDownButtonDisabled = disabled,
    } = props;

    const classNames = useClassNames('arg-action-dropdown-button');
    const buttonsContainerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickActions = useCallback((evt: MenuInfo) => {
        const { key } = evt;

        const action = actions.find((action: ArgActionDropdownButtonAction) => action.key === key);
        action?.onClick?.(evt);
        setIsOpen(false);
    }, [actions]);

    const menuCls = {
        [`size-${size}`]: true,
    };

    const popoverMenu = (
        <ArgMenu
            className={classNames('&-popover-menu', menuCls)}
            data-testid='arg-action-dropdown-button-popover-menu'
            onClick={handleClickActions}
        >
            {
                actions.map((action: ArgActionDropdownButtonAction) => {
                    return (
                        <ArgMenuItem
                            key={action.key}
                            data-testid={`extra-actions-${action.key}`}
                            className={classNames('&-popover-menu-item')}
                            disabled={action.isDisabled}
                            tooltip={action.tooltip}
                        >
                            {renderText(action.label)}
                        </ArgMenuItem>
                    );
                })
            }
        </ArgMenu>
    );

    const iconName = getIconName(isOpen);

    return (
        <div ref={buttonsContainerRef} className={classNames('&', className)}>
            <ArgButton {...props} className={classNames('&-action')} />
            <ArgButton
                data-testid='dropwdown-toggle-button'
                className={classNames('&-dropdown')}
                icon={iconName}
                size={size}
                disabled={isDropDownButtonDisabled}
                popover={popoverMenu}
                popoverPlacement='bottomRight'
                popoverClassName={classNames('&-popover', popoverClassName)}
                onPopoverVisibleChange={setIsOpen}
                popoverVisible={isOpen}
            />
        </div>
    );
}

function getIconName(isOpen: boolean) {
    if (isOpen) {
        return 'icon-cheveron-up';
    }

    return 'icon-cheveron-down';
}
