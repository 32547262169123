import React from 'react';

import { ArgUploaderEngine } from './arg-uploader-context-provider';

export const defaultUploaderEngine: ArgUploaderEngine = {
    add: () => {
    },
    get: () => [],
    clear: () => {
    },
    clearUploaded: () => {
    },
    queue: [],
    uploaded: [],
    current: undefined,
};

export const ArgUploaderContext = React.createContext<ArgUploaderEngine>(defaultUploaderEngine);
