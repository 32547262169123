import { ADMIN_PERMISSIONS_REQUIRED } from 'src/administration/permissions/permissions';
import { EXPLORATION_PERMISSIONS_REQUIRED } from 'src/exploration/model/permissions';
import { PREPARATION_PERMISSIONS_REQUIRED } from 'src/preparation/permissions/permissions';
import { SETTINGS_PERMISSIONS_REQUIRED } from 'src/settings/permissions/permissions';
import { ADMINISTRATION_ROOT_URL, COLLECT_ROOT_URL, EXPLORATION_ROOT_URL, PREPARATION_ROOT_URL, PROCEO_ROOT_URL, SETTINGS_ROOT_URL } from '../containers/routes-urls';
import { Environment } from '../../utils/environment';

export type ModuleType =
    | 'DataExploration'
    | 'DataPreparation'
    | 'DataCollect'
    | 'Administration'
    | 'Settings'
    | 'Proceo';

export interface ArgonosModule {
    url: string;
    enabled: boolean;
    requiredPermissions: string[];
    icon: string;
}

const DISABLE_DATA_COLLECT = true;

export const MODULES: Record<ModuleType, ArgonosModule> = {
    Administration: {
        url: ADMINISTRATION_ROOT_URL,
        enabled: Environment.isAdministrationEnabled(),
        requiredPermissions: ADMIN_PERMISSIONS_REQUIRED,
        icon: 'icon-cog',
    },
    Settings: {
        url: SETTINGS_ROOT_URL,
        enabled: Environment.isSettingsEnabled(),
        requiredPermissions: SETTINGS_PERMISSIONS_REQUIRED,
        icon: 'icon-cog',
    },
    DataExploration: {
        url: EXPLORATION_ROOT_URL,
        enabled: Environment.isDataExplorationEnabled(),
        requiredPermissions: EXPLORATION_PERMISSIONS_REQUIRED,
        icon: Environment.dataExplorationIcon,
    },
    DataPreparation: {
        url: PREPARATION_ROOT_URL,
        enabled: Environment.isDataPreparationEnabled(),
        requiredPermissions: PREPARATION_PERMISSIONS_REQUIRED,
        icon: Environment.dataPreparationIcon,
    },
    DataCollect: {
        url: COLLECT_ROOT_URL,
        enabled: DISABLE_DATA_COLLECT ? false : Environment.isDatCollectEnabled(),
        requiredPermissions: [],
        icon: 'icon-shrink',
    },
    Proceo: {
        url: PROCEO_ROOT_URL,
        enabled: Environment.isProceoEnabled(),
        requiredPermissions: [],
        icon: Environment.proceoIcon,
    },
};
