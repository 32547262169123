import React from 'react';

export interface DraggingEvent {
    types: readonly string[];
}

export interface DragDropContextProps {
    dragging: boolean;
    draggingEvent?: DraggingEvent;
}

export const DragDropContext = React.createContext<DragDropContextProps>({
    dragging: false,
    draggingEvent: undefined,
});
