import { useEffect, useMemo, useRef } from 'react';
import { forEach } from 'lodash';
import Debug from 'debug';

import { ToolContext, ToolTreeContext, ToolTreeNode } from './tool-context';
import { useContextUpdate } from './use-context-update';

const debug = Debug('basic:arg-toolbar:use-tool-nodes');

export function useToolNodes(toolContext: ToolContext, prefix?: string, removeEditors?: boolean): [ToolTreeNode[], ToolTreeContext] {
    useContextUpdate(toolContext);

    const treeContextRef = useRef<ToolTreeContext>();
    if (!treeContextRef.current) {
        treeContextRef.current = {
            fetchedTools: {},
        };
    }

    const tree = useMemo<ToolTreeNode[]>(() => {
        const tree = toolContext.computeTree(treeContextRef.current, prefix, removeEditors);

        debug('memoToolTreeNode', 'stateId=', toolContext.stateId, 'prefix=', prefix, 'tree=', tree);

        return tree;
    }, [toolContext.stateId, prefix, removeEditors]);

    useEffect(() => {
        debug('mount', 'toolContext=', toolContext);

        toolContext.mount();

        return () => {
            debug('unmount', 'toolContext=', toolContext);

            const pm = treeContextRef.current?.fetchedTools;

            forEach(pm, (p) => {
                p.progressMonitor?.cancel();
            });

            toolContext.onUnmount();
        };
    }, [toolContext]);

    return [tree, treeContextRef.current];
}
