import { createContext, ReactNode, useContext } from 'react';
import { first, isEmpty } from 'lodash';

import { ArgMapTiles, ArgMapTilesId } from '../model/arg-map-tiltes';
import { ProgressMonitor } from '../../../progress-monitors/progress-monitor';


export type GetMapTilesReturn = [ArgMapTiles[] | undefined, ProgressMonitor | undefined, Error | undefined];

export interface ArgMapTilesConfiguration {
    getMapTiles(): GetMapTilesReturn;

    getComponentMapTilesId(): ArgMapTilesId;
}

const ArgMapTilesContext = createContext<ArgMapTilesConfiguration | undefined>(undefined);

interface ArgMapContextProviderProps {
    children: ReactNode;
    configuration: ArgMapTilesConfiguration;
}

export function ArgMapTilesContextProvider(props: ArgMapContextProviderProps) {
    const {
        children,
        configuration,
    } = props;

    return <ArgMapTilesContext.Provider value={configuration}>
        {children}
    </ArgMapTilesContext.Provider>;
}


export function useArgMapTiles(): [ArgMapTiles[] | undefined, ProgressMonitor | undefined, Error | undefined] {
    const context = useContext(ArgMapTilesContext);
    if (!context) {
        throw new Error('ArgMapTiles context is not defined');
    }

    const ret = context.getMapTiles();

    return ret;
}

export function useComponentArgMapTiles(): [ArgMapTiles | undefined, ProgressMonitor | undefined, Error | undefined] {
    const context = useContext(ArgMapTilesContext);
    if (!context) {
        throw new Error('ArgMapTiles context is not defined');
    }

    const ret = context.getMapTiles();

    const [mapTilesList, progressMonitor, error] = ret;

    if (isEmpty(mapTilesList)) {
        return [undefined, progressMonitor, error];
    }

    let mapTiles: ArgMapTiles | undefined = undefined;

    const mainMapTilesId = context.getComponentMapTilesId();
    if (mainMapTilesId) {
        mapTiles = mapTilesList!.find((p) => p.id === mainMapTilesId);
    }

    mapTiles = first(mapTilesList)!;

    return [mapTiles, undefined, undefined];
}
