import { defineMessages } from 'react-intl';

import { ArgErrorsMap, ArgMessageValues, ArgRenderedText } from '../components/basic';
import { getGlobalNotifications } from '../components/basic/arg-notifications/notifications';
import { isResponseError } from '../components/basic/utils/response-error';

const messages = defineMessages({
    problemMessage: {
        id: 'common.alertError.problem.Message',
        defaultMessage: 'Problem',
    },
    problemDescription: {
        id: 'common.alertError.problem.Description',
        defaultMessage: 'Oops, something went wrong ...',
    },
    forbiddenGenericMessage: {
        id: 'common.alertError.problem.forbiddenGenericMessage',
        defaultMessage: 'You have insufficient privilege to perform this action',
    },
    notFoundGenericMessage: {
        id: 'common.alertError.problem.NotFoundGenericMessage',
        defaultMessage: 'Resource not found',
    },
});

export function notifyCustomMessage(message: ArgRenderedText, description?: ArgRenderedText, messageValues?: ArgMessageValues, error?: Error) {
    getGlobalNotifications().snackError({ message }, error, messageValues);
}

export function handle403Error() {
    notifyCustomMessage(messages.forbiddenGenericMessage);
}

export function handle404Error() {
    notifyCustomMessage(messages.notFoundGenericMessage);
}

export function handleDefaultError() {
    notifyCustomMessage(messages.problemMessage, messages.problemDescription);
}

export function notifyErrorMessage(error?: Error, errorMap?: ArgErrorsMap, messageValues?: ArgMessageValues): boolean {
    if (!isResponseError(error)) {
        return false;
    }

    const problemDetails = error.problemDetails;

    if (!problemDetails) {
        return false;
    }

    const registeredError = errorMap?.[problemDetails.type];
    if (!registeredError) {
        return false;
    }

    const errorMessageValues = { ...messageValues, ...problemDetails };

    notifyCustomMessage(registeredError.title, registeredError.description, errorMessageValues, error);

    return true;
}
