import { KeyboardEvent, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';
import { filter, find, forEach, isEqual, pull } from 'lodash';

import { EventEmitter } from '../utils/event-emitter';
import { immutableSet } from '../utils/immutable-set';

export type KeyBindingIdentifier = string;

export type KeyBindingsScopeIdentifier = string;

export type KeyBindingHandler = (event: KeyboardEvent) => void;

export interface KeyBindingOptions {
    preventDefault?: boolean;
}

export const NO_PREVENT_DEFAULT: KeyBindingOptions = { preventDefault: false };

export type KeyCommand = string;

export const GLOBAL_SCOPE_ID = 'global';

export function defineKeyBindings<K extends keyof any, T = KeyBindingDescriptor, U extends Record<K, T> = Record<K, T>>(keyBindings: U): U {
    return keyBindings;
}

export interface KeyBindingScopeDescriptor {
    id: KeyBindingsScopeIdentifier;
    name: MessageDescriptor;
    description?: MessageDescriptor;
    icon?: string | (() => ReactNode);

    extends?: KeyBindingScopeDescriptor;
    hidden?: boolean;
}

export interface KeyBindingDescriptor {
    id: KeyBindingIdentifier;
    scope: KeyBindingScopeDescriptor;
    name: MessageDescriptor;
    description?: MessageDescriptor;
    icon?: string | (() => ReactNode);
    locked?: boolean;
    global?: boolean;

    defaultKeys?: KeyCommand; // ctrl+A   shift+alt++
}

export interface KeyBindingWithHandler {
    def: KeyBindingDescriptor;
    handler: KeyBindingHandler;
    options?: KeyBindingOptions;
}

export interface KeyBindingsConfiguration {
    scopes: {
        id: KeyBindingsScopeIdentifier,
        bindings: {
            id: KeyBindingIdentifier;
            keys: KeyCommand;
        }[]
    }[];
}

interface KeyBindingEventTypes {
    UserConfigChanged(config?: KeyBindingsConfiguration): void;
}

export class KeyBindingsScopeDefs {
    readonly #parent?: KeyBindingsScopeDefs;
    readonly #scope: KeyBindingScopeDescriptor;
    #children?: KeyBindingsScopeDefs[];
    #handlers?: KeyBindingWithHandler[];
    readonly #inherited?: boolean;

    readonly #defs?: Record<KeyBindingsScopeIdentifier, KeyBindingDescriptor>;
    #config?: KeyBindingsConfiguration;

    #cachedList?: KeyBindingWithHandler[];

    readonly #eventEmitter?: EventEmitter<KeyBindingEventTypes>;

    constructor(scope: KeyBindingScopeDescriptor, parent?: KeyBindingsScopeDefs, defs?: Record<KeyBindingsScopeIdentifier, KeyBindingDescriptor>, inherited?: boolean) {
        this.#parent = parent;
        this.#scope = scope;
        this.#inherited = inherited;

        this.#defs = defs;

        if (!parent) {
            this.#eventEmitter = new EventEmitter<KeyBindingEventTypes>();
        }
    }

    get scope(): KeyBindingScopeDescriptor {
        return this.#scope;
    }

    get root(): KeyBindingsScopeDefs {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let t: KeyBindingsScopeDefs | undefined = this;
        for (; t.#parent; t = t.#parent!) ;

        return t;
    }

    get children(): readonly KeyBindingsScopeDefs[] {
        if (!this.#children) {
            return [];
        }

        return this.#children;
    }

    get eventEmitter(): EventEmitter<KeyBindingEventTypes> {
        return this.root.#eventEmitter!;
    }

    get defs(): Record<KeyBindingsScopeIdentifier, KeyBindingDescriptor> {
        return this.root.#defs || {};
    }

    private clearCache() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        for (let p: KeyBindingsScopeDefs | null = this; p.#parent; p = p.#parent) {
            p.#cachedList = undefined;
        }
    }

    addChild(child: KeyBindingsScopeDefs) {
        if (!this.#children) {
            this.#children = [];
        }
        this.#children.push(child);

        this.clearCache();
    }

    removeChild(child: KeyBindingsScopeDefs) {
        if (!this.#children?.length) {
            return;
        }
        pull(this.#children, child); // Mutate array

        this.clearCache();
    }

    addKeyBinding(def: KeyBindingDescriptor, handler: KeyBindingHandler, options?: KeyBindingOptions) {
        if (!this.#handlers) {
            this.#handlers = [];
        }

        this.#handlers.push({
            def,
            handler,
            options,
        });

        this.clearCache();
    }

    removeKeyBinding(def: KeyBindingDescriptor, handler: KeyBindingHandler) {
        if (!this.#handlers) {
            return;
        }

        const newHandlers = this.#handlers.filter((h) => {
            if (h.def === def && h.handler === handler) {
                return false;
            }

            return true;
        });

        this.#handlers = newHandlers;

        this.clearCache();
    }

    listHandlers(list?: KeyBindingWithHandler[]): KeyBindingWithHandler[] {
        if (this.#cachedList) {
            //  return this.#cachedList;
        }

        if (!list) {
            list = [];
            this.#cachedList = list;
        }

        forEach(this.#children, (child) => {
            child.listHandlers(list);
        });

        if (this.#handlers?.length) {
            list.push(...this.#handlers);
        }

        return list;
    }

    listScopes(list: KeyBindingsScopeDefs[] = []): KeyBindingsScopeDefs[] {
        list.push(this);

        forEach(this.#children, (child) => {
            child.listScopes(list);
        });

        return list;
    }

    getNotInherited(): KeyBindingsScopeDefs | undefined {
        if (this.#children) {
            const found = this.#children.find((child) => {
                return child.getNotInherited();
            });
            if (found) {
                return found;
            }
        }

        if (this.#inherited === false) {
            return this;
        }

        return undefined;
    }

    computeKeyCommand(keyBinding: KeyBindingDescriptor, scope?: KeyBindingScopeDescriptor): KeyCommand | undefined {
        const config = this.root.#config;
        if (!config) {
            return keyBinding.defaultKeys;
        }

        if (!scope) {
            scope = keyBinding.scope;
        }

        const configScope = find(config.scopes, (s) => s.id === scope!.id);
        if (!configScope) {
            return keyBinding.defaultKeys;
        }

        const binding = find(configScope.bindings, (b) => b.id === keyBinding.id);
        if (!binding) {
            return keyBinding.defaultKeys;
        }

        return binding.keys;
    }

    setUserConfig(newConfig?: KeyBindingsConfiguration): boolean {
        const config = this.root.#config;

        if (isEqual(config, newConfig)) {
            return false;
        }

        this.root.#config = newConfig;

        this.eventEmitter.emit('UserConfigChanged', newConfig);

        return true;
    }

    getUserConfig(): KeyBindingsConfiguration | undefined {
        return this.root.#config;
    }

    resetUserConfig(scope: KeyBindingScopeDescriptor, keyBindingDescriptor: KeyBindingDescriptor): boolean {
        const config = this.root.#config;

        if (!config) {
            return false;
        }

        let newConfig = config;

        if (keyBindingDescriptor.defaultKeys) {
            const sameKey = listSameKeyCommand(scope, this.defs, config, keyBindingDescriptor.defaultKeys);
            if (sameKey && sameKey?.id !== keyBindingDescriptor.id) {
                newConfig = this._changeUserConfig(newConfig, scope, sameKey, '')!;
            }
        }

        newConfig = this._resetUserConfig(newConfig, scope, keyBindingDescriptor);
        if (newConfig === config) {
            return false;
        }

        const ret = this.setUserConfig(newConfig);

        return ret;
    }

    private _resetUserConfig(config: KeyBindingsConfiguration, scope: KeyBindingScopeDescriptor, keyBindingDescriptor: KeyBindingDescriptor): KeyBindingsConfiguration {
        const scopeIndex = config.scopes.findIndex((s) => s.id === scope.id);
        if (scopeIndex < 0) {
            return config;
        }

        const configScope = config.scopes[scopeIndex];

        const bIndex = configScope.bindings.findIndex((b) => b.id === keyBindingDescriptor.id);
        if (bIndex < 0) {
            return config;
        }
        let newConfig;
        if (configScope.bindings.length === 1) {
            const newScopes = [...config.scopes];
            newScopes.splice(scopeIndex, 1);

            newConfig = immutableSet(config, 'scopes', newScopes);
        } else {
            const newList = [...configScope.bindings];
            newList.splice(bIndex, 1);

            newConfig = immutableSet(config, ['scopes', scopeIndex, 'bindings'], newList);
        }

        return newConfig;
    }


    changeUserConfig(scope: KeyBindingScopeDescriptor, keyBindingDescriptor: KeyBindingDescriptor, keyCommand: KeyCommand | undefined): boolean {
        const config = this.root.#config;
        let newConfig = config;

        if (keyCommand) {
            const sameKey = listSameKeyCommand(scope, this.defs, config, keyCommand);
            if (sameKey && sameKey?.id !== keyBindingDescriptor.id) {
                newConfig = this._changeUserConfig(newConfig, scope, sameKey, '');
            }
        }

        newConfig = this._changeUserConfig(newConfig, scope, keyBindingDescriptor, keyCommand);
        if (newConfig === config) {
            return false;
        }

        const ret = this.setUserConfig(newConfig);

        return ret;
    }

    private _changeUserConfig(config: KeyBindingsConfiguration | undefined, scope: KeyBindingScopeDescriptor, keyBindingDescriptor: KeyBindingDescriptor, keyCommand: KeyCommand | undefined): KeyBindingsConfiguration | undefined {
        if (!config) {
            if (keyCommand === undefined) {
                return config;
            }
            const newConfig = {
                scopes: [{
                    id: scope.id,
                    bindings: [{
                        id: keyBindingDescriptor.id,
                        keys: keyCommand,
                    }],
                }],
            };

            return newConfig;
        }

        const scopeIndex = config.scopes.findIndex((s) => s.id === scope.id);
        if (scopeIndex < 0) {
            if (keyCommand === undefined) {
                return config;
            }

            const newConfig = immutableSet(config, ['scopes', config.scopes.length], {
                id: scope.id,
                bindings: [{
                    id: keyBindingDescriptor.id,
                    keys: keyCommand,
                }],
            });

            return newConfig;
        }

        const configScope = config.scopes[scopeIndex];

        const bIndex = configScope.bindings.findIndex((b) => b.id === keyBindingDescriptor.id);
        if (bIndex < 0) {
            const newConfig = immutableSet(config, ['scopes', scopeIndex, 'bindings', configScope.bindings.length], {
                id: keyBindingDescriptor.id,
                keys: keyCommand,
            });

            return newConfig;
        }

        if (configScope.bindings[bIndex].keys === keyCommand) {
            return config;
        }

        const newConfig = immutableSet(config, ['scopes', scopeIndex, 'bindings', bIndex, 'keys'], keyCommand);

        return newConfig;
    }
}

function listSameKeyCommand(currentScope: KeyBindingScopeDescriptor, keys: Record<KeyBindingsScopeIdentifier, KeyBindingDescriptor>, userConfig: KeyBindingsConfiguration | undefined, keyCommand: KeyCommand) {
    const ks = filter(keys, (k) => {
        for (let scope: KeyBindingScopeDescriptor | undefined = currentScope; scope; scope = scope.extends) {
            if (scope.id === k.scope.id) {
                return true;
            }
        }

        return false;
    });

    const userScopeConfig = userConfig?.scopes.find((s) => s.id === currentScope.id);

    const found = ks.find((k) => {
        const found = userScopeConfig?.bindings.find((b) => b.id === k.id);
        if (found) {
            if (found.keys === keyCommand) {
                return k;
            }

            return undefined;
        }

        if (k.defaultKeys === keyCommand) {
            return k;
        }

        return undefined;
    });

    return found;
}
