import React, { useCallback } from 'react';

import { ArgIcon } from '../arg-icon/arg-icon';
import { useClassNames } from '../arg-hooks/use-classNames';
import { CalendarMode } from './arg-calendar';
import { dayjs } from '../utils/dayjs';

import './arg-calendar-header.less';

export interface ArgCalendarHeaderProps {
    value?: dayjs.Dayjs;
    mode?: CalendarMode;
    onChange: (date: dayjs.Dayjs) => void;
    onTypeChange?: (type: CalendarMode) => void;
}

export function ArgCalendarHeader(props: ArgCalendarHeaderProps) {
    const {
        value,
        mode,
        onChange,
        onTypeChange,
    } = props;

    const classNames = useClassNames('arg-calendar-header');

    const handleNextMonthChange = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (mode === 'decade') {
            return;
        }

        onChange((dayjs(value).isValid() ? dayjs(value) : dayjs()).add(1, mode));
    }, [value, mode, onChange]);

    const setPreviousMonth = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (mode === 'decade') {
            return;
        }

        onChange((dayjs(value).isValid() ? dayjs(value) : dayjs()).subtract(1, mode));
    }, [value, mode, onChange]);

    return (
        <div className={classNames('&')} data-testid='arg-calendar-header'>
            {/* Go to previous month / year */}
            <button
                type='button'
                onClick={setPreviousMonth}
                className={classNames('&-button')}
                data-testid='arg-calendar-header-previous'
            >
                <ArgIcon name='icon-triangle-left' />
            </button>

            <div className={classNames('&-range')}>
                {/* Current month */}
                {mode !== 'year' && (
                    <button
                        type='button'
                        onClick={() => onTypeChange && onTypeChange('year')}
                        className={classNames('&-button')}
                        data-testid='arg-calendar-header-month'
                    >
                        {(dayjs(value).isValid() ? dayjs(value) : dayjs()).format('MMM')}
                    </button>
                )}

                {/* Current year */}
                <button
                    type='button'
                    onClick={() => onTypeChange && onTypeChange('decade')}
                    className={classNames('&-button')}
                    data-testid='arg-calendar-header-year'
                >
                    {(dayjs(value).isValid() ? dayjs(value) : dayjs()).get('year')}
                </button>
            </div>

            {/* Go to next month / year */}
            <button
                type='button'
                onClick={handleNextMonthChange}
                className={classNames('&-button')}
                data-testid='arg-calendar-header-next'
            >
                <ArgIcon name='icon-triangle-right' />
            </button>
        </div>
    );
}
