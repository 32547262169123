import { Navigate, Route, Routes } from 'react-router-dom';

import { Logout } from './components/features/logout/logout';
import { Login } from './components/features/login/login';
import { AuthenticatedAppsRouter } from './components/containers/authenticated-apps-router';

export function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='login' element={<Navigate to='/' replace={true} />} />
            <Route path='logout' element={<Logout />} />
            <Route path='logout' element={<Logout />} />
            <Route path='*' element={<AuthenticatedAppsRouter />} />
        </Routes>
    );
}

