import React, { DragEvent, MouseEvent, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isArray, isFunction, isObject, isString } from 'lodash';
import { isElement } from 'react-is';
import { MessageDescriptor } from 'react-intl';

import { ArgButton, ButtonClickEvent } from '../arg-button/arg-button';
import { DndAction, Droppable, DroppableProvided, DroppableStateSnapshot } from '../arg-dnd/droppable';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgTab, ArgTabAction, ArgTabKey, ArgTabMenuItems, ArgTagRenderTooltip } from './arg-tabs-types';
import { ArgTabMenu } from './arg-tab-menu';
import { ArgTabsContext, DND_TAB_PROPERTY_NAME } from './arg-tabs';
import { ArgIcon } from '../arg-icon/arg-icon';
import { $yield } from '../utils/yield';
import { ArgMessageValues, ArgRenderedText } from '../types';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgDndCleanUp } from '../arg-dnd/types';
import { KeyBindingDescriptor } from '../keybindings/keybinding';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';
import { KeyBindingTooltipContent } from '../keybindings/keybinding-tooltip-content';

const DISABLE_TOOLTIP = false;
const DISABLE_DROP = false;
const FORCE_UNDERLINE_COLOR = false;

export interface ArgTabTitleProps {
    className?: ClassValue;
    tab: ArgTab;
    selected?: boolean;
    closable?: boolean;
    icon?: ReactNode;
    title: ArgRenderedText;
    additional?: ReactNode
    onChange: (tabKey: ArgTabKey | undefined, action: ArgTabAction) => void;
    dropAction?: DndAction;
    menu?: ArgTabMenuItems | ReactNode;

    tooltip?: boolean | ReactNode | ArgTagRenderTooltip | MessageDescriptor;
    description?: ReactNode;

    getBodyElement: (tabKey: ArgTabKey) => HTMLElement | undefined;

    fillDataTransfer?: (dataTransfer: DataTransfer) => ArgDndCleanUp | undefined;

    badge?: ReactNode;
    underlineColor?: string;

    messageValues?: ArgMessageValues;
    activeKeyBinding?: KeyBindingDescriptor;
}

export function ArgTabTitle(props: ArgTabTitleProps) {
    const {
        tab,
        className,
        selected,
        title,
        icon,
        closable,
        onChange,
        menu,
        dropAction,
        tooltip,
        description,
        getBodyElement,
        additional,
        fillDataTransfer,
        badge,
        underlineColor,
        messageValues,
        activeKeyBinding,
    } = props;

    const { key: tabKey, hideTitleLabel, draggable = true } = tab;

    const classNames = useClassNames('arg-tabs-tab');

    const tabsContext = useContext(ArgTabsContext)!;
    const {
        registerTab,
    } = tabsContext;

    const _title = renderText(title, messageValues);

    useEffect(() => {
        registerTab(tabKey, icon, _title, description);
    }, [registerTab, tabKey, icon, _title, description]);

    let _menu: ReactNode;
    if (isElement(menu)) {
        _menu = menu;
    } else if (isObject(menu) || isArray(menu)) {
        _menu = <ArgTabMenu
            key='menu'
            items={menu as ArgTabMenuItems}
            className={classNames('&-menu', '&-actions-item', 'hide-size-menu')}
        />;
    }

    const handleRenderTitle = useCallback(() => {
        if (isFunction(tooltip)) {
            return tooltip(tab);
        }
        if (isElement(tooltip) || tooltip === false) {
            return tooltip;
        }

        const _tooltip = (tooltip === true) ? title : tooltip;

        return (
            <KeyBindingTooltipContent
                tooltip={_tooltip}
                keyBinding={activeKeyBinding}
                messageValues={messageValues}
            />
        );
    }, [tab, tooltip, messageValues, activeKeyBinding, title]);

    const [dragging, setDragging] = useState<boolean>();

    const cls = {
        selected,
        dragging,
    };

    const handleTitleClick = useCallback((event: MouseEvent) => {
        if (event.defaultPrevented) {
            return;
        }
        event.preventDefault();

        onChange?.(tabKey, ArgTabAction.Show);
    }, [onChange, tabKey]);

    const handleTabClose = useCallback((event: ButtonClickEvent) => {
        onChange?.(tabKey, ArgTabAction.Close);
    }, [onChange, tabKey]);

    const cleanUpRef = useRef<ArgDndCleanUp | undefined>(undefined);

    const handleDragStart = useCallback((event: DragEvent) => {
        $yield(() => {
            setDragging(true);
        });

        if (cleanUpRef.current) {
            cleanUpRef.current();
            cleanUpRef.current = undefined;
        }

        event.dataTransfer.effectAllowed = 'copy';

        event.dataTransfer.setData('application/arg-tab', tabKey);
        localStorage.setItem(DND_TAB_PROPERTY_NAME, tabKey);

        const cleanUp = fillDataTransfer?.(event.dataTransfer);

        cleanUpRef.current = cleanUp;
    }, [fillDataTransfer, tabKey]);

    const handleDragEnd = useCallback((event: DragEvent) => {
        $yield(() => {
            setDragging(false);
        });
        localStorage.removeItem(DND_TAB_PROPERTY_NAME);

        if (cleanUpRef.current) {
            cleanUpRef.current();
            cleanUpRef.current = undefined;
        }
    }, []);

    let _icon = icon;
    if (isString(icon)) {
        _icon = <ArgIcon
            className={classNames('&-title-icon')}
            name={icon}
        />;
    }

    let buttonStyle: any = undefined;
    if (underlineColor || FORCE_UNDERLINE_COLOR) {
        buttonStyle = {
            '--arg-tab-underline-color': underlineColor || FORCE_UNDERLINE_COLOR,
        };
    }

    const clsRound = {
        selected,
    };

    const buttonCls = {
        'has-no-title-label': !!hideTitleLabel,
    };

    let button = (
        <button
            key='title'
            type='button'
            data-testid='button-title'
            className={classNames('&-title', buttonCls)}
            onClick={handleTitleClick}
            style={buttonStyle}
        >
            {_icon && <div className={classNames('&-title-iconContainer')}>
                {_icon}
            </div>}
            {_title && hideTitleLabel !== true &&
                <div className={classNames('&-title-text', icon ? 'hide-size-title' : undefined)}>
                    {_title}
                </div>}
            {additional && <div className={classNames('&-title-additional', icon ? 'hide-size-close' : undefined)}>
                {additional}
            </div>}
            {(underlineColor || FORCE_UNDERLINE_COLOR) &&
                <div className={classNames('&-title-underline', 'hide-size-title')} />
            }
        </button>
    );

    if (!DISABLE_TOOLTIP && tooltip) {
        button = <ArgTooltip2 title={handleRenderTitle}>
            {button}
        </ArgTooltip2>;
    }

    const comp = <>
        {button}
        {_menu}
        {closable !== false && <ArgButton
            key='close'
            data-testid='button-close'
            className={classNames('&-close', 'hide-size-close')}
            type='ghost'
            size='medium'
            icon='icon-cross'
            onClick={handleTabClose}
        />}

        {badge && <div className={classNames('&-badge', 'hide-size-badge')}>
            {badge}
        </div>}

        <div className={classNames('&-round-left', clsRound)} />
        <div className={classNames('&-round-right', clsRound)} />

    </>;

    const droppableBody = comp;

    const droppable = <Droppable
        actions={dropAction}
        className={classNames('&', className, cls)}
        data-tabs={tabKey}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        draggable={draggable}
    >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot | undefined) => {
            return <>
                {droppableBody}
                <div className='drop-outline' />
            </>;
        }}
    </Droppable>;

    return droppable;
}
