import _, { CollectionChain } from 'lodash';
import Debug from 'debug';

import syncWorker from '../../../workers/sync-worker';
import { SyncWorkerMessage } from '../../../workers/sync-worker-message';
import { EventEmitter } from '../utils/event-emitter';

export type GetSelectedItemKey<T> = (item: T) => string;

export type SelectionSource = string;
export const OnChange = 'Change';

export interface SelectionState {
    selection: Record<string, true>;
    countKeys: number;
    source: SelectionSource;
}

export interface SelectionProviderEventTypes {
    Change: (source: string) => void;
}

const debug = Debug('argonode:components:features:SelectionProvider');

export class SelectionProvider<T, S extends SelectionState = SelectionState> extends EventEmitter<SelectionProviderEventTypes> {
    protected getSelectedItemKey: GetSelectedItemKey<T>;
    #selection: Record<string, true> = {};
    #countKeys = 0;
    #cachedKeys: string[] | undefined;
    readonly #id: string;
    #synced?: boolean;

    constructor(id: string, getSelectedItemKey: GetSelectedItemKey<T>) {
        super();

        this.#id = id;
        this.getSelectedItemKey = getSelectedItemKey;
    }

    // You must not call this function, internal USE ONLY
    start() {
        this.#synced = true;
        debug('Started with id: %s', this.#id);
        syncWorker.registerHandler(SyncWorkerMessage.SelectionChanged, this.onSelectionChanged);
    }

    // You must not call this function, internal USE ONLY
    stop() {
        this.#synced = false;
        debug('Stopped with id: %s', this.#id);
        syncWorker.unregisterHandler(SyncWorkerMessage.SelectionChanged, this.onSelectionChanged);
    }

    protected getState(source: SelectionSource): S {
        return {
            selection: this.#selection,
            countKeys: this.#countKeys,
            source,
        } as S;
    }

    protected setState(payload: S): void {
        this.#selection = payload.selection;
        this.#countKeys = payload.countKeys;
        this.#cachedKeys = undefined;
    }

    protected changeState(source: SelectionSource) {
        const payload = this.getState(source);
        if (!this.#synced || localStorage?.DISABLED_SELECTION_WORKERS) {
            this.onSelectionChanged(payload, this.#id);

            return;
        }
        syncWorker.sendMessage(SyncWorkerMessage.SelectionChanged, payload, this.#id);
    }

    private onSelectionChanged = (payload: S, id?: string) => {
        if (id !== this.#id) {
            return;
        }

        this.setState(payload);
        this.updateStateId();
        this.emit(OnChange, payload.source);
    };

    add(selection: T | T[] | string | string[], source: SelectionSource): boolean {
        const changed = this.doAdd(selection);
        if (changed) {
            this.changeState(source);
        }

        return changed;
    }

    protected doAdd(selection: T | T[] | string | string[]) {
        debug('add', 'selection=', selection);
        if (Array.isArray(selection)) {
            let changed = false;

            selection.forEach((item: T | string) => {
                const key = typeof (item) === 'string' ? item : this.getSelectedItemKey(item);

                if (this.#selection[key]) {
                    return;
                }
                if (!changed) {
                    changed = true;
                    this.#selection = { ...this.#selection };
                }
                this.#selection[key] = true;
                this.#countKeys++;
            });
            if (!changed) {
                return false;
            }
        } else {
            const key = typeof (selection) === 'string' ? selection : this.getSelectedItemKey(selection);

            if (this.#selection[key]) {
                return false;
            }
            this.#selection = {
                ...this.#selection,
                [key]: true,
            };
            this.#countKeys++;
        }

        this.#cachedKeys = undefined;

        return true;
    }

    set(selection: T | T[] | string | string[], source: SelectionSource): boolean {
        debug('set', 'selection=', selection, 'source=', source);
        const changed = this.doSet(selection);

        if (changed) {
            this.changeState(source);
        }

        return changed;
    }

    protected doSet(selection: T | T[] | string | string[]) {
        debug('set', 'selection=', selection);
        if (Array.isArray(selection)) {
            let changed = false;
            const nextSelection: Record<string, true> = {};
            let nextCount = 0;

            if (this.#countKeys !== selection.length) {
                changed = true;
            }

            selection.forEach((item: T | string) => {
                const key = typeof (item) === 'string' ? item : this.getSelectedItemKey(item);
                nextSelection[key] = true;
                nextCount++;

                if (!changed) {
                    if (this.#selection[key]) {
                        return;
                    }
                }
                changed = true;
            });
            if (!changed) {
                return false;
            }
            this.#selection = nextSelection;
            this.#countKeys = nextCount;
        } else {
            const key = typeof (selection) === 'string' ? selection : this.getSelectedItemKey(selection);
            if (this.#countKeys === 1 && this.#selection[key]) {
                return false;
            }

            this.#selection = { [key]: true };
            this.#countKeys = 1;
        }

        this.#cachedKeys = undefined;

        return true;
    }

    remove(selection: T | T[] | string | string[], source: SelectionSource): boolean {
        const changed = this.doRemove(selection);
        if (changed) {
            this.changeState(source);
        }

        return changed;
    }

    protected doRemove(selection: T | T[] | string | string[]) {
        debug('remove', 'selection=', selection);
        if (!this.#countKeys) {
            return false;
        }

        if (Array.isArray(selection)) {
            let changed = false;

            selection.forEach((item: T | string) => {
                const key = typeof (item) === 'string' ? item : this.getSelectedItemKey(item);

                if (!this.#selection[key]) {
                    return;
                }
                if (!changed) {
                    changed = true;
                    this.#selection = { ...this.#selection };
                }
                delete this.#selection[key];
                this.#countKeys--;
            });

            if (!changed) {
                return false;
            }
        } else {
            const key = typeof (selection) === 'string' ? selection : this.getSelectedItemKey(selection);

            if (!this.#selection[key]) {
                return false;
            }

            this.#selection = { ...this.#selection };
            delete this.#selection[key];
            this.#countKeys--;
        }

        this.#cachedKeys = undefined;

        return true;
    }

    clear(source: SelectionSource): boolean {
        const changed = this.doClear();
        if (changed) {
            this.changeState(source);
        }

        return changed;
    }

    protected doClear() {
        debug('clear', 'selection');
        if (!this.#countKeys) {
            return false;
        }
        this.#selection = {};
        this.#cachedKeys = undefined;
        this.#countKeys = 0;

        return true;
    }

    private $list(): string[] {
        if (!this.#countKeys) {
            return [];
        }

        if (!this.#cachedKeys) {
            this.#cachedKeys = Object.keys(this.#selection);
        }

        return this.#cachedKeys;
    }


    list(): string[] {
        if (!this.#countKeys) {
            return [];
        }

        const list = this.$list();

        return [...list];
    }

    $keys(): Record<string, true> {
        if (!this.#countKeys) {
            return {};
        }

        return this.#selection;
    }

    get count(): number {
        return this.#countKeys;
    }

    chainKeys(): CollectionChain<string> {
        return _.chain(this.#selection).keys();
    }

    has(item: T): boolean {
        if (!this.#countKeys) {
            return false;
        }

        const key = this.getSelectedItemKey(item);

        return this.#selection[key] || false;
    }

    hasKey(key: string): boolean {
        if (!this.#countKeys) {
            return false;
        }

        return this.#selection[key] || false;
    }

    first(): string | undefined {
        if (!this.#countKeys) {
            return undefined;
        }

        return this.chainKeys().head().value();
    }
}
