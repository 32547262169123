import React, { useCallback, useRef } from 'react';
import { isEmpty, isNil, values } from 'lodash';
import { defineMessages } from 'react-intl';

import { ArgChangeReason } from '../../../types';
import { parseRadius } from '../../common/helpers/parse-radius';
import { formatRadius } from '../../common/helpers/format-radius';
import { ArgCombo } from '../../../arg-combo/arg-combo';
import { DEFAULT_RADIUS_UNIT, GeoAreaValue, RadiusUnit } from '../../common/model/geolocation-value';
import { ArgInputNumber } from '../../../arg-input/arg-input-number';
import { ClassValue, useClassNames } from '../../../arg-hooks/use-classNames';
import { ProgressMonitor } from '../../../progress-monitors/progress-monitor';
import { LatitudeAndLongitudeResponse } from '../../common/model/geolocation-json';
import { ArgButton } from '../../../arg-button/arg-button';
import { ArgInputText } from '../../../arg-input/arg-input-text';
import { useCallbackAsync } from 'src/components/basic/arg-hooks/use-callback-async';
import { ArgGeoshapeMap, DEFAULT_RADIUS_IN_METTER } from './arg-geoshape-map';
import { useArgNotifications } from '../../../arg-notifications/notifications';
import { geolocationMessages } from '../../common/common';
import { ArgResizableMapContainer } from '../../common/arg-resizable-map-container';
import { MapMode } from './MapMode';

import './arg-geoshape-form.less';

const messages = defineMessages({
    addressPlaceholder: {
        id: 'basic.arg-geo-picker.geoshape-picker.form.addressPlaceholder',
        defaultMessage: 'Search a place or area',
    },
});

interface ArgGeoshapeFormProps {
    className?: ClassValue;
    value?: GeoAreaValue;
    onChange?: (value: GeoAreaValue | ((value: GeoAreaValue | undefined) => GeoAreaValue | undefined) | undefined) => void;
    getAddressCoordinates: (address: string, progressMonitor: ProgressMonitor) => Promise<LatitudeAndLongitudeResponse | undefined>;
    onRadiusUnitChange: (radiusUnit?: RadiusUnit) => void;
    radiusUnit: RadiusUnit;
}

export function ArgGeoshapeForm(props: ArgGeoshapeFormProps) {
    const {
        value,
        className,
        onChange,
        getAddressCoordinates,
        radiusUnit = RadiusUnit.Kilometer,
        onRadiusUnitChange,
    } = props;

    const classNames = useClassNames('arg-geogshape-form');
    const map = useRef(undefined as L.Map | undefined);

    const notifications = useArgNotifications();

    const [handleAddressChange] = useCallbackAsync(async (progressMonitor: ProgressMonitor, address: string | null, reason?: ArgChangeReason): Promise<void> => {
        if (reason === 'clear' || !address) {
            onChange?.(undefined);

            return;
        }

        if (reason === 'blur' || value?.centeredArea?.address === address) {
            return;
        }

        try {
            const addressCoordinates = await getAddressCoordinates(address, progressMonitor);
            if (!addressCoordinates) {
                return;
            }

            onChange?.(prev => {
                const newValue: GeoAreaValue = {
                    centeredArea: {
                        latLng: {
                            lat: addressCoordinates.latitude,
                            lng: addressCoordinates.longitude,
                        },
                        address,
                        radius: prev?.centeredArea?.radius ?? DEFAULT_RADIUS_IN_METTER,
                    },
                };

                return newValue;
            });
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                throw Error;
            }
            notifications.snackError({ message: geolocationMessages.loadAddressError }, error as Error);
            throw Error;
        }
    }, [getAddressCoordinates, notifications, onChange, value?.centeredArea?.address]);

    const handleRadiusChange = useCallback((radius: number | null) => {
        onChange?.(prev => {
            const newValue: GeoAreaValue = {
                centeredArea: {
                    ...prev?.centeredArea!,
                    radius: parseRadius(radius, radiusUnit),
                },
            };

            return newValue;
        });
    }, [onChange, radiusUnit]);

    return (
        <ArgResizableMapContainer map={map} className={className}>
            <div className={classNames('&-header')}>
                {/* Address input */}
                <ArgInputText
                    autoFocus={true}
                    value={value?.centeredArea?.address}
                    placeholder={messages.addressPlaceholder}
                    right={
                        <ArgButton
                            type='ghost'
                            icon='icon-map-marker'
                            // loading={getAddressProgressMonitor?.isRunning}
                            className={classNames('&-header-input-right')}
                        />
                    }
                    onChange={handleAddressChange}
                    data-testid='arg-geoshape-form-address-input'
                    className={classNames('&-header-input', '&-header-input-address')}
                />

                {/* Radius input */}
                <ArgInputNumber
                    min={0}
                    step={1}
                    placeholder={geolocationMessages.radiusPlaceholder}
                    onChange={handleRadiusChange}
                    value={formatRadius(value?.centeredArea?.radius, radiusUnit)}
                    className={classNames('&-header-input', '&-header-input-radius')}
                    left={
                        <span className={classNames('arg-input-left', '&-header-input-left')}>
                            ±
                        </span>
                    }
                    data-testid='arg-geoshape-form-radius-input'
                    disabled={isRadiusAndUnitInputsDisabled(value)}
                />

                <ArgCombo<RadiusUnit>
                    cardinality='one'
                    items={values(RadiusUnit)}
                    onChange={onRadiusUnitChange}
                    initialValue={DEFAULT_RADIUS_UNIT}
                    getItemLabel={(item) => geolocationMessages[item]}
                    value={radiusUnit || DEFAULT_RADIUS_UNIT}
                    placeholder={geolocationMessages.radiusUnitPlaceholder}
                    data-testid='arg-geoshape-form-radius-unit-input'
                    className={classNames('&-header-input', '&-header-input-unit')}
                    disabled={isRadiusAndUnitInputsDisabled(value)}
                />
            </div>
            <div className={classNames('&-body')} data-testid='arg-geoshape-form-map-container'>
                <ArgGeoshapeMap
                    value={value}
                    initialMapMode={MapMode.CenterArea}
                    forwardRef={map}
                    onChange={onChange}
                    className={classNames('&-body-map')}
                />
            </div>
        </ArgResizableMapContainer>
    );
}

function isRadiusAndUnitInputsDisabled(value: GeoAreaValue | undefined): boolean {
    return (isNil(value?.centeredArea?.address) || isEmpty(value?.centeredArea?.address)) && (isNil(value?.centeredArea?.latLng) || isEmpty(value?.centeredArea?.latLng));
}
