import React from 'react';

import { ArgInputNumber, ArgInputNumberProps } from './arg-input-number';


export type ArgInputIntegerProps = Omit<ArgInputNumberProps, 'formatValue' | 'parseValue' | 'onKeyPress' | 'type'>;

export function ArgInputInteger(props: ArgInputIntegerProps) {
    return <ArgInputNumber numberType='integer' {...props} />;
}
