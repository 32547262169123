import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { IAceEditorProps } from 'react-ace';

import { ClassValue, useClassNames } from 'src/components/basic';
import { ArgButton } from '../arg-button/arg-button';
import { ArgAceEditorInput, ArgAceLanguage } from './arg-input-expression-editor';
import { Snippet } from './types';

import './arg-input-expression-snippet-detail.less';

const CLASSNAME = 'arg-input-expression-snippet-detail';

const SNIPPET_ACE_PROPS: IAceEditorProps = {
    showGutter: false,
    readOnly: true,
    fontSize: 14,
};

const messages = defineMessages({
    insertSnippet: {
        id: 'basic.arg-input-expression-snippet-detail.insertSnippet',
        defaultMessage: 'Insert snippet',
    },
});

export interface ArgInputExpressionSnippetDetailProps {
    className?: ClassValue;
    snippet: Snippet;
    language: string | ArgAceLanguage;
    onBackToList?: () => void;
    onInsertSnippet?: (snippet: Snippet) => void;
}

export function ArgInputExpressionSnippetDetail(props: ArgInputExpressionSnippetDetailProps) {
    const { className, snippet, language, onBackToList, onInsertSnippet } = props;
    const classNames = useClassNames(CLASSNAME);

    const handleBackToList = useCallback(() => {
        if (!onBackToList) {
            return;
        }

        onBackToList();
    }, [onBackToList]);

    const handleInsertSnippet = useCallback(() => {
        onInsertSnippet?.(snippet);
    }, [onInsertSnippet, snippet]);

    return (
        <div className={classNames('&', className)} data-testid='arg-input-expression-snippet-detail'>
            <div className={classNames('&-title-container')}>
                <ArgButton
                    size='medium'
                    type='ghost'
                    icon='icon-arrow-thin-left'
                    className={classNames('&-back-icon', 'clamp-2')}
                    onClick={handleBackToList}
                    label={snippet.title}
                    tooltip={snippet.title}
                    data-testid='back-icon'
                />
            </div>
            <div className={classNames('&-body')}>
                <div className={classNames('&-editor-ace-container')}>
                    <ArgAceEditorInput
                        className={classNames('&-editor-ace')}
                        value={snippet.content}
                        language={language}
                        aceProps={SNIPPET_ACE_PROPS}
                        focus={true}
                    />
                </div>
                <div className={classNames('&-action')}>
                    <ArgButton
                        className={classNames('&-insert-snippet-button')}
                        label={messages.insertSnippet}
                        type='ghost'
                        onClick={handleInsertSnippet}
                    />
                </div>
            </div>
        </div>
    );
}
