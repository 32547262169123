import { CaseId } from './folder-case-piece';

// User
export const USER_DND_TYPE = 'application/arg-user';

// Case
export const FOLDER_DND_TYPE = 'application/arg-folder';

export interface FolderDndDataTransfer {
    folderId: CaseId;
    folderName: string;
}

export interface UserDataTransfer {
    [k: string]: string | number | boolean;
}
