import { defineMessages } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { Ace } from 'ace-builds';

import { ArgModal } from '../arg-modal/arg-modal';
import { ArgInputExpressionExpander, ArgInputExpressionExpanderProps } from './arg-input-expression-expander';
import { useClassNames } from '../arg-hooks/use-classNames';

import './arg-input-expression-modal.less';

const messages = defineMessages({
    expressionTitle: {
        id: 'basic.arg-input-expression-modal.Title',
        defaultMessage: 'Edit expression',
    },
    expressionOkButton: {
        id: 'basic.arg-input-expression-modal.Apply',
        defaultMessage: 'Apply',
    },
});

export type ArgInputExpressionModalProps = ArgInputExpressionExpanderProps

export function ArgInputExpressionModal(props: ArgInputExpressionModalProps) {
    const {
        className,
        onCancel,
        onSave,
        language,
        valueEditor,
        cursorStart,
        aceProps,
        ...restProps
    } = props;

    const classNames = useClassNames('arg-input-expression-modal');

    const [cursorExpression, setCursorExpression] = useState<Ace.Point | undefined>(cursorStart);
    const [expressionValue, setExpressionValue] = useState<string | undefined>(valueEditor);

    const saveExpression = useCallback((value: string | undefined) => {
        setExpressionValue(value);
    }, []);

    const handleCursorChange = useCallback((selection: any) => {
        setCursorExpression(selection.cursor.getPosition());
    }, []);

    const handleCancel = useCallback(() => {
        onCancel?.(cursorExpression);
    }, [cursorExpression, onCancel]);

    const handleSubmit = useCallback(() => {
        onSave?.(expressionValue || '', language, cursorExpression);
        handleCancel();
    }, [cursorExpression, expressionValue, handleCancel, language, onSave]);

    useEffect(() => {
        setExpressionValue(valueEditor);
    }, [valueEditor]);

    return (
        <ArgModal
            size='xlarge'
            maskClosable={false}
            centered={true}
            onClose={handleCancel}
            onCancel={handleCancel}
            className={classNames('&', className)}
            title={messages.expressionTitle}
            okText={messages.expressionOkButton}
            onOk={handleSubmit}
            data-testid='expression-expander-modal'
        >
            <ArgInputExpressionExpander
                {...restProps}
                language={language}
                cursorStart={cursorExpression}
                aceProps={{
                    ...aceProps,
                    onCursorChange: handleCursorChange,
                }}
                valueEditor={expressionValue}
                onSave={saveExpression}
                onCancel={onCancel}
            />
        </ArgModal>
    );
}
