import React, { useContext } from 'react';

import { User } from '../model/user';


export interface UseGetMeReturnType {
    me: Readonly<User>;
}

export const UserContext = React.createContext<UseGetMeReturnType | undefined>(undefined);

export function useGetMe(): UseGetMeReturnType {
    const ret = useContext(UserContext)!;

    if (!ret) {
        throw new Error('User is not in context');
    }

    return ret;
}
