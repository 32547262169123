import { useEffect, useState } from 'react';
import Debug from 'debug';
import { Locale } from 'antd/lib/locale-provider';
import enUS from 'antd/lib/locale/en_US';

import { getArgLocale } from './get-locale';
import { en_150 } from './langs/en-150';


const debug = Debug('common:i18n:AntdLocale');

const DEFAULT_LOCALE = 'en-150';

async function loadLocale(localeName: string): Promise<{ readonly default: Locale }> {
    const p = getArgLocale(localeName);
    if (p) {
        return p.getAntdLocale();
    }

    return en_150.getAntdLocale();
}

export function useAntdLocale(localeName: string | undefined): Locale {
    const [locale, setLocale] = useState<Locale>(enUS);

    if (!localeName) {
        localeName = DEFAULT_LOCALE;
        debug('Initialize locale to default', localeName);
    }

    useEffect(() => {
        let install = true;

        debug('Loading locale', localeName);

        loadLocale(localeName!).then((result) => {
            debug('Loaded locale', localeName, result);

            if (!install) {
                return;
            }

            setLocale(result.default);
        }, (error) => {
            console.error(error);
            setLocale(enUS);
        });

        return () => {
            install = false;
        };
    }, [localeName]);

    return locale;
}
