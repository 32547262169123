import EventEmitter3, { EventListener, EventNames, ValidEventTypes } from 'eventemitter3';

export interface StateId {
    id: any;
    url: string;
}

const INITIAL_STATE_ID = {
    id: 0,
    url: '',
};

export interface IEventReceiver<EventTypes extends ValidEventTypes = string | symbol> {
    once<T extends EventNames<EventTypes>>(
        event: T,
        fn: EventListener<EventTypes, T>,
        context?: Context
    ): this;

    on<T extends EventNames<EventTypes>>(
        event: T,
        fn: EventListener<EventTypes, T>,
        context?: Context
    ): this;

    off<T extends EventNames<EventTypes>>(
        event: T,
        fn?: EventListener<EventTypes, T>,
        context?: Context,
        once?: boolean
    ): this;
}

export class EventEmitter<EventTypes extends ValidEventTypes = string | symbol> extends EventEmitter3<EventTypes | '*'> implements IEventReceiver<EventTypes> {
    #stateId: StateId;

    constructor(initialStateId: StateId = INITIAL_STATE_ID) {
        super();

        this.#stateId = initialStateId;
    }

    protected updateStateId() {
        const prev = this.#stateId;

        this.#stateId = {
            ...prev,
            id: prev.id + 1,
        };
    }

    get stateId(): StateId {
        return this.#stateId;
    }

    emit<T extends EventEmitter3.EventNames<EventTypes | '*'>>(
        event: T,
        ...args: EventEmitter3.EventArgs<EventTypes | '*', T>
    ): boolean {
        const ret = super.emit(event, ...args);
        super.emit('*', this.#stateId, event, ...args);

        return ret;
    }
}
