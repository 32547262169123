import { createContext, useContext } from 'react';

import { ArgGlobalNotificationType, GlobalNotificationDescription, NotificationKey } from './types';
import { ArgMessageValues } from '../types';

export const DEFAULT_NOTIFICATIONS: ArgGlobalNotificationType = {
    snackClose(key: NotificationKey): void {
    },
    snackError(description?: GlobalNotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Error', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    snackInfo(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Info', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    snackOpen(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Open', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    snackSuccess(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Success', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    snackWarning(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Warning', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    notifClose(key: NotificationKey): void {
    },
    notifError(description?: GlobalNotificationDescription, error?: Error, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Error', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    notifInfo(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Info', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    notifOpen(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Open', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    notifSuccess(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Success', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
    notifWarning(description: GlobalNotificationDescription, messageValues?: ArgMessageValues): NotificationKey {
        console.error('Warning', 'description=', description, 'messageValues=', messageValues);
        throw new Error('Not initialized');
    },
};

export const ArgNotificationsContext = createContext<ArgGlobalNotificationType>(DEFAULT_NOTIFICATIONS);

let globalNotifications: ArgGlobalNotificationType;

export function getGlobalNotifications(): ArgGlobalNotificationType {
    if (!globalNotifications) {
        throw new Error('Global Notifications is not initialized');
    }

    return globalNotifications;
}

export function initializeGlobalNotifications(_globalNotifications: ArgGlobalNotificationType) {
    globalNotifications = _globalNotifications;
}


export function useArgNotifications(): ArgGlobalNotificationType {
    const argNotification = useContext(ArgNotificationsContext);

    if (argNotification === undefined) {
        throw new Error('useArgNotifications must be used within a ArgNotificationsProvider');
    }

    return argNotification;
}
