import React, { ReactNode } from 'react';
import { defineMessages } from 'react-intl';

import { ClassValue, useClassNames } from 'src/components/basic';

import './arg-divider.less';

const CLASSNAME = 'arg-divider';
const messages = defineMessages({});

export type DividerType = 'horizontal' | 'vertical';

export interface ArgDividerProps {
    className?: ClassValue;
    children?: ReactNode;
    type?: DividerType;
}

export function ArgDivider(props: ArgDividerProps) {
    const {
        className,
        children,
        type = 'horizontal',
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const cls = {
        '&-vertical': type === 'vertical',
        '&-horizontal': type === 'horizontal',
    };

    return (
        <div
            className={classNames('&', cls, className)}
            data-testid='arg-divider'
            role='separator'
        >
            {children}
        </div>
    );
}
