import { useEffect, useRef, useState } from 'react';
import Debug from 'debug';

import { OnChange, SelectionProvider } from './selection-provider';
import { $yield } from '../utils/yield';

const debug = Debug('argonode:components:providers:UseSelection');

function getList<U>(selectionProvider: SelectionProvider<U>): string[] {
    return selectionProvider.list();
}

export function useSelectionList<U = any>(selectionProvider: SelectionProvider<U>): [string[], number] {
    return useSelection(selectionProvider, getList);
}

function StateId<T, U>(selectionProvider: SelectionProvider<U>): T {
    return selectionProvider.stateId as unknown as T;
}

export function useSelection<T, U = any>(selectionProvider: SelectionProvider<U> | undefined, fct: (selectionProvider: SelectionProvider<U>) => T = StateId): [T, number] {
    const [selection, setSelection] = useState<[T, number]>(() => {
        if (!selectionProvider) {
            return [null as unknown as T, 0];
        }

        return [fct(selectionProvider), selectionProvider.count];
    });
    const destroyedRef = useRef<boolean>(false);

    useEffect(() => {
        if (!selectionProvider) {
            return;
        }

        function $setSelection(source: string) {
            if (!selectionProvider) {
                return;
            }
            setSelection((prev) => {
                const t = fct(selectionProvider);

                debug('useSelection', 'Update selection=', t, 'source=', source);

                if (prev?.[0] === t && prev?.[1] === selectionProvider.count) {
                    debug('useSelection', 'no update');

                    return prev;
                }
                debug('useSelection', 'returns update');

                return [t, selectionProvider.count];
            });
        }

        function updateSelection(source: string) {
            $yield(() => {
                if (destroyedRef.current || !selectionProvider) {
                    return;
                }

                $setSelection(source);
            });
        }

        selectionProvider.on(OnChange, updateSelection);

        $setSelection('$init');

        return () => {
            destroyedRef.current = true;
            selectionProvider.off(OnChange, updateSelection);
        };
    }, [selectionProvider, fct]);

    return selection;
}

export function useIsSelected<T>(selectionProvider: SelectionProvider<T> | undefined, me: T): boolean {
    const [itIsMe, setItIsMe] = useState<boolean>(() => {
        return (me && selectionProvider?.has(me)) || false;
    });
    const destroyedRef = useRef<boolean>(false);

    useEffect(() => {
        if (!selectionProvider) {
            return;
        }

        function $setSelection(source: string) {
            const is = me && selectionProvider?.has(me);

            setItIsMe(!!is);
        }

        function updateSelection(source: string) {
            $yield(() => {
                if (destroyedRef.current) {
                    return;
                }
                $setSelection(source);
            });
        }

        selectionProvider.on(OnChange, updateSelection);

        $setSelection('$init');

        return () => {
            selectionProvider.off(OnChange, updateSelection);
        };
    }, [selectionProvider, me]);

    useEffect(() => {
        return () => {
            destroyedRef.current = true;
        };
    }, []);

    return itIsMe;
}
