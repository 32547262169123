import React from 'react';
import { isString } from 'lodash';

import { ArgIcon } from '../arg-icon/arg-icon';

export interface BreadcrumbSeparatorProps {
    separatorColor: string,
    separator: React.ReactNode,
    index: number,
}

export function BreadcrumbSeparator(props: BreadcrumbSeparatorProps) {
    const { separatorColor, separator, index } = props;

    if (index === 0) {
        return null;
    }

    if (isString(separator)) {
        return (
            <ArgIcon
                name={separator}
                color={separatorColor}
                data-testid={`arg-${separator}-breadcrumb-separator`}
            />
        );
    }

    return <>{separator}</>;
}
