import Debug from 'debug';

import { CancelledWorkError, ProgressMonitor } from '../progress-monitors/progress-monitor';

const debug = Debug('basic:utils:AsyncTimeout');

export async function promiseTimeout<T = any>(func: () => Promise<T>, timeMs: number): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        setTimeout(() => {
            func().then(resolve, reject);
        }, timeMs);
    });
}

export async function asyncTimeout(timeMs: number, progressMonitor?: ProgressMonitor): Promise<void> {
    let resolved = false;
    let clearTimeoutHandler: (eventProgressMonitor: ProgressMonitor) => void;

    return new Promise((resolve, reject) => {
        const timerId = setTimeout(() => {
            resolved = true;

            if (progressMonitor) {
                progressMonitor.off('Cancel', clearTimeoutHandler);
                progressMonitor.off('Done', clearTimeoutHandler);

                try {
                    progressMonitor.verifyCancelled();
                } catch (x) {
                    resolved = true;
                    reject(x);

                    return;
                }
            }

            resolve();
        }, timeMs);

        if (progressMonitor) {
            clearTimeoutHandler = (eventProgressMonitor: ProgressMonitor) => {
                if (resolved) {
                    return;
                }

                debug('asyncTimeout', 'Get a clearTimeout from ', eventProgressMonitor, 'ProgressMonitorEventTypes=', progressMonitor);

                clearTimeout(timerId);

                resolved = true;
                reject(new CancelledWorkError(progressMonitor));
            };

            progressMonitor.on('Cancel', clearTimeoutHandler);
            progressMonitor.on('Done', clearTimeoutHandler);
        }
    });
}

export async function waitPromiseValue<T>(value: T, timeMs: number): Promise<T> {
    return new Promise<T>(resolve => {
        setTimeout(() => {
            resolve(value);
        }, timeMs);
    });
}
