import { Placement } from '@floating-ui/react';

export type TooltipPlacement = 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';


// Convert tooltip placement to floating-ui placement.
export function toFloatingUIPlacement(placement: TooltipPlacement): Placement {
    switch (placement) {
        case 'top':
        case 'bottom':
        case 'left':
        case 'right':
            return placement;

        case 'topLeft':
            return 'top-start';

        case 'topRight':
            return 'top-end';

        case 'bottomLeft':
            return 'bottom-start';

        case 'bottomRight':
            return 'bottom-end';

        case 'leftTop':
            return 'left-start';

        case 'leftBottom':
            return 'left-end';

        case 'rightTop':
            return 'right-start';


        case 'rightBottom':
        default:
            return 'right-end';
    }
}
