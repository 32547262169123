import { useCallback, useRef } from 'react';

type LatestCallbackReturnType<T extends (...args: any[]) => any> = (...args: Parameters<T>) => ReturnType<T>;

// This is a hook that let you get the latest version of a callback without adding it to dependency. This is usefull when you need the callback into a useEffect but don't want to react to its changes.
//
//This is the poor man version for the coming (now experimental) useEffectEvent (https://react.dev/reference/react/experimental_useEffectEvent).
export function useLatestCallback(callback: undefined): undefined;
export function useLatestCallback<T extends (...args: any[]) => any>(callback: T): LatestCallbackReturnType<T>;
export function useLatestCallback<T extends (...args: any[]) => any>(callback: T | undefined): LatestCallbackReturnType<T> | undefined;
export function useLatestCallback<T extends (...args: any[]) => any>(callback: T | undefined): LatestCallbackReturnType<T> | undefined {
    const valueRef = useRef(callback);
    valueRef.current = callback;
    const ret = useCallback((...args: Parameters<T>) => {
        return valueRef.current?.(...args);
    }, []);

    return ret;
}
