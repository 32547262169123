import { useEffect, useState } from 'react';
import Debug from 'debug';

import { getArgLocale } from './get-locale';

const debug = Debug('common:i18n:LoadMessages');

export function useLoadMessages(locale: string | undefined): Record<string, string> | undefined | null {
    const [messages, setMessages] = useState<Record<string, string> | undefined | null>();

    useEffect(() => {
        let install = true;

        setMessages(undefined);

        if (!locale) {
            return;
        }

        const argLocale = getArgLocale(locale);
        if (!argLocale) {
            return;
        }

        const p = argLocale.getMessages?.();
        if (!p) {
            setMessages(null);

            return;
        }

        p.then((messages) => {
            if (!install) {
                return;
            }

            debug('loadMessages', 'Messages loaded (', Object.keys(messages).length, ')');

            setMessages(messages);
        }, (error) => {
            console.error('Can not load messages error=', error);
        });

        return () => {
            install = false;
        };
    }, [locale]);

    return messages;
}
