import { ArgUserInfo } from 'src/components/basic';
import { User } from '../model/user';

export function computeDigram(name: string): string {
    if (!name || name.length < 2) {
        return name;
    }

    const sp = name.split(' ');
    if (sp.length > 1) {
        return (sp[0].charAt(0) + sp[1].charAt(0)).toUpperCase();
    }

    const sp2 = /(\w)[^\d]*(\d)/.exec(name);
    if (sp2) {
        return (sp2[1] + sp2[2]).toUpperCase();
    }

    return name.substring(0, 2).toUpperCase();
}

export function computeUserName(user: User | ArgUserInfo | Partial<User>): string {
    if ((user as ArgUserInfo).displayName) {
        const displayName = (user as ArgUserInfo).displayName;

        return displayName!;
    }

    if ((user as User).fullName) {
        const fullName = (user as User).fullName;

        return fullName;
    }

    if ((user as User).userName) {
        const userName = (user as any).userName;

        return userName;
    }

    if ((user as User).firstName) {
        let username = (user as User).firstName;
        if ((user as User).lastName) {
            username += ` ${(user as User).lastName}`;
        }

        return username!;
    }

    const username = (user as ArgUserInfo).id;

    return String(username);
}
