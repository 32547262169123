import { defineMessages } from 'react-intl';

import { NavItem } from '../models/navigation';

export const USERS_AND_GROUPS_ROUTES = '/settings/users-and-groups';
export const GROUPS_ROUTES = '/settings/groups';
export const GROUPS_ACTIVE_ROUTE = `${GROUPS_ROUTES}`;
export const GROUP_ROUTE = `${GROUPS_ROUTES}/:groupId`;

const messages = defineMessages({
    usersAndGroups: {
        id: 'settings.users.menu.title',
        defaultMessage: 'Users and Groups',
    },
});

export const usersNavItem: NavItem[] = [
    {
        path: USERS_AND_GROUPS_ROUTES,
        label: messages.usersAndGroups,
        icon: 'icon-users-and-groups',
        iconSize: 24,
        children: [],
    },
];
