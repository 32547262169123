import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import React, { DragEvent, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { isEmpty, size } from 'lodash';
import Debug from 'debug';

import { getDataTestIdFromProps } from '../utils';
import { ArgUploaderContext } from './arg-uploader-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { UploadMethod, UploadRequestInformation } from './arg-uploader-context-provider';
import { $yield } from '../utils/yield';

import './arg-drag-and-drop-uploader.less';

const debug = Debug('basic:arg-drag-drop:Uploader');

const messages = defineMessages({
    drop: {
        id: 'basic.arg-drag-and-drop-uploader.Drop',
        defaultMessage: 'Drop your files here to upload them',
    },
});

const FILES_DND_TYPE = 'Files';

export interface ArgDragAndDropUploaderProps {
    className?: ClassValue;
    children?: ReactNode;
    information?: UploadRequestInformation;
    disabled?: boolean;
    method: UploadMethod;
    onAccept?: (file: File) => boolean;
//  *** Removed    onDrop?: (files: File[], context: ArgUploaderEngine) => void;
    dropMessage?: MessageDescriptor;
}

export function ArgDragAndDropUploader(props: ArgDragAndDropUploaderProps) {
    const {
        className,
        children,
        disabled,
        //      onDrop,
        onAccept,
        method,
        information,
        dropMessage,
    } = props;

    const intl = useIntl();

    const uploader = useContext(ArgUploaderContext);

    const zone = useRef<HTMLDivElement>(null);

    const [isDragging, setDragging] = useState<boolean>(false);

    const cls = {
        'is-dragging': isDragging,
    };

    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-drag-and-drop-uploader');

    const dragCounter = useRef<number>(0);

    const handleDragIn = useCallback((event: DragEvent<HTMLDivElement>) => {
        if (disabled) {
            return;
        }

        const isFileType = event.dataTransfer.types.includes(FILES_DND_TYPE);
        if (!isFileType) {
            return;
        }

        dragCounter.current++;

        const items = event.dataTransfer.items;

        if (size(items) > 0) {
            $yield(() => {
                setDragging(true);
            });
        }
    }, [disabled]);

    const handleDragOut = useCallback((event: DragEvent) => {
        if (disabled) {
            return;
        }

        const isFileType = event.dataTransfer.types.includes(FILES_DND_TYPE);
        if (!isFileType) {
            return;
        }

        dragCounter.current--;

        if (dragCounter.current === 0) {
            $yield(() => {
                setDragging(false);
            });
        }
    }, [disabled]);

    const handleDragOver = useCallback((event: DragEvent) => {
        if (disabled) {
            return;
        }

        const isFileType = event.dataTransfer.types.includes(FILES_DND_TYPE);
        if (!isFileType) {
            return;
        }

        event.preventDefault();
    }, [disabled]);

    const handleDrop = useCallback((event: DragEvent) => {
        if (disabled) {
            return;
        }

        debug('handleDrop', 'event=', event);

        const isFileType = event.dataTransfer.types.includes(FILES_DND_TYPE);
        if (!isFileType) {
            return;
        }

        event.preventDefault();

        const dataTransfer = event.dataTransfer;
        const files = dataTransfer.files;

        if (isEmpty(files)) {
            $yield(() => {
                setDragging(false);
            });

            return;
        }

        let filteredFiles: File[] = [...files];
        if (onAccept) {
            filteredFiles = filteredFiles.filter(onAccept);
        }

        if (!isEmpty(filteredFiles)) {
            // On drop side effect
            //onDrop?.(filteredFiles, uploader);

            //if (!onDrop) {
            // Send the files to the queue of the uploader
            uploader.add({
                files: filteredFiles,
                information,
                method,
            });
            //}
        }

        dataTransfer.clearData();

        dragCounter.current = 0;

        $yield(() => {
            setDragging(false);
        });
    }, [disabled, information, method, onAccept, uploader]);

    return (
        <div
            ref={zone}
            data-testid={dataTestId}
            onDragEnter={handleDragIn}
            onDragOver={handleDragOver}
            onDragLeave={handleDragOut}
            onDrop={handleDrop}
            className={classNames('&', cls, className)}
            data-label={intl.formatMessage(dropMessage || messages.drop)}
        >
            {children}
        </div>
    );
}
