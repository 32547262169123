import { FormatDateOptions, IntlShape } from 'react-intl';

export interface IncludeTimeDetails {
    includeHours?: boolean;
    includeMinutes?: boolean;
    includeSeconds?: boolean;
}

export type IncludeTime = boolean | IncludeTimeDetails;

const FORMAT_DATE_OPTIONS: FormatDateOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
};

const getDate = (includeTime: IncludeTimeDetails): Date => {
    let date = new Date(2000, 2, 1);
    if (includeTime.includeHours) {
        date = new Date(2000, 2, 1, 10);
    }
    if (includeTime.includeMinutes) {
        date = new Date(2000, 2, 1, 10, 20);
    }
    if (includeTime.includeSeconds) {
        date = new Date(2000, 2, 1, 10, 20, 30);
    }

    return date;
};

const getFormatDateOptions = (includeTime: IncludeTimeDetails): FormatDateOptions => {
    const formatDateOptions: FormatDateOptions = {
        hour: includeTime.includeHours ? 'numeric' : undefined,
        minute: includeTime.includeMinutes ? 'numeric' : undefined,
        second: includeTime.includeSeconds ? 'numeric' : undefined,
        hour12: false,
    };

    return formatDateOptions;
};

export const internalisationDateFormat = (intl: IntlShape, includeTime?: IncludeTime) => {
    const f = intl.formatDate(new Date(2000, 2, 1, 10));
    const dateFormat = f
        .replace('2000', 'YYYY')
        .replace(/3|03/, 'MM')
        .replace(/1|01/, 'DD');

    if (!includeTime) {
        return dateFormat;
    }

    if (includeTime === true) {
        let timeFormat = '';
        const dateTime = new Date(2000, 2, 1, 10, 20, 30);
        timeFormat = intl.formatTime(dateTime, FORMAT_DATE_OPTIONS)
            .replace('10', 'HH')
            .replace('20', 'mm')
            .replace('30', 'ss');

        const finalFormat = `${dateFormat} ${timeFormat}`;

        return finalFormat;
    }

    const date = getDate(includeTime);
    const formatDateOptions = getFormatDateOptions(includeTime);
    let timeFormat = intl.formatTime(date, formatDateOptions);

    if (includeTime.includeHours) {
        timeFormat = timeFormat.replace('10', 'HH');
    }
    if (includeTime.includeMinutes) {
        timeFormat = timeFormat.replace('20', 'mm');
    }
    if (includeTime.includeSeconds) {
        timeFormat = timeFormat.replace('30', 'ss');
    }

    const finalFormat = `${dateFormat} ${timeFormat}`;

    return finalFormat;
};

export const internalisationDatePlaceholder = (intl: IntlShape, includeTime?: IncludeTime) => {
    const dateFormat = internalisationDateFormat(intl, includeTime);

    if (intl.locale === 'fr' || intl.locale === 'fr-FR') {
        const frenchPlaceholder = dateFormat
            .replace('YYYY', 'AAAA')
            .replace('DD', 'JJ');

        return frenchPlaceholder;
    }

    return dateFormat;
};
