import { createContext } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ModuleType } from './modules';

export type ApplicationType =
    | 'Application'
    | ModuleType;

export interface ApplicationDescriptor {
    type: ApplicationType;
    applicationName: string | MessageDescriptor;
    logoURL?: string;
    iconURL?: string;
    color?: string;
    //    documentTitle?: string;
}

export const DEFAULT_APPLICATION_DESCRIPTOR: ApplicationDescriptor = {
    type: 'Administration',
    applicationName: 'Argonos',
};

export const ApplicationDescriptorContext = createContext<ApplicationDescriptor>(DEFAULT_APPLICATION_DESCRIPTOR);
