import { defineMessages } from 'react-intl';
import { icon } from 'leaflet';

import { RadiusUnit } from './model/geolocation-value';

import MakerImage from './assets/marker.svg';

export const geolocationMessages = defineMessages({
    addressShapePlaceholder: {
        id: 'basic.arg-geo-picker.common.form.AdressShapePlaceholder',
        defaultMessage: 'Define a place or area',
    },
    loadAddressError: {
        id: 'basic.arg-geo-picker.common.LoadAddressError',
        defaultMessage: 'Failed to retrieve the corresponding address',
    },
    radiusPlaceholder: {
        id: 'basic.arg-geo-picker.common.form.radius.placeholder',
        defaultMessage: 'Radius',
    },
    radiusUnitPlaceholder: {
        id: 'basic.arg-geo-picker.common.form.radius.unit.placeholder',
        defaultMessage: 'Unit',
    },
    [RadiusUnit.Kilometer]: {
        id: 'basic.arg-geo-picker.common.form.radius.unit.values.kilometer',
        defaultMessage: 'km',
    },
    [RadiusUnit.Meter]: {
        id: 'basic.arg-geo-picker.common.form.radius.unit.values.meter',
        defaultMessage: 'm',
    },
});

export const MarkerIcon: L.Icon<L.IconOptions> = icon(
    {
        iconUrl: MakerImage,
        iconAnchor: [10, 20],
        iconSize: [20, 20],
    }
);
