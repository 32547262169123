import { isObject } from 'lodash';
import { CSSProperties, ReactNode } from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgSize } from '../types';

import './arg-badge.less';

const DEFAULT_SIZE = 'small';
const DEFAULT_COLOR = 'red';

export type ArgBadges = ReactNode | ReactNode[];

export type ArgBadgeLiteralPosition = 'topLeft' | 'top' | 'topRight' | 'right' | 'bottomRight' | 'bottom' | 'bottomLeft' | 'left' | 'center';

export type ArgBadgePixelPosition = { left?: number, right?: number, top?: number, bottom?: number };

export type ArgBadgeDeltaPosition = { literalPosition: ArgBadgeLiteralPosition, deltaX?: number, deltaY?: number };

export type ArgBadgePosition = ArgBadgeLiteralPosition | ArgBadgePixelPosition | ArgBadgeDeltaPosition;

export interface CustomArgBadgeDeltaStyle {
    [key: `--arg-badge-delta-${string}`]: string;
}

export interface ArgBadgeProps {
    className?: ClassValue;
    icon?: string | 'dot' | 'important';
    count?: number;
    style?: CSSProperties & CustomArgBadgeDeltaStyle;
    position: ArgBadgePosition;
    size?: ArgSize;
    color?: 'red' | 'green' | 'dark' | 'blue' | 'yellow' | 'grey' | 'primary';
}

export function ArgBadge(props: ArgBadgeProps) {
    const {
        className,
        icon,
        style,
        position,
        size = DEFAULT_SIZE,
        color = DEFAULT_COLOR,
        count,
    } = props;

    const classNames = useClassNames('arg-badge');

    const label = computeLabelFromCount(count);
    const { iconName, type } = computeIconAndType(count, icon);

    const cls = {
        [`size-${size}`]: true,
        [`color-${color}`]: true,
        [`type-${type}`]: true,
        'is-count': count !== undefined,
    };

    const isAdvancedPosition = isObject(position);

    if (!isAdvancedPosition) {
        cls[`position-${position}`] = true;
    } else if ('literalPosition' in position) {
        cls[`position-${position.literalPosition}`] = true;
    }

    const computedStyle = isAdvancedPosition
        ? { ...style, ...getAdvancedPositionStyle(position) }
        : style;

    return (
        <div
            className={classNames('&', cls, className)}
            style={computedStyle}
            data-testid='arg-badge'
        >
            {(iconName != undefined) && <i className={classNames(iconName, '&-icon')} />}
            {(label !== undefined) && (
                <span className={classNames('&-count')}>
                    {label}
                </span>
            )}
        </div>
    );
}

function computeLabelFromCount(count: number | undefined): string | undefined {
    if (!count) {
        return undefined;
    }

    if (count > 9) {
        return '9+';
    }

    return count.toString();
}

function computeIconAndType(count: number | undefined, icon: string | undefined) {
    if (count !== undefined || !icon || icon === 'dot') {
        return { iconName: undefined, type: 'dot' };
    }

    if (icon === 'important') {
        return { iconName: 'icon-exclamation-point', type: 'important' };
    }

    return { iconName: icon, type: 'icon' };
}


function getAdvancedPositionStyle(position: ArgBadgePosition) {
    const positionStyle: CSSProperties & CustomArgBadgeDeltaStyle = {};

    const manualPosition = position as ArgBadgePixelPosition;
    if (manualPosition.left !== undefined) {
        positionStyle.left = `${manualPosition.left}px`;
    }
    if (manualPosition.right !== undefined) {
        positionStyle.right = `${manualPosition.right}px`;
    }
    if (manualPosition.top !== undefined) {
        positionStyle.top = `${manualPosition.top}px`;
    }
    if (manualPosition.bottom !== undefined) {
        positionStyle.bottom = `${manualPosition.bottom}px`;
    }

    const deltaPosition = position as ArgBadgeDeltaPosition;
    if (deltaPosition.deltaX !== undefined) {
        positionStyle['--arg-badge-delta-left'] = `${-deltaPosition.deltaX}px`;
        positionStyle['--arg-badge-delta-right'] = `${-deltaPosition.deltaX}px`;
    }
    if (deltaPosition.deltaY !== undefined) {
        positionStyle['--arg-badge-delta-top'] = `${-deltaPosition.deltaY}px`;
        positionStyle['--arg-badge-delta-bottom'] = `${-deltaPosition.deltaY}px`;
    }

    return positionStyle;
}
